export const CHECK_INVITATION = `
query checkInvitation($invitation_uid: String!) {
  checkInvitation(invitation_uid: $invitation_uid) {
    __typename
    ... on Invitation {
      id
      its
      uts
      dts
      uid
      tenant_id
      role_id
      user_id
      tenant{
        name
      }
      role{
        name
      }
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;
