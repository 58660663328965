export const CREATE_SEEN_TOUR = `
mutation createSeenTour($tour_id: Int!) {
  createSeenTour(tour_id: $tour_id) {
    __typename
    ... on SeenTourUser {
      tour_id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
