import moment from 'moment';
import { DEFAULT_FORMAT_DATE } from '../../../../shared/MomentJS';

const createDateFormatYYYYMMDD = (fecha: string): string => {
  const año = fecha.slice(0, 4);
  const mes = fecha.slice(4, 6);
  const dia = fecha.slice(6, 8);

  return `${dia}-${mes}-${año}`;
};

const createDateFormatYYYYDDMM = (fecha: string): string => {
  const año = fecha.slice(0, 4);
  const dia = fecha.slice(4, 6);
  const mes = fecha.slice(6, 8);

  return `${dia}-${mes}-${año}`;
};

const createDateFormatDDMMYYYY = (fecha: string): string => {
  const dia = fecha.slice(0, 2);
  const mes = fecha.slice(2, 4);
  const año = fecha.slice(4, 8);

  return `${dia}-${mes}-${año}`;
};

const date = (fecha: string): string => {
  const dateFormatWithoutSeparator = /^\d{8}$/;
  let dateFormated: string | undefined;
  if (dateFormatWithoutSeparator.test(fecha)) {
    const YYYYMMDD = createDateFormatYYYYMMDD(fecha);
    const YYYYDDMM = createDateFormatYYYYDDMM(fecha);
    const DDMMYYYY = createDateFormatDDMMYYYY(fecha);
    const arrayFechas = [YYYYMMDD, YYYYDDMM, DDMMYYYY];

    for (let index = 0; index < arrayFechas.length; index++) {
      dateFormated = formatDate(arrayFechas[index]);
      if (dateFormated && moment(dateFormated, DEFAULT_FORMAT_DATE).isValid()) {
        return dateFormated;
      }
    }
  } else {
    dateFormated = formatDate(fecha);
    if (dateFormated && moment(dateFormated, DEFAULT_FORMAT_DATE).isValid()) {
      return dateFormated;
    }
  }

  return '';
};

const formatDate = (fecha: string): string | undefined => {
  const dateFormatsDDMMYYYY = [
    /\b(\d{1,2})[/.-](\d{1,2})[/.-](\d{2})\b/,
    /\b(\d{1,2})[/.-](\d{2})[/.-](\d{2})\b/,
    /\b(\d{2})[/.-](\d{1,2})[/.-](\d{2})\b/,
    /\b(\d{1,2})[/.-](\d{1,2})[/.-](\d{4})\b/,
    /\b(\d{2})[/.-](\d{1,2})[/.-](\d{4})\b/,
    /\b(\d{1,2})[/.-](\d{2})[/.-](\d{4})\b/,
  ];

  for (const dateFormat of dateFormatsDDMMYYYY) {
    const match = RegExp(dateFormat).exec(fecha);
    if (match) {
      const day = match[1].padStart(2, '0');
      const month = match[2].padStart(2, '0');
      const year = match[3].length === 2 ? `20${match[3]}` : match[3];
      return `${day}-${month}-${year}`;
    }
  }
  const dateFormatsYYYYMMDD =
    /\b(\d{4})[/.-](0?[1-9]|1[0-2])[/.-]([12][0-9]|3[01]|0?[1-9])\b/;

  const matchYYYYMMDD = RegExp(dateFormatsYYYYMMDD).exec(fecha);

  if (matchYYYYMMDD) {
    const day = matchYYYYMMDD[3].padStart(2, '0');
    const month = matchYYYYMMDD[2].padStart(2, '0');
    const year =
      matchYYYYMMDD[1].length === 2
        ? `20${matchYYYYMMDD[1]}`
        : matchYYYYMMDD[1];
    return `${day}-${month}-${year}`;
  }

  const dateFormatsYYYYDDMM =
    /\b(\d{4})[/.-]([12][0-9]|3[01]|0?[1-9])[/.-](0?[1-9]|1[0-2])\b/;

  const matchYYYYDDMM = RegExp(dateFormatsYYYYDDMM).exec(fecha);
  if (matchYYYYDDMM) {
    const day = matchYYYYDDMM[2].padStart(2, '0');
    const month = matchYYYYDDMM[3].padStart(2, '0');
    const year =
      matchYYYYDDMM[1].length === 2
        ? `20${matchYYYYDDMM[1]}`
        : matchYYYYDDMM[1];
    return `${day}-${month}-${year}`;
  }

  return undefined;
};

export default date;
