export const INTEGRATION_SETTING_TENANT = `
query integrationSettingTenant($filter: IntegrationSettingTenantFilterInput!) {
  integrationSettingTenant(filter: $filter) {
    __typename
    ... on IntegrationSettingTenant {
      id
      integration_setting_integration_id
      integration_setting_integration{
        id
        integration_setting{
          id
          name
          description
          translation_key
        }
        integration{
          name
          id
        }
      }
      tenant_id
      value
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;
