export const DOCUMENT_TYPE_BUSINESS_VALIDATIONS = `
query documentTypeBusinessValidations(
  $filter: FilterDocumentTypeBusinessValidationInput,
    $orderBy:  DocumentTypeBusinessValidationOrderInput,
    $searchText: String,
    $skip: Int,
    $take: Int
  ){
    documentTypeBusinessValidations(
      filter: $filter
      orderBy: $orderBy
      searchText:$searchText      
      skip: $skip
      take: $take
    ) {
      __typename
      ... on DocumentTypeBusinessValidation{
        id
        code
        name
        default_value
        document_type_id
        translation_key
        document_type{
          id
          schema_id
          name
        }
        document_type_business_validation_tenant {
          id
          document_type_business_validation_id
          value
          tenant_id
          }
        }
      ... on ResultError{
        status_code
        message
      }
    }
  }
`;
