export const UPSERT_NOTIFICATION_TYPES = `
mutation upsertUserNotificationType($tenant_id: Int!, $input: UserNotificationTypeInput!) {
  upsertUserNotificationType(tenant_id: $tenant_id, input: $input) {
    __typename
    ... on UserNotificationType {
      id
      user_id
      notification_type_id
    }
    ...on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;
