import Icon from '@ant-design/icons';
import { ReactComponent as IconSvg } from '../../assets/Filter.svg';

interface FilterIconProps {
  fill?: string; // para el color de relleno propio del svg
  [key: string]: any; // otras props para pasar al componente Icon
}

export const FilterIcon = ({ fill = '#1C1B1F', ...props }: FilterIconProps) => {
  return <Icon component={IconSvg} {...props} style={{ fill }} />;
};
