import './RegisterPage.less';
import { PageLoading } from '@ant-design/pro-layout';
import { NavContext } from '@ionic/react';
import { Badge, Button, Divider, Form, Input } from 'antd';
import { useContext, useEffect, useState } from 'react';
import queryString from 'query-string';
import { EnumsValues } from '../../enums/EnumsValues';
import { CustomMessage } from '../../hooks';
import { IAppSetting } from '../../interfaces';
import GraphqlService from '../../services/graphql/GraphqlService';
import { ContextApp } from '../../contexts/ContextApp';
import { IFormCreateUserInput } from './interfaces';
import { Tools } from '../../shared';
import { useLocation } from 'react-router';
import { FeatureFlagWrapper } from '../FeatureFlagWrapper/FeatureFlagWrapper';
import { notificationContext } from '../../contexts/NotificationContext';

export const RegisterPage = () => {
  const { navigate } = useContext(NavContext);
  const { t } = useContext(ContextApp);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [passwordRegex, setPasswordRegex] = useState<IAppSetting>();
  const [validateUserByEmail, setValidateUserByEmail] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [queryStringParams, setQueryStringParams] = useState<any>();
  const { Query, customRequest, Mutation } = GraphqlService();
  const { search } = useLocation();
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);

  const { openNotification } = useContext(notificationContext);
  const { getErrorMessage } = CustomMessage();

  const checkPublicRegister = async (): Promise<void> => {
    try {
      const data: IAppSetting = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingNames.UserPublicRegister },
        },
      });
      const isUserRegisterPublic = data.setting_value === 'true';
      if (!isUserRegisterPublic) navigate('/');
    } catch (error) {
      openNotification({
        type: 'error',
        msj: getErrorMessage(error),
        context: 'RegisterPage.checkPublicRegister.1',
      });
    }
  };

  const getRegex = async () => {
    try {
      const data: IAppSetting = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingNames.PasswordRegex },
        },
      });
      setPasswordRegex(() => data);
    } catch (error: any) {
      openNotification({
        type: 'error',
        msj: getErrorMessage(error),
        context: 'RegisterPage.getRegex.1',
      });
    }
  };

  const getSettingValidateUserByEmail = async () => {
    try {
      const data: IAppSetting = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingNames.ValidateUserByEmail },
        },
      });
      setValidateUserByEmail(() => data.setting_value === 'true');
    } catch (error: any) {
      openNotification({
        type: 'error',
        msj: getErrorMessage(error),
        context: 'RegisterPage.getSettingValidateUserByEmail.1',
      });
    }
  };

  const createUser = async (
    value: IFormCreateUserInput & { language_id?: number },
  ) => {
    delete value.repeatPassword;
    const { tenantName, ...input } = value;
    setFormLoading(() => true);
    if (queryStringParams.segment) {
      input.target = queryStringParams.segment;
    }

    if (
      process.env.REACT_APP_TELECOM_URL &&
      window.location.href.includes(process.env.REACT_APP_TELECOM_URL)
    ) {
      input.target = 'telecom';
    }

    try {
      await customRequest({
        mutation: Mutation.createUserPublic,
        variables: {
          input,
          tenantName,
        },
      });
      navigate('/');
      openNotification({
        type: 'success',
        msj: validateUserByEmail
          ? t('message.abm.userCreatedSuccessfullyWithValidationByEmail')
          : t('message.abm.userCreatedSuccessfully'),
        context: 'TableUser.createUser.2',
      });
    } catch (error: any) {
      openNotification({
        type: 'error',
        msj: getErrorMessage(error),
        context: 'RegisterPage.createUser.1',
      });
    }
    setFormLoading(() => false);
  };

  useEffect(() => {
    setQueryStringParams(queryString.parse(search));
  }, [search]);

  useEffect(() => {
    Promise.all([
      checkPublicRegister(),
      getRegex(),
      getSettingValidateUserByEmail(),
    ]).finally(() => setLoading(() => false));
  }, []);

  return (
    <>
      <div className="register-page">
        {loading ? (
          <>
            <PageLoading />
          </>
        ) : (
          <>
            <FeatureFlagWrapper
              featureFlag={EnumsValues.FeatureFlags.registerPublic}
              publicAccess={true}
            >
              <div className="register-page__container">
                <Form layout="vertical" onFinish={createUser}>
                  <h1 className="register-page__container__title">
                    {t('action.createAccount')}
                  </h1>
                  <Form.Item
                    className="register-page_errorMsj"
                    normalize={(value) => value?.trim()}
                    rules={[
                      {
                        required: true,
                        message: t('error.abm.tenantRequired'),
                      },
                      {
                        validator(_, value) {
                          if (!Tools.validateTenantName(value)) {
                            return Promise.reject(
                              t('error.abm.tenantNameFormat'),
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                    name={'tenantName'}
                    label={
                      <label className="register-page__container__label-form">
                        {t('entity.organizationName')}
                      </label>
                    }
                  >
                    <Input
                      placeholder={t('message.placeholder.enterTenant')}
                      maxLength={200}
                    />
                  </Form.Item>
                  <Form.Item
                    className="register-page_errorMsj"
                    normalize={(value) => value?.trim()}
                    rules={[
                      {
                        required: true,
                        message: t('error.abm.firstnameRequired'),
                      },
                    ]}
                    name={'firstname'}
                    label={
                      <label className="register-page__container__label-form">
                        {t('entity.firstname')}
                      </label>
                    }
                  >
                    <Input
                      placeholder={t('message.placeholder.enterFirstname')}
                      maxLength={200}
                    />
                  </Form.Item>
                  <Form.Item
                    className="register-page_errorMsj"
                    normalize={(value) => value?.trim()}
                    rules={[
                      {
                        required: true,
                        message: t('error.abm.lastnameRequired'),
                      },
                    ]}
                    name={'lastname'}
                    label={
                      <label className="register-page__container__label-form">
                        {t('entity.lastname')}
                      </label>
                    }
                  >
                    <Input
                      placeholder={t('message.placeholder.enterLastname')}
                      maxLength={200}
                    />
                  </Form.Item>
                  <Form.Item
                    className="register-page_errorMsj"
                    normalize={(value) => value?.trim()}
                    rules={[
                      {
                        required: true,
                        message: t('error.abm.emailRequired'),
                      },
                      {
                        max: EnumsValues.MaxLengthInputs.SystemUser_Email,
                        //TODO: Resolver traducción
                        message: t('error.abm.maxLengthEmail', {
                          maxLength:
                            EnumsValues.MaxLengthInputs.SystemUser_Email,
                        }),
                      },
                      {
                        type: 'email',
                        message: t('error.abm.emailNotValid'),
                      },
                    ]}
                    name={'email'}
                    label={
                      <label className="register-page__container__label-form">
                        {t('entity.email')}
                      </label>
                    }
                  >
                    <Input
                      placeholder={t('message.placeholder.enterEmail')}
                      maxLength={200}
                    />
                  </Form.Item>
                  {!validateUserByEmail ? (
                    <>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: t('error.abm.enterPassword'),
                          },
                          {
                            validator(_, value) {
                              let regex = new RegExp(
                                String(passwordRegex?.setting_value),
                              );
                              if (!value || regex.test(value)) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(t('error.abm.passwordRequirements')),
                              );
                            },
                          },
                        ]}
                        name="password"
                        label={
                          <label className="register-page__container__label-form">
                            {t('entity.password')}
                          </label>
                        }
                      >
                        <Input.Password
                          autoComplete="password"
                          placeholder={t('message.placeholder.enterPassword')}
                          maxLength={100}
                        />
                      </Form.Item>

                      <Form.Item>
                        <div className="register-page__container__message-regex">
                          <Badge color="#606366" status="default" />
                          {/* //TODO:  Resolver que hacer con la traducción de las descripciones de las settings*/}
                          <p>{passwordRegex?.description}</p>
                        </div>
                      </Form.Item>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: t('error.abm.repeatedPasswordRequired'),
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue('password') === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  t('error.abm.repeatedPasswordNotMatching'),
                                ),
                              );
                            },
                          }),
                        ]}
                        name="repeatPassword"
                        label={
                          <label className="register-page__container__label-form">
                            {t('entity.repeatPassword')}
                          </label>
                        }
                      >
                        <Input.Password
                          placeholder={t('message.placeholder.repeatPassword')}
                          maxLength={200}
                        />
                      </Form.Item>
                    </>
                  ) : null}
                  <Form.Item style={{ marginBottom: 0 }}>
                    <div className="register-page__container__terms">
                      <input
                        type="checkbox"
                        name="acceptTerms"
                        className="register-page__container__terms__checkbox"
                        onChange={(ev) =>
                          setAcceptedTerms(() => ev.target.checked)
                        }
                      />
                      <span className="register-page__container__terms__description">
                        <span>{t('action.readAndAcceptedThe')}</span>
                        <a href="/termsandconditions" target="_blank">
                          {t('entity.termsAndConditions')}
                        </a>
                      </span>
                    </div>
                    <Button
                      className={`${
                        acceptedTerms
                          ? 'register-page__container__button-save'
                          : 'register-page__container__button-save-disabled'
                      } `}
                      loading={formLoading}
                      type="primary"
                      htmlType="submit"
                      disabled={!acceptedTerms}
                    >
                      {t('action.create')}
                    </Button>
                    <Divider className="register-page__container__divider" />
                  </Form.Item>
                </Form>
              </div>
            </FeatureFlagWrapper>
            <div className="register-page__container__buttonGroup-sign-in">
              {t('action.alreadyHaveAccount')}
              <Button
                className="register-page__container__button-sign-in"
                disabled={formLoading}
                type="link"
                onClick={() => navigate(`/login${search || ''}`)}
              >
                {t('action.signIn')}
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};
