export const PERMISSIONS = `
query permissions(
  $filter: FilterPermissionInput
  $orderBy: PermissionOrderInput
  $skip: Int
  $take: Int
  $searchText: String
) {
  permissions(
    filter: $filter
    orderBy: $orderBy
    skip: $skip
    take: $take
    searchText: $searchText
  ) {
    __typename
    ... on Permissions {
      id
      name
      code
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
