export const NOTIFICATION_CATEGORIES = `
query notificationCategories {
  notificationCategories {
    __typename
    ... on NotificationCategory {
      id
      name
      notification_type {
        id
        name
      }
    }
    ...on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;
