import { useContext } from 'react';
import { Button, Card } from 'antd';
import { NavContext } from '@ionic/react';
import './enable2a.less';
import { ContextApp } from '../../../contexts/ContextApp';

const Enable2FA = () => {
  const { navigate } = useContext(NavContext);
  const { t } = useContext(ContextApp);

  return (
    <>
      <Card
        title={`${t('entity.enable')} ${t('entity.authentication')} 2FA`}
        className="eneable2fa__card"
      >
        <p>{t('2FA.description')}</p>
        <Button
          type="primary"
          onClick={() => {
            navigate('/2fa/generate');
          }}
        >
          {t('2FA.enable')}
        </Button>
      </Card>
    </>
  );
};

export default Enable2FA;
