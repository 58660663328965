import './AlertCard.less';

interface IContactCardProps {
  icon: JSX.Element;
  title: string;
  description: string;
  subDescription?: string;
  button?: JSX.Element;
}

export const AlertCard = (props: IContactCardProps) => {
  const { icon, title, description, button, subDescription } = props;

  return (
    <div className="AlertCard-container">
      <div className="AlertCard-container--information">
        {icon}
        <div>
          <p className="AlertCard-container--information--title">{title}</p>
          <p className="AlertCard-container--information--description">
            {description} <br/>
            {subDescription}
          </p>
        </div>
      </div>
      {button}
    </div>
  );
};
