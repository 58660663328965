import './DisabledComponentWrapper.less';
interface DisabledComponentWrapperProps {
  children: JSX.Element;
  disabled: boolean;
}

const DisabledComponentWrapper = (props: DisabledComponentWrapperProps) => {
  const { children, disabled } = props;
  return (
    <div className={disabled ? 'disabled-component--disabled' : ''}>
      {children}
    </div>
  );
};

export default DisabledComponentWrapper;
