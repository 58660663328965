import { useContext } from 'react';
import { Button, Card, Modal } from 'antd';
import GraphqlService from '../../../services/graphql/GraphqlService';
import { NavContext } from '@ionic/react';
import { IUser } from '../../../interfaces';
import { ContextApp } from '../../../contexts/ContextApp';
import { CustomMessage } from '../../../hooks';
import { EnumsValues } from '../../../enums/EnumsValues';
import { notificationContext } from '../../../contexts/NotificationContext';

const Disable2FA = () => {
  // services and hooks
  const { Mutation, customRequest } = GraphqlService();
  const { openNotification } = useContext(notificationContext);
  const { getErrorMessage } = CustomMessage();

  // contexts
  const { setUser, t } = useContext(ContextApp);
  const { navigate } = useContext(NavContext);

  const disableConfirmedUser = async () => {
    try {
      const data = await customRequest({
        mutation: Mutation.disableTwoFactorAuth,
      });
      if (data.status) {
        setUser((oldState: IUser) => {
          let newState = JSON.parse(JSON.stringify(oldState));
          newState['two_factor_auth_active'] = false;
          return newState;
        });
        localStorage.setItem(
          EnumsValues.LocalStorageKeys.TwoFactorValidated,
          EnumsValues.TwoFactorValidatedValues.False,
        );
        navigate('/');
        openNotification({
          type: 'success',
          msj: t('message.2faDisabledSuccess'),
          context: 'Disable2FA.disableConfirmedUser.1',
        });
      } else {
        openNotification({
          type: 'error',
          msj: t('error.backend.2faDisabledError'),
          context: 'Disable2FA.disableConfirmedUser.2',
        });
      }
    } catch (error: any) {
      openNotification({
        type: 'error',
        msj: getErrorMessage(error),
        context: 'Disable2FA.disableConfirmedUser.3',
      });
    }
  };

  const disableAuth = () => {
    Modal.confirm({
      content: (
        <>
          <div>{t('2FA.disableConfirmation')}</div>
        </>
      ),
      cancelText: t('action.cancel'),
      okText: t('entity.disable'),
      maskClosable: true,
      onOk: () => {
        disableConfirmedUser();
      },
    });
  };

  return (
    <>
      <Card title={t('2FA.disableTitle')} style={{ width: '100%' }}>
        <p style={{ marginBottom: '30px' }}>{t('2FA.enabledDescription')}</p>
        <Button type="primary" danger onClick={disableAuth}>
          {t('2FA.disable')}
        </Button>
      </Card>
    </>
  );
};

export default Disable2FA;
