export const EXTRACTION_LOG = `
query extractionLog(
  $receipt_header_id: Int!
  ) {
  extractionLog(receipt_header_id: $receipt_header_id) {
  __typename
  ... on ExtractionLog{
  id
  receipt_header_id,
  json_response,
  its
  }
  ... on ResultError {
  status_code
  message
  }
  }
  }`;
