import { useContext } from 'react';
import { ContextApp } from '../../../contexts/ContextApp';
import Status2FA from '../../../components/totp2FA/Status2FA/Status2FA';
import Enable2FA from '../../../components/totp2FA/Enable2FA/Enable2FA';
import Disable2FA from '../../../components/totp2FA/Disable2FA/Disable2FA';

const Config2FA = () => {
  const { user } = useContext(ContextApp);

  return (
    <>
      <Status2FA status={user?.two_factor_auth_active} />
      {user?.two_factor_auth_active ? <Disable2FA /> : <Enable2FA />}
    </>
  );
};

export default Config2FA;
