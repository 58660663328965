export const TENANT_WITH_RECEIPT_OR_DECISION_ITEM_INCOMPLETE = `
query tenantWithReceiptOrDesicionItemIncomplete($tenant_id:Int!) {
    tenantWithReceiptOrDesicionItemIncomplete(tenant_id:$tenant_id){
      __typename
      ... on BooleanResult {
        result
      }
      ... on ResultError {
        status_code
        message
        message_translation_key
      }
    }
  }
`;

export const TENANT_WITH_ACTIVE_INTEGRATION_INCOMPLETE = `
query tenantWithActiveIntegrationIncomplete($tenant_id:Int!) {
    tenantWithActiveIntegrationIncomplete(tenant_id:$tenant_id){
      __typename
      ... on BooleanResult {
        result
      }
      ... on ResultError {
        status_code
        message
        message_translation_key
      }
    }
  }
  
`;
