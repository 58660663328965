import { Input } from 'antd';
import { FormInstance } from 'antd/es/form';
import { useContext, useEffect, useMemo } from 'react';
import { ContextApp } from '../../../contexts/ContextApp';
import { ITenant } from '../../../interfaces';
import { ITaxType } from '../../../interfaces/Tenant';
import { ExportableColumn } from '../../../shared/Exporter';

interface IUseTenantTaxNumberProps {
  selectedTaxType: number | undefined;
  taxTypesCombo: ITaxType[];
  formColumnName?: string;
  formColumnTitle?: string;
  required?: boolean;
  formInstance?: FormInstance;
}

export const useTenantColumnTaxNumber = (props: IUseTenantTaxNumberProps) => {
  const {
    selectedTaxType,
    taxTypesCombo,
    formColumnName,
    formColumnTitle,
    required = false,
    formInstance,
  } = props;
  const { t } = useContext(ContextApp);
  useEffect(() => {
    if (formInstance && selectedTaxType)
      formInstance.validateFields([formColumnName || 'tax_number']);
  }, [selectedTaxType]);

  const columnTaxNumber: ExportableColumn<ITenant> = useMemo(
    () => ({
      export: true,
      dataIndex: formColumnName || 'tax_number',
      title: formColumnTitle || t('entity.tax_number'),
      render: (_, record) => record.tax_number || '-',
      formItemProps: {
        normalize: (value: string) => value?.trimLeft(),
        label:`${t('entity.tax_number')} ${t('action.optional')}`,
        rules: [
          {
            validator: (_, value: string) => {
              if (!selectedTaxType) return Promise.resolve();
              const {
                regular_expression: regex,
                error_key,
                error_message,
              } = taxTypesCombo.find((type) => type.id === selectedTaxType)!;

              if (!RegExp(regex).test(value)) {
                return Promise.reject(t(error_key as never) || error_message);
              }
              return Promise.resolve();
            },
          },
          {
            required: required || !!selectedTaxType,
            message: t('tenantEditPage.taxNumberRequired'),
          },
        ],
      },
      renderFormItem: () => (
        <Input
          disabled={!selectedTaxType}
          placeholder={`${t('action.input.enter')} ${t(
            'entity.tax_number',
          ).toLocaleLowerCase()}`}
        />
      ),
      align: 'left',
      hideInTable: false,
      hideInSearch: true,
      hideInForm: false,
    }),
    [selectedTaxType, taxTypesCombo],
  );
  return { columnTaxNumber };
};
