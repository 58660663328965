export const RECEIPT_CONFIGURATIONS = `
query receiptConfigurations(
    $filter: FilterReceiptConfigurationInput,
    $orderBy: ReceiptConfigurationOrderInput
  ){
    receiptConfigurations(
      filter:$filter, 
      orderBy:$orderBy
    ){
      __typename
      ... on ReceiptConfiguration{
        id
        json_receipt_header
        json_sender
        json_receipt_detail_product
        json_receipt_detail_discount
        json_concept_tax
        json_concept_discount
      }
    }
  }
  `;

export const RECEIPT_CONFIGURATION_BY_RECEIPT = `
query receiptConfigurationByReceipt($receipt_header_id: Int!){
  receiptConfigurationByReceipt(receipt_header_id: $receipt_header_id){
    __typename
    ... on  ReceiptConfiguration{
      id
      receipt_type_id
      json_receipt_header
      json_sender
      json_receipt_detail_product
      json_receipt_detail_discount
      json_concept_tax
      json_concept_discount
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}`;

export const RECEIPT_CONFIGURATION_BY_RECEIPT_TYPE = `
query receiptConfigurationByReceiptType($receipt_type_id: Int!, $tenant_id: Int!){
  receiptConfigurationByReceiptType(receipt_type_id:$receipt_type_id,  tenant_id: $tenant_id){
    __typename
    ... on  ReceiptConfiguration{
      id
      receipt_type_id
      json_receipt_header
      json_sender
      json_receipt_detail_product
      json_receipt_detail_discount
      json_concept_tax
      json_concept_discount
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}`;
