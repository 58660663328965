import { CloseX } from '../../../Icons/CloseX';
import './FilterTag.less';

interface FilterTagProps {
  name: string;
  value: string;
  onCleanFilter: (name: string, value: string) => void;
}
const FilterTag = (props: FilterTagProps) => {
  const { name, value, onCleanFilter } = props;
  return (
    <div className="filter-tag">
      <div className="filter-tag__name">{value}</div>
      <CloseX
        className="filter-tag__icon"
        onClick={() => onCleanFilter(name, value)}
      />
    </div>
  );
};

export default FilterTag;
