import { useContext } from 'react';
import { ContextApp } from '../../../../contexts/ContextApp';
import { MenuTrigger } from '../../MenuTrigger';
import './index.less';

type IColorOption = 'white' | 'blue';
interface ILogoComponent {
  color?: IColorOption;
}

export const LogoComponent = (props: ILogoComponent) => {
  const { color = 'white' } = props;
  const { mainMenuCollapsed } = useContext(ContextApp);
  if (color === 'blue') {
    return (
      <div className="LogoComponent" style={{width:"100px"}}>
        {mainMenuCollapsed ? null : (<MenuTrigger />)}
        <img src="assets/icon/logo_braulio_color.png" alt="logo" />
      </div>
    );
  }

  return (
    <div className="LogoComponent">
      {mainMenuCollapsed ? null : (<MenuTrigger />)}
      <img src="assets/icon/logo_braulio.png" className="menu-logo" alt="logo" />
    </div>
  );
};
