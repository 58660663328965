import {
  FieldAttributeTenant,
  SchemaField,
  UpdateFieldAttributeTenantInput,
} from '../../../interfaces/ValidationSchema';

const getAllFieldAttributeTenants = (
  schemaFields: SchemaField[],
): FieldAttributeTenant[] => {
  return schemaFields.flatMap((schemaField): FieldAttributeTenant[] => {
    if (schemaField.field) {
      if (schemaField.field.field_type.code !== 'object') {
        return schemaField.field.field_attribute_tenant ?? [];
      } else {
        return getAllFieldAttributeTenants(
          schemaField.schema_field as SchemaField[],
        );
      }
    } else {
      return [];
    }
  });
};

export const getCustomFieldAttributeTenants = (
  schemaFields: SchemaField[],
): UpdateFieldAttributeTenantInput[] => {
  const attributes = getAllFieldAttributeTenants(schemaFields);
  const filteredAttributes = attributes.filter((item) => item.tenant_id);

  // TODO: cuando permitamos cambiar la configuracion de la regexp, hay que agregar el campo "configuration" aca
  const pruneAttributes = filteredAttributes.map(
    ({
      field_id,
      attribute_type_id,
      value,
      configuration,
    }: FieldAttributeTenant) => ({
      field_id,
      attribute_type_id,
      value,
      configuration,
    }),
  );

  return pruneAttributes;
};
