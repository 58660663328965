import { ReloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useContext } from 'react';
import { ContextApp } from '../../../contexts/ContextApp';
import MomentTimezoneService from '../../../services/moment-timezone/MomentTimezoneService';
import './AvailableQuotaCard.less';

type QuotaCardTypes = '1' | '2';

interface IAvailableQuotaCardProps {
  version: QuotaCardTypes;
}

export const AvailableQuotaCard = (props: IAvailableQuotaCardProps) => {
  const { version } = props;
  const {
    t,
    availableQuota,
    tenantsAssociatedToUser,
    selectedTenantId,
    getAvailableQuota,
    notifications,
  } = useContext(ContextApp);
  const { getDate } = MomentTimezoneService();

  const selectedTenant = tenantsAssociatedToUser.find(
    (tenant) => tenant.id === selectedTenantId,
  );

  const selectAvailableQuota = (versionSelected: QuotaCardTypes) => {
    if (versionSelected === '1') {
      return (
        <>
          <p>{t('ReceiptTray.maxAvailableQuota')}</p>
          {
            <span>
              {notifications?.quotaIsExpired ? 0 : availableQuota || 0}
            </span>
          }
          <Button
            type="link"
            shape="circle"
            icon={<ReloadOutlined style={{ fontSize: 18 }} />}
            onClick={() => getAvailableQuota(selectedTenantId)}
          />
        </>
      );
    }
    return (
      <>
        <p>{t('ReceiptTray.availableQuota')}</p>
        {<span>{notifications?.quotaIsExpired ? 0 : availableQuota || 0}</span>}
      </>
    );
  };

  return (
    <div className="AvailableQuotaCard-container">
      <div className="AvailableQuotaCard-container--info">
        <div className="AvailableQuotaCard-container--info__flex">
          {selectAvailableQuota(version)}
        </div>
        {selectedTenant?.receipts_quota_tenant?.quota_expiration_date && (
          <>
            <div className="AvailableQuotaCard-container--info__flex">
              <p>{t('ReceiptTray.expiration')}</p>
              <span>
                {getDate({
                  element:
                    selectedTenant.receipts_quota_tenant.quota_expiration_date,
                })}
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
