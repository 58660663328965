export const CREATE_SIGNATURE = `
mutation createSignature($input: SignatureInput!) {
  createSignature(input: $input) {
    __typename
    ... on Signature {
      encoding
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const DELETE_SIGNATURE = `
mutation deleteSignature($id: Int!) {
  deleteSignature(id: $id) {
    __typename
    ... on Signature {
      id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
