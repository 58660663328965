import { Col } from 'antd';
import ChangePdfPage from '../ChangePdfPage/ChangePdfPage';
import { useLayoutEffect, useRef } from 'react';
import { EnumsValues } from '../../../../../../enums/EnumsValues';

interface IPageChangerProps {
  pages: number;
  actualPageNro: number;
  setActualPageNro: (pageNumber: number) => void;
}

enum PageChangerAction {
  LEFT = 'left',
  RIGHT = 'right',
}

const PageChanger = (props: IPageChangerProps) => {
  const { pages, actualPageNro, setActualPageNro } = props;

  const hasPagesToLeft = useRef(false);
  const hasPagesToRight = useRef(false);
  const changePage = ({
    pages,
    currentPageNumber,
    action,
  }: {
    pages: number;
    currentPageNumber: number;
    action: PageChangerAction.LEFT | PageChangerAction.RIGHT;
  }) => {
    let newPage = currentPageNumber;
    if (
      currentPageNumber > EnumsValues.ConstNumbers.one &&
      action === PageChangerAction.LEFT
    ) {
      newPage = currentPageNumber - EnumsValues.ConstNumbers.one;
    } else if (
      currentPageNumber < pages &&
      action === PageChangerAction.RIGHT
    ) {
      newPage = currentPageNumber + EnumsValues.ConstNumbers.one;
    }

    setActualPageNro(newPage);
  };

  useLayoutEffect(() => {
    hasPagesToLeft.current = actualPageNro > EnumsValues.ConstNumbers.one;
    hasPagesToRight.current =
      actualPageNro !== EnumsValues.ConstNumbers.negativeOne &&
      actualPageNro < pages;
  }, [actualPageNro, pages]);

  return (
    <Col
      span={24}
      className="canvas-nextPage-btn"
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div className="btn-group-pageinfo">
        <ChangePdfPage
          disabled={!hasPagesToLeft}
          onClick={() =>
            changePage({
              pages,
              currentPageNumber: actualPageNro,
              action: PageChangerAction.LEFT,
            })
          }
          icon="left"
        />

        <div>
          {actualPageNro} / {pages}
        </div>
        <ChangePdfPage
          disabled={!hasPagesToRight}
          onClick={() =>
            changePage({
              pages,
              currentPageNumber: actualPageNro,
              action: PageChangerAction.RIGHT,
            })
          }
          icon="right"
        />
      </div>
    </Col>
  );
};
export default PageChanger;
