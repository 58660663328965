import { Modal } from 'antd';
import ButtonTertiary from '../../../components/common/Buttons/ButtonTertiary';
import ButtonSecondary from '../../../components/common/Buttons/ButtonSecondary';
import ButtonAction from '../../../components/common/Buttons/ButtonAction';
import { useContext } from 'react';
import { ContextApp } from '../../../contexts/ContextApp';
import './WarningModal.less';

interface WarningModalProps {
  isVisible: boolean;
  onStay: () => void;
  onExitWithoutSaving: () => void;
  onSaveAndExit: () => void;
}

const WarningModal = (props: WarningModalProps) => {
  const { isVisible, onStay, onExitWithoutSaving, onSaveAndExit } = props;

  const { t } = useContext(ContextApp);

  return (
    <Modal
      destroyOnClose
      title={
        <div className="modal-content__title">
          <span className="material-symbols-outlined receipt-exclamation-icon">
            error
          </span>
          {t('unsavedChangesModal.title')}
        </div>
      }
      open={isVisible}
      onCancel={onStay}
      footer={null}
      className="unsaved-changes-modal"
    >
      <div className="modal-content">
        <p className="modal-content__text">
          {t('unsavedChangesModal.paragraph')}
        </p>
        <div className="modal-content__actions">
          <ButtonTertiary onClick={onStay}>{t('action.return')}</ButtonTertiary>
          <ButtonSecondary onClick={onExitWithoutSaving}>
            {t('action.exitWithoutSaving')}
          </ButtonSecondary>
          <ButtonAction onClick={onSaveAndExit}>
            {t('action.saveAndExit')}
          </ButtonAction>
        </div>
      </div>
    </Modal>
  );
};

export default WarningModal;
