export const TENANT_EMAIL_RECEIPTS = ` 
query tenantEmailReceipts(
    $filter: FilterTenantEmailReceiptInput!
    $orderBy: TenantEmailReceiptOrderInput
    $skip: Int
    $take: Int
    $searchText: String,
  ) {
    tenantEmailReceipts(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take, searchText: $searchText) {
      __typename
      ... on TenantEmailReceipt {
        id
        tenant_id
        email_or_domain
        is_domain
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
  `;

  export const CHECK_TENANT_BUY = ` 
  query checkTenantBuy(
      $tenant_id: Int!,
    ) {
      checkTenantBuy(tenant_id: $tenant_id) {
        __typename
        ... on BooleanResult {
          result
        }
        ... on ResultError {
          status_code
          message
        }
      }
    }
    `;