import ProTable, { ActionType } from '@ant-design/pro-table';
import { Badge, Button, Input, Modal, Select, Tooltip } from 'antd';
import { SaveForm } from '../../components/common/ABM';
import {
  useContext,
  useCallback,
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  useMemo,
} from 'react';
import { ExportableColumn } from '../../shared/Exporter';
import { SorterResult } from 'antd/lib/table/interface';
import GraphqlService from '../../services/graphql/GraphqlService';
import { Authorization, Tools, ABM } from '../../shared';
import ModalConfigureMultiple from '../../components/common/ModalConfigureMultiple/Index';
import { IRole } from '../../interfaces/role';
import { IAppSetting } from '../../interfaces/AppSetting';
import { isPlatform } from '@ionic/react';
import { IUser } from '../../interfaces/user';
import { CustomMessage } from '../../hooks';
import { ContextApp } from '../../contexts/ContextApp';
import { EnumsValues } from '../../enums/EnumsValues';
import useProTableForMobile from '../../hooks/useProTableForMobile';
import './User.less';
import moment from 'moment-timezone';
import ModalNotifications from '../../components/common/ModalNotifications/Index';
import { NotificationCategory } from '../../interfaces/NotificationCategory';
import { UserNotificationType } from '../../interfaces/UserNotificationType';
import { notificationContext } from '../../contexts/NotificationContext';
import ToolbarFilterOrder from '../../components/common/ToolbarFilterOrder/ToolbarFilterOrder';

/**
 * Configure manualmente los campos de filtrado
 */

// dataIndex de las columnas que se desean mostrar como info en la busqueda por texto
const LIST_FILTER_LABELS = ['firstname', 'lastname', 'email', 'role'];
const LIST_SORTER = ['firstname', 'lastname'];

export default function UserPage(props: any) {
  // props

  // states
  const {
    functions,
    languages,
    user,
    setUser,
    t,
    selectedTenantId,
    tenantsAssociatedToUser,
  } = useContext(ContextApp);
  const [searchText, setSearchText] = useState('');
  const [updateModalVisible, handleUpdateModalVisible] =
    useState<boolean>(false);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [invitationModalVisible, setInvitationModalVisible] = useState(false);
  const [manageRolesModalVisible, setManageRolesModalVisible] = useState(false);
  const [manageNotificationsModalVisible, setManageNotificationsModalVisible] =
    useState(false);
  const [editForm, setEditFormValues] = useState<any>({});
  const [dataManageRoles, setDataManageRoles] = useState<IUser>({
    id: 0,
    password: '',
    email: '',
    firstname: '',
    lastname: '',
    status: 0,
    roles: [],
    is_system_user: false,
    two_factor_auth_active: false,
    validated: false,
    last_tenant_id: 0,
  });
  const [userIdNotificationsModal, setUserIdNotificationsModal] =
    useState<number>();
  const [formLoading, setFormLoading] = useState(false);
  const [sorter, setSorter] = useState<string>('');
  const [dataUserCombo, setDataUserCombo] = useState<IUser[]>([]);
  const [dataRoles, setDataRoles] = useState<IRole[]>([]);
  const [passwordRegex, setPasswordRegex] = useState<IAppSetting>();
  const [validateUserByEmail, setValidateUserByEmail] =
    useState<boolean>(false);
  const [defaultDateFormat, setDefaultDateFormat] = useState<string>();
  const [defaultDateTimeFormat, setDefaultDateTimeFormat] = useState<string>();
  const [defaultTimezone, setDefaultTimezone] = useState<string>();
  const [notificationCategories, setNotificationCategories] = useState<
    NotificationCategory[]
  >([]);
  const [userNotificationTypes, setUserNotificationTypes] = useState<
    UserNotificationType[]
  >([]);

  // services and hooks
  const { mobileOnSizeChangeProTable, showComponent } = useProTableForMobile({
    layout: 'horizontal',
  });
  const { Query, Mutation, customRequest } = GraphqlService();
  const { openNotification } = useContext(notificationContext);
  const { getErrorMessage } = CustomMessage();

  // refs
  const actionRef = useRef<ActionType>();
  const variables = useRef<any>({});
  const reloadUserCombo = useRef<boolean>(true);
  const customParamsRef = useRef<any>({});

  const newUrlParams: { [key: string]: string } = {};
  const newUrlParamsArr: string[][] = props.location.search
    .replace('?', '')
    .split('&')
    .map((param: string) => param.split('='));
  newUrlParamsArr.forEach((param: string[]) => {
    if (param[1]) {
      newUrlParams[param[0]] = param[1];
    }
  });

  const [defaultRoleValue, setDefaultRoleValue] =
    useState<number | undefined>();

  useEffect(() => {
    setDefaultRoleValue(() => Number(newUrlParams.role_id) || undefined);
  }, [newUrlParams.role_id]);

  // contexts

  // methods

  /**
   * Se configura por cada ABM diferente
   */
  const TITLE_CREATE_FORM = `${t('action.create')} ${t(
    'entity.user',
  ).toLocaleLowerCase()}`;
  const TITLE_UPDATE_FORM = `${t('action.modify')} ${t(
    'entity.user',
  ).toLocaleLowerCase()}`;
  const TITLE_INVITATION_FORM = `${t('action.create')} ${t(
    'entity.invitation',
  ).toLocaleLowerCase()}`;

  const handleSearch = (value: string) => {
    setSearchText(value);
    if (actionRef.current?.reloadAndRest) {
      actionRef.current.reloadAndRest();
    }
  };

  const getRoles = async () => {
    try {
      const data: IRole[] = await customRequest({
        query: Query.roles,
      });
      setDataRoles(() => data);
      return {
        current: 1,
        data: data,
        pageSize: '1',
        success: true,
        total: data.length,
      };
    } catch (error) {
      openNotification({
        type: 'error',
        msj: getErrorMessage(error),
        context: 'UserPage.getRoles.1',
      });
      return {
        current: 1,
        data: [],
        pageSize: '1',
        success: true,
        total: 0,
      };
    }
  };

  const getNotificationCategories = async () => {
    try {
      const data = await customRequest({
        query: Query.notificationCategories,
      });
      setNotificationCategories(() => data);
      return {
        current: 1,
        data: data,
        pageSize: '1',
        success: true,
        total: data.length,
      };
    } catch (error) {
      openNotification({
        type: 'error',
        msj: getErrorMessage(error),
        context: 'UserPage.getNotificationCategories.1',
      });
      return {
        current: 1,
        data: [],
        pageSize: '1',
        success: true,
        total: 0,
      };
    }
  };

  const getUserNotificationTypes = async (userId: number) => {
    try {
      const data = await customRequest({
        query: Query.userNotificationTypes,
        variables: {
          user_id: userId,
          tenant_id: selectedTenantId,
        },
      });
      setUserNotificationTypes(() => data);
      return {
        current: 1,
        data: data,
        pageSize: '1',
        success: true,
        total: data.length,
      };
    } catch (error) {
      openNotification({
        type: 'error',
        msj: getErrorMessage(error),
        context: 'UserPage.getUserNotificationTypes.1',
      });
      return {
        current: 1,
        data: [],
        pageSize: '1',
        success: true,
        total: 0,
      };
    }
  };

  useEffect(() => {
    getRegex();
    getNotificationCategories();
    getSettingValidateUserByEmail();
    getRoles();
    getSettingDefaultValueDateFormat();
    getSettingDefaultValueDateTimeFormat();
    getSettingDefaultValueTimezone();
  }, []);

  const renderFilterText = () => {
    const searchProTableCollection = document.getElementsByClassName(
      'user-pro-table-search',
    );

    if (!searchProTableCollection.length) {
      return;
    }

    const searchProTable = searchProTableCollection[0];

    const filterTextDivClassname = 'user-pro-table-search-filter-text';

    const div = document.createElement('div');
    div.innerText = t('action.filters');
    div.className = filterTextDivClassname;

    let filterTextFound = false;

    for (
      let index = EnumsValues.ConstNumbers.zero;
      index < searchProTable.children.length;
      index++
    ) {
      const element = searchProTable.children[index];
      if (element.className === filterTextDivClassname) {
        filterTextFound = true;
        break;
      }
    }

    if (!filterTextFound) searchProTable.prepend(div);
  };
  useLayoutEffect(() => {
    renderFilterText();
  }, []);

  const roleFilters: string[] = useMemo(
    () => [
      EnumsValues.SystemRolesNames.TenantAdmin,
      EnumsValues.SystemRolesNames.TenantUser,
      EnumsValues.SystemRolesNames.APIPublic,
    ],
    [EnumsValues],
  );

  const exclusiveFilters: string[] = useMemo(
    () => [EnumsValues.SystemRolesNames.APIPublic],
    [EnumsValues],
  );

  const request = async (params: any) => {
    delete variables.current.filter;
    delete variables.current.orderBy;
    variables.current = {};
    variables.current.filter = {};

    if (defaultRoleValue && !params.role_id) {
      params.role_id = defaultRoleValue;
    }

    const search: any = ABM.valuesResult(params);
    if (searchText) {
      variables.current.searchText = searchText;
    } else {
      delete variables.current.searchText;
    }

    if (customParamsRef.current && customParamsRef.current.filter) {
      Object.entries(customParamsRef.current.filter).forEach(([key, value]) => {
        if (
          Tools.isDefined(value) &&
          (!Array.isArray(value) || value.length > 0)
        ) {
          variables.current.filter[key] = value;
        }
      });
    }

    LIST_SORTER.forEach((element) => {
      try {
        if (search.sorter[element]) {
          if (!variables.current.orderBy) {
            variables.current.orderBy = {};
          }
          variables.current.orderBy.direction =
            Tools.getTypeOrderByTableSortParam(search.sorter[element]);
          variables.current.orderBy.field = element;
        }
      } catch (error) {
        // este error esta contemplado porque seguro el filtro que busca no se encuentra
      }
    });

    variables.current.filter.tenants = selectedTenantId
      ? [selectedTenantId]
      : [];

    try {
      const data: IUser[] = await customRequest({
        query: Query.users,
        variables: variables.current,
      });
      if (reloadUserCombo.current) {
        setDataUserCombo(() => data);
        reloadUserCombo.current = false;
      }
      return {
        current: 1,
        data,
        pageSize: '1',
        success: true,
        total: data.length,
      };
    } catch (error) {
      openNotification({
        type: 'error',
        msj: getErrorMessage(error),
        context: 'UserPage.request.1',
      });
      return {
        current: 1,
        data: [],
        pageSize: '1',
        success: true,
        total: 0,
      };
    }
  };

  const createUser = async (value: any) => {
    const { role_id, timezone, date_format, date_time_format, phone } = value;
    delete value.role_id;
    delete value.timezone;
    delete value.date_format;
    delete value.date_time_format;
    delete value.repeatPassword;
    delete value.phone;

    const defaultLanguage = languages.find(
      (language) => language.default === true,
    );
    let inputProfile = {
      language_id: defaultLanguage?.id,
      timezone,
      date_format,
      date_time_format,
      phone,
    };
    if (dataUserCombo.some((ele) => ele.email === value.email)) {
      openNotification({
        type: 'error',
        msj: t('error.abm.userWithThisEmailAlreadyExists'),
        context: 'TableUser.createUser.1',
      });
    } else {
      setFormLoading(() => true);

      value.firstname = value.firstname.trim();
      value.lastname = value.lastname.trim();
      try {
        await customRequest({
          mutation: Mutation.createUserTenant,
          variables: {
            input: value,
            role_id,
            inputProfile,
            tenant_id: selectedTenantId,
          },
        });
        setCreateModalVisible(false);
        openNotification({
          type: 'success',
          msj: t('message.createSuccess'),
          context: 'TableUser.createUser.3',
        });
        if (actionRef.current?.reset) {
          reloadUserCombo.current = true;
          actionRef.current.reset();
        }
      } catch (error: any) {
        setFormLoading(() => false);
        if (error.status_code && error.message) {
          return openNotification({
            type: 'error',
            msj: getErrorMessage(error),
            context: 'TableUser.createUser.4',
          });
        }
        openNotification({
          type: 'error',
          msj: t('error.abm.create'),
          context: 'TableUser.createUser.5',
        });
      }
      setFormLoading(() => false);
    }
  };

  const upsertUserRoles = async (
    id: number,
    addRoles: number[],
    deleteRoles: number[],
  ) => {
    if (!addRoles.length && !deleteRoles.length) {
      return;
    }
    try {
      await customRequest({
        mutation: Mutation.upsertUserRole,
        variables: {
          input: {
            user_id: id,
            addRoleIds: addRoles,
            deleteRoleIds: deleteRoles,
            tenant_id: selectedTenantId,
          },
        },
      });

      openNotification({
        type: 'success',
        msj: t('message.updateSuccess'),
        context: 'TableUser.upsertUserRoles.1',
      });
      if (actionRef.current?.reset) {
        reloadUserCombo.current = true;
        actionRef.current.reset();
      }
    } catch (error: any) {
      if (error.status_code && error.message) {
        return openNotification({
          type: 'error',
          msj: getErrorMessage(error),
          context: 'TableUser.upsertUserRoles.3',
        });
      }
      openNotification({
        type: 'error',
        msj: t('error.abm.update'),
        context: 'TableUser.upsertUserRoles.4',
      });
    }
    setFormLoading(() => false);
    setManageRolesModalVisible(false);
  };

  const updateUser = async (value: any) => {
    const { timezone, date_format, date_time_format, phone } = value;

    delete value.timezone;
    delete value.date_format;
    delete value.date_time_format;
    delete value.phone;
    delete value.repeatPassword;

    const defaultLanguage = languages.find(
      (language) => language.default === true,
    );

    const inputProfile = {
      language_id: defaultLanguage?.id,
      timezone,
      date_format,
      date_time_format,
      phone,
    };

    let dataUser;
    if (dataUserCombo.some((ele) => ele.id === editForm.id)) {
      dataUser = dataUserCombo.find((ele) => ele.id === editForm.id);
      if (value.email === dataUser?.email) {
        delete value.email;
      }
    }
    setFormLoading(() => true);
    value.firstname = value.firstname.trim();
    value.lastname = value.lastname.trim();
    try {
      const data = await customRequest({
        mutation: Mutation.updateUser,
        variables: {
          input: {
            ...value,
            id: editForm.id,
          },
          inputProfile: Object.keys(inputProfile).length
            ? { ...inputProfile }
            : undefined,
        },
      });
      const { profile, profile_id } = data;
      if (user?.profile !== profile) {
        if (user?.id === data.id) {
          setUser((oldState: IUser) => {
            const newState = { ...oldState };
            newState.profile = profile;
            newState.profile_id = profile_id;
            return newState;
          });
        }
      }

      if (
        dataUser &&
        value.email &&
        dataUser.email !== value.email &&
        validateUserByEmail
      ) {
        openNotification({
          type: 'success',
          msj: t('message.abm.userUpdatedByAdminWithValidationByEmail'),
          context: 'TableUser.updateUser.4',
        });
      } else {
        openNotification({
          type: 'success',
          msj: t('message.updateSuccess'),
          context: 'TableUser.updateUser.2',
        });
      }

      handleUpdateModalVisible(false);
      if (actionRef.current?.reset) {
        reloadUserCombo.current = true;
        actionRef.current.reset();
      }
    } catch (error: any) {
      if (error.status_code && error.message) {
        openNotification({
          type: 'error',
          msj: getErrorMessage(error),
          context: 'TableUser.updateUser.3',
        });
      } else {
        openNotification({
          type: 'error',
          msj: t('error.abm.update'),
          context: 'TableUser.updateUser.4',
        });
      }
    }
    setFormLoading(() => false);
  };

  const removeConfirmedUser = async (value: any) => {
    try {
      await customRequest({
        mutation: Mutation.deleteUserTenant,
        variables: {
          user_id: value.id,
          tenant_id: selectedTenantId,
        },
      });

      openNotification({
        type: 'success',
        msj: t('message.deleteSuccess'),
        context: 'User.removeConfirmedUser.2',
      });
      if (actionRef.current?.reset) {
        reloadUserCombo.current = true;
        actionRef.current.reset();
      }
    } catch (error: any) {
      openNotification({
        type: 'error',
        msj: getErrorMessage(error),
        context: 'User.removeConfirmedUser.3',
      });
    }
  };

  const removeUser = (value: any) => {
    Modal.confirm({
      content: (
        <>
          <div>
            {t('message.confirmRemoveUser', { userEmail: value.email })}
          </div>
        </>
      ),
      icon: (
        <span
          className="material-symbols-outlined userPage-exclamation-icon"
          translate="no"
        >
          error
        </span>
      ),
      onOk: () => {
        removeConfirmedUser(value);
      },
      okButtonProps: { className: 'save-btn' },
    });
  };

  const getRegex = async () => {
    try {
      const data: IAppSetting = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingNames.PasswordRegex },
        },
      });
      setPasswordRegex(() => data);
    } catch (error: any) {
      openNotification({
        type: 'error',
        msj: getErrorMessage(error),
        context: 'User.getRegex.1',
      });
    }
  };

  const getSettingValidateUserByEmail = async () => {
    try {
      const data: IAppSetting = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingNames.ValidateUserByEmail },
        },
      });
      setValidateUserByEmail(() => data.setting_value === 'true');
    } catch (error: any) {
      openNotification({
        type: 'error',
        msj: getErrorMessage(error),
        context: 'User.getSettingValidateUserByEmail.1',
      });
    }
  };

  const getSettingDefaultValueDateFormat = async () => {
    try {
      const data: IAppSetting = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingNames.DefaultValueDateFormat },
        },
      });
      setDefaultDateFormat(() => data.setting_value);
    } catch (error: any) {
      openNotification({
        type: 'error',
        msj: getErrorMessage(error),
        context: 'User.getSettingDefaultValueDateFormat.1',
      });
    }
  };

  const getSettingDefaultValueDateTimeFormat = async () => {
    try {
      const data: IAppSetting = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingNames.DefaultValueDateTimeFormat },
        },
      });
      setDefaultDateTimeFormat(() => data.setting_value);
    } catch (error: any) {
      openNotification({
        type: 'error',
        msj: getErrorMessage(error),
        context: 'User.getSettingDefaultValueDateTimeFormat.1',
      });
    }
  };

  const getSettingDefaultValueTimezone = async () => {
    try {
      const data: IAppSetting = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingNames.DefaultValueTimezone },
        },
      });
      setDefaultTimezone(() => data.setting_value);
    } catch (error: any) {
      openNotification({
        type: 'error',
        msj: getErrorMessage(error),
        context: 'User.getSettingDefaultValueTimezone.1',
      });
    }
  };

  const createInvitation = async (value: {
    role_id: number;
    tenant_id: number;
    email: string;
  }) => {
    setFormLoading(() => true);
    try {
      await customRequest({
        mutation: Mutation.createInvitation,
        variables: {
          ...value,
        },
      });
      openNotification({
        type: 'success',
        msj: t('message.createSuccess'),
        context: 'UserPage.createInvitation.2',
      });
      setInvitationModalVisible(false);
    } catch (error: any) {
      if (error.status_code && error.message) {
        setFormLoading(() => false);
        return openNotification({
          type: 'error',
          msj: getErrorMessage(error),
          context: 'UserPage.createInvitation.3',
        });
      }
      openNotification({
        type: 'error',
        msj: t('error.abm.create'),
        context: 'UserPage.createInvitation.4',
      });
    }
    setFormLoading(() => false);
  };

  const invitationColumns = useCallback(
    (): ExportableColumn[] => [
      {
        export: false,
        dataIndex: 'tenant_id',
        title: t('entity.tenant'),
        renderFormItem: () => (
          <Select
            disabled
            value={selectedTenantId}
            options={tenantsAssociatedToUser.map((tenant) => ({
              label: tenant.name,
              value: tenant.id,
            }))}
            getPopupContainer={(node) => node.parentNode}
            placeholder={`${t('action.input.select')} ${t(
              'entity.tenant',
            ).toLocaleLowerCase()}`}
            maxLength={200}
          />
        ),
        align: 'left',
        type: ABM.TYPE_COLUMN.NUMBER,
      },
      {
        export: false,
        dataIndex: 'email',
        title: t('entity.email'),
        formItemProps: {
          rules: [
            {
              required: true,
              message: t('error.abm.emailRequired'),
            },
            {
              type: 'email',
              message: t('error.abm.emailNotValid'),
            },
          ],
        },
        renderFormItem: () => (
          <Input
            placeholder={`${t('action.input.enter')} ${t(
              'entity.email',
            ).toLocaleLowerCase()}`}
            maxLength={200}
          />
        ),
        align: 'left',
        hideInTable: false,
      },
      {
        export: false,
        dataIndex: 'role_id',
        title: t('entity.role'),
        formItemProps: {
          rules: [
            {
              required: true,
              message: t('error.abm.roleRequired'),
            },
          ],
        },
        renderFormItem: () => (
          <Select
            options={dataRoles
              .filter((rol) => roleFilters.includes(rol.name))
              .map((rol) => ({
                label: rol.name,
                value: rol.id,
              }))}
            placeholder={`${t('action.input.select')} ${t(
              'entity.role',
            ).toLocaleLowerCase()}`}
            getPopupContainer={(node) => node.parentNode}
            allowClear
            showSearch
            filterOption={(inputValue, option: any) =>
              option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
              0
            }
          />
        ),
      },
    ],
    [dataRoles, tenantsAssociatedToUser],
  );

  const columns = useCallback(
    (
      editMode: boolean,
      filterMode: boolean = false,
    ): ExportableColumn<IUser>[] => [
      {
        export: false,
        dataIndex: 'idUser',
        title: 'id',
        hideInTable: true,
        hideInSearch: true,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.NUMBER,
      },
      {
        export: false,
        dataIndex: 'id',
        title: t('entity.user'),
        renderFormItem: () => (
          <Select
            options={dataUserCombo.map((dataUser) => ({
              label: dataUser.email,
              value: dataUser.id,
            }))}
            placeholder={`${t('action.input.select')} ${t(
              'entity.user',
            ).toLocaleLowerCase()}`}
            getPopupContainer={(node) => node.parentNode}
            allowClear
            showSearch
            filterOption={(inputValue, option: any) =>
              option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
              0
            }
          />
        ),
        hideInTable: true,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        export: true,
        dataIndex: 'firstname',
        title: t('entity.firstname'),
        sorter: true,
        formItemProps: {
          rules: filterMode
            ? []
            : [
                {
                  required: true,
                  message: t('error.abm.firstnameRequired'),
                },
              ],
        },
        render: (_: any, record: { firstname: any }) => (
          <span translate="no">{record.firstname || '-'} </span>
        ),
        renderFormItem: () => (
          <Input
            placeholder={`${t('action.input.enter')} ${t(
              'entity.firstname',
            ).toLocaleLowerCase()}`}
            maxLength={200}
          />
        ),
        align: 'left',
        hideInTable: false,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: true,
        dataIndex: 'firstname',
        title: t('entity.firstname'),
        sorter: true,
        formItemProps: {
          rules: filterMode
            ? []
            : [
                {
                  required: true,
                  message: t('error.abm.firstnameRequired'),
                },
              ],
        },
        render: (_: any, record: { firstname: any }) => record.firstname || '-',
        renderFormItem: () => (
          <Input
            allowClear
            placeholder={`${t('action.input.enter')} ${t(
              'entity.firstname',
            ).toLocaleLowerCase()}`}
            maxLength={200}
          />
        ),
        align: 'left',
        hideInTable: true,
        hideInForm: true,
      },
      {
        export: true,
        dataIndex: 'lastname',
        title: t('entity.lastname'),
        sorter: true,
        formItemProps: {
          rules: filterMode
            ? []
            : [
                {
                  required: true,
                  message: t('error.abm.lastnameRequired'),
                },
              ],
        },
        render: (_: any, record: { lastname: any }) => (
          <span translate="no">{record.lastname || '-'}</span>
        ),
        renderFormItem: () => (
          <Input
            placeholder={`${t('action.input.enter')} ${t(
              'entity.lastname',
            ).toLocaleLowerCase()}`}
            maxLength={200}
          />
        ),
        align: 'left',
        hideInSearch: true,
      },
      {
        export: true,
        dataIndex: 'lastname',
        title: t('entity.lastname'),
        sorter: true,
        formItemProps: {
          rules: filterMode
            ? []
            : [
                {
                  required: true,
                  message: t('error.abm.lastnameRequired'),
                },
              ],
        },
        render: (_: any, record: { lastname: any }) => record.lastname || '-',
        renderFormItem: () => (
          <Input
            allowClear
            placeholder={`${t('action.input.enter')} ${t(
              'entity.lastname',
            ).toLocaleLowerCase()}`}
            maxLength={200}
          />
        ),
        align: 'left',
        hideInTable: true,
        hideInForm: true,
      },
      {
        export: true,
        dataIndex: 'email',
        title: t('entity.email'),
        formItemProps: {
          normalize: (value: string) => value.trim(),
          rules: filterMode
            ? []
            : [
                {
                  required: true,
                  message: t('error.abm.emailRequired'),
                },
                {
                  max: EnumsValues.MaxLengthInputs.SystemUser_Email,
                  message: t('error.abm.emailMaxLength', {
                    max: EnumsValues.MaxLengthInputs.SystemUser_Email,
                  }),
                },
                {
                  type: 'email',
                  message: t('error.abm.emailNotValid'),
                },
                {
                  validator: (_, value) => {
                    if (editMode) {
                      let dataUser = dataUserCombo.find(
                        (ele) => ele.id === editForm.id,
                      );
                      if (dataUser?.email === value) {
                        return Promise.resolve();
                      }
                      if (
                        dataUserCombo.some(
                          (ele) =>
                            ele.email === value && ele.id !== dataUser?.id,
                        )
                      ) {
                        return Promise.reject(
                          new Error(t('error.abm.emailNotAvailable')),
                        );
                      }
                      return Promise.resolve();
                    } else {
                      if (dataUserCombo.some((ele) => ele.email === value)) {
                        return Promise.reject(
                          new Error(t('error.abm.emailNotAvailable')),
                        );
                      }
                      return Promise.resolve();
                    }
                  },
                },
              ],
        },
        render: (_: any, record: { email: any }) => record.email || '-',
        renderFormItem: () => (
          <Input
            placeholder={`${t('action.input.enter')} ${t(
              'entity.email',
            ).toLocaleLowerCase()}`}
            maxLength={200}
          />
        ),
        align: 'left',
        hideInTable: false,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        dataIndex: 'role',
        export: true,
        title: t('entity.role'),
        render: (_: any, record: IUser) => {
          return (
            <>
              {Array.from(
                new Set(record.user_role?.map((ur) => ur.role.name)),
              ).map((name) => (
                <span key={name}>{name}</span>
              ))}
            </>
          );
        },
        align: 'left',
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        renderDataExport: (record: any) => {
          let roles: string = '';
          if (record.user_role?.length) {
            record.user_role.map((element: any, index: number) => {
              roles = roles + element.role.name;
              if (index < record.user_role.length - 1) {
                roles = roles + ' - ';
              }
              return roles;
            });
          }
          return roles;
        },
      },
      {
        export: false,
        dataIndex: 'role_id',
        title: t('entity.role'),
        renderFormItem: () => (
          <Select
            options={dataRoles.map((rol) => ({
              label: rol.name,
              value: rol.id,
            }))}
            {...(defaultRoleValue ? { value: defaultRoleValue } : {})}
            onDeselect={() => setDefaultRoleValue(undefined)}
            onClear={() => setDefaultRoleValue(undefined)}
            placeholder={`${t('action.input.select')} ${t(
              'entity.role',
            ).toLocaleLowerCase()}`}
            getPopupContainer={(node) => node.parentNode}
            mode="multiple"
            allowClear
            showSearch
            filterOption={(inputValue, option: any) =>
              option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
              0
            }
          />
        ),
        hideInTable: true,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        export: false,
        dataIndex: 'role_id',
        title: t('entity.role'),
        formItemProps: {
          rules: filterMode
            ? []
            : [
                {
                  required: true,
                  message: t('error.abm.roleRequired'),
                },
              ],
        },
        renderFormItem: () => (
          <Select
            options={dataRoles
              .filter((rol) => roleFilters.includes(rol.name))
              .map((rol) => ({
                label: rol.name,
                value: rol.id,
              }))}
            placeholder={`${t('action.input.select')} ${t(
              'entity.role',
            ).toLocaleLowerCase()}`}
            getPopupContainer={(node) => node.parentNode}
            allowClear
            showSearch
            filterOption={(inputValue, option: any) =>
              option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
              0
            }
          />
        ),
        hideInTable: true,
        hideInSearch: true,
        hideInForm: editMode,
      },
      {
        export: false,
        dataIndex: 'timezone',
        title: t('entity.timezone'),
        renderFormItem: () => (
          <Select
            options={moment.tz
              .names()
              .map((option) => ({
                value: option,
                label: `(GMT ${moment.tz(option).format('Z')}) ${option.replace(
                  '_',
                  ' ',
                )}`,
              }))
              .sort(Tools.sortByZone)}
            placeholder={`${t('action.input.select')} ${t(
              'entity.timezone',
            ).toLocaleLowerCase()}`}
            getPopupContainer={(node) => node.parentNode}
            allowClear
            showSearch
            filterOption={(inputValue, option: any) =>
              option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
              0
            }
          />
        ),
        hideInTable: true,
        hideInSearch: true,
        hideInForm: true,
      },
      {
        export: false,
        dataIndex: 'date_format',
        title: t('entity.dateFormat'),
        renderFormItem: () => (
          <Input
            placeholder={`${t('action.input.enter')} ${t(
              'entity.dateFormat',
            ).toLocaleLowerCase()}`}
            allowClear
          />
        ),
        hideInTable: true,
        hideInSearch: true,
        hideInForm: true,
      },
      {
        export: false,
        dataIndex: 'date_time_format',
        title: t('entity.dateTimeFormat'),
        renderFormItem: () => (
          <Input
            placeholder={`${t('action.input.enter')} ${t(
              'entity.dateTimeFormat',
            ).toLocaleLowerCase()}`}
            allowClear
          />
        ),
        hideInTable: true,
        hideInSearch: true,
        hideInForm: true,
      },
      {
        export: false,
        dataIndex: 'phone',
        title: `${t('entity.phone')} ${t('action.optional')}`,
        renderFormItem: () => (
          <Input
            placeholder={`${t('action.input.enter')} ${t(
              'entity.phone',
            ).toLocaleLowerCase()}`}
            allowClear
          />
        ),
        hideInTable: true,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        title: t('entity.op'),
        dataIndex: 'option',
        valueType: 'option',
        fixed: 'right',
        align: 'right',
        className: 'options-column',
        export: false,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        render: (_, record) => (
          <>
            <Tooltip
              key="notifications_tooltip"
              title={t('entity.notifications')}
              trigger={isPlatform('desktop') ? 'hover' : ' focus'}
            >
              <Button
                disabled={
                  !(
                    Authorization.security(
                      functions,
                      EnumsValues.Functions.UpdateUserNotificationType,
                    ) && !record.is_system_user
                  )
                }
                type="text"
                icon={
                  <span
                    className="material-symbols-outlined userPage-action-icon"
                    translate="no"
                  >
                    notifications
                  </span>
                }
                style={{ width: 'auto', height: 'auto' }}
                className="pointer"
                onClick={async () => {
                  await getUserNotificationTypes(record.id);
                  setUserIdNotificationsModal(record.id);
                  setManageNotificationsModalVisible(true);
                }}
              />
            </Tooltip>
            <Tooltip
              key="edit_user"
              trigger={isPlatform('desktop') ? 'hover' : ' focus'}
              title={`${t('action.modify')} ${t(
                'entity.user',
              ).toLocaleLowerCase()}`}
            >
              <Button
                disabled={
                  !(
                    Authorization.security(
                      functions,
                      EnumsValues.Functions.UserUpdate,
                    ) &&
                    Authorization.security(
                      functions,
                      EnumsValues.Functions.UserProfileUpdate,
                    ) &&
                    !record.is_system_user &&
                    (!validateUserByEmail ||
                      (validateUserByEmail && record.validated))
                  )
                }
                style={{ width: 'auto', height: 'auto' }}
                type="text"
                icon={
                  <span
                    className="material-symbols-outlined userPage-action-icon"
                    translate="no"
                  >
                    edit
                  </span>
                }
                className="pointer"
                onClick={() => {
                  setEditFormValues(() => record);
                  handleUpdateModalVisible(() => true);
                }}
              />
            </Tooltip>
            <Badge dot count={record.user_role?.length === 0 ? 1 : 0}>
              <Tooltip
                key="configure_rol_tooltip"
                title={`${t('action.manage')} ${t(
                  'entity.role',
                ).toLocaleLowerCase()}`}
                trigger={isPlatform('desktop') ? 'hover' : ' focus'}
              >
                <Button
                  disabled={
                    !(
                      Authorization.security(
                        functions,
                        EnumsValues.Functions.ManageUser,
                      ) && !record.is_system_user
                    )
                  }
                  style={{ width: 'auto', height: 'auto' }}
                  type="text"
                  icon={
                    <span
                      className="material-symbols-outlined userPage-action-icon"
                      translate="no"
                    >
                      person
                    </span>
                  }
                  className="pointer"
                  onClick={() => {
                    setDataManageRoles(() => record);
                    setManageRolesModalVisible(true);
                  }}
                />
              </Tooltip>
            </Badge>
            <Tooltip
              key="remove_user_tooltip"
              trigger={isPlatform('desktop') ? 'hover' : ' focus'}
              title={`${t('action.remove')} ${t(
                'entity.user',
              ).toLocaleLowerCase()}`}
            >
              <Button
                disabled={
                  !(
                    Authorization.security(
                      functions,
                      EnumsValues.Functions.ManageUser,
                    ) && !record.is_system_user
                  )
                }
                style={{ width: 'auto', height: 'auto' }}
                type="text"
                icon={
                  <span
                    className="material-symbols-outlined userPage-action-icon"
                    translate="no"
                  >
                    delete
                  </span>
                }
                className="pointer"
                onClick={() => {
                  removeUser(record);
                }}
              />
            </Tooltip>
          </>
        ),
      },
      {
        export: false,
        dataIndex: 'password',
        title: `${t('entity.password')} ${t('action.optional')}`,
        formItemProps: {
          rules: filterMode
            ? []
            : [
                {
                  required: !editMode,
                  message: t('error.abm.passwordRequired'),
                },
                {
                  validator(_, value) {
                    let regex = new RegExp(
                      String(passwordRegex?.setting_value),
                    );
                    if (!value || regex.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error('La contraseña no cumple con los requisitos'),
                    );
                  },
                },
              ],
        },
        renderFormItem: () => (
          <Input.Password
            autoComplete="password"
            placeholder={`${t('action.input.enter')} ${t(
              'entity.password',
            ).toLocaleLowerCase()}`}
            maxLength={100}
          />
        ),
        hideInTable: true,
        hideInSearch: true,
        hideInForm: validateUserByEmail && !editMode,
      },
      {
        export: false,
        renderFormItem: () => (
          <div className="messageRegExp">
            <Badge color="#606366" status="default" />
            <p className="textRegExp">{passwordRegex?.description}</p>
          </div>
        ),
        hideInTable: true,
        hideInSearch: true,
        hideInForm: validateUserByEmail && !editMode,
      },
      {
        export: false,
        dataIndex: 'repeatPassword',
        title: `${t('action.repeat')} ${t(
          'entity.password',
        ).toLocaleLowerCase()} ${t('action.optional')}`,
        formItemProps: {
          rules: filterMode
            ? []
            : [
                {
                  required: !editMode,
                  message: t('error.abm.repeatedPasswordRequired'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t('error.abm.repeatedPasswordNotMatching')),
                    );
                  },
                }),
              ],
        },
        renderFormItem: () => (
          <Input.Password
            placeholder={`${t('action.repeat')} ${t(
              'entity.password',
            ).toLocaleLowerCase()}`}
            maxLength={200}
          />
        ),
        hideInTable: true,
        hideInSearch: true,
        hideInForm: validateUserByEmail && !editMode,
        dependencies: ['password'],
      },
    ],
    [dataUserCombo, editForm, passwordRegex, dataRoles, defaultRoleValue],
  );

  let LIST_FILTER_NAMES = columns(false)
    .filter((value) => {
      if (
        LIST_FILTER_LABELS.find(
          (element) =>
            element === value.dataIndex && value.hideInTable === false,
        )
      ) {
        return value.title;
      }
      return false;
    })
    .map((element) => {
      return element.title;
    });

  const INPUT_SEARCH_PLACEHOLDER = LIST_FILTER_NAMES.join(', ');

  const upsertNotificationTypes = async (
    userId: number,
    addTypes: number[],
    removeTypes: number[],
  ) => {
    if ((!addTypes.length && !removeTypes.length) || !userIdNotificationsModal)
      return;

    try {
      await customRequest({
        mutation: Mutation.upsertUserNotificationType,
        variables: {
          tenant_id: selectedTenantId,
          input: {
            user_id: userId,
            remove_types: removeTypes,
            add_types: addTypes,
          },
        },
      });
      openNotification({
        type: 'success',
        msj: t('message.updateSuccess'),
      });
      setManageNotificationsModalVisible(false);
    } catch (error: any) {
      openNotification({
        type: 'error',
        msj: getErrorMessage(error),
        context: 'upsertNotificationTypes',
      });
    }
  };

  return (
    <>
      <ProTable<IUser>
        onSizeChange={mobileOnSizeChangeProTable}
        components={{
          table: showComponent(),
        }}
        actionRef={actionRef}
        className="protable-user"
        rowKey="id"
        size="small"
        options={{
          density: false,
          reload: false,
        }}
        search={false}
        onChange={(_, _filter, _sorter) => {
          const sorterResult = _sorter as SorterResult<IUser>;
          if (sorterResult.field) {
            setSorter(`${sorterResult.field}_${sorterResult.order}`);
          }
        }}
        onReset={() => {
          setDefaultRoleValue(() => undefined);
          setSearchText('');
        }}
        params={{
          sorter,
        }}
        toolBarRender={() => [
          <ToolbarFilterOrder
            onSearch={handleSearch}
            showButtonNew={false}
            columns={columns(false, true)}
            onFinishFilter={(values) => {
              customParamsRef.current.filter = values;
              if (actionRef.current?.reload) {
                actionRef.current.reload(true);
              }
            }}
            filterNames={INPUT_SEARCH_PLACEHOLDER}
          />,
          <>
            {Authorization.security(
              functions,
              EnumsValues.Functions.ManageUser,
            ) ? (
              <>
                <Button
                  hidden={true}
                  type="primary"
                  className="userPage-add-btn"
                  onClick={() => {
                    setInvitationModalVisible(true);
                  }}
                >
                  <span
                    className="material-symbols-outlined userPage-add-icon"
                    translate="no"
                  >
                    person_add
                  </span>{' '}
                  {t('action.invite')}
                </Button>
                <Button
                  type="primary"
                  className="userPage-add-btn"
                  onClick={() => {
                    setCreateModalVisible(true);
                  }}
                >
                  {t('entity.new')}
                </Button>
              </>
            ) : null}
          </>,
        ]}
        /**
         * @description este metodo debe poder ejecutar siempre la consulta al backend
         */
        request={async (params, sorter, filter) =>
          request({ ...params, sorter, filter })
        }
        columns={columns(false)}
      />
      <SaveForm
        style={{ minWidth: '448px' }}
        loading={formLoading}
        title={TITLE_CREATE_FORM}
        onCancel={() => {
          setCreateModalVisible(false);
        }}
        modalVisible={createModalVisible}
        onOk={(value) => createUser(value)}
        columns={columns(false)}
        values={{
          timezone: defaultTimezone,
          date_format: defaultDateFormat,
          date_time_format: defaultDateTimeFormat,
        }}
        saveFormFooterIcon={{
          reset: <></>,
        }}
        buttonCancel={true}
        buttonReset={false}
      />
      <SaveForm
        loading={formLoading}
        title={TITLE_INVITATION_FORM}
        onCancel={() => {
          setInvitationModalVisible(false);
        }}
        modalVisible={invitationModalVisible}
        onOk={(value) => createInvitation(value)}
        columns={invitationColumns()}
        values={{
          tenant_id: selectedTenantId,
        }}
        cancelText={t('action.reset')}
        submitText={t('action.send')}
        saveFormFooterIcon={{
          reset: <></>,
        }}
        buttonCancel={true}
        buttonReset={false}
      />
      {editForm && (
        <SaveForm
          style={{ minWidth: '448px' }}
          loading={formLoading}
          title={TITLE_UPDATE_FORM}
          modalVisible={updateModalVisible}
          values={{
            ...editForm,
            timezone: editForm.profile?.timezone,
            date_format: editForm.profile?.date_format,
            date_time_format: editForm.profile?.date_time_format,
            phone: editForm.profile?.phone,
          }}
          columns={columns(true)}
          onOk={(value) => updateUser(value)}
          onCancel={() => {
            handleUpdateModalVisible(false);
            setEditFormValues({});
          }}
          saveFormFooterIcon={{
            reset: <></>,
          }}
          buttonCancel={true}
          buttonReset={false}
        />
      )}
      <ModalConfigureMultiple<IRole>
        renderLabel={(entity) => entity.name}
        checks={dataRoles.filter((rol) => roleFilters.includes(rol.name))}
        data={[
          dataManageRoles.id,
          dataManageRoles.user_role?.map((dataRole) => dataRole.role.id) || [],
        ]}
        onCancel={() => {
          setManageRolesModalVisible(false);
        }}
        onOk={(id_user, add_roles, delete_roles) => {
          return upsertUserRoles(id_user, add_roles, delete_roles);
        }}
        modalVisible={manageRolesModalVisible}
        titleModal={`${t('action.manage')} ${t(
          'entity.roles',
        ).toLocaleLowerCase()}`}
        exclusiveChecks={dataRoles.filter((rol) =>
          exclusiveFilters.includes(rol.name),
        )}
        exclusiveAlertMessage={t('message.publicApiExclusiveRoleAlert')}
      />
      {manageNotificationsModalVisible && (
        <ModalNotifications
          onCancel={() => {
            setManageNotificationsModalVisible(false);
          }}
          onOk={(addTypes, deleteTypes) => {
            userIdNotificationsModal &&
              upsertNotificationTypes(
                userIdNotificationsModal,
                addTypes,
                deleteTypes,
              );
          }}
          titleModal={`${t('action.manage')} ${t(
            'entity.notifications',
          ).toLocaleLowerCase()}`}
          notificationCategories={notificationCategories}
          userNotificationTypes={userNotificationTypes}
        />
      )}
    </>
  );
}
