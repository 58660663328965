import { Table, TableProps } from 'antd';
import './CustomTable.less';
import { ArrowRight } from '../../Icons/ArrowRight';

const CustomTable = <T extends object>(props: TableProps<T>) => {
  const className = `custom-table ${props.className || ''}`;

  const defaultExpandable: TableProps<T>['expandable'] = {
    expandIcon: ({
      expanded,
      onExpand,
      record,
    }: {
      expanded: boolean;
      onExpand: (
        record: T,
        e: React.MouseEvent<HTMLElement, MouseEvent>,
      ) => void;
      record: T;
    }) =>
      props.expandable?.childrenColumnName &&
      (record as any)[props.expandable.childrenColumnName]?.length > 0 && (
        <ArrowRight
          className={
            expanded
              ? 'custom-table__icon custom-table__icon--active'
              : 'custom-table__icon'
          }
          onClick={(e) => onExpand(record, e)}
        />
      ),
  };

  return (
    <Table<T>
      {...props}
      expandable={{ ...defaultExpandable, ...props.expandable }}
      className={className}
    />
  );
};

export default CustomTable;
