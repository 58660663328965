import { Input, Modal } from 'antd';
import {
  FieldAttributeTenant,
  SchemaField,
} from '../../../../interfaces/ValidationSchema';
import './FieldConfigurationModal.less';
import { ContextApp } from '../../../../contexts/ContextApp';
import { useContext } from 'react';
import ButtonSecondary from '../../../../components/common/Buttons/ButtonSecondary';

interface FieldRestoreModalProps {
  modalVisible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  currentAttribute: FieldAttributeTenant;
  currentField: SchemaField;
}

const FieldConfigurationModal = (props: FieldRestoreModalProps) => {
  const { modalVisible, setModalVisible, currentAttribute, currentField } =
    props;

  const { t } = useContext(ContextApp);

  return (
    <Modal
      destroyOnClose
      title={
        <div className="modal-content__title">
          {t('settingsPage.fieldConfigurationModal.title', {
            fieldName: (
              t((currentField?.field?.translation_key as never) || '') as string
            ).toLowerCase(),
          })}
        </div>
      }
      open={modalVisible}
      onCancel={() => setModalVisible(false)}
      footer={null}
      className="field-configuration-modal"
    >
      {currentAttribute?.configuration && (
        <div className="modal-content">
          <div className="modal-content__input">
            <span className="modal-content__input__label">
              {t('settingsPage.fieldConfigurationModal.inputText1')}
            </span>
            <Input
              value={t(
                currentAttribute?.attribute_type?.translation_key as never,
              )}
              disabled
            ></Input>
          </div>
          <div className="modal-content__input">
            <span className="modal-content__input__label">
              {t('settingsPage.fieldConfigurationModal.inputText2')}
            </span>
            <Input value={currentAttribute.configuration} disabled></Input>
          </div>
          <div className="modal-content__actions">
            <ButtonSecondary onClick={() => setModalVisible(false)}>
              {t('action.return')}
            </ButtonSecondary>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default FieldConfigurationModal;
