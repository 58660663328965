import { ReceiptTypeDocumentType } from '../interfaces/ReceiptTypeDocumentType';
import GraphqlService from '../services/graphql/GraphqlService';

const useDocumentTypeService = () => {
  const { Query, customRequest } = GraphqlService();

  const getDocumentTypeByReceiptType = async (params: {
    tenant_id: number;
    receipt_type_id: number;
  }): Promise<ReceiptTypeDocumentType> => {
    const { tenant_id, receipt_type_id } = params;
    return customRequest({
      query: Query.receiptTypeDocumentTypeByReceiptType,
      variables: {
        tenant_id,
        receipt_type_id,
      },
    });
  };

  return {
    getDocumentTypeByReceiptType,
  };
};

export default useDocumentTypeService;
