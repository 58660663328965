export const GET_DOCUMENT_TYPE_CONFIGURATION_FOR_TENANT = `
query getDocumentTypeConfigurationForTenant($input: DocumentTypeInformationInput!) {
  getDocumentTypeConfigurationForTenant(input: $input) {
    __typename
    ... on DocumentTypeInformationForTenant {
      documentTypeId
      tenant_id
      selectable
      automatically_discarded
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`