import { Button, ButtonProps } from 'antd';

const ButtonSecondary = (props: ButtonProps) => {
  return (
    <Button type="ghost" {...props}>
      {props.children}
    </Button>
  );
};

export default ButtonSecondary;
