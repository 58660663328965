export const CREATE_PRODUCT = `
mutation createProduct($input: CreateProductInput!) {
  createProduct(input: $input) {
    __typename
    ... on Product {
      id
      code
      description
      tenant_id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const DELETE_PRODUCT = `
mutation deleteProduct($id: Int!) {
  deleteProduct(id: $id) {
    __typename
    ... on Product {
      id
      code
      description
      tenant_id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const UPDATE_PRODUCT = `
mutation updateProduct($input: UpdateProductInput!) {
  updateProduct(input: $input) {
    __typename
    ... on Product {
      id
      code
      description
      tenant_id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
