import './index.less';
import React, { useContext, useMemo, useState } from 'react';
import { Badge, Button, Card, Popover } from 'antd';
import { IAppSetting } from '../../../../interfaces/AppSetting';
import { ContextApp } from '../../../../contexts/ContextApp';
import { EnumsValues } from '../../../../enums/EnumsValues';
import GraphqlService from '../../../../services/graphql/GraphqlService';
import { useHistory } from 'react-router';
import { tourContext } from '../../../../contexts/TourContext';

enum ContactType {
  Support,
  Commercial,
}

interface IHelpCardProps {
  title: string;
  paragraph1: string;
  paragraph2?: string;
  ctaLabel1: string;
  ctaAction1: () => void;
  ctaLabel2: string;
  ctaAction2: () => void;
}

const Help = () => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const { t, setMainMenuCollapsed } = useContext(ContextApp);
  const { customRequest, Query } = GraphqlService();
  const history = useHistory();
  const { createTour } = useContext(tourContext);

  const goToContactPage = async (contactType: ContactType) => {
    const contactTypes = {
      [ContactType.Support]: EnumsValues.SettingNames.ContactLinkOnHelp,
      [ContactType.Commercial]: EnumsValues.SettingNames.ContactLinkOnPackPage,
    };
    try {
      const data: IAppSetting = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: {
            key: contactTypes[contactType],
          },
        },
      });
      window.open(data.setting_value);
    } catch (error) {
      //intentional
    }
  };

  const goToArrangeMeeting = async () => {
    try {
      const data: IAppSetting = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: {
            key: EnumsValues.SettingNames.ArrangeMeetingLinkOnHelp,
          },
        },
      });
      window.open(data.setting_value);
    } catch (error) {
      //intentional
    }
  };

  const goToTutorial = async () => {
    try {
      const data: IAppSetting = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: {
            key: EnumsValues.SettingNames.TutorialLinkOnHelp,
          },
        },
      });
      window.open(data.setting_value);
    } catch (error) {
      //intentional
    }
  };

  const goToEmail = async () => {
    try {
      const data: IAppSetting = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: {
            key: EnumsValues.SettingNames.supportEmail,
          },
        },
      });
      window.open(`mailto:${data.setting_value}`);
    } catch (error) {
      //intentional
    }
  };

  const goToTour = () => {
    setPopoverVisible(false);
    if (history.location.pathname !== '/app/home') {
      history.push({
        pathname: '/app/home',
        state: {
          runTour: true,
        },
      });
    } else {
      setMainMenuCollapsed(false);
      createTour(EnumsValues.TourSections.Menu);
    }
  };
  const HelpCard = (props: IHelpCardProps) => {
    const {
      title,
      paragraph1,
      paragraph2,
      ctaLabel1,
      ctaAction1,
      ctaLabel2,
      ctaAction2,
    } = props;
    return (
      <Card className="help__cards-container__card">
        <div className="help__cards-container__card--body">
          <h3>{title}</h3>
          <p>{paragraph1}</p>
          {paragraph2 && <p>{paragraph2}</p>}
        </div>
        <div className="help__cards-container__card--cta">
          <Button type="primary" onClick={ctaAction1}>
            {ctaLabel1}
          </Button>
          <Button type="primary" onClick={ctaAction2}>
            {ctaLabel2}
          </Button>
        </div>
      </Card>
    );
  };

  const popoverContent = useMemo(() => {
    let innerComponent: JSX.Element | JSX.Element[];

    innerComponent = (
      <div className="help">
        <div className="help__header">
          <h2 className="help__header--title">{t('help.title')}</h2>
          <p className="help__header--description">{t('help.paragraph')}</p>
        </div>
        <div className="help__cards-container">
          <HelpCard
            title={t('help.card1.title')}
            paragraph1={t('help.card1.paragraph1')}
            paragraph2={t('help.card1.paragraph2')}
            ctaAction1={goToTour}
            ctaLabel1={t('help.card1.startTour')}
            ctaLabel2={t('help.card1.startTutorial')}
            ctaAction2={goToTutorial}
          />
          <HelpCard
            title={t('help.card2.title')}
            paragraph1={t('help.card2.paragraph1')}
            ctaAction1={() => goToContactPage(ContactType.Support)}
            ctaLabel1={t('help.card2.whatsapp')}
            ctaLabel2={t('help.card2.email')}
            ctaAction2={goToEmail}
          />
          <HelpCard
            title={t('help.card3.title')}
            paragraph1={t('help.card3.paragraph1')}
            ctaAction1={() => goToContactPage(ContactType.Commercial)}
            ctaLabel1={t('help.card3.whatsapp')}
            ctaLabel2={t('help.card3.scheduleAMeeting')}
            ctaAction2={goToArrangeMeeting}
          />
        </div>
      </div>
    );
    return <div className="help-popover">{innerComponent}</div>;
  }, []);

  const handlePopoverVisibleChange = (visible: boolean) => {
    setPopoverVisible(visible);
  };

  return (
    <div>
      <Popover
        arrowPointAtCenter
        autoAdjustOverflow
        content={popoverContent}
        placement="bottomRight"
        trigger="click"
        overlayClassName="help-popover-overlay"
        open={popoverVisible}
        onOpenChange={handlePopoverVisibleChange}
      >
        <Badge size="small" className="help-button">
          <span className="material-symbols-outlined icon-help" translate='no'>help</span>{' '}
          {t('help.help')}
        </Badge>
      </Popover>
    </div>
  );
};

export default Help;
