import moment from 'moment-timezone';
import { useContext } from 'react';
import { ContextApp } from '../../contexts/ContextApp';
import {
  DEFAULT_FORMAT_DATE,
  DEFAULT_TIMEZONE,
  FORMAT_DATE_TIME_1,
  FORMAT_DATE_TIME_7,
} from '../../shared/MomentJS';

interface IGetDateWithTimeInput {
  element: Date | number | string;
}
const MomentTimezoneService = () => {
  const { user } = useContext(ContextApp);

  const getDateUTC = (date: IGetDateWithTimeInput) => {
    const { element } = date;
    return moment.utc(element).format(FORMAT_DATE_TIME_7);
  };

  const getDateWithOffsetAndTimezone = (date: IGetDateWithTimeInput) => {
    let timezone = user?.profile?.timezone;
    let date_format = getUserDateTimeFormat();
    let date_moment = '';
    const { element } = date;
    if (moment(element).isValid()) {
      if (timezone && moment.tz.names().some((ele) => ele === timezone)) {
        date_moment = moment(element).tz(timezone).format(date_format);
        date_moment = `(GMT ${moment(element)
          .tz(timezone)
          .format(`Z`)}) ${date_moment} (${timezone})`;
      } else {
        date_moment = moment(element)
          .tz(
            process.env.REACT_APP_TIMEZONE
              ? process.env.REACT_APP_TIMEZONE
              : '',
          )
          .format(date_format);
        date_moment = `(GMT ${moment(element)
          .tz(
            process.env.REACT_APP_TIMEZONE
              ? process.env.REACT_APP_TIMEZONE
              : '',
          )
          .format(`Z`)}) ${date_moment} (${
          process.env.REACT_APP_TIMEZONE ? process.env.REACT_APP_TIMEZONE : ''
        })`;
      }
      return date_moment;
    }
  };

  const getDateWithTime = (date: IGetDateWithTimeInput) => {
    let timezone = user?.profile?.timezone;
    let date_time_format = getUserDateTimeFormat();
    let date_moment = '';
    const { element } = date;
    if (moment(element).isValid()) {
      if (timezone && moment.tz.names().some((ele) => ele === timezone)) {
        date_moment = moment(element).tz(timezone).format(date_time_format);
      } else {
        date_moment = moment(element)
          .tz(process.env.REACT_APP_TIMEZONE ?? DEFAULT_TIMEZONE)
          .format(date_time_format);
      }
      return date_moment;
    }
  };

  const getDate = (date: IGetDateWithTimeInput) => {
    let timezone = user?.profile?.timezone;
    let date_format = getUserDateFormat();
    let date_moment = '';
    const { element } = date;
    if (moment(element).isValid()) {
      if (timezone && moment.tz.names().some((ele) => ele === timezone)) {
        date_moment = moment(element).tz(timezone).format(date_format);
      } else {
        date_moment = moment(element)
          .tz(
            process.env.REACT_APP_TIMEZONE
              ? process.env.REACT_APP_TIMEZONE
              : '',
          )
          .format(date_format);
      }
      return date_moment;
    }
  };

  const getDateAsMoment = (date: IGetDateWithTimeInput) => {
    let timezone = user?.profile?.timezone;
    let date_moment: moment.Moment;
    const { element } = date;
    if (moment(element).isValid()) {
      if (timezone && moment.tz.names().some((ele) => ele === timezone)) {
        date_moment = moment(element).tz(timezone);
      } else {
        date_moment = moment(element).tz(
          process.env.REACT_APP_TIMEZONE ? process.env.REACT_APP_TIMEZONE : '',
        );
      }
      return date_moment;
    }
  };

  const getUserDateFormat = () => {
    return user?.profile?.date_format || DEFAULT_FORMAT_DATE;
  };

  const getUserDateTimeFormat = () => {
    return user?.profile?.date_time_format || FORMAT_DATE_TIME_1;
  };

  return {
    getDateWithOffsetAndTimezone,
    getDateWithTime,
    getDate,
    getDateUTC,
    getUserDateFormat,
    getUserDateTimeFormat,
    getDateAsMoment,
  };
};

export default MomentTimezoneService;
