import {
  BusinessValidation,
  UpdateBusinessValidationInput,
} from '../interfaces/BusinessValidation';
import {
  ReviewerConfiguration,
  ReviewerConfigurationTypeInformationInput,
  ReviewerConfigurationTypeOption,
  UpdateReviewerConfigurationTypeTenantInput,
} from '../interfaces/ReviewerConfiguration';
import {
  FieldAttributeTenant,
  Schema,
  UpdateFieldAttributeTenantInput,
} from '../interfaces/ValidationSchema';
import GraphqlService from '../services/graphql/GraphqlService';

const useDataValidationService = () => {
  const { Query, customRequest, Mutation } = GraphqlService();

  const getFieldAttributeTenantsByReceiptType = async (
    tenant_id: number,
    receipt_type_id: number,
  ): Promise<FieldAttributeTenant[]> => {
    return customRequest({
      query: Query.fieldAttributeTenantsByReceiptType,
      variables: {
        tenant_id,
        receipt_type_id,
      },
    });
  };

  const getSchema = async (
    tenant_id: number,
    receipt_type_id: number,
  ): Promise<Schema[]> => {
    return customRequest({
      query: Query.schemas,
      variables: {
        filter: {
          tenant_id,
          receipt_type_id,
        },
      },
    });
  };

  const getBusinessSettings = async (
    tenant_id: number,
    document_type_id: number,
  ): Promise<BusinessValidation[]> => {
    return customRequest({
      query: Query.documentTypeBusinessValidations,
      variables: {
        filter: {
          tenant_id,
          document_type_id,
        },
      },
    });
  };

  const updateManyFieldAttributeTenant = async (
    input: UpdateFieldAttributeTenantInput[],
    receipt_type_id: number,
    tenant_id: number,
  ) => {
    return customRequest({
      query: Mutation.updateManyFieldAttributeTenant,
      variables: {
        input,
        receipt_type_id,
        tenant_id,
      },
    });
  };

  const updateManyDocumentTypeBusinessValidationTenant = async (
    input: UpdateBusinessValidationInput[],
    document_type_id: number,
    tenant_id: number,
  ) => {
    return customRequest({
      query: Mutation.updateManyDocumentTypeBusinessValidationTenant,
      variables: {
        input,
        document_type_id,
        tenant_id,
      },
    });
  };

  const getReviewerConfigurationTypeOptions = async (params: {
    tenant_id: number;
  }): Promise<ReviewerConfigurationTypeOption[]> => {
    const { tenant_id } = params;
    return customRequest({
      query: Query.reviewerConfigurationTypeOptions,
      variables: {
        filter: {
          tenant_id,
        },
      },
    });
  };

  const getReviewerConfigurationTypeByDocumentTypeId = async (
    params: ReviewerConfigurationTypeInformationInput,
  ): Promise<ReviewerConfiguration> => {
    const { tenant_id, document_type_id } = params;
    return customRequest({
      query: Query.getReviewerConfigurationTypeByDocumentTypeId,
      variables: {
        input: {
          tenant_id,
          document_type_id,
        },
      },
    });
  };

  const updateReviewerConfigurationTypeTenant = async (
    document_type_id: number,
    tenant_id: number,
    input: UpdateReviewerConfigurationTypeTenantInput,
  ) => {
    return customRequest({
      query: Mutation.updateReviewerConfigurationTypeTenant,
      variables: {
        document_type_id,
        tenant_id,
        input,
      },
    });
  };

  return {
    getFieldAttributeTenantsByReceiptType,
    getSchema,
    getBusinessSettings,
    updateManyFieldAttributeTenant,
    updateManyDocumentTypeBusinessValidationTenant,
    getReviewerConfigurationTypeOptions,
    getReviewerConfigurationTypeByDocumentTypeId,
    updateReviewerConfigurationTypeTenant,
  };
};

export default useDataValidationService;
