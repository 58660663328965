export const TAX_TYPES = `
query taxTypes(
    $filter: FilterTaxTypeInput!
    $orderBy: TaxTypeOrderInput
    $skip: Int
    $take: Int
  ) {
    taxTypes(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take) {
      __typename
      ... on TaxType {
        id
        code
        name
        description
        active
        translation_key
        regular_expression
        its,
        uts,
        dts
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
`;
