import { IIntegration } from '../../../interfaces/Integration';
import { IPack } from '../../../interfaces/Pack';
import { AvailableQuotaCard } from '../AvailableQuotaCard/AvailableQuotaCard';
import { CardToSelect } from '../CardToSelect/CardToSelect';
import './CardsContainer.less';

interface ICardsContainerProps<T> {
  data: Array<T>;
  information: {
    title: string;
    description: string | JSX.Element;
    otherDescription?: string;
  };
  cardHeaderJsx: (value: T) => JSX.Element;
  cardBodyJsx: (value: T) => JSX.Element;
  buttonLabel: string | ((value: T) => string);
  cardDivider: boolean;
  onClick: (value: T) => void;
  isButtonLoading?: number;
  selectedIntegration?: number;
  buttonLabelIfSelected?: string;
  extraButton?: JSX.Element;
  availableQuota?: boolean;
  buttonDisabled?: boolean;
}

export function CardsContainer<T extends IPack | IIntegration>(
  props: ICardsContainerProps<T>,
) {
  const {
    data,
    information,
    cardBodyJsx,
    cardHeaderJsx,
    buttonLabel,
    cardDivider,
    selectedIntegration,
    buttonLabelIfSelected,
    onClick,
    buttonDisabled,
    extraButton,
    isButtonLoading,
    availableQuota,
  } = props;

  return (
    <div className="CardsContainer">
      <div className="information">
        <div className="information--container">
          <div>
            <p className="information--title">{information.title}</p>
            <p className="information--description">
              {information.description}
            </p>
          </div>
          {information.otherDescription && (
            <p className="information--description">
              {information.otherDescription}
            </p>
          )}
          {availableQuota && <AvailableQuotaCard version="2" />}
        </div>
      </div>
      <div className="ListOfCardsToSelect">
        {data.map((value) => (
          <CardToSelect<T>
            data={value}
            key={value.id}
            cardHeaderJsx={cardHeaderJsx(value)}
            cardBodyJsx={cardBodyJsx(value)}
            buttonLabel={
              typeof buttonLabel === 'string' ? buttonLabel : buttonLabel(value)
            }
            cardDivider={cardDivider}
            selectedIntegration={selectedIntegration}
            buttonLabelIfSelected={buttonLabelIfSelected}
            onClick={onClick}
            isButtonLoading={isButtonLoading}
            buttonDisabled={buttonDisabled}
          />
        ))}
      </div>
      {extraButton}
    </div>
  );
}
