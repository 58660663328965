import { createContext } from 'react';
import { NotificationOptions } from './NotificationContextProvider';

export interface INotificationContext {
  openNotification: (op: NotificationOptions) => void;
}

export const notificationContext = createContext<INotificationContext>({
  openNotification: () => {},
});
