import { Button, ButtonProps } from 'antd';

const ButtonAction = (props: ButtonProps) => {
  const className = `btn-save ${props.className || ''}`;

  return (
    <Button className={className} {...props}>
      {props.children}
    </Button>
  );
};

export default ButtonAction;
