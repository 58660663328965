export const TWO_FACTOR_AUTH = `
  mutation twoFactorAuth($id: Int!, $token: String!) {
  twoFactorAuth(id: $id, token: $token) {
    __typename
    ... on ResponseMessage {
      status
      response
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const GENERATE_TOTP_URL = `
  mutation generateTotpUrl {
  generateTotpUrl {
    __typename
    ... on ResponseMessage {
      status
      response
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const DISABLE_TWO_FACTOR_AUTH = `
  mutation disableTwoFactorAuth {
  disableTwoFactorAuth {
    __typename
    ... on ResponseMessage {
      status
      response
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
