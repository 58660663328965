import {
  CheckOutlined,
  InfoCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { useContext } from 'react';
import { ContextApp } from '../../contexts/ContextApp';
import { Tools } from '../../shared';
import './CardIndicator.less';
interface ICardIndicatorProps {
  type: 'done' | 'incomplete' | 'error';
  quantity: number;
  percentageIncrease?: number | null;
  percentage: number;
  variation: number;
  icon?: JSX.Element;
  title?: string;
  subTitle?: string;
}

export const CardIndicator = (props: ICardIndicatorProps) => {
  const { t } = useContext(ContextApp);
  const {
    type,
    quantity,
    percentageIncrease,
    percentage,
    icon,
    title,
    subTitle,
  } = props;
  const getIconByType = () => {
    if (type === 'done') return <CheckOutlined />;
    if (type === 'incomplete') return <SyncOutlined />;
    return <InfoCircleOutlined />;
  };

  const getTitleByType = () => {
    if (type === 'done') return t('message.titleReceiptsDone');
    if (type === 'incomplete') return t('message.titleReceiptsIncomplete');
    return t('message.titleReceiptsError');
  };

  const getTitlePercentageByType = () => {
    if (type === 'done') return t('message.titlePercentageDone');
    if (type === 'incomplete') return t('message.titlePercentageIncomplete');
    return t('message.titlePercentageError');
  };

  return (
    <div className="card-indicator">
      <div className={`card-indicator__header ${type}`}>
        <div className="card-indicator__header__content">
          <span className="card-indicator__header__content__icon">
            {icon || getIconByType()}
          </span>
          <span className="card-indicator__header__content__title">
            {title || getTitleByType()}
          </span>
          <div className="card-indicator__header__content__container-indicators">
            <span className="card-indicator__header__content__container-indicators__quantity">
              {quantity < 0 ? 0 : quantity}
            </span>
            {percentageIncrease && (
              <div
                className={`card-indicator__header__content__container-indicators__container-variation ${type}-variation`}
              >
                <span className="card-indicator__header__content__container-indicators__container-variation__variation">
                  {percentageIncrease < 0
                    ? Tools.formatRealNumber(percentageIncrease, {
                        maximumFractionDigits: 1,
                      })
                    : `+${Tools.formatRealNumber(percentageIncrease, {
                        maximumFractionDigits: 1,
                      })}`}
                  %
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="card-indicator__content">
        <span className="card-indicator__content__title">
          {subTitle || getTitlePercentageByType()}
        </span>
        <span
          className={`card-indicator__content__percentage-label ${type}-color-percentage`}
        >
          {Tools.formatRealNumber(
            percentage > 100 ? 100 : percentage < 0 ? 0 : percentage,
            { maximumFractionDigits: 1 },
          )}
          %
        </span>
        <div className="card-indicator__content__container-progressbar">
          <div className="card-indicator__content__container-progressbar__progressbar-base">
            <div
              className={`card-indicator__content__container-progressbar__progressbar-base__progressbar ${type}`}
              style={{
                width: `${
                  percentage > 100 ? 100 : percentage < 0 ? 0 : percentage
                }%`,
              }}
            >
              &nbsp;
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
