import { useContext } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { ContextApp } from '../../contexts/ContextApp';

const DefaultRedirectPage = () => {
  const location = useLocation();
  const { user } = useContext(ContextApp);

  if (user) {
    return <Redirect to="/app/home" />;
  } else {
    return (
      <Redirect
        to={{
          ...location,
          pathname: '/login',
        }}
      />
    );
  }
};

export default DefaultRedirectPage;
