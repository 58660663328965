import { PageLoading } from '@ant-design/pro-layout';
import { NavContext } from '@ionic/react';
import { Button } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router';
import { ContextApp } from '../../contexts/ContextApp';
import { EnumsValues } from '../../enums/EnumsValues';
import { CustomMessage } from '../../hooks';
import GraphqlService from '../../services/graphql/GraphqlService';
import './ValidateEmailPage.less';
import { notificationContext } from '../../contexts/NotificationContext';

export const ValidateEmailPage = () => {
  const { t } = useContext(ContextApp);
  const { navigate } = useContext(NavContext);
  const [validateTokenIsValid, setValidateTokenIsValid] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>(false);
  const [userHasValidated, setUserHasValidated] = useState<boolean>(false);
  const [userAlreadyValidated, setUserAlreadyValidated] =
    useState<boolean>(false);
  const { search } = useLocation();
  const [firstReactLoad, setFirstReactLoad] = useState<boolean>(false);
  const queryStrings = new URLSearchParams(search);
  const token = queryStrings.get('token');
  const { Query, Mutation, customRequest } = GraphqlService();
  const { openNotification } = useContext(notificationContext);
  const { getErrorMessage } = CustomMessage();
  const history = useHistory();

  const validateValidationToken = async () => {
    /* Verificamos si el token es válido*/
    setLoading(true);
    try {
      const response = await customRequest({
        query: Query.verifyCompleteRegisterByEmailToken,
        variables: { token },
      });
      setValidateTokenIsValid(response.result);

      await completeValidation();
    } catch (error: any) {
      if (error.status_code) {
        if (
          error.status_code ===
          EnumsValues.ErrorsStatusCode.userIsAlreadyValidatedCode
        ) {
          setUserAlreadyValidated(true);
          setLoading(false);
          openNotification({
            type: 'success',
            msj: getErrorMessage(error),
            context: 'ValidateUpdatedEmailPage.validateValidationToken.1',
          });
          history.push({
            pathname: '/login',
          });
        } else {
          openNotification({
            type: 'error',
            msj: getErrorMessage(error),
            context: 'ValidateUpdatedEmailPage.validateValidationToken.2',
          });
        }
      }

      setValidateTokenIsValid(false);
    }

    setLoading(false);
  };

  const completeValidation = async () => {
    setLoading(true);
    try {
      await customRequest({
        query: Mutation.completeRegister,
        variables: {
          token,
        },
      });
      openNotification({
        type: 'success',
        msj: t('message.abm.userEmailValidatedSuccessfully'),
        context: 'ValidateUpdatedEmailPage.completeValidation.1',
      });
      setUserHasValidated(true);
    } catch (error: any) {
      openNotification({
        type: 'error',
        msj: getErrorMessage(error),
        context: 'ValidateUpdatedEmailPage.completeValidation.2',
      });
    }
    setLoading(false);
  };

  const resendValidateToken = async () => {
    setLoading(true);
    try {
      await customRequest({
        query: Mutation.resendValidateToken,
        variables: {
          expired_token: token,
          is_email_update: true,
        },
      });

      openNotification({
        type: 'info',
        msj: t('message.resendUpdateTokenSuccess'),
        context: 'ValidateUpdatedEmailPage.resendValidateToken.1',
      });
      navigate('/login');
    } catch (error: any) {
      openNotification({
        type: 'error',
        msj: getErrorMessage(error),
        context: 'ValidateUpdatedEmailPage.resendValidateToken.2',
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (firstReactLoad) {
      if (token) validateValidationToken();
    } else {
      setFirstReactLoad(true);
    }
  }, [firstReactLoad]);

  if (!token) return <Redirect to={'/'} />;
  return (
    <div className="complete-register-page">
      {loading ? (
        <>
          <PageLoading />
        </>
      ) : (validateTokenIsValid && userHasValidated) || userAlreadyValidated ? (
        <>
          <div className="complete-register-page__container-form">
            <h3 className="complete-register-page__container-form__title">
              {t('message.validateEmailTitle')}
            </h3>
            <p className="complete-register-page__container-form__password">
              {t('message.userValidationCompletedMessage')}
            </p>
            <Button type="primary" onClick={() => navigate('/login')}>
              {t('action.goToLogin')}
            </Button>
          </div>
        </>
      ) : userAlreadyValidated ? (
        <>
          <div>
            <p>{t('message.userValidationAlreadyDoneMessage')}</p>
            <Button type="primary" onClick={() => navigate('/login')}>
              {t('action.goToLogin')}
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="complete-register-page__container-token-is-expired">
            <h3 className="complete-register-page__container-token-is-expired__title">
              {t('message.completeRegisterTokenIsExpiredTitle')}
            </h3>
            <p>{t('message.tokenIsExpiredMessage')}</p>
            <div className="complete-register-page__container-token-is-expired__container-buttons">
              <Button
                className="complete-register-page__container-token-is-expired__container-buttons__button-resend-activation-link"
                onClick={() => resendValidateToken()}
              >
                {t('action.sendActivationMail')}
              </Button>
              <br />
              <Button
                className="complete-register-page__container-token-is-expired__container-buttons__button-go-to-login"
                type="link"
                onClick={() => navigate('/login')}
              >
                {t('action.goToLogin')}
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
