export const UPSERT_USERTENANT = `
mutation upsertUserTenant($input: UpsertUserTenantInput!) {
  upsertUserTenant(input: $input) {
    __typename
    ... on Count {
      count
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const CREATE_TENANT = `
mutation createTenant($input: CreateTenantInput!, $file: Upload) {
  createTenant(input: $input, file: $file) {
    __typename
    ... on Tenant {
      id
      name
      tenant_status_id
      tenant_status{
        id
        name
      }
      tax_type_id
      tax_type{
        id
        code
        name
        active
        translation_key
        regular_expression
        error_message
        error_key
      }
      its
      billing_name
      address
      tax_number
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const DELETE_TENANT = `
mutation deleteTenants($ids: [Int!]!) {
  deleteTenants(ids: $ids) {
    __typename
    ... on Count {
      count
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const UPDATE_TENANT = `
mutation updateTenant($id: Int!, $input: UpdateTenantInput!, $file: Upload) {
  updateTenant(id: $id, input: $input, file: $file) {
    __typename
    ... on Tenant {
      id
      name
      tenant_status_id
      tenant_status{
        id
        name
      }
      tax_type_id
      tax_type{
        id
        code
        name
        active
        translation_key
        regular_expression
        error_message
        error_key
      }
      its
      billing_name
      address
      tax_number
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const UPDATE_TENANT_INTEGRATION = `
mutation updateTenantIntegration($tenant_id: Int!, $integration_selected_id: Int!, $integration_id: Int, $integration_finishes_in_finished: Boolean ) {
  updateTenantIntegration(
    tenant_id: $tenant_id
    integration_selected_id: $integration_selected_id
    integration_id: $integration_id
    integration_finishes_in_finished:$integration_finishes_in_finished
  ) {
    __typename
    ... on Tenant {
      id
      name
      integration_id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const UNSUBSCRIBE_TENANT = `
mutation unsubscribeTenant($tenant_id: Int!) {
  unsubscribeTenant(tenant_id: $tenant_id) {
    __typename
    ... on Tenant {
      tenant_status_id
    }

    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const SET_TENANT_STATUS_ACTIVO = `
mutation setTenantStatusActivo($tenant_id: Int!) {
  setTenantStatusActivo(tenant_id: $tenant_id) {
    __typename
    ... on Tenant {
      id
      name
      tenant_status_id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
