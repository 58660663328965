export const UPSERT_REVIEW_RECEIPT_DETAIL_FIELD = `
mutation upsertReviewReceiptDetailField($input: UpsertReviewReceiptDetailFieldInput!){
    upsertReviewReceiptDetailField(input:$input)
    {
        __typename
        ... on ReviewReceiptDetailField {
            id
            receipt_detail_id
            field
            value
            json_value
            page_number
            its,
            uts,
            dts
        }
        ...on ResultError {
            status_code
            message
            message_translation_key
        }
    }
}
`;

export const DELETE_MANY_REVIEW_RECEIPT_DETAIL_FIELD = `
mutation deleteManyReviewReceiptDetailField($input: DeleteReviewReceiptDetailFieldInput!){
    deleteManyReviewReceiptDetailField(input:$input)
    {
        __typename
        ... on ResponseMessage {
            status
            response
        }
        ...on ResultError {
            status_code
            message
            message_translation_key
        }
    }
}
`;