export const SEEN_TOURS = `
query seenTours {
  seenTours {
    __typename
    ... on SeenTours {
      seenTours
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
