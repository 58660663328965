import { useEffect, useState } from 'react';
import './TermsAndConditions.less';
import { IAppSetting } from '../../interfaces';
import GraphqlService from '../../services/graphql/GraphqlService';
import { EnumsValues } from '../../enums/EnumsValues';

export const TermsAndConditions = () => {
  const { Query, customRequest } = GraphqlService();
  const [supportEmail, setSupportEmail] =
    useState<string>('soporte@braulio.io');

  const getSupportEmail = async () => {
    try {
      const data: IAppSetting = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingNames.supportEmail },
        },
      });
      if (data.setting_value) {
        setSupportEmail(data.setting_value);
      }
    } catch (error) {
      //intentional
    }
  };

  useEffect(() => {
    getSupportEmail();
  }, []);
  return (
    <div className="termsandconditions">
      <div className="termsandconditions-header">
        <div className="termsandconditions-header-logo">
          <img src="assets/icon/logo_braulio_color.png" alt="logo" />
        </div>
      </div>
      <div className="termsandconditions-body">
        <div className="termsandconditions-body-main">
          <p className="termsandconditions-body-main-header--centered">
            TÉRMINOS Y CONDICIONES DE USO - BRAULIO®
          </p>

          <p className="termsandconditions-body-main-header">
            1.- ACEPTACIÓN DE TÉRMINOS Y CONDICIONES
          </p>
          <p>
            A continuación se detallan los Términos y Condiciones de Uso de la
            Plataforma Digital BRAULIO, en adelante denominada BRAULIO®. Todos
            los servicios de BRAULIO®, en sus diversas modalidades y plataforma
            son provistos por la empresa Compañía Latinoamericana de Sistemas
            SAS, en adelante denominada LA EMPRESA. Es requisito indispensable
            para la utilización de BRAULIO® y sus servicios asociados, la
            aceptación de los presentes Términos y Condiciones por parte de los
            Usuarios. Estos Términos y Condiciones no podrán ser modificados por
            parte de los Usuarios, sin perjuicio que podrán ser completados,
            modificados, ampliados, o disminuidos a futuro por LA EMPRESA, sin
            estar sujeta para ello a ninguna condición en particular. <br />
            Los Usuarios que se registren en la Plataforma BRAULIO® y/o hagan
            uso de la misma, reconocen expresamente que LA EMPRESA es la única
            que ejerce y goza de todos los derechos inherentes y emergentes a la
            propiedad intelectual del software, y por tanto su protección y
            tutela. <br />
            <span className="tac-bold-text">
              El registro de cualquier persona como Usuario de BRAULIO® implica
              la aceptación de los presentes Términos y Condiciones, por tal
              motivo es condición previa al registro haber examinado de forma
              detallada los mismos. El Usuario sólo podrá acceder a su registro
              una vez finalizada la lectura de los presentes Términos y
              Condiciones de Uso. <br />
              Por todo lo expuesto el Usuario declara conocer y aceptar que al
              registrarse como Usuario de BRAULIO®, automáticamente acepta de
              los presentes Términos y Condiciones en forma total e
              incondicionada.
            </span>
          </p>
          <p className="termsandconditions-body-main-header">
            2.- CAPACIDAD PARA CONTRATAR
          </p>
          <p>
            Sólo podrán utilizar la plataforma BRAULIO® aquellas personas
            mayores de edad o emancipadas que no cuenten con restricciones de
            ninguna naturaleza en la capacidad de contratar. Estas restricciones
            no podrán ser transitorias ni definitivas, y se constituyen en un
            impedimento para poder acceder al servicio. Quedan directamente
            impedidos de utilizar este servicio aquellas personas menores de
            edad, o que se encuentren transitoriamente o en forma definitiva
            restringidos en su capacidad de contratación. Rige en cuanto a la
            capacidad para contratar las disposiciones vigentes aplicables del
            Código Civil y Comercial de la República Argentina (arts 1000, 1001
            cc sgtes y concordantes), y también cualquier otra disposición que a
            futuro pueda complementar, ampliar o disminuir la capacidad de
            contratar de las personas{' '}
            <span className="tac-bold-text">
              En tal sentido al registrarse el Usuario en la plataforma declara
              conocer y aceptar la presente condición.
            </span>{' '}
            Si el Usuario fuera una persona jurídica, LA EMPRESA podrá exigir
            que el mismo acredite su capacidad para representar a la misma, ya
            sea como representante legal, apoderado, mandatario o cualquier otro
            título que lo habilite para la celebrar contratos y actuar en nombre
            del Usuario.
          </p>
          <p className="termsandconditions-body-main-header">
            3.- DESCRIPCIÓN DEL SERVICIO
          </p>
          <p>
            BRAULIO® es una plataforma digital que facilita la recolección y
            extracción de datos de comprobantes digitales y su correspondiente
            gestión. Le permite al Usuario que adjunte comprobantes en formato
            PDF, los reconoce y extrae la información. La plataforma permite
            exportar la información en formato Excel o CSV; o integrarse con un
            sistema de gestión de forma automática, sin perjuicio que el mismo
            podrá ser adaptado a futuro a otro tipo de archivos o comprobantes
            que se requiera recolección. Para llevar a cabo la tarea, la
            plataforma toma comprobante por comprobante cargado por el Usuario
            en la sección prevista a tal fin, realiza la extracción e interactúa
            con la plataforma web para mantener actualizado el estado de cada
            uno. Concluye el proceso al momento de digitalizar los datos de los
            comprobantes para que luego el Usuario los exporte o los integre en
            su sistema de gestión. Los comprobantes son almacenados en un
            entorno CLOUD de AWS, dentro de la cuenta contratada por LA EMPRESA.
            <br />
            LA EMPRESA se reserva la facultad y posibilidad de limitar
            transitoriamente o en forma definitiva distintas o determinadas
            características y servicios de BRAULIO®. LA EMPRESA podrá incluso
            restringir el acceso a la plataforma, en forma total o parcial sin
            previo aviso, cuando mediare necesidad de mantenimiento o incluso
            sin expresar ningún tipo de causa. La continuidad del uso por parte
            del Usuario respecto de la plataforma, una vez introducidos
            eventuales cambios o modificaciones, implica la aceptación integral
            de las condiciones de uso de las nuevas funcionalidades. <br />
            En caso de interrupción del servicio por cualquier causa, el Usuario
            acepta que no generará o devengará derecho a su parte a obtener
            créditos, suma de dinero o cualquier otro beneficio, liberalidad o
            reconocimiento por parte de LA EMPRESA, aún cuando la interrupción
            dure periodos prolongados. El Usuario renuncia por medio del
            presente a realizar cualquier reclamo y/a acción contra LA EMPRESA
            por tal motivo. <br />
            LA EMPRESA podrá restringir, limitar o suspender, en forma temporal
            o permanente el acceso de cualquier USUARIO a la plataforma, aún sin
            expresión de causa y por el tiempo que considere necesario, sin que
            dicha circunstancia le confiera derecho alguno al Usuario a reclamar
            compensación, indemnización o cualquier otro tipo de emolumento en
            su favor, renunciando en este acto a hacer cualquier reclamo y/o
            acción en tal sentido.
          </p>
          <p className="termsandconditions-body-main-header">
            4.- ACCESO A BRAULIO®
          </p>
          <p>
            El Usuario accede a BRAULIO® a través de la página web de la
            plataforma situada en la dirección{' '}
            <span className="tac-bold-text">BRAULIO.io</span>. El mismo deberá
            registrarse como Usuario de la plataforma, creando una cuenta
            comercial (tenant). Esta cuenta comercial quedará determinada a
            través de un identificador alfanumérico único para el Usuario,
            asociado automáticamente a la dirección de correo electrónico
            ingresada al momento de registrarse. El Usuario deberá confirmar su
            e-mail a través de un enlace que recibe en su bandeja de entrada,
            quedando operativo el sistema a partir de dicho momento con la
            consecuente configuración de una clave. En cada oportunidad que el
            Usuario desee utilizar BRAULIO®, será necesario el ingreso de su
            Usuario y contraseña -logueo- La cuenta es absolutamente
            intransferible, y solo para el uso particular y exclusivo del
            Usuario registrado. El Usuario reconoce que una vez que ejecuta el
            logueo, y en consecuencia ingresa su Usuario y contraseña a la
            plataforma, este queda estrictamente bajo su dominio sin posibilidad
            de injerencia en el manejo de información por parte de LA EMPRESA.
          </p>
          <p className="termsandconditions-body-main-header">
            5.- SUPUESTOS DE USO INDEBIDO DE BRAULIO®- PROHIBICIONES
          </p>
          <p>
            En caso que LA EMPRESA detecte la utilización indebida de BRAULIO®
            y/o su uso fuera de los parámetros establecidos a través de estos
            Términos y Condiciones, podrá en forma automática, y sin
            requerimiento o necesidad de interpelación alguna, suspender la
            utilización de la plataforma. En caso de suspensión del uso de la
            plataforma, sin importar el tiempo o cantidad de créditos
            contratados, LA EMPRESA no tendrá obligación alguna de restituir
            ninguna suma de dinero por aquellos días, periodos o créditos
            pendientes de uso hasta el momento de la suspensión, incluso si
            fuese definitiva. <br />
            <span className="tac-bold-text">
              Quedan expresamente prohibidas las siguientes acciones por parte
              del Usuario:
            </span>{' '}
            incluir rastreadores de página de la plataforma; intentar el acceso
            y descifrado del código fuente, lo que implica la abstención de
            descompilar, acceder al código fuente, reproducir, realizar
            ingeniería inversa o intentar derivar el código fuente, sus
            algoritmos, lo que incluye cualquier parte de los servicios o de la
            aplicación que lo conforme y/o de cualquier software del cual se
            pueda valer BRAULIO® para su funcionamiento y operatividad; copiar,
            alquilar, distribuir, o transferir cualquier derecho o beneficio que
            reciba el Usuario mediante los Términos y Condiciones presentes del
            Servicio o los que se establezcan a futuro. <br />
            Queda prohibida la reproducción total o parcial del software, por
            cualquier medio y bajo cualquier forma, permanente o transitoria; la
            traducción, adaptación, arreglo o cualquier otra transformación y la
            reproducción de los resultados que surjan, y cualquier forma de
            distribución pública, incluido eventual alquiler del software o
            cesión de uso a terceros no autorizados sea a título oneroso o
            gratuito. <br />
            Estos actos constituyen un incumplimiento ante el cual LA EMPRESA se
            reserva el derecho de accionar, sea en vía civil, comercial o penal
            según corresponda, a los fines de obtener reparación de todo daño y
            perjuicio que tal accionar le ocasione. <br />
            En ningún caso LA EMPRESA será responsable del uso indebido y/o
            fraude en que el Usuario y/o terceros puedan incurrir a través de la
            utilización de BRAULIO® y sus servicios. Para el supuesto que LA
            EMPRESA se vea involucrada en cualquier tipo de proceso ya sea
            judicial, administrativo y/o de cualquier otro tipo, con motivo de
            conductas ejecutadas por el Usuario en la plataforma, éste será el
            único y exclusivo responsable de reintegrar y reembolsar todos
            aquellos gastos casuísticos, costas, honorarios y todos aquellos y
            cualesquiera que se ocasionen en eventual defensa de sus derechos
            que tenga que encaminar LA EMPRESA. <br />
            <span className="tac-bold-text">
              Al aceptar estos Términos y Condiciones, el Usuario acepta
              expresamente en brindar indemnidad a LA EMPRESA ante cualquiera de
              las circunstancias descritas en la presente cláusula, o cualquier
              otra que pueda involucrarla, frente a terceras personas, físicas o
              jurídicas, instituciones públicas o privadas.
            </span>{' '}
            Los Usuarios liberan expresamente de responsabilidad a LA EMPRESA
            por cualquier reclamo de terceros referidos a la utilización del
            servicio.
          </p>
          <p className="termsandconditions-body-main-header">
            6.- TARIFAS POR USO DE BRAULIO®
          </p>
          <p>
            <span className="tac-bold-text">6.1.-</span> Una vez concretado el
            acto de registro que permite ingresar al Usuario a la plataforma,
            éste accederá a BRAULIO®, donde podrá adquirir un pack de créditos
            para poder procesar los comprobantes o realizar una prueba gratuita,
            a saber: <br />
            <span className="tac-bold-text">a) Prueba gratuita:</span> consiste
            en la posibilidad de utilizar, por única vez, diez (10) créditos
            para procesar 10 comprobantes por medio de la plataforma sin costo
            alguno, una vez utilizado estos diez (10) créditos no tendrá
            posibilidad alguna de poder procesar más comprobantes. En el caso de
            no utilizar la totalidad de los diez (10) créditos otorgados de
            forma gratuita, la cuenta permanecerá habilitada por un plazo
            determinado, en el cual los créditos poseen fecha de vencimiento,
            una vez cumplido el vencimiento, no podrán ser utilizados. <br />
            <span className="tac-bold-text">
              b) Compra y pago de créditos:
            </span>{' '}
            deberá realizarse a través de MercadoPago. El tipo de servicio que
            desee contratar deberá ser seleccionado escogiendo alguno de los
            packs disponibles que se verán reflejados al ingreso de la
            plataforma.{' '}
            <span className="tac-bold-text">
              A cambio de un crédito el Usuario podrá procesar un comprobante.
            </span>{' '}
            Los créditos tienen fecha de vencimiento según el pack adquirido
            para cada tarifa en particular. Para el caso que por motivos ajenos
            a LA EMPRESA, eventualmente el comprobante no arribe al estado
            “terminado”, el crédito será consumido de igual modo. En tal caso el
            Usuario primero deberá constatar el estado técnico de sus propios
            equipos, conexión a Internet, los soportes físicos, sistema
            operativo y de conexión propios, para luego, en caso de persistir
            inconvenientes, comunicarse con LA EMPRESA detallando claramente el
            incidente, con captura de pantalla y toda información que sea
            necesaria o relevante, dirigiéndose al siguiente correo electrónico:{' '}
            <a href={`mailto:${supportEmail}`}>
              <span className="tac-bold-text">{supportEmail}</span>
            </a>
            . LA EMPRESA deja constancia que la plataforma BRAULIO® cuenta con
            una funcionalidad por la cual si la cuenta registrada no loguea
            Usuarios por un término de noventa (90) días corridos, BRAULIO® dará
            de baja la cuenta comercial eliminando todos los archivos y datos de
            la misma. El Usuario declara conocer y aceptar esta condición, y
            renuncia a realizar cualquier reclamo en tal sentido. <br />
            <span className="tac-bold-text">6.2.-</span> En caso que el Usuario
            requiera de algún tipo de conexión o plan específico distinto de
            aquellos automáticos o preestablecidos que la plataforma ofrezca,
            podrá contactarse con el área de ventas. La plataforma dispondrá
            para dichos fines con un botón de whatsapp o cualquier otro método
            que a futuro pueda incorporar de comunicación. <br />
            <span className="tac-bold-text">6.3.-</span> LA EMPRESA podrá en
            cualquier momento modificar o reemplazar cualquiera de las tarifas
            previstas para cada servicio, incluso todo otro concepto sujeto a
            facturación a través de la plataforma, para lo cual se obliga a
            notificar previamente ya sea a través del sitio o la aplicación,
            correo electrónico o el medio digital que estime conducente a dicho
            fines en los plazos indicados previamente. <br />
            <span className="tac-bold-text">6.4.-</span> LA EMPRESA, a su
            exclusivo criterio, podrá establecer el uso y sistema de premios
            para sus Usuarios en función de sus consumos, o cualquier otro
            parámetro que estime oportuno, tales como sus volúmenes de consumo
            de créditos o cualquier otro que a futuro estime pertinente. Los
            mismos podrán consistir en eventuales descuentos, bonificaciones y/o
            créditos de regalo. También, a su exclusivo criterio, LA EMPRESA
            podrá modificar o finalizar los mismos. <br />
            <span className="tac-bold-text">6.5.-</span> Una vez adquiridos y
            abonados los servicios de BRAULIO®, LA EMPRESA remitirá al Usuario
            la respectiva factura.
          </p>
          <p className="termsandconditions-body-main-header">
            7.- CANCELACIÓN DE LICENCIAS DE TERCEROS
          </p>
          <p>
            BRAULIO® funciona con procesos internos y tecnología RPA (Robot
            process automation) para reconocer y extraer información de los
            comprobantes, y para integrar algunos de los sistemas de gestión sin
            utilizar API. Los Usuarios reconocen y aceptan expresamente la
            propiedad y titularidad de sus respectivos dueños de cada software y
            proceso a utilizarse, como titularidad en las licencias y
            habilitación para la explotación de los procesos que correspondan,
            en cabeza de cada uno de sus propietarios o titulares. Con motivo de
            lo referido previamente el Usuario declara asimismo conocer y
            aceptar esta situación y por consiguiente también declara conocer
            que las referidas licencias pueden ser canceladas, modificadas,
            alteradas o reemplazadas por otras, o versiones distintas por
            decisión unilateral de sus titulares, propietarios o poseedores de
            dichas licencias. Esta circunstancia eventualmente puede afectar la
            plataforma y en consecuencia no funcionar, ya sea en forma
            circunstancial, transitoria o quizás hasta definitiva. En dicho
            caso, y particularmente ante eventual cancelación de licencias por
            parte de terceros, LA EMPRESA realizará los esfuerzos que estén a su
            alcance para restituir la funcionalidad y plena operatividad; y en
            el supuesto que esto devenga imposible y consecuentemente implique
            la cancelación definitiva de BRAULIO®, se regirá por las cláusulas
            que más abajo se expresarán para el supuesto de finalización del
            servicio Bajo estas circunstancias y de suceder cancelación de
            licencias por parte de terceros que impidan el funcionamiento de
            BRAULIO®, los Usuarios declaran conocer y aceptar que LA EMPRESA
            nada le adeudará por tal motivo, por tratarse de una causa ajena por
            la cual no debe responder, ya sea por conceptos eventualmente
            indemnizatorios y/o cualquier otra índole, y SOLO se encontrará
            obligada a restituir aquellos importes o créditos pendientes de uso
            por servicios eventualmente no prestados o no recibidos por el
            Usuario a través de BRAULIO®.
          </p>
          <p className="termsandconditions-body-main-header">
            8.- SEGURIDAD DE ACCESO
          </p>
          <p>
            Dentro de BRAULIO® el Usuario podrá activar la autenticación por
            doble factor para añadir mayor seguridad a su cuenta. LA EMPRESA no
            es responsable en el caso que el Usuario comparta su clave de acceso
            o brinde acceso a personas determinadas o ajenas a la organización.
            Las claves o Password suministradas por el sistema son
            intransferibles y personales de cada Usuario que contrata el
            servicio a través de la plataforma, y por consiguiente es
            responsable de las consecuencias que puedan surgir por el hecho de
            eventualmente haberlas transferido o divulgado de algún modo. LA
            EMPRESA podrá cancelar en forma automática el servicio en el
            supuesto que detecte transferencia o que se comparta indebidamente
            la clave suministrada al Usuario.
          </p>
          <p className="termsandconditions-body-main-header">
            9.- PROPIEDAD INTELECTUAL
          </p>
          <p>
            El Usuario reconoce y acepta que la propiedad intelectual de BRAULIO
            ®, es única y exclusiva de LA EMPRESA. También reconoce que la marca
            y aquellos elementos de diseño y estéticos, tales como logos,
            cartelería y cualquier otro bien inmaterial que exista en el sitio o
            fuera de este, incluso su denominación BRAULIO®, son de propiedad
            única y exclusiva de LA EMPRESA.
          </p>
          <p className="termsandconditions-body-main-header">
            10.- POLÍTICA DE PRIVACIDAD
          </p>
          <p>
            LA EMPRESA se compromete a realizar todas las acciones tendientes
            para proteger la privacidad de la información personal de los
            Usuarios de la plataforma. Por tal motivo LA EMPRESA pone en
            conocimiento del Usuario que a partir de la plataforma BRAULIO®
            podrá recopilar información general. Tal información puede incluir
            los siguientes datos: nombre completo y/o razón social, nombre de
            facturación, domicilio, tipo de identificador fiscal, número de
            identificador fiscal, dirección de correo de acceso y habilitación
            de cuenta para utilizar la plataforma BRAULIO®, dirección de correo
            remitente para el envío de comprobantes a procesar en la plataforma
            BRAULIO®, nombre de dominio y el sitio web específico al que acceda
            el Usuario. Dicha Información General, puede ser recogida cada vez
            que el Usuario acceda o use el servicio. La finalidad de la
            recolección de información es para que LA EMPRESA ofrezca a los
            Usuarios servicios y funcionalidades que se adecuen a sus
            necesidades, y así mismo para personalizar servicios, actualizar
            mejoras, sean de rendimiento o aptitudes, todo ello para hacer que
            la experiencia con BRAULIO® sea lo más satisfactoria posible. Los
            fines de obtención de información personal son pura y exclusivamente
            para el desarrollo y mejora del software y en ningún caso para
            comercializar datos, información o cualquier otra circunstancia con
            terceros ajenos a LA EMPRESA. Asimismo, LA EMPRESA pone en
            conocimiento del Usuario que podría utilizar cookies para realizar
            un seguimiento de la actividad en el uso de BRAULIO®. La información
            proporcionada por las cookies será utilizada por LA EMPRESA al solo
            efecto de mejorar la experiencia del Usuario con BRAULIO®. La
            Información Personal será tratada de acuerdo con todas las
            exigencias legales vigentes y aplicables en el territorio de la
            República Argentina. <br />
            LA EMPRESA se reserva el derecho, a su exclusivo criterio, sin
            necesidad de evacuar notificación alguna al Usuario, de cambiar o
            modificar estas Políticas de Privacidad, en cualquier momento, sin
            sujeción a plazo o condición alguna. Tales cambios tendrán efecto
            inmediatamente luego de su publicación en la Plataforma o a partir
            de que LA EMPRESA notifique al Usuario de estos, lo que ocurra
            primero. Si luego de la publicación de los cambios el Usuario
            continúa utilizando a BRAULIO®, tal uso implicará la aceptación de
            tales modificaciones y su adhesión incondicionada a la política de
            privacidad de LA EMPRESA. <br />
            No obstante, las medidas de seguridad implementadas por LA EMPRESA,
            el Usuario declara conocer y aceptar que cada vez que brinda
            información personal en Internet, existe potencial riesgo de que
            terceras personas puedan interceptar y utilizar esa información, por
            tal motivo reconoce y acepta que LA EMPRESA los datos proporcionados
            y/o recibidos, pueden ser interceptados de algún modo,
            independientemente de los esfuerzos que la misma realice a fin de
            evitar tal circunstancia. <br />
            LA EMPRESA podrá compartir y divulgar Información a requerimiento de
            la ley, sentencia judicial o medida cautelar de autoridad competente
            que así lo requiera, o cuando sea necesario hacer cumplir o aplicar
            los Términos y Condiciones y otros acuerdos, o para proteger los
            derechos, propiedad o seguridad de LA EMPRESA, sus empleados,
            clientes u otras personas o Usuarios de la BRAULIO®. <br />
            En caso de que LA EMPRESA detecte por parte de cualquier Usuario de
            BRAULIO® actividad ilegal, contraria a las buenas costumbres, la
            moral, o violación de cualquier norma jurídica, o perjuicios a
            terceros u otros Usuarios, podrá efectuar eventuales denuncias que
            estime pertinente y en consecuencia divulgar información personal
            del Usuario o los Usuarios conforme sea necesario.
          </p>
          <p className="termsandconditions-body-main-header">
            11.- LIMITACIÓN DE GARANTÍA
          </p>
          <p>
            El Usuario declara haber sido debidamente informado respecto de las
            características y formas de utilización de BRAULIO® y por tanto
            asume la total responsabilidad por la elección de la plataforma para
            el logro de los resultados. LA EMPRESA no garantiza al Usuario
            obtención de un resultado determinado por el uso de BRAULIO® y por
            lo tanto no será responsable en tal sentido. Asimismo LA EMPRESA no
            garantiza que la plataforma, en todo o en parte, se encuentre libre
            de errores, funciona sin interrupción, es compatible con cualquier
            hardware o configuración de software.
          </p>
          <p className="termsandconditions-body-main-header">
            12.- LIMITACIÓN DE RESPONSABILIDAD
          </p>
          <p>
            LA EMPRESA declara y el Usuario acepta que, en ningún caso LA
            EMPRESA será responsable por cualquier daño directo, indirecto,
            consecuencial, incluyendo, pero no limitado a: pérdida de
            beneficios, pérdida de ganancias, pérdida de datos y/o pérdidas de
            chance y/o cualquier otra relacionada con el uso del SOFTWARE. En
            ningún caso LA EMPRESA, sus sociedades vinculadas y/o contratistas,
            tendrán responsabilidad por daños especiales, emergentes o
            indirectos o daños por lucro cesante, sean fundados en
            incumplimiento contractual, responsabilidad extracontractual
            (incluida la culpa), u otros. <br />
            LA EMPRESA no es responsable frente al Usuario, por cualquier
            inconveniente ocasionado por la carga deficiente de datos que el
            Usuario realice en el uso de la plataforma. <br />
            LA EMPRESA no se hace responsable frente a la eventual pérdida de
            archivos, independientemente de la causa que afecte o impida el
            acceso a los mismos. Por tal motivo el Usuario será el único
            responsable de contar con los respectivos backup de toda aquella
            documentación en su poder que carga y le pueda ser útil, necesaria o
            requerida ya sea para sí mismo o para eventuales organismos del
            Estado en sus diversas administraciones o incluso respecto de
            terceros. LA EMPRESA no conserva para sí misma ningún tipo de
            información o archivos de sus clientes ni cuenta con backups,
            respaldos o cuentas de seguridad. De modo tal que ante la eventual
            pérdida de archivos o información queda exenta de cualquier tipo de
            responsabilidad al respecto. El Usuario declara conocer y aceptar
            esta condición y renuncia a realizar cualquier acción en tal
            sentido.
          </p>
          <p className="termsandconditions-body-main-header">13.- INDEMNIDAD</p>
          <p>
            El Usuario exime de toda y cualquier responsabilidad, y en
            consecuencia mantendrá indemne a LA EMPRESA, sus miembros,
            empleados, socios y accionistas por cualquier reclamo, sea judicial
            o extrajudicial, o demanda formulada por cualquier Usuario y/o
            tercero, por cualquier infracción a los Términos y Condiciones,
            cualquier ley y/o derechos de terceros, abarcando toda y cualquier
            circunstancia en que LA EMPRESA se pueda ver involucrada, sea
            judicial o extrajudicial, incluso mediaciones prejudiciales, en
            cualquier fuero y jurisdicción incluida la federal, y de cualquier
            naturaleza, sea civil, penal, laboral, comercial, administrativo o
            contencioso administrativo o cualquiera que se trate.
          </p>
          <p className="termsandconditions-body-main-header">
            14.- CASO FORTUITO Y FUERZA MAYOR
          </p>
          <p>
            LA EMPRESA queda excluida de toda y cualquier responsabilidad frente
            a cualquier catástrofe natural, huelga, cierre forzoso de cualquier
            índole, incluido el lock out, interrupción en el suministro de
            energía eléctrica, teléfono, telecomunicaciones, y cualquier otra
            causa natural, tecnológica, política o económica o cualquier otra
            que se trate, no imputable o atribuible a LA EMPRESA. Las demoras
            que puedan existir en la restitución del servicio, sea a causa de la
            fuerza mayor o caso fortuito o cualquier otra que implique
            interrupción del funcionamiento de BRAULIO®, no dará lugar o derecho
            a ningún tipo de indemnización al Usuario, quien renuncia en este
            acto a realizar cualquier reclamo y/o acción en tal sentido.
          </p>
          <p className="termsandconditions-body-main-header">
            15.- INEXISTENCIA DE RELACIÓN DE CONSUMO
          </p>
          <p>
            Las operaciones objeto del presente que se realicen utilizando
            BRAULIO® es entre sujetos de derecho que no son consumidores finales
            y forman parte de una etapa productiva, motivo por el cuál la
            relación jurídica prestataria del servicio a través de BRAULIO® se
            encuentra plenamente excluida de todas las normas y disposiciones
            que rigen el derecho del consumo.
          </p>
          <p className="termsandconditions-body-main-header">
            16.- RESCISIÓN SIN CAUSA
          </p>
          <p>
            En cualquier momento durante la vigencia del presente, LA EMPRESA
            podrá rescindir y concluir el servicio sin necesidad de expresión de
            causa ni preaviso. El Usuario reconoce que LA EMPRESA nada adeudará,
            sea por conceptos eventualmente indemnizatorios y/o cualquier otro,
            con motivo de la recisión del contrato y por tal motivo renuncia a
            cualquier acción y/o reclamo en tal sentido. <br />
            En tal caso LA EMPRESA solo se encontrará obligada a restituir
            aquellos importes por servicios o créditos eventualmente no
            prestados o no recibidos por el Usuario a través de BRAULIO®. El
            plazo de devolución nunca será inferior a los 10 días hábiles desde
            la cancelación o rescisión sin causa del servicio.
          </p>
          <p className="termsandconditions-body-main-header">
            17.- LEY APLICABLE Y JURISDICCIÓN
          </p>
          <p>
            Los presentes Términos y Condiciones se regirán e interpretarán de
            conformidad con las leyes de la República Argentina. Cualquier
            controversia derivada de los mismos, su existencia, validez,
            interpretación, alcance o cumplimiento, será sometida a la
            Jurisdicción de los Tribunales ordinarios de la ciudad de Concepción
            del Uruguay, Provincia de Entre Ríos, República Argentina,
            renunciando los Usuarios a cualquier otro fuero y jurisdicción que
            les pudiera corresponder, incluso el federal.
          </p>
        </div>
      </div>
    </div>
  );
};
