const Authorization = () => {};

/**
 * Verifies authentication and authorization of user.
 * @param user context user.
 * @param systemFunction function id or code which is checked with the user role (optional).
 * @returns retorna true en caso de que el usuario cuente con el permiso correspondiente.
 */
Authorization.security = (functions: string[], systemFunction: string) => {
  if (!functions) return false;
  return functions.indexOf(systemFunction) > -1;
};

export { Authorization };
