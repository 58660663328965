import './CardStatisticsEntities.less';
interface ICardStatisticsEntitiesProps {
  icon: JSX.Element;
  title: string;
  frequency: number;
}
export const CardStatisticsEntities = ({
  frequency,
  title,
  icon,
}: ICardStatisticsEntitiesProps) => {
  return (
    <div className="card-statistics-entities">
      <div className="card-statistics-entities__container-icon">{icon}</div>
      <div className="card-statistics-entities__container-title">{title}</div>
      <div className="card-statistics-entities__container-frequency">
        {frequency}
      </div>
    </div>
  );
};
