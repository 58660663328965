import { Badge, List, Popover, Spin } from 'antd';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ContextApp } from '../../../../contexts/ContextApp';
import { EnumsValues } from '../../../../enums/EnumsValues';

import useNotification from '../../../../hooks/useNotification';
import { INotificationItem } from '../../../../interfaces/Notification';
import './index.less';
import Notification from './Notification/Notification';

const Notifications = () => {
  const { t, notifications } = useContext(ContextApp);
  const [notificationsList, setNotificationsList] = useState<
    INotificationItem[]
  >([]);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const { loading } = useNotification({
    primary_execute: true,
  });

  useEffect(() => {
    const MissBillingDataNotificationItem: INotificationItem = {
      id: EnumsValues.NotificationsItemIds.MissBillingData,
      title: t('notifications.title.missBillingDataTitle'),
      description: t('notifications.description.missBillingDataDescription'),
      linkTo: EnumsValues.NotificationsItemLinkTo.MissBillingData,
    };
    const MissDataInDecisionTableNotificationItem: INotificationItem = {
      id: EnumsValues.NotificationsItemIds.MissDataInDecisionTable,
      title: t('notifications.title.thereIsUnrelatedDataTitle'),
      description: t('notifications.description.missDataRelationDescription'),
      linkTo: EnumsValues.NotificationsItemLinkTo.MissDataInDecisionTable,
    };
    const QuotaIsExpiredNotificationItem: INotificationItem = {
      id: EnumsValues.NotificationsItemIds.QuotaIsExpired,
      title: t('notifications.title.quotaIsExpiredTitle'),
      description: t('notifications.description.quotaIsExpiredDescription'),
      linkTo: EnumsValues.NotificationsItemLinkTo.QuotaIsExpired,
    };
    const ThereIsNotQuotaNotificationItem: INotificationItem = {
      id: EnumsValues.NotificationsItemIds.ThereIsNotQuota,
      title: t('notifications.title.thereIsNotQuotaTitle'),
      description: t('notifications.description.thereIsNotQuotaDescription'),
      linkTo: EnumsValues.NotificationsItemLinkTo.ThereIsNotQuota,
    };
    if (notifications?.missBillingData) {
      setNotificationsList((oldState) => {
        const newState = [...oldState];
        if (
          !newState.find((ele) => ele.id === MissBillingDataNotificationItem.id)
        )
          newState.push(MissBillingDataNotificationItem);
        return newState;
      });
    } else {
      setNotificationsList((oldState) => {
        const newState = [...oldState];
        return newState.filter(
          (ele) => ele.id !== EnumsValues.NotificationsItemIds.MissBillingData,
        );
      });
    }
    if (notifications?.missDataInDecisionTable) {
      setNotificationsList((oldState) => {
        const newState = [...oldState];
        if (
          !newState.find(
            (ele) => ele.id === MissDataInDecisionTableNotificationItem.id,
          )
        )
          newState.push(MissDataInDecisionTableNotificationItem);
        return newState;
      });
    } else {
      setNotificationsList((oldState) => {
        const newState = [...oldState];
        return newState.filter(
          (ele) =>
            ele.id !== EnumsValues.NotificationsItemIds.MissDataInDecisionTable,
        );
      });
    }
    if (notifications?.quotaIsExpired) {
      setNotificationsList((oldState) => {
        const newState = [...oldState];
        if (
          !newState.find((ele) => ele.id === QuotaIsExpiredNotificationItem.id)
        )
          newState.push(QuotaIsExpiredNotificationItem);
        return newState;
      });
    } else {
      setNotificationsList((oldState) => {
        const newState = [...oldState];
        return newState.filter(
          (ele) => ele.id !== EnumsValues.NotificationsItemIds.QuotaIsExpired,
        );
      });
    }
    if (notifications?.thereIsNotQuota) {
      setNotificationsList((oldState) => {
        const newState = [...oldState];
        if (
          !newState.find((ele) => ele.id === ThereIsNotQuotaNotificationItem.id)
        )
          newState.push(ThereIsNotQuotaNotificationItem);
        return newState;
      });
    } else {
      setNotificationsList((oldState) => {
        const newState = [...oldState];
        return newState.filter(
          (ele) => ele.id !== EnumsValues.NotificationsItemIds.ThereIsNotQuota,
        );
      });
    }
  }, [notifications]);

  const popoverContent = useMemo(() => {
    let innerComponent: JSX.Element | JSX.Element[];
    if (loading) {
      innerComponent = <Spin />;
    } else {
      innerComponent = notificationsList.length ? (
        <>
          <List
            size="large"
            bordered
            dataSource={notificationsList}
            renderItem={(item: INotificationItem) => (
              <Notification
                linkTo={item.linkTo}
                title={item.title}
                description={item.description}
                onClick={() => {
                  handlePopoverVisibleChange(false);
                }}
              />
            )}
          />
        </>
      ) : (
        <div className="notifications-popover__container-not-notifications">
          <span>{t('message.thereIsNotNotificationsToShow')}</span>
        </div>
      );
    }
    return <div className="notifications-popover">{innerComponent}</div>;
  }, [notificationsList, loading]);

  const handlePopoverVisibleChange = (visible: boolean) => {
    setPopoverVisible(visible);
  };

  return (
    <>
      <Popover
        arrowPointAtCenter
        autoAdjustOverflow
        content={popoverContent}
        placement="bottomRight"
        trigger="click"
        overlayClassName="notification-popover-overlay"
        open={popoverVisible}
        onOpenChange={handlePopoverVisibleChange}
      >
        <Badge dot={!!notificationsList.length} size="small">
          <span
            translate="no"
            className="material-symbols-outlined"
            style={{
              color: 'black',
              fontSize: 24,
              cursor: 'pointer',
            }}
          >
            notifications
          </span>
        </Badge>
      </Popover>
    </>
  );
};

export default Notifications;
