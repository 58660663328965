import { Select } from 'antd';
import { useMemo, useContext } from 'react';
import { ContextApp } from '../../../contexts/ContextApp';
import { EnumsValues, TenantStatus } from '../../../enums/EnumsValues';
import { CustomMessage } from '../../../hooks';
import { ITenant } from '../../../interfaces';
import GraphqlService from '../../../services/graphql/GraphqlService';
import { useHistory } from 'react-router';
import { notificationContext } from '../../../contexts/NotificationContext';

const { Option, OptGroup } = Select;

export const TenantSelector = () => {
  const { t, tenantsAssociatedToUser, selectedTenantId, checkAuth } =
    useContext(ContextApp);
  const { openNotification } = useContext(notificationContext);
  const { getErrorMessage } = CustomMessage();
  const { Mutation, customRequest } = GraphqlService();
  const history = useHistory();

  const handleChange = async (value: string) => {
    const tenantId = tenantsAssociatedToUser?.find(
      (tenant) => tenant.id === Number(value),
    )?.id;
    if (tenantId) {
      await updateLastTenantIdSelected(tenantId);
      localStorage.setItem(
        EnumsValues.LocalStorageKeys.LastTenantId,
        String(tenantId),
      );
      checkAuth(tenantId);
      history.push({
        pathname: '/app/home',
      });
    }
  };

  const activeTenants = useMemo(() => {
    return tenantsAssociatedToUser?.filter(
      (tenant: ITenant) => tenant.tenant_status_id !== TenantStatus.Inactiva,
    );
  }, [tenantsAssociatedToUser]);

  const inactiveTenants = useMemo(() => {
    return tenantsAssociatedToUser?.filter(
      (tenant: ITenant) => tenant.tenant_status_id === TenantStatus.Inactiva,
    );
  }, [tenantsAssociatedToUser]);

  const updateLastTenantIdSelected = async (tenant_id: number) => {
    try {
      await customRequest({
        query: Mutation.updateLastTenantIdSelected,
        variables: {
          tenant_id,
        },
      });
    } catch (error: any) {
      // Intentional
      openNotification({
        context: 'TenantSelector.updateLastTenantIdSelected.1',
        msj: getErrorMessage(error),
        type: 'error',
      });
    }
  };

  return (
    <Select
      defaultValue={selectedTenantId?.toString()}
      style={{ width: 200 }}
      onChange={handleChange}
      placeholder={t('entity.tenant')}
      getPopupContainer={(node) => node.parentNode}
      showSearch
      filterOption={(inputValue: string, option: any) =>
        option?.children?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
      }
    >
      {activeTenants?.map((tenant: ITenant) => (
        <Option value={tenant.id.toString()} key={tenant.id} >
          {tenant.name}
        </Option>
      ))}
      {inactiveTenants.length ? (
        <OptGroup label={t('tenantSelector.inactives')}>
          {inactiveTenants?.map((tenant: ITenant) => (
            <Option value={tenant.id.toString()} key={tenant.id}>
              {tenant.name}
            </Option>
          ))}
        </OptGroup>
      ) : null}
    </Select>
  );
};
