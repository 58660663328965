export const CREATE_ORDER = `
mutation createOrder($input: CreateOrderInput!) {
  createOrder(input: $input) {
    __typename
    ... on OrderWithPreferenceId {
      id
      tenant_id
      receipt_quota
      expire_date
      order_status_id
      preference_id
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;
