import { createContext } from 'react';

interface IContextWizard {
  currentStepWizard: number;
  nextStepWizard: Function;
  prevStepWizard: Function;
  checkPackPurchaseStep: Function;
}

const context: IContextWizard = {
  nextStepWizard: () => {},
  prevStepWizard: () => {},
  currentStepWizard: 0,
  checkPackPurchaseStep: () => {},
};

export const ContextWizard = createContext(context);
