import './style.less';

interface IProps {
  phoneNumber: string;
}

const WhatsAppFloatingActionButton = ({ phoneNumber }: IProps) => {

  const WhatsappButtonComponent = (): JSX.Element => {
    return (        
      <a target="_blank" rel="noreferrer" id="direct-whatsapp" title="WhatsApp" href={"https://wa.me/" + phoneNumber}>
        <img src="assets/icon/whatsapp.svg" alt="WhatsApp"/>
      </a>
    );
  };

  return <WhatsappButtonComponent />;
};

export default WhatsAppFloatingActionButton;
