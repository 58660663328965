export const UPDATE_MANY_DOCUMENT_TYPE_BUSINESS_VALIDATION_TENANT = `
mutation updateManyDocumentTypeBusinessValidationTenant(
  $document_type_id: Int!,
  $tenant_id: Int!,
  $input: [UpdateDocumentTypeBusinessValidationTenantInput!]!
) {
  updateManyDocumentTypeBusinessValidationTenant(
    document_type_id:$document_type_id,
    tenant_id: $tenant_id, 
    input: $input) {
    __typename
    ... on ResponseMessage {
      status
      response
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;
