import { Switch } from 'antd';

import './ConceptRuleSubtableParticular.less';

import { IConceptRuleGrouped } from '../../interfaces/Concept';
import { useCallback, useContext, useEffect, useState } from 'react';
import ProTable from '@ant-design/pro-table';
import { ExportableColumn } from '../../shared/Exporter';
import { PageLoading } from '@ant-design/pro-layout';
import GraphqlService from '../../services/graphql/GraphqlService';
import { Mutation } from '../../services/graphql/mutation';
import { notificationContext } from '../../contexts/NotificationContext';
import { CustomMessage } from '../../hooks';
import { ContextApp } from '../../contexts/ContextApp';
import { EnumsValues } from '../../enums/EnumsValues';
export interface IReceiptDetailSubTableProps {
  conceptRuleGrouped: IConceptRuleGrouped;
  conceptMasterId?: number;
  onChange?: () => void;
}

export default function ConceptRuleSubtableGeneral(
  props: IReceiptDetailSubTableProps,
) {
  const { t, selectedTenantId } = useContext(ContextApp);
  const { conceptRuleGrouped, conceptMasterId, onChange } = props;
  const [loading, setLoading] = useState(true);
  const [conceptGeneralRuleGrouped, setConceptGeneralRuleGrouped] = useState<
    IConceptRuleGrouped[]
  >([]);
  const { customRequest } = GraphqlService();
  const { openNotification } = useContext(notificationContext);
  const { getErrorMessage } = CustomMessage();

  useEffect(() => {
    if (conceptRuleGrouped) {
      const parsedData: IConceptRuleGrouped[] = [];
      parsedData.push(conceptRuleGrouped);
      setConceptGeneralRuleGrouped(parsedData);
      setLoading(false);
    }
  }, [conceptRuleGrouped]);

  const updateConcept = async (
    conceptRule: IConceptRuleGrouped,
    value: boolean,
  ) => {
    if (!conceptRule) return;

    try {
      await customRequest({
        mutation: Mutation.updateConceptRule,
        variables: {
          input: {
            id: conceptRule.generalRuleId?.concept_rule_id,
            tenant_id: selectedTenantId,
            concept_master_id: conceptMasterId,
            concept_rule_type_id: EnumsValues.ConceptRuleType.general,
            concept_rule_status_id: value
              ? EnumsValues.ConceptRuleStatus.activo
              : EnumsValues.ConceptRuleStatus.inactivo,
            concept_rule_conditional_type: [
              {
                concept_conditional_type_id:
                  EnumsValues.ConceptConditionalType.etiquetaImpuesto,
                value: conceptRule.tag,
              },
            ],
          },
        },
      });

      if (onChange) {
        onChange();
      }

      await openNotification({
        msj: t('ConceptRule.subTable.message.ruleStatusUpdated'),
        type: 'success',
      });
    } catch (error: any) {
      if (error.status_code && error.message) {
        openNotification({
          msj: getErrorMessage(error),
          type: 'error',
        });
      }
    }
  };

  const columns = useCallback(
    (
      _editMode?: boolean,
      _createMode?: boolean,
    ): ExportableColumn<IConceptRuleGrouped>[] => [
      {
        title: (
          <div className="subtable-title-col">
            <p className="subtable-title">
              {t('ConceptRule.subTable.generalRules')}
            </p>
            <p className="subtable-title-description">
              {t('ConceptRule.subTable.generalRulesDescription')}
            </p>
          </div>
        ),
        dataIndex: 'value',
        key: 'value',
        export: true,
        render: () => <div>{t('ConceptRule.subTable.associateToAllCUIT')}</div>,
        hideInSearch: true,
      },
      {
        title: '',
        dataIndex: 'option',
        valueType: 'option',
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        export: false,
        align: 'right',
        render: (_, record) => (
          <>
            <Switch
              defaultChecked={
                record &&
                record.concept_rule_general_status_id ===
                  EnumsValues.ConceptRuleStatus.activo
                  ? true
                  : false
              }
              onChange={(e) => updateConcept(record, e)}
            />
          </>
        ),
      },
    ],
    [conceptGeneralRuleGrouped],
  );

  return loading ? (
    <PageLoading />
  ) : (
    <>
      <ProTable
        className="protable-concept-rule-subtable"
        columns={columns()}
        dataSource={conceptGeneralRuleGrouped}
        pagination={false}
        rowKey="tag"
        search={false}
        toolBarRender={false}
      />
    </>
  );
}
