export const TENANTS = `
  query tenants(
    $filter: FilterTenantInput,
    $orderBy:  TenantOrderInput,
    $skip: Int,
    $take: Int,
    $searchText: String,
  ) {
    tenants (
      filter: $filter,
      orderBy: $orderBy
      skip: $skip
      take: $take
      searchText: $searchText
    ) {
      __typename
      ... on Tenant {
        id
        name
        tenant_picture_id
        tenant_status_id
        tenant_status{
          id
          name
        }
        tax_type_id
        tax_type{
          id
          code
          name
          active
          translation_key
          regular_expression
          error_message
          error_key
        }
        its
        billing_name
        address
        tax_number
        target_id
        target{
          id
          name
          description
        }
        integration_finishes_in_finished
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
`;

export const USER_TENANT = `
  query findManyUserTenants(
      $filter: FilterUserTenantInput!,
      $orderBy:  UserTenantOrderInput,
      $skip: Int,
      $take: Int,
    ) {
    findManyUserTenants(
      filter: $filter,
      orderBy: $orderBy
      skip: $skip
      take: $take
    ) {
      __typename
      ...on UserTenant {
        id
        user_id
        tenant_id
        tenant {
          name
          id
          tenant_status_id
          tenant_status{
            id
            name
          }
          tax_type_id
          tax_type{
            id
            code
            name
            active
            translation_key
            regular_expression
            error_message
            error_key
          }
          tenant_picture_id
          its
          billing_name
          address
          tax_number
          target_id
          target{
            id
            name
            description
          }
        }
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
`;

export const TENANT = `
query tenant($input: GetTenantInput!) {
  tenant(input: $input) {
    __typename
    ... on Tenant {
      id
      name
      tenant_picture_id
      tenant_status_id
      integration_id
      target_id
      tenant_status{
        id
        name
      }
      tax_type_id
      tax_type{
        id
        code
        name
        active
        translation_key
        regular_expression
        error_message
        error_key
      }
      its
      billing_name
      address
      tax_number
      integration {
        id
        name
        code
      }
      target{
        id
        name
        description
      }
      receipts_quota_tenant{
        initial_receipt_quota
        pack_id
        status_quota_id
        quota_expiration_date
        receipts_quota
        quota_start_date
        pack{
          id
          name
          offer_status
          price
          target_id
          status
          start_date
          expiration_date

        }
      }
      integration_by_email
      integration_email
      integration_finishes_in_finished
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const TENANT_COUNT = `
query tenantCount($filter: FilterTenantInput, $orderBy: TenantOrderInput, $searchText: String,) {
  tenantCount(filter: $filter, orderBy: $orderBy, searchText: $searchText) {
    __typename
    ... on Count {
      count
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const GET_TENANT_PICTURE = `
query getTenantPicture($id: Int!){
  getTenantPicture(id:$id){
    __typename
    ... on TenantPicture{
      id
      filename
      mimetype
      encoding
      file
    }
    ... on ResultError{
      status_code
      message
    }
  }
}
`;

export const TENANTS_ASSOCIATED_WITH_USER = `
query tenantsAssociatedWithUser {
  tenantsAssociatedWithUser {
    __typename
    ... on Tenant {
      id
      its
      uts
      dts
      name
      tenant_picture_id
      integration_id
      tenant_picture {
        id
        its
        uts
        dts
        filename
        mimetype
        encoding
        file
      }
      billing_name
      address
      tax_type_id
      tax_type {
        id
        its
        uts
        dts
        code
        name
        description
        active
        translation_key
        regular_expression
        error_message
        error_key
      }
      tax_number
      tenant_status_id
      tenant_status {
        id
        its
        uts
        dts
        name
      }
      receipts_quota_tenant{
        receipts_quota
        quota_start_date
        quota_expiration_date
      }
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;
