import { Button, Result } from 'antd';
import './ExpiredSession.less';
import { UserService } from '../../../services/UserService';
import { useContext } from 'react';
import { ContextApp } from '../../../contexts/ContextApp';

const ExpiredSession = () => {
  const { t } = useContext(ContextApp);
  const { logout } = UserService();
  return (
    <Result
      status="403"
      title="401"
      subTitle={t('error.expiredSession.expiredSessionSubtitle')}
      extra={
        <Button
          type="primary"
          onClick={() => {
            logout();
          }}
          key="console"
        >
          {t('action.logIn')}
        </Button>
      }
    />
  );
};

export default ExpiredSession;
