export const TARGETS = `
query targets($filter: FilterTargetInput, $orderBy: TargetOrderInput) {
  targets(filter: $filter, orderBy: $orderBy) {
    __typename
    ... on Target {
      id
      name
      description
      free_receipts_quota
      free_validity_days
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
