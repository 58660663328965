import { useContext, useEffect, useState } from 'react';
import { ContextApp } from '../contexts/ContextApp';

import { NotficationService } from '../services/NotificationService';
import { useCurrentRoute } from './useCurrentRoute';

const useNotification = (args: { primary_execute?: boolean }) => {
  const { primary_execute } = args;
  const { getNotifications } = NotficationService();
  const { tenantsAssociatedToUser, selectedTenantId, setNotifications } =
    useContext(ContextApp);
  const [loading, setLoading] = useState<boolean>(false);

  const { leafRoute } = useCurrentRoute();

  const refreshNotification = () => {
    const current_tenant = tenantsAssociatedToUser.find(
      (ele) => ele.id === selectedTenantId,
    );

    if (current_tenant) {
      setLoading(true);
      getNotifications(current_tenant)
        .then((data) => {
          setNotifications(data);
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (primary_execute) {
      refreshNotification();
    }
  }, [selectedTenantId, tenantsAssociatedToUser, leafRoute]);

  return {
    loading,
    refreshNotification,
  };
};
export default useNotification;
