export const UPDATE_TENANT_SETTING_VALUE = `
mutation updateTenantSettingValue($input: [TenantSettingCurrentValueInput!]!, $tenant_id: Int!) {
  updateTenantSettingValue(input: $input, tenant_id: $tenant_id) {
    __typename
    ... on TenantSettingCurrentValue{
      id
      tenant_setting_id
      status
      value
      tenant_setting{
        id
        name
        description
        default_value
        setting_type_id
        setting_type{
          id
          name
          description
        }
      }
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;