import { BusinessSettingsActionTypes } from '../enum';
import { BusinessSettingsAction, BusinessSettingsState } from '../interface';

const actionHandlers: {
  [K in BusinessSettingsActionTypes]: (
    state: BusinessSettingsState,
    action: Extract<BusinessSettingsAction, { type: K }>,
  ) => BusinessSettingsState;
} = {
  [BusinessSettingsActionTypes.SET_BUSINESS_SETTINGS]: (_state, action) => {
    return action.payload;
  },

  [BusinessSettingsActionTypes.TOGGLE_SWITCH]: (state, action) => {
    return {
      ...state,
      current: state.current.map((businessSetting) => {
        if (businessSetting.id === action.payload.id) {
          if (
            businessSetting.document_type_business_validation_tenant &&
            businessSetting.document_type_business_validation_tenant.length > 0
          ) {
            return {
              ...businessSetting,
              document_type_business_validation_tenant: [
                {
                  ...businessSetting
                    .document_type_business_validation_tenant[0],
                  value: action.switchStatus,
                },
              ],
            };
          } else {
            return {
              ...businessSetting,
              document_type_business_validation_tenant: [
                {
                  document_type_business_validation_id:
                    businessSetting.document_type_id,
                  tenant_id: action.tenantId,
                  value: action.switchStatus,
                },
              ],
            };
          }
        }
        return businessSetting;
      }),
    };
  },

  [BusinessSettingsActionTypes.RESET_SETTING_TO_DEFAULT]: (state, action) => {
    return {
      ...state,
      current: state.current.map((businessSetting) => {
        if (businessSetting.id === action.payload.id) {
          return {
            ...businessSetting,
            document_type_business_validation_tenant: [],
          };
        } else {
          return businessSetting;
        }
      }),
    };
  },

  [BusinessSettingsActionTypes.RESET_SETTING_TO_PREVIOUS_STATE]: (
    state,
    action,
  ) => {
    return {
      ...state,
      current: state.current.map((businessSetting) => {
        if (businessSetting.id === action.payload.id) {
          return {
            ...businessSetting,
            document_type_business_validation_tenant: state.original.find(
              (setting) => setting.id === action.payload.id,
            )?.document_type_business_validation_tenant,
          };
        } else {
          return businessSetting;
        }
      }),
    };
  },

  [BusinessSettingsActionTypes.RESET_ALL_SETTINGS_TO_DEFAULT]: (state) => {
    return {
      ...state,
      current: state.current.map((setting) => {
        return {
          ...setting,
          document_type_business_validation_tenant: [],
        };
      }),
    };
  },

  [BusinessSettingsActionTypes.RESET_ALL_SETTINGS_TO_PREVIOUS_STATE]: (
    state,
  ) => {
    return {
      ...state,
      current: state.original,
    };
  },
};

export const businessSettingsReducer = (
  state: BusinessSettingsState,
  action: BusinessSettingsAction,
) => {
  const handler = actionHandlers[action.type];
  if (handler) {
    try {
      return handler(state, action as any);
    } catch (error) {
      console.log(`Error handling action ${action.type}:`, error);
      return state;
    }
  }
  return state;
};
