import { Modal } from 'antd';
import { useContext } from 'react';
import { BusinessValidation } from '../../../../../interfaces/BusinessValidation';
import { ContextApp } from '../../../../../contexts/ContextApp';
import ButtonTertiary from '../../../../../components/common/Buttons/ButtonTertiary';
import ButtonSecondary from '../../../../../components/common/Buttons/ButtonSecondary';
import ButtonAction from '../../../../../components/common/Buttons/ButtonAction';
import './BusinessSettingRestoreModal.less';

interface BusinessSettingRestoreModalProps {
  modalVisible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onRestoreToPreviousState: () => void;
  onRestoreToDefault: () => void;
  currentSetting: BusinessValidation;
}

const BusinessSettingRestoreModal = (
  props: BusinessSettingRestoreModalProps,
) => {
  const {
    modalVisible,
    setModalVisible,
    onRestoreToPreviousState,
    onRestoreToDefault,
    currentSetting,
  } = props;

  const { t } = useContext(ContextApp);
  return (
    <Modal
      destroyOnClose
      title={
        <div className="modal-content__title">
          <span className="material-symbols-outlined receipt-exclamation-icon">
            error
          </span>
          {t('settingsPage.businessSettingRestoreModal.title')}
        </div>
      }
      open={modalVisible}
      onCancel={() => setModalVisible(false)}
      footer={null}
      className="business-setting-restore-modal"
    >
      <div className="modal-content">
        <p className="modal-content__text">
          {t('settingsPage.businessSettingRestoreModal.paragraph', {
            settingName: (
              t((currentSetting.translation_key as never) || '') as string
            ).toLowerCase(),
          })}
        </p>
        <div className="modal-content__actions">
          <ButtonTertiary onClick={() => setModalVisible(false)}>
            Cancelar
          </ButtonTertiary>
          <ButtonSecondary onClick={onRestoreToDefault}>
            {t('settingsPage.businessSettingRestoreModal.restoreToDefault')}
          </ButtonSecondary>
          <ButtonAction onClick={onRestoreToPreviousState}>
            {t(
              'settingsPage.businessSettingRestoreModal.restoreToPreviousState',
            )}
          </ButtonAction>
        </div>
      </div>
    </Modal>
  );
};

export default BusinessSettingRestoreModal;
