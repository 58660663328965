import { useEffect, useState, useCallback } from 'react';
import { Form, Select } from 'antd';
import {
  ISettingProperties,
  ISettingToUpdate,
} from '../../interfaces/TenantSetting';
import { Query } from '../../services/graphql/query';
import GraphqlService from '../../services/graphql/GraphqlService';
import { ISettingType } from '../../interfaces/TenantSetting';
import './TenantSettingSelect.less';

type LanguageSelectProps = {
  settingsArray: ISettingToUpdate[];
  handleChange: (id: any, value: any) => void;
};

export const useSettingLanguageSelect = (props: LanguageSelectProps) => {
  const { settingsArray, handleChange } = props;
  const [languages, setLanguages] = useState<string[]>([]);
  const { customRequest } = GraphqlService();

  useEffect(() => {
    getLanguages();
  }, []);

  const getLanguages = async () => {
    const data: string[] = await customRequest({
      query: Query.languages,
    });

    setLanguages(data);
  };

  const LanguageSelect = useCallback(
    (props: ISettingProperties) => {
      const valueSelected = settingsArray
        .find((item) => item.id === props.id)
        ?.value?.toString();

      return (
        <Form.Item className='margin-component' required key={props.key || undefined}>
          {!languages.length || !settingsArray.length ? (
            <Select loading className="setting-select" />
          ) : (
            <Select
              className="setting-select"
              onChange={(e) => handleChange(props.id, e)}
              getPopupContainer={(node) => node.parentNode}
              value={valueSelected}
              options={languages.map((item: any) => ({
                label: item.native_name,
                value: item.id.toString(),
              }))}
            ></Select>
          )}
        </Form.Item>
      );
    },
    [languages, settingsArray],
  );

  const languageSetting: ISettingType = {
    render: useCallback(
      (props) => {
        return <LanguageSelect {...props} />;
      },
      [languages, settingsArray],
    ),
  };

  return { languageSetting };
};

export default useSettingLanguageSelect;
