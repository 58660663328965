export const DELETE_PENDING_RECEIPT = `
mutation deletePendingReceipt($tenant_id: Int!, $receipt_name: String!) {
  deletePendingReceipt(tenant_id: $tenant_id, receipt_name: $receipt_name) {
    __typename
    ... on BaseFile {
      filename
      mimetype
      encoding
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const CREATE_PENDING_RECEIPTS = `
mutation createPendingReceipts($file: Upload!, $tenant_id: Int!, $validate_duplicity: Boolean) {
  createPendingReceipts(file: $file, tenant_id: $tenant_id, validate_duplicity: $validate_duplicity) {
    __typename
    ... on PendingReceipts {
      filename
      mimetype
      encoding
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
