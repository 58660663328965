export const DECISION_ITEMS = `
query decisionItems(
    $searchText: String
    $filter:  FilterDecisionItemInput
    $orderBy:  DecisionItemOrderInput
    $skip: Int
    $take: Int
  ){
  decisionItems(
    searchText: $searchText
    filter: $filter
    orderBy: $orderBy
    skip: $skip
    take: $take
  ){
    __typename
    ...on DecisionItem{
      id
      tenant_id
      tax_type_id
      tax_number
      description
      description_type
      distance
      product_id
      cost_center_id
      product{
        id
        description
        code
        tenant_id
      }
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;
export const DECISION_ITEM_COUNT = `
query decisionItemCount(
  $filter: FilterDecisionItemInput, 
  $orderBy: DecisionItemOrderInput,
  $searchText: String,
) {
  decisionItemCount(
    filter: $filter,
    orderBy: $orderBy,
    searchText: $searchText
  ) {
    __typename
    ... on Count {
      count
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;
