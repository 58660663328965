import {
  TENANT_WITH_ACTIVE_INTEGRATION_INCOMPLETE,
  TENANT_WITH_RECEIPT_OR_DECISION_ITEM_INCOMPLETE,
} from './Notification';
import { print } from 'graphql';
import {
  USERS,
  AUTH,
  LOGOUT,
  RESET_PASSWORD,
  VERIFY_RECOVERY_PASSWORD_TOKEN,
  USERS_COUNT,
  GET_PROFILE_PICTURE,
  GET_PROFILE_FILE,
  USER_COUNT_TO_INDICATOR,
} from './User';
import { SETTINGS, SETTING_KEY } from './AppSetting';
import { LANGUAGES } from './Language';
import { ROLE, ROLES } from './Role';
import { PERMISSIONS } from './Permission';
import { ROLE_PERMISSION, ROLE_PERMISSIONS } from './RolePermission';
import { GET_SIGNATURE } from './Signature';
import {
  GET_TENANT_PICTURE,
  TENANT,
  TENANTS,
  TENANTS_ASSOCIATED_WITH_USER,
  TENANT_COUNT,
  USER_TENANT,
} from './Tenant';
import {
  EXPORT_RECEIPT_HEADERS,
  EXPORT_RECEIPT_HEADERS_BY_MAIL,
  EXPORT_RECEIPT_HEADERS_DYNAMIC,
  GET_RECEIPT_HEADER_PDF,
  RECEIPTS_HEADERS,
  RECEIPT_HEADER,
  RECEIPT_HEADER_COUNT,
  RECEIPT_HEADER_TO_INDICATOR,
  RECEIPT_TAX_TYPE,
  STATES,
} from './Receipt';
import { VERIFY_COMPLETE_REGISTER_BY_EMAIL_TOKEN } from './VeryTokens';
import { RECEIPTS_DETAILS } from './ReceiptDetail';
import { EXPORT_LOGS, GET_LOGS, LAST_LOG_BY_VALIDATING_RECEIPT } from './log';
import { DECISION_ITEMS, DECISION_ITEM_COUNT } from './DecisionItem';
import {
  AVAILABLE_QUOTA,
  GET_PENDING_RECEIPT,
  PENDING_RECEIPTS,
} from './PendingReceipt';
import { PACKS } from './Pack';
import { INTEGRATIONS } from './Integration';
import { INTEGRATION_SETTINGS } from './IntegrationSetting';
import { INTEGRATION_SETTING_TENANT } from './IntegrationSettingTenant';
import { TARGETS } from './Target';
import { CHECK_INVITATION } from './Invitation';
import { PRODUCTS, PRODUCTS_BY_DESCRIPTION, PRODUCT_COUNT } from './Product';
import { SEEN_TOURS } from './SeenTours';
import { TAX_TYPES } from './TaxType';
import { CURRENCY_TYPES } from './CurrencyType';
import { RECEIPT_TYPES } from './receiptType';
import { RECEIPT_LETTERS } from './ReceiptLetter';
import { EXTRACTION_LOG } from './ExtractionLog';
import { TENANT_STATES } from './TenantStatus';
import { CHECK_TENANT_BUY, TENANT_EMAIL_RECEIPTS } from './TenantEmailReceipt';
import { GROUPERS } from './grouper';
import {
  RECEIPT_CONFIGURATIONS,
  RECEIPT_CONFIGURATION_BY_RECEIPT,
  RECEIPT_CONFIGURATION_BY_RECEIPT_TYPE,
} from './ReceiptConfiguration';
import {
  TENANT_SETTING_CURRENT_VALUE,
  TENANT_SETTINGS_CURRENT_VALUES,
} from './TenantSetting';
import { RECEIPT_DETAIL_ITEM_TYPES } from './ReceiptDetailItemType';
import {
  CONCEPT_CONDITIONALS,
  CONCEPT_CONDITIONAL_TYPES,
  CONCEPT_MASTERS,
  CONCEPT_MASTER_COUNT,
  CONCEPT_RULE_TYPES,
  CONCEPT_TYPES,
  LOGICAL_OPERATION_TYPES,
} from './Concept';
import { RECEIPT_CONCEPT_EXTRACTEDS } from './ReceiptConceptExtracted';
import {
  GET_ALL_FEATURE_FLAG_CONFIG,
  GET_ALL_FEATURE_FLAGS_CONFIG_FOR_TENANT_ID,
  GET_ALL_PUBLIC_FEATURE_FLAG_CONFIG,
  GET_ONE_FEATURE_FLAG_CONFIG_VALUE,
  GET_ONE_PUBLIC_FEATURE_FLAG_CONFIG_VALUE,
} from './FeatureFlag';
import { NOTIFICATION_CATEGORIES } from './NotificationCategories';
import { USER_NOTIFICATION_TYPES } from './UserNotificationType';
import { GET_DOCUMENT_TYPE_CONFIGURATION_FOR_TENANT } from './DocumentType';
import { SCHEMAS } from './Schemas';
import { FIELD_ATTRIBUTE_TENANTS_BY_RECEIPT_TYPE } from './FieldAttributeTenant';
import { DOCUMENT_TYPE_BUSINESS_VALIDATIONS } from './BusinessValidation';
import { RECEIPT_TYPE_DOCUMENT_TYPE_BY_RECEIPT_TYPE } from './ReceiptTypeDocumentType';
import {
  GET_REVIEWER_CONFIGURATION_TYPE_BY_DOCUMENT_TYPE_ID,
  REVIEWER_CONFIGURATION_TYPE_OPTIONS,
} from './ReviewerConfiguration';

const Query = {
  authenticate: { name: 'authenticate', gql: AUTH },
  getAppSettingByKey: { name: 'getAppSettingByKey', gql: SETTING_KEY },
  getAppSettings: { name: 'getAppSettings', gql: SETTINGS },
  languages: { name: 'languages', gql: print(LANGUAGES) },
  logout: { name: 'logout', gql: LOGOUT },
  permissions: { name: 'permissions', gql: PERMISSIONS },
  resetPassword: { name: 'resetPassword', gql: RESET_PASSWORD },
  role: { name: 'role', gql: ROLE },
  roles: { name: 'roles', gql: ROLES },
  rolePermission: { name: 'rolePermission', gql: ROLE_PERMISSION },
  rolePermissions: { name: 'rolePermissions', gql: ROLE_PERMISSIONS },
  users: { name: 'users', gql: USERS },
  userCount: { name: 'userCount', gql: USERS_COUNT },
  verifyRecoveryPasswordToken: {
    name: 'verifyRecoveryPasswordToken',
    gql: VERIFY_RECOVERY_PASSWORD_TOKEN,
  },
  getProfilePicture: { name: 'getProfilePicture', gql: GET_PROFILE_PICTURE },
  getProfileFile: { name: 'getProfileFile', gql: GET_PROFILE_FILE },
  getSignature: { name: 'getSignature', gql: GET_SIGNATURE },
  tenants: { name: 'tenants', gql: TENANTS },
  findManyUserTenants: { name: 'findManyUserTenants', gql: USER_TENANT },
  tenant: { name: 'tenant', gql: TENANT },
  getTenantPicture: {
    name: 'getTenantPicture',
    gql: GET_TENANT_PICTURE,
  },
  tenantCount: { name: 'tenantCount', gql: TENANT_COUNT },
  receiptHeaderCount: { name: 'receiptHeaderCount', gql: RECEIPT_HEADER_COUNT },
  receiptsHeaders: {
    name: 'receiptsHeaders',
    gql: RECEIPTS_HEADERS,
  },
  exportReceiptHeaders: {
    name: 'exportReceiptHeaders',
    gql: EXPORT_RECEIPT_HEADERS,
  },
  exportLogs: {
    name: 'exportLogs',
    gql: EXPORT_LOGS,
  },
  lastLogByValidatingReceipt: {
    name: 'lastLogByValidatingReceipt',
    gql: LAST_LOG_BY_VALIDATING_RECEIPT,
  },
  extractionLog: {
    name: 'extractionLog',
    gql: EXTRACTION_LOG,
  },
  receiptHeader: {
    name: 'receiptHeader',
    gql: RECEIPT_HEADER,
  },
  states: {
    name: 'states',
    gql: STATES,
  },
  verifyCompleteRegisterByEmailToken: {
    name: 'verifyCompleteRegisterByEmailToken',
    gql: VERIFY_COMPLETE_REGISTER_BY_EMAIL_TOKEN,
  },
  receiptsDetails: { name: 'receiptsDetails', gql: RECEIPTS_DETAILS },
  tenantsAssociatedWithUser: {
    name: 'tenantsAssociatedWithUser',
    gql: TENANTS_ASSOCIATED_WITH_USER,
  },
  getLogs: {
    name: 'getLogs',
    gql: GET_LOGS,
  },
  decisionItems: {
    name: 'decisionItems',
    gql: DECISION_ITEMS,
  },
  userCountToIndicator: {
    name: 'userCountToIndicator',
    gql: USER_COUNT_TO_INDICATOR,
  },
  receiptHeaderToIndicator: {
    name: 'receiptHeaderToIndicator',
    gql: RECEIPT_HEADER_TO_INDICATOR,
  },
  getReceiptHeaderPdf: {
    name: 'getReceiptHeaderPdf',
    gql: GET_RECEIPT_HEADER_PDF,
  },
  decisionItemCount: {
    name: 'decisionItemCount',
    gql: DECISION_ITEM_COUNT,
  },
  getPendingReceipt: {
    name: 'getPendingReceipt',
    gql: GET_PENDING_RECEIPT,
  },
  pendingReceipts: {
    name: 'pendingReceipts',
    gql: PENDING_RECEIPTS,
  },
  availableQuota: {
    name: 'availableQuota',
    gql: AVAILABLE_QUOTA,
  },
  packs: {
    name: 'packs',
    gql: PACKS,
  },
  integrations: {
    name: 'integrations',
    gql: INTEGRATIONS,
  },
  integrationSettings: {
    name: 'integrationSettings',
    gql: INTEGRATION_SETTINGS,
  },
  integrationSettingTenant: {
    name: 'integrationSettingTenant',
    gql: INTEGRATION_SETTING_TENANT,
  },
  tenantWithReceiptOrDesicionItemIncomplete: {
    name: 'tenantWithReceiptOrDesicionItemIncomplete',
    gql: TENANT_WITH_RECEIPT_OR_DECISION_ITEM_INCOMPLETE,
  },
  tenantWithActiveIntegrationIncomplete: {
    name: 'tenantWithActiveIntegrationIncomplete',
    gql: TENANT_WITH_ACTIVE_INTEGRATION_INCOMPLETE,
  },
  targets: {
    name: 'targets',
    gql: TARGETS,
  },
  checkInvitation: {
    name: 'checkInvitation',
    gql: CHECK_INVITATION,
  },
  productsByDescription: {
    name: 'productsByDescription',
    gql: PRODUCTS_BY_DESCRIPTION,
  },
  products: {
    name: 'products',
    gql: PRODUCTS,
  },
  productCount: {
    name: 'productCount',
    gql: PRODUCT_COUNT,
  },
  seenTours: {
    name: 'seenTours',
    gql: SEEN_TOURS,
  },
  taxTypes: {
    name: 'taxTypes',
    gql: TAX_TYPES,
  },
  currencyTypes: {
    name: 'currencyTypes',
    gql: CURRENCY_TYPES,
  },
  receipTypes: {
    name: 'receiptTypes',
    gql: RECEIPT_TYPES,
  },
  receiptLetters: {
    name: 'receiptLetters',
    gql: RECEIPT_LETTERS,
  },
  exportReceiptHeadersByEmails: {
    name: 'exportReceiptHeadersByEmails',
    gql: EXPORT_RECEIPT_HEADERS_BY_MAIL,
  },
  tenantStates: {
    name: 'tenantStates',
    gql: TENANT_STATES,
  },
  tenantEmailReceipts: {
    name: 'tenantEmailReceipts',
    gql: TENANT_EMAIL_RECEIPTS,
  },
  checkTenantBuy: {
    name: 'checkTenantBuy',
    gql: CHECK_TENANT_BUY,
  },
  groupers: {
    name: 'groupers',
    gql: GROUPERS,
  },
  receiptConfigurations: {
    name: 'receiptConfigurations',
    gql: RECEIPT_CONFIGURATIONS,
  },
  receiptConfigurationByReceipt: {
    name: 'receiptConfigurationByReceipt',
    gql: RECEIPT_CONFIGURATION_BY_RECEIPT,
  },
  receiptConfigurationByReceiptType: {
    name: 'receiptConfigurationByReceiptType',
    gql: RECEIPT_CONFIGURATION_BY_RECEIPT_TYPE,
  },
  tenantSettingsCurrentValues: {
    name: 'tenantSettingsCurrentValues',
    gql: TENANT_SETTINGS_CURRENT_VALUES,
  },
  receiptDetailItemTypes: {
    name: 'receiptDetailItemTypes',
    gql: RECEIPT_DETAIL_ITEM_TYPES,
  },
  conceptTypes: {
    name: 'conceptTypes',
    gql: CONCEPT_TYPES,
  },
  logicalOperationTypes: {
    name: 'logicalOperationTypes',
    gql: LOGICAL_OPERATION_TYPES,
  },
  conceptRuleTypes: {
    name: 'conceptRuleTypes',
    gql: CONCEPT_RULE_TYPES,
  },
  conceptConditionals: {
    name: 'conceptConditionals',
    gql: CONCEPT_CONDITIONALS,
  },
  conceptConditionalTypes: {
    name: 'conceptConditionalTypes',
    gql: CONCEPT_CONDITIONAL_TYPES,
  },
  conceptMasters: {
    name: 'conceptMasters',
    gql: CONCEPT_MASTERS,
  },
  conceptMasterCount: {
    name: 'conceptMasterCount',
    gql: CONCEPT_MASTER_COUNT,
  },
  receiptConceptExtracteds: {
    name: 'receiptConceptExtracteds',
    gql: RECEIPT_CONCEPT_EXTRACTEDS,
  },
  receiptTaxTypes: {
    name: 'receiptTaxTypes',
    gql: RECEIPT_TAX_TYPE,
  },
  getAllPublicFeatureFlagsConfig: {    
    name: 'getAllPublicFeatureFlagsConfig',
    gql: GET_ALL_PUBLIC_FEATURE_FLAG_CONFIG,
},
  getAllFeatureFlagsConfig: {
    name: 'getAllFeatureFlagsConfig',
    gql: GET_ALL_FEATURE_FLAG_CONFIG,
  },
  getAllFeatureFlagsConfigForTenantId: {
    name: 'getAllFeatureFlagsConfigForTenantId',
    gql: GET_ALL_FEATURE_FLAGS_CONFIG_FOR_TENANT_ID,
  },
  getOneFeatureFlagConfigValue: {
    name: 'getOneFeatureFlagConfigValue',
    gql: GET_ONE_FEATURE_FLAG_CONFIG_VALUE,
  },
  getOnePublicFeatureFlagConfigValue: {
    name: 'getOnePublicFeatureFlagConfigValue',
    gql: GET_ONE_PUBLIC_FEATURE_FLAG_CONFIG_VALUE,
  },
  notificationCategories: {
    name: 'notificationCategories',
    gql: NOTIFICATION_CATEGORIES,
  },
  userNotificationTypes: {
    name: 'userNotificationTypes',
    gql: USER_NOTIFICATION_TYPES,
  },
  getDocumentTypeConfigurationForTenant: {
    name: 'getDocumentTypeConfigurationForTenant',
    gql: GET_DOCUMENT_TYPE_CONFIGURATION_FOR_TENANT,
  },
  schemas: {
    name: 'schemas',
    gql: SCHEMAS,
  },
  fieldAttributeTenantsByReceiptType: {
    name: 'fieldAttributeTenantsByReceiptType',
    gql: FIELD_ATTRIBUTE_TENANTS_BY_RECEIPT_TYPE,
  },
  documentTypeBusinessValidations: {
    name: 'documentTypeBusinessValidations',
    gql: DOCUMENT_TYPE_BUSINESS_VALIDATIONS,
  },
  receiptTypeDocumentTypeByReceiptType: {
    name: 'receiptTypeDocumentTypeByReceiptType',
    gql: RECEIPT_TYPE_DOCUMENT_TYPE_BY_RECEIPT_TYPE,
  },
  reviewerConfigurationTypeOptions: {
    name: 'reviewerConfigurationTypeOptions',
    gql: REVIEWER_CONFIGURATION_TYPE_OPTIONS,
  },
  getReviewerConfigurationTypeByDocumentTypeId: {
    name: 'getReviewerConfigurationTypeByDocumentTypeId',
    gql: GET_REVIEWER_CONFIGURATION_TYPE_BY_DOCUMENT_TYPE_ID,
  },
  tenantSettingCurrentValue: {
    name: 'tenantSettingCurrentValue',
    gql: TENANT_SETTING_CURRENT_VALUE,
  },
  exportReceiptHeadersDynamic: {
    name: 'exportReceiptHeadersDynamic',
    gql: EXPORT_RECEIPT_HEADERS_DYNAMIC,
  },
};

export { Query };
