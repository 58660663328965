import { Button, Card, Divider } from 'antd';
import { useContext } from 'react';
import { ContextApp } from '../../../contexts/ContextApp';
import { EnumsValues } from '../../../enums/EnumsValues';
import { IIntegration } from '../../../interfaces/Integration';
import { IPack } from '../../../interfaces/Pack';
import { Authorization } from '../../../shared';
import './CardToSelect.less';

export interface ICardToSelectProps<T> {
  data: T;
  cardHeaderJsx: JSX.Element;
  cardBodyJsx: JSX.Element;
  buttonLabel: string;
  cardDivider: boolean;
  onClick: (value: T) => void;
  isButtonLoading?: number;
  selectedIntegration?: number;
  buttonLabelIfSelected?: string;
  buttonDisabled?: boolean;
}

export function CardToSelect<T extends IPack | IIntegration>(
  props: ICardToSelectProps<T>,
) {
  const { functions } = useContext(ContextApp);

  const {
    data,
    cardHeaderJsx,
    cardBodyJsx,
    buttonLabel,
    cardDivider,
    selectedIntegration,
    buttonLabelIfSelected,
    isButtonLoading,
    buttonDisabled,
    onClick,
  } = props;

  const isPack = (data: IPack | IIntegration): data is IPack => {
    return (data as IPack).offer_status !== undefined;
  };

  const isIntegration = (data: IPack | IIntegration): data is IIntegration => {
    return (data as IIntegration).logo !== undefined;
  };

  const selectedCard = data.id === selectedIntegration;

  const hasDiscountOrActiveStatus = (data: IPack | IIntegration): boolean => {
    if (
      (isPack(data) && data.offer_status === 1) ||
      (isIntegration(data) &&
        (selectedCard ||
          EnumsValues.IntegrationStatus.ComingSoon ===
            data.integration_status_id))
    ) {
      return true;
    }
    return false;
  };

  return (
    <div >
      {hasDiscountOrActiveStatus(data) && cardHeaderJsx}
      <Card
        style={{
          borderRadius: hasDiscountOrActiveStatus(data)
            ? '0px 0px 10px 10px'
            : '10px',
          border: selectedCard ? '2px solid  #3BAA61' : undefined,
        }}
        bodyStyle={{
          padding: '10px 30px',
        }}
      >
        <div className="CardToSelect">
          {cardBodyJsx}
          {cardDivider && <Divider style={{ margin: 0 }} />}
          <Button
            type="primary"
            onClick={() => onClick(data)}
            disabled={
              (isButtonLoading && isButtonLoading !== data.id) ||
              (isIntegration(data) &&
                (data.integration_status_id ===
                  EnumsValues.IntegrationStatus.ComingSoon ||
                  (data.integration_status_id ===
                    EnumsValues.IntegrationStatus.Active &&
                    !Authorization.security(
                      functions,
                      EnumsValues.Functions.TenantUpdate,
                    )))) ||
              buttonDisabled
            }
            loading={isButtonLoading === data.id}
            key={data.id}
          >
            {selectedCard ? buttonLabelIfSelected : buttonLabel}
          </Button>
        </div>
      </Card>
    </div>
  );
}
