import { extraDecimalWithDot } from '../pages/ReviewReceipt/regex/regexValues';

export const formatValueOnLoad = (value: number | string) => {
  let valueString = Number(value).toFixed(2).toString();

  if (extraDecimalWithDot.test(valueString)) {
    valueString = valueString.replace(/,/g, '');
    valueString = Number(valueString).toFixed(3).toString();
    valueString = valueString.replace('.', ',');
    valueString = valueString.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  } else {
    valueString = valueString.replace('.', ',');
    valueString = valueString.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  return valueString;
};
