export const VERIFY_COMPLETE_REGISTER_BY_EMAIL_TOKEN = `
query verifyCompleteRegisterByEmailToken($token: String!){
    verifyCompleteRegisterByEmailToken(
      token: $token
    ){
      __typename
      ...on CompleteRegisterByEmail{
        result
      }
      ...on ResultError{
        status_code
        message
      }
    }
  }

`;
