export const REMOVE_CONCEPT_MASTER_BY_ID = `
mutation RemoveConceptMasterById($conceptMasterId: Int!, $tenantId: Int!) {
    removeConceptMasterById(concept_master_id: $conceptMasterId, tenant_id: $tenantId) {
      ... on BooleanResult {
        result
      }
      ... on ResultError {
        status_code
        message
        message_translation_key
      }
    }
  }
`

export const CREATE_CONCEPT_MASTER = `
mutation createConceptMaster($input: CreateConceptMasterInput!) {
  createConceptMaster(input: $input) {
    __typename
    ... on  ConceptMaster{
      id
      name
      code
      tenant_id
      logical_operation_type_id
      logical_operation_type {
        id
        name
      }
      concept_type_id
      concept_type {
        id
        name
      }
      concept_rule {
        id
        concept_master_id
        concept_rule_type_id
        concept_rule_type {
          id
          name
        }
        concept_rule_conditional_type {
          id
          concept_rule_id
          concept_conditional_type_id
          value
          concept_conditional_type {
            id
            concept_conditional_id
            concept_type_id
            concept_conditional {
              id
              name
            }
            concept_type{
              id
              name
            }
          }
        }
      }
      its,
      uts
    }
    ...on ResultError {
      status_code
      message
      message_translation_key
    }
    
  }
}`


export const UPDATE_CONCEPT_MASTER = `
mutation updateConceptMaster($input: UpdateConceptMasterInput!) {
  updateConceptMaster(input: $input) {
    __typename
    ... on  ConceptMaster{
      id
      name
      code
      tenant_id
      logical_operation_type_id
      logical_operation_type {
        id
        name
      }
      concept_type_id
      concept_type {
        id
        name
      }
      concept_rule {
        id
        concept_master_id
        concept_rule_type_id
        concept_rule_type {
          id
          name
        }
        concept_rule_conditional_type {
          id
          concept_rule_id
          concept_conditional_type_id
          value
          concept_conditional_type {
            id
            concept_conditional_id
            concept_type_id
            concept_conditional {
              id
              name
            }
            concept_type{
              id
              name
            }
          }
        }
      }
      its,
      uts
    }
    ...on ResultError {
      status_code
      message
      message_translation_key
    }
    
  }
}`

export const DELETE_CONCEPT_MASTER = `
mutation deleteConceptMaster($id: Int!) {
  deleteConceptMaster(id: $id) {
    __typename
    ... on  ConceptMaster{
      id
      name
      code
      tenant_id
      logical_operation_type_id
      logical_operation_type {
        id
        name
      }
      concept_type_id
      concept_type {
        id
        name
      }
      concept_rule {
        id
        concept_master_id
        concept_rule_type_id
        concept_rule_type {
          id
          name
        }
        concept_rule_conditional_type {
          id
          concept_rule_id
          concept_conditional_type_id
          value
          concept_conditional_type {
            id
            concept_conditional_id
            concept_type_id
            concept_conditional {
              id
              name
            }
            concept_type{
              id
              name
            }
          }
        }
      }
      its,
      uts
    }
    ...on ResultError {
      status_code
      message
      message_translation_key
    }
    
  }
}`


export const CREATE_CONCEPT_RULE = `
mutation createConceptRule($input: CreateConceptRuleInput!) {
  createConceptRule(input: $input) {
    __typename
    ... on  ConceptRule{
      id
      concept_master_id
      concept_rule_type_id
      concept_rule_status_id
      concept_rule_type {
        id
        name
      }
      concept_rule_conditional_type {
        id
        concept_rule_id
        concept_conditional_type_id
        value
        concept_conditional_type {
          id
          concept_conditional_id
          concept_type_id
          concept_conditional {
            id
            name
          }
          concept_type{
            id
            name
          }
        }
      }
      concept_master {
        id
        name
        code
        tenant_id
        logical_operation_type_id
        logical_operation_type {
          id
          name
        }
        concept_type_id
        concept_type {
          id
          name
        }
      }
      its,
      uts
    }
    ...on ResultError {
      status_code
      message
      message_translation_key
    }
    
  }
}`

export const DELETE_CONCEPT_RULE = `
mutation deleteConceptRule($id: Int!) {
  deleteConceptRule(id: $id) {
   __typename
    ... on  ConceptRule{
      id
      concept_master_id
      concept_rule_type_id
      concept_rule_status_id
      concept_rule_type {
        id
        name
      }
      concept_rule_conditional_type {
        id
        concept_rule_id
        concept_conditional_type_id
        value
        concept_conditional_type {
          id
          concept_conditional_id
          concept_type_id
          concept_conditional {
            id
            name
          }
          concept_type{
            id
            name
          }
        }
      }
      concept_master {
        id
        name
        code
        tenant_id
        logical_operation_type_id
        logical_operation_type {
          id
          name
        }
        concept_type_id
        concept_type {
          id
          name
        }
      }
      its,
      uts
    }
    ...on ResultError {
      status_code
      message
      message_translation_key
    }
    
  }
}`


export const UPDATE_CONCEPT_RULE = `

mutation updateConceptRule($input: UpdateConceptRuleInput!) {
  updateConceptRule(input: $input) {
    __typename
    ... on  ConceptRule{
      id
      concept_master_id
      concept_rule_type_id
      concept_rule_status_id
      concept_rule_type {
        id
        name
      }
      concept_rule_conditional_type {
        id
        concept_rule_id
        concept_conditional_type_id
        value
        concept_conditional_type {
          id
          concept_conditional_id
          concept_type_id
          concept_conditional {
            id
            name
          }
          concept_type{
            id
            name
          }
        }
      }
      concept_master {
        id
        name
        code
        tenant_id
        logical_operation_type_id
        logical_operation_type {
          id
          name
        }
        concept_type_id
        concept_type {
          id
          name
        }
      }
      its,
      uts
    }
    ...on ResultError {
      status_code
      message
      message_translation_key
    }
    
  }
}
`


export const UPDATE_MANY_CONCEPT_RULES = `

mutation updateManyConceptRules($input: UpdateManyConceptRuleInput!) {
  updateManyConceptRules(input: $input) {
    __typename
    ... on Count {
      count
    }
    ...on ResultError {
      status_code
      message
      message_translation_key
    }
    
  }
}`

export const DELETE_MANY_CONCEPT_RULES = `

mutation deleteManyConceptRules($ids: [Int!]!) {
  deleteManyConceptRules(ids: $ids) {
    __typename
    ... on Count {
      count
    }
    ...on ResultError {
      status_code
      message
      message_translation_key
    }
    
  }
}`