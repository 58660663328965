import { useEffect, useState, useCallback, useContext } from 'react';
import { Form, Select } from 'antd';
import {
  ISettingProperties,
  ISettingToUpdate,
} from '../../interfaces/TenantSetting';
import { Query } from '../../services/graphql/query';
import GraphqlService from '../../services/graphql/GraphqlService';
import { ISettingType } from '../../interfaces/TenantSetting';
import './TenantSettingSelect.less';
import { IReceiptTaxType } from '../../interfaces/Receipt';
import { ContextApp } from '../../contexts/ContextApp';

type Props = {
  settingsArray: ISettingToUpdate[];
  handleChange: (id: any, value: any) => void;
};

export const useSettingReceiptTaxTypeSelect = (props: Props) => {
  const { settingsArray, handleChange } = props;
  const [receiptTaxTypes, setReceiptTaxTypes] = useState<IReceiptTaxType[]>([]);
  const { customRequest } = GraphqlService();
  const { selectedTenantId } = useContext(ContextApp);

  useEffect(() => {
    getReceiptTaxTypes(selectedTenantId);
  }, [selectedTenantId]);

  const getReceiptTaxTypes = async (tenantId: number | undefined) => {
    const data: IReceiptTaxType[] = await customRequest({
      query: Query.receiptTaxTypes,
      variables: { filter: { tenant_id: tenantId } },
    });
    setReceiptTaxTypes(data);
  };

  const ReceiptTaxTypeSelect = useCallback(
    (props: ISettingProperties) => {
      const valueSelected = settingsArray
        .find((item) => item.id === props.id)
        ?.value?.toString();

      return (
        <Form.Item
          className="margin-component"
          required
          key={props.key || undefined}
        >
          {!receiptTaxTypes.length || !settingsArray.length ? (
            <Select loading className="setting-select" />
          ) : (
            <Select
              className="setting-select"
              onChange={(e) => handleChange(props.id, e)}
              getPopupContainer={(node) => node.parentNode}
              value={valueSelected}
              options={receiptTaxTypes.map((item) => ({
                label: item.name,
                value: item.id.toString(),
              }))}
            ></Select>
          )}
        </Form.Item>
      );
    },
    [receiptTaxTypes, settingsArray],
  );

  const receiptTaxTypeSetting: ISettingType = {
    render: useCallback(
      (props) => {
        return <ReceiptTaxTypeSelect {...props} />;
      },
      [receiptTaxTypes, settingsArray],
    ),
  };

  return { receiptTaxTypeSetting };
};

export default useSettingReceiptTaxTypeSelect;
