export const UPDATE_REVIEWER_CONFIGURATION_TYPE_TENANT = `
mutation updateReviewerConfigurationTypeTenant(
  $document_type_id: Int!,
  $tenant_id: Int!,
  $input: UpdateReviewerConfigurationTypeTenantInput!
) {
  updateReviewerConfigurationTypeTenant(
    document_type_id:$document_type_id,
    tenant_id: $tenant_id, 
    input: $input) {
    __typename
    ... on ResponseMessage {
      status
      response
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;
