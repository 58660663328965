import { Modal } from 'antd';
import './RestoreAllModal.less';
import { ContextApp } from '../../../contexts/ContextApp';
import { useContext } from 'react';
import ButtonTertiary from '../../../components/common/Buttons/ButtonTertiary';
import ButtonSecondary from '../../../components/common/Buttons/ButtonSecondary';
import ButtonAction from '../../../components/common/Buttons/ButtonAction';

interface RestoreAllModalProps {
  modalVisible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onRestoreAllToDefault: () => void;
  onRestoreAllToPreviousState: () => void;
}

const RestoreAllModal = (props: RestoreAllModalProps) => {
  const {
    modalVisible,
    setModalVisible,
    onRestoreAllToDefault,
    onRestoreAllToPreviousState,
  } = props;

  const { t } = useContext(ContextApp);

  return (
    <Modal
      destroyOnClose
      title={
        <div className="modal-content__title">
          <span className="material-symbols-outlined receipt-exclamation-icon">
            error
          </span>
          {t('settingsPage.restoreAllModal.title')}
        </div>
      }
      open={modalVisible}
      onCancel={() => setModalVisible(false)}
      footer={null}
      className="restore-all-modal"
    >
      <div className="modal-content">
        <p className="modal-content__text">
          {t('settingsPage.restoreAllModal.paragraph')}
        </p>
        <div className="modal-content__actions">
          <ButtonTertiary onClick={() => setModalVisible(false)}>
            Cancelar
          </ButtonTertiary>
          <ButtonSecondary onClick={onRestoreAllToDefault}>
            {t('settingsPage.restoreAllModal.restoreToDefault')}
          </ButtonSecondary>
          <ButtonAction onClick={onRestoreAllToPreviousState}>
            {t('settingsPage.restoreAllModal.restoreToPreviousState')}
          </ButtonAction>
        </div>
      </div>
    </Modal>
  );
};

export default RestoreAllModal;
