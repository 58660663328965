import './GenericCard.less';

interface IContactCardProps {
  icon: JSX.Element;
  title?: string | JSX.Element | undefined;
  description: string | JSX.Element;
  button?: JSX.Element;
  className?: string;
  orientation?: 'horizontal' | 'vertical';
  onCardClick?: () => void;
}

export const GenericCard = (props: IContactCardProps) => {
  const {
    icon,
    title,
    description,
    button,
    className,
    orientation = 'horizontal',
    onCardClick,
  } = props;

  return (
    <div className={`GenericCard-container ${className}`} onClick={onCardClick}>
      <div
        className="GenericCard-container--information"
        style={{
          flexDirection: orientation === 'horizontal' ? 'row' : 'column',
          alignItems: orientation === 'horizontal' ? 'center' : 'flex-start',
        }}
      >
        {icon}
        <div>
          {title ? <p className="GenericCard-container--information--title">{title}</p> : null}
          <p className="GenericCard-container--information--description">
            {description}
          </p>
        </div>
      </div>
      {button}
    </div>
  );
};
