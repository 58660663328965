import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useContext } from 'react';
import { ErrorCard } from '../components/common/ErrorCard/ErrorCard';
import { ContextApp } from '../contexts/ContextApp';
import './useNotificationCards.less';

const useNotificationCards = () => {
  const { t, notifications } = useContext(ContextApp);

  let errorCard: JSX.Element | null = null;
  const ExclamationIcon = (
    <ExclamationCircleOutlined className="icon--exclamationCircleOutlined" />
  );

  let errorDescription = (
    <>
      {t('ReceiptTray.dueCreditsCardDescriptionA')}{' '}
      <a
        href='/app/packs'
        className="ErrorCard-container--description--link"
      >
        {t('ReceiptTray.dueCreditsCardDescriptionB')}
      </a>{' '}
      {t('ReceiptTray.dueCreditsCardDescriptionC')}
    </>
  );

  if (notifications?.quotaIsExpired) {
    errorCard = (
      <ErrorCard
        icon={ExclamationIcon}
        title={t('ReceiptTray.dueCreditsCardTitle')}
        description={errorDescription}
      />
    );
  } else if (notifications?.thereIsNotQuota) {
    errorCard = (
      <ErrorCard
        icon={ExclamationIcon}
        title={t('PacksPage.thereIsNoQuota')}
        description={errorDescription}
      />
    );
  }
  return {
    errorCard,
  };
};
export default useNotificationCards;
