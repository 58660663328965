import { MoreOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Tooltip } from 'antd';
import { tooltipTrigger } from '../../../../shared/antdUtils';
import { MenuItemType } from 'antd/lib/menu/hooks/useItems';

export interface ExtendedMenuItemType extends MenuItemType {
  hidden?: boolean;
  tooltipMessageOnDisabled?: string;
}

interface IActionMenuItem {
  items: ExtendedMenuItemType[];
}

const ActionMenu = (props: IActionMenuItem) => {
  const { items } = props;

  return (
    <Dropdown.Button
      trigger={['click']}
      type="text"
      size="large"
      icon={<MoreOutlined />}
      overlay={
        <Menu style={{ borderRadius: '5px', minWidth: 140 }}>
          {items.map(
            ({
              icon,
              key,
              onClick,
              label,
              disabled,
              hidden,
              tooltipMessageOnDisabled = '',
            }) => (
              <Menu.Item
                key={key}
                onClick={onClick}
                disabled={disabled}
                hidden={hidden}
                className='Menu-action-buttons'
              >
                <Tooltip
                  key={key}
                  trigger={tooltipTrigger}
                  title={disabled && tooltipMessageOnDisabled}
                  placement="left"
                  arrowPointAtCenter
                >
                  <span style={{ marginRight: 10, display:'flex' }}>{icon}</span>
                  {label}
                </Tooltip>
              </Menu.Item>
            ),
          )}
        </Menu>
      }
    />
  );
};
export default ActionMenu;
