import { useContext } from 'react';
import { ContextApp } from '../../../contexts/ContextApp';

import './index.less';

export const MenuTrigger = () => {
  const { mainMenuCollapsed, setMainMenuCollapsed } = useContext(ContextApp);
  return (
    <div className="menuTrigger">
      {mainMenuCollapsed ? (
        <span
        translate='no'
          className="material-symbols-outlined trigger" 
          onClick={() => setMainMenuCollapsed(() => !mainMenuCollapsed)}
        >
          menu
        </span>
      ) : (
        <span
        translate='no'
          className="material-symbols-outlined trigger" 
          onClick={() => setMainMenuCollapsed(() => !mainMenuCollapsed)}
        >
          menu
        </span>
      )}
    </div>
  );
};
