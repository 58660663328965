export const CREATE_INTEGRATION_SETTING_TENANT = `
mutation createIntegrationSettingTenant(
  $tenant_id: Int!
  $input: [IntegrationSettingTenantCreateInput!]!
) {
  createIntegrationSettingTenant(tenant_id: $tenant_id, input: $input) {
    __typename
    ... on ResponseMessage {
      status
      response
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const UPDATE_MANY_INTEGRATION_SETTING_TENANT = `
mutation updateManyIntegrationSettingTenant(
  $tenant_id: Int!
  $input: [IntegrationSettingTenantUpdateInput!]!
) {
  updateManyIntegrationSettingTenant(tenant_id: $tenant_id, input: $input) {
    __typename
    ... on ResponseMessage {
      status
      response
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}

`;
