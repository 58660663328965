import { createContext, MutableRefObject } from 'react';
import { IConfigApp, IConfig, IEnvironment } from '../interfaces/environment';
import { IUser, IUserFirebase } from '../interfaces/user';
import { IRole } from '../interfaces/role';
import { Language } from '../interfaces/language';
import { TParameter, TReturn } from '../i18n/i18n.model';
import { StringMap, TOptions } from 'i18next';
import { ITenant } from '../interfaces';
import { INotifications } from '../interfaces/Notification';
import { IFeatureFlagConfiguration } from '../interfaces/FeatureFlag';
const configApp: any = {};
const environment: any = {};
const config: any = {};
const roles: any[] = [];
const functions: string[] = [];

interface IContextApp {
  configApp: IConfigApp;
  setUser: Function;
  user?: IUser;
  setLoading: Function;
  loading: boolean;
  setLoadingMessage: Function;
  loadingMessage: string;
  setShowLoading: Function;
  showLoading: boolean;
  environment: IEnvironment;
  setEnvironment: Function;
  config: IConfig;
  setConfig: Function;
  roles: IRole[];
  setRoles: Function;
  functions: string[];
  setFunctions: Function;
  featureFlags: IFeatureFlagConfiguration | undefined,
  setFeatureFlags: Function;
  dataLoaded: boolean;
  setDataLoaded: Function;
  checkAuth: (lastTenantId?: number) => Promise<void>;
  mainMenuCollapsed: boolean;
  setMainMenuCollapsed: Function;
  menuBreakpointCollapsed: boolean;
  setMenuBreakpointCollapsed: Function;
  sessionExpired: boolean;
  setSessionExpired: Function;
  languages: Language[];
  userDataFirebase?: IUserFirebase;
  setUserDataFirebase: Function;
  pictureProfileUrl: string;
  setPictureProfileUrl: Function;
  t: <S extends string>(
    p: TParameter<S>,
    options?: TOptions<StringMap>,
  ) => TReturn<S>;
  tenantsAssociatedToUser: ITenant[];
  getTenantsAssociatedWithUser: () => Promise<ITenant[]>;
  selectedTenantId: number | undefined;
  setSelectedTenantId: Function;
  availableQuota: number | undefined;
  getAvailableQuota: Function;
  notifications?: INotifications;
  setNotifications: Function;
  seenToursRef: MutableRefObject<number[]>,
  isNumeric: Function;
}

const context: IContextApp = {
  configApp,
  setUser: () => {},
  user: undefined,
  setLoading: () => {},
  loading: false,
  setLoadingMessage: () => {},
  loadingMessage: '',
  setShowLoading: () => {},
  showLoading: true,
  environment,
  setEnvironment: () => {},
  config,
  setConfig: () => {},
  setRoles: () => {},
  setFunctions: () => {},
  roles,
  functions,
  featureFlags: undefined,
  setFeatureFlags: () => {},
  dataLoaded: false,
  setDataLoaded: () => {},
  checkAuth: async () => {},
  mainMenuCollapsed: false,
  setMainMenuCollapsed: () => {},
  menuBreakpointCollapsed: false,
  setMenuBreakpointCollapsed: () => {},
  sessionExpired: false,
  setSessionExpired: () => {},
  languages: [],
  setUserDataFirebase: () => {},
  userDataFirebase: undefined,
  pictureProfileUrl: '',
  setPictureProfileUrl: () => {},
  t: () => '' as any,
  tenantsAssociatedToUser: [],
  getTenantsAssociatedWithUser: async () => [],
  selectedTenantId: 0,
  setSelectedTenantId: () => {},
  availableQuota: 0,
  getAvailableQuota: () => {},
  notifications: undefined,
  setNotifications: () => {},
  seenToursRef: {current: []},
  isNumeric: () => {},
};

export const ContextApp = createContext(context);
