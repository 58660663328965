import { Input, Typography } from 'antd';
import { useContext, useMemo } from 'react';
import { ContextApp } from '../../../contexts/ContextApp';
import { ITenant } from '../../../interfaces';
import { ExportableColumn } from '../../../shared/Exporter';

interface IUseTenantColumnBillingNameProps {
  formColumnName?: string;
  formColumnTitle?: string;
  required?: boolean;
}

export const useTenantColumnBillingName = (
  props: IUseTenantColumnBillingNameProps,
) => {
  const { Text } = Typography;
  const { formColumnName, formColumnTitle, required = false } = props;

  const { t } = useContext(ContextApp);

  const columnBillingName: ExportableColumn<ITenant> = useMemo(
    () => ({
      export: true,
      dataIndex: formColumnName || 'billing_name',
      title: formColumnTitle || t('entity.billing_name'),
      render: (_: any, record: any) =>
        record.billing_name ? (
          <Text
          
            style={{ width: 90 }}
            ellipsis={{
              tooltip: record.billing_name,
            }}
          >
            <span translate='no'>{record.billing_name}</span>
          </Text>
        ) : (
          '-'
        ),
      renderFormItem: () => (
        <Input
          placeholder={`${t('action.input.enter')} ${t(
            'entity.billing_name',
          ).toLocaleLowerCase()}`}
        />
      ),
      formItemProps: {
        normalize: (value: string) => value?.trimLeft(),
        label:`${t('entity.billing_name')} ${t('action.optional')}`,
        rules: [
          {
            required: required,
            message: t('tenantEditPage.billingNameRequired'),
          },
        ],
      },
      align: 'left',
      hideInTable: false,
      hideInSearch: true,
      hideInForm: false,
    }),
    [],
  );

  return { columnBillingName };
};
