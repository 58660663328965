import { NavContext } from '@ionic/react';
import { useContext } from 'react';
import { ContextApp } from '../../contexts/ContextApp';
import './SelectTenant.less';

export const SelectTenant = () => {
  const { t, selectedTenantId } = useContext(ContextApp);
  const { navigate } = useContext(NavContext);
  if (selectedTenantId) navigate('/');
  return (
    <div className="container-message-tenant-not-selected">
      <span className="container-message-tenant-not-selected__message-tenant-not-selected">
        {t('message.tenantIsNotSelected')}
      </span>
    </div>
  );
};
