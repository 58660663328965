export const USER_NOTIFICATION_TYPES = `
query userNotificationTypes ($user_id: Int!, $tenant_id: Int!){
  userNotificationTypes (user_id: $user_id, tenant_id: $tenant_id){
    __typename
    ... on UserNotificationType {
      id
      user_id
      notification_type_id
    }
    ...on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;
