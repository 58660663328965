import { ReactNode } from 'react';
import { notificationContext } from './NotificationContext';
import notification, { NotificationPlacement } from 'antd/lib/notification';
import { message as antdMessage } from 'antd';

const Provider = notificationContext.Provider;

type NotificationType = 'success' | 'info' | 'warning' | 'error';

export interface NotificationOptions {
  msj: string;
  descr?: string;
  filename?: string;
  placement?: NotificationPlacement;
  type: NotificationType;
  context?: string;
}

const NotificationContextProvider = ({ children }: { children: ReactNode }) => {
  const [api, contextHolder] = notification.useNotification();

  function destroy() {
    antdMessage.destroy();
    document
      .querySelectorAll('.ant-message')
      .forEach((elem) => elem.parentNode?.removeChild(elem));
  }

  const openNotification = async (op: NotificationOptions) => {
    op.placement = op.placement ? op.placement : 'topRight';
    op.type = op.type ? op.type : 'info';

    switch (op.type) {
      case 'success':
        destroy();
        api.success({
          message: op.msj,
          description: (
            <>
              <p style={{ fontSize: '13px', marginBottom: 0 }}>{op.descr}</p>
              <p
                style={{
                  fontSize: '13px',
                  color: 'rgba(0, 0, 0, 0.6)',
                  marginBottom: 0,
                }}
              >
                {op.filename}
              </p>
            </>
          ),
          placement: op.placement,
          style: {
            width: 'auto',
          },
        });
        break;
      case 'error':
        destroy();
        api.error({
          message: op.msj,
          description: op.descr ? <p>{op.descr}</p> : null,
          placement: op.placement,
          duration: 0,
          style: {
            width: 'auto',
          },
        });
        break;
      case 'warning':
        destroy();
        api.warning({
          message: op.msj,
          description: (
            <>
              <p style={{ fontSize: '13px', marginBottom: 0 }}>{op.descr}</p>
              <p
                style={{
                  fontSize: '13px',
                  color: 'rgba(0, 0, 0, 0.6)',
                  marginBottom: 0,
                }}
              >
                {op.filename}
              </p>
            </>
          ),
          placement: op.placement,
          style: {
            width: 'auto',
          },
        });
        break;
      case 'info':
        destroy();
        api.info({
          message: op.msj,
          description: (
            <>
              <p style={{ fontSize: '13px', marginBottom: 0 }}>{op.descr}</p>
              <p
                style={{
                  fontSize: '13px',
                  color: 'rgba(0, 0, 0, 0.6)',
                  marginBottom: 0,
                }}
              >
                {op.filename}
              </p>
            </>
          ),
          placement: op.placement,
          duration: 0,
          style: {
            width: 'auto',
          },
        });
        break;
      default:
        break;
    }
  };
  return (
    <Provider
      value={{
        openNotification,
      }}
    >
      {children}
      {contextHolder}
    </Provider>
  );
};

export default NotificationContextProvider;
