import { Form, Select, Tooltip } from 'antd';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ContextApp } from '../../contexts/ContextApp';
import { EnumsValues } from '../../enums/EnumsValues';
import { IDescriptionType } from '../../interfaces/DescriptionType';
import { ABM } from '../../shared';

import { ExportableColumn } from '../../shared/Exporter';
import { CustomMessage } from '../CustomMessage';
import { notificationContext } from '../../contexts/NotificationContext';

interface IUseDescriptionTypeProps {
  formColumnName?: string;
  formColumnTitle?: string;
  required?: boolean;
  hideInSearch?: boolean;
  hideInTable?: boolean;
  hideInForm?: boolean;
  editMode?: boolean;
  initialValue?: number;
  onSelect?: (v?: string) => void;
  withTooltip?: boolean;
}

interface IBaseInterface {
  description_type: number;
}

export function useColumnDescriptionType<T extends IBaseInterface>(
  props: IUseDescriptionTypeProps,
) {
  const {
    formColumnName,
    formColumnTitle,
    required = false,
    hideInForm,
    hideInSearch,
    hideInTable,
    editMode,
    initialValue,
    onSelect,
    withTooltip,
  } = props;
  const { t } = useContext(ContextApp);
  const { openNotification } = useContext(notificationContext);
  const { getErrorMessage } = CustomMessage();
  const [selectedDescriptionType, setSelectedDescriptionType] =
    useState<number>();
  const [descriptionTypeCombo, setDescriptionTypesCombo] = useState<
    IDescriptionType[]
  >([]);

  const getDescriptionTypes = async () => {
    try {
      // TODO: Reemplazar por consulta a backend cuando se creen servicios de esta entidad
      setDescriptionTypesCombo([
        {
          id: EnumsValues.DescriptionType.Equal,
          name: 'Igual',
          description:
            'La descripción recibida es exactamente igual a la descripción registrada',
          description_translation_key: 'Description.EQUAL',
          active: true,
          translation_key: 'entity.EQUAL',
        },
        {
          id: EnumsValues.DescriptionType.Contain,
          name: 'Contenida',
          description:
            'La descripción recibida contiene a la descripción registrada',
          description_translation_key: 'Description.CONTAIN',
          active: true,
          translation_key: 'entity.CONTAIN',
        },
        {
          id: EnumsValues.DescriptionType.Near,
          name: 'Cercana',
          description:
            'La descripción recibida tiene distancia de la descripción registrada menor o igual a "distancia"',
          description_translation_key: 'Description.NEAR',
          active: true,
          translation_key: 'entity.NEAR',
        },
      ]);
    } catch (error: any) {
      openNotification({
        type: 'error',
        msj: getErrorMessage(error),
        context: 'UseColumnDescriptionType.getDescriptionTypes.1',
      });
    }
  };

  useEffect(() => {
    getDescriptionTypes();
  }, []);

  const columnDescriptionType: ExportableColumn<T> = useMemo(
    () => ({
      export: true,
      dataIndex: formColumnName || 'description_type',
      title: formColumnTitle || t('entity.descriptionType'),
      render: (_, record) =>
        t(
          descriptionTypeCombo.find((ele) => ele.id === record.description_type)
            ?.translation_key as never,
          {
            defaultValue:
              descriptionTypeCombo.find(
                (ele) => ele.id === record.description_type,
              )?.name || '-',
          },
        ),
      renderDataExport: (record) => {
        return (
          descriptionTypeCombo.find((ele) => ele.id === record.description_type)
            ?.name || '-'
        );
      },
      align: 'left',
      formItemProps: {
        rules: [
          {
            required: required,
            message: t('error.abm.descriptionTypeRequired'),
          },
        ],
      },
      renderFormItem: (_, __, formInstance) =>
        !withTooltip ? (
          <Select
            options={descriptionTypeCombo.map((descriptionType) => ({
              label: t(descriptionType.translation_key as never, {
                defaultValue: descriptionType.name || '-',
              }),
              title: t(descriptionType.description_translation_key as never, {
                defaultValue: descriptionType.description || '-',
              }),
              value: editMode
                ? descriptionType.id.toString()
                : descriptionType.id,
            }))}
            getPopupContainer={(node) => node.parentNode}
            allowClear
            placeholder={`${t('message.placeholder.selectDescriptionType')}`}
            showSearch
            filterOption={(inputValue: string, option: any) =>
              option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
              0
            }
            onClear={() => {
              formInstance.setFieldsValue({ description_type: undefined });
              setSelectedDescriptionType(undefined);
              if (onSelect) onSelect(undefined);
            }}
            onSelect={(description_type: string) => {
              formInstance.setFieldsValue({ description_type });
              setSelectedDescriptionType(+description_type);
              if (onSelect) onSelect(description_type);
            }}
          />
        ) : (
          <div className="input-help" translate='no'>
            <Form.Item name="description_type">
              <Select
                options={descriptionTypeCombo.map((descriptionType) => ({
                  label: t(descriptionType.translation_key as never, {
                    defaultValue: descriptionType.name || '-',
                  }),
                  title: t(
                    descriptionType.description_translation_key as never,
                    {
                      defaultValue: descriptionType.description || '-',
                    },
                  ),
                  value: editMode
                    ? descriptionType.id.toString()
                    : descriptionType.id,
                }))}
                getPopupContainer={(node) => node.parentNode}
                allowClear
                placeholder={`${t(
                  'message.placeholder.selectDescriptionType',
                )}`}
                showSearch
                filterOption={(inputValue: string, option: any) =>
                  option?.label
                    ?.toLowerCase()
                    .indexOf(inputValue.toLowerCase()) >= 0
                }
                onClear={() => {
                  formInstance.setFieldsValue({ description_type: undefined });
                  setSelectedDescriptionType(undefined);
                  if (onSelect) onSelect(undefined);
                }}
                onSelect={(description_type: string) => {
                  formInstance.setFieldsValue({ description_type });
                  setSelectedDescriptionType(+description_type);
                  if (onSelect) onSelect(description_type);
                }}
              />
            </Form.Item>
            <div className="input-help-icon">
              <Tooltip
                title={
                  <span>
                    {t('tour.DecisionTable.step2.part1')}
                    <br />
                    <br />
                    <ul style={{ listStyleType: 'disc' }}>
                      <li>
                        <b>{t('entity.EQUAL')}</b>:{' '}
                        {t('tour.DecisionTable.step2.part2')}
                      </li>
                      <li>
                        <b>{t('entity.CONTAIN')}</b>:{' '}
                        {t('tour.DecisionTable.step2.part3')}
                      </li>
                      <li>
                        <b>{t('entity.NEAR')}</b>:{' '}
                        {t('tour.DecisionTable.step2.part4')}
                      </li>
                    </ul>
                  </span>
                }
                placement="right"
              >
                <span className="material-symbols-outlined dataRelation-info-icon" translate='no'>
                  info
                </span>
              </Tooltip>
            </div>
          </div>
        ),
      hideInSearch,
      hideInForm,
      hideInTable,
      type: ABM.TYPE_COLUMN.NUMBER,
      initialValue,
    }),
    [descriptionTypeCombo],
  );

  return {
    columnDescriptionType,
    selectedDescriptionType,
    setSelectedDescriptionType,
    descriptionTypeCombo,
  };
}
