import { useContext } from 'react';
import { Modal } from 'antd';
import { useHistory } from 'react-router';
import { CheckCircleFilled } from '@ant-design/icons';

import { ContextApp } from '../../contexts/ContextApp';

import './ReviewReceiptExitModal.less';

interface ReviewReceiptExitModalProps {
  visible: boolean;
}

const ReviewReceiptExitModal = (props: ReviewReceiptExitModalProps) => {
  const { visible } = props;

  const { t } = useContext(ContextApp);
  const history = useHistory();

  return (
    <Modal
      destroyOnClose
      title={
        <div className="review-receipt-exit-modal-title">
          <CheckCircleFilled className="review-receipt-exit-modal-title-icon" />
          {t('ReviewReceipt.exitModalOnReview.title')}
        </div>
      }
      className="review-receipt-exit-modal"
      open={visible}
      okButtonProps={{
        onClick: () => {
          return history.push({
            pathname: '/app/receipt',
            state: { keepFilter: true },
          });
        },
      }}
      cancelButtonProps={{ hidden: true }}
      okText={t('ReviewReceipt.exitModalOnReview.okButton')}
      maskClosable={false}
      closable={false}
    >
      <div> {t('ReviewReceipt.exitModalOnReview.description')}</div>
      <br />
      <div> {t('ReviewReceipt.exitModalOnReview.redirection')}</div>
    </Modal>
  );
};
export default ReviewReceiptExitModal;
