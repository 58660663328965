import { Button, Drawer, Form, Input, Tooltip } from 'antd';
import './ToolbarFilterOrder.less';
import { useContext, useEffect, useState } from 'react';
import { ContextApp } from '../../../contexts/ContextApp';
import { Tools } from '../../../shared';
import { useForm } from 'antd/es/form/Form';
import { IToolbarFilterOrderProps } from './interfaces';
// import useRadioButtonOrderBy from '../../../hooks/useRadioButtonOrderBy';
import { tooltipTrigger } from '../../../shared/antdUtils';
import { FilterIcon } from '../../Icons/FilterIcon';
import ButtonAction from '../Buttons/ButtonAction';
import ButtonTertiary from '../Buttons/ButtonTertiary';
import FilterTags from './FilterTags/FilterTags';

const ToolbarFilterOrder = <T,>({
  filterNames,
  onSearch,
  onClickButtonNew,
  showButtonNew,
  hideSearch,
  onFinishFilter,
  // onFinishOrder,
  columns,
  showButtonRefresh,
  onClickButtonRefresh,
  initialValuesFilter,
  onClickButtonClean,
  showButtonClean = false,
}: IToolbarFilterOrderProps<T>) => {
  const { t } = useContext(ContextApp);
  // const [openDrawerOrder, setOpenDrawerOrder] = useState<boolean>(false);
  const [openDrawerFilter, setOpenDrawerFilter] = useState<boolean>(false);
  // const [valueRadioGroup, setValueRadioGroup] = useState<string>();

  // const { configurationLabelValue } = useRadioButtonOrderBy();
  const [formSearchTextRef] = useForm();
  const [formFilterRef] = useForm();
  const [formPrincipalFilterRef] = useForm();
  const [isHovered, setIsHovered] = useState(false);
  // const [formOrderRef] = useForm();

  // const onChangeRadioGroup = (e: RadioChangeEvent, columnChange: string) => {
  //   const dataIndexOrderColumns = columns
  //     .filter((filterSorter) => filterSorter.sorter)
  //     .map((ele) => ele.dataIndex) as string[];
  //   formOrderRef.resetFields(
  //     dataIndexOrderColumns.filter((ele) => ele !== columnChange),
  //   );
  //   setValueRadioGroup(e.target.value);
  // };

  useEffect(() => {
    if (initialValuesFilter && formFilterRef) {
      formFilterRef.setFieldsValue(initialValuesFilter);
    }
  }, [formFilterRef, initialValuesFilter]);

  return (
    <div className="toolbar-filter-order">
      {columns &&
        columns.length > 0 &&
        columns.some(
          (column) => column.principalFilter && !column.hideInSearch,
        ) && (
          <div className="toolbar-filter-order__container-filter-order-principal-filter-operations__container-principal-filter">
            <span className="toolbar-filter-order__container-filter-order-principal-filter-operations__container-principal-filter__label">
              {Tools.capitalize(t('action.filter'))}:
            </span>
            {columns
              .filter(
                (column) => column.principalFilter && !column.hideInSearch,
              )
              .map((ele, index) => (
                <>
                  <Form
                    form={formPrincipalFilterRef}
                    onFinish={(values) => {
                      onFinishFilter && onFinishFilter(values);
                      setOpenDrawerFilter(false);
                    }}
                    className="toolbar-filter-order__container-filter-order-principal-filter-operations__container-principal-filter__form"
                  >
                    {ele.renderFormItem ? (
                      <>
                        {ele.title ? (
                          <>
                            <Tooltip
                              key={`principal-filter-${index}`}
                              title={Tools.capitalize(
                                t('action.filterBy', {
                                  replace: {
                                    title: String(ele.title).toLowerCase(),
                                  },
                                }),
                              )}
                              trigger={tooltipTrigger}
                            >
                              <Form.Item
                                name={ele.dataIndex}
                                {...ele.formItemProps}
                              >
                                {ele.renderFormItem(
                                  { type: 'form' },
                                  {
                                    defaultRender: () => {
                                      return <></>;
                                    },
                                    type: 'form',
                                  },
                                  formPrincipalFilterRef,
                                )}
                              </Form.Item>
                            </Tooltip>
                          </>
                        ) : (
                          <>
                            <Form.Item
                              name={ele.dataIndex}
                              {...ele.formItemProps}
                            >
                              {ele.renderFormItem(
                                { type: 'form' },
                                {
                                  defaultRender: () => {
                                    return <></>;
                                  },
                                  type: 'form',
                                },
                                formPrincipalFilterRef,
                              )}
                            </Form.Item>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {ele.title ? (
                          <>
                            <Tooltip
                              key={`principal-filter-${index}`}
                              title={Tools.capitalize(
                                t('action.filterBy', {
                                  replace: {
                                    title: String(ele.title).toLowerCase(),
                                  },
                                }),
                              )}
                              trigger={tooltipTrigger}
                            >
                              <Form.Item
                                name={ele.dataIndex}
                                {...ele.formItemProps}
                              >
                                <Input
                                  placeholder={t(
                                    'placeholder.abm.inputPlaceholder',
                                    {
                                      replace: {
                                        entity:
                                          ele.title ||
                                          ele.dataIndex ||
                                          t('label.value'),
                                      },
                                    },
                                  )}
                                />
                              </Form.Item>
                            </Tooltip>
                          </>
                        ) : (
                          <>
                            <Form.Item
                              name={ele.dataIndex}
                              {...ele.formItemProps}
                            >
                              <Input
                                placeholder={t(
                                  'placeholder.abm.inputPlaceholder',
                                  {
                                    replace: {
                                      entity:
                                        ele.title ||
                                        ele.dataIndex ||
                                        t('label.value'),
                                    },
                                  },
                                )}
                              />
                            </Form.Item>
                          </>
                        )}
                      </>
                    )}
                  </Form>
                </>
              ))}
            <Button
              className="toolbar-filter-order__container-filter-order-principal-filter-operations__container-principal-filter__button-apply-filter"
              type="primary"
              onClick={() => {
                formPrincipalFilterRef.submit();
              }}
            >
              {Tools.capitalize(t('action.apply'))}
            </Button>
          </div>
        )}
      <div className="toolbar-filter-order__left">
        {columns &&
        columns.length > 0 &&
        columns.filter((column) => column.sorter || !column.hideInSearch)
          .length ? (
          <>
            {/* {columns.some((ele) => ele.sorter) && (
      <>
        <div className="toolbar-filter-order__container-filter-order-principal-filter-operations__container-buttons__container-button">
          <Button
            type="text"
            onClick={() => {
              setOpenDrawerOrder(true);
            }}
          >
            {'<TodoSortIcon />'}
            {Tools.capitalize(t('action.order'))}
          </Button>
        </div>
      </>
    )} */}
            {columns.some((column) => !column.hideInSearch) && (
              <>
                <div className="toolbar-filter-order__container-filter-order-principal-filter-operations__container-buttons__container-button">
                  <Button
                    className="filter-button"
                    type="text"
                    onClick={() => {
                      setOpenDrawerFilter(true);
                    }}
                  >
                    <FilterIcon
                      fill={isHovered ? '#03729f' : '#1C1B1F'}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    />
                    {columns.some(
                      (column) =>
                        column.principalFilter && !column.hideInSearch,
                    )
                      ? Tools.capitalize(t('action.moreFilter'))
                      : Tools.capitalize(t('action.filter'))}
                  </Button>
                </div>
              </>
            )}
          </>
        ) : null}
        <FilterTags columns={columns} formInstance={formFilterRef} />
      </div>
      <div className="toolbar-filter-order__right">
        {!hideSearch && (
          <>
            <div className="toolbar-filter-order__container-filter-order-principal-filter-operations__container-search">
              <Tooltip
                key="searchtext"
                title={
                  filterNames
                    ? `${Tools.capitalize(t('action.searchBy'))} ${filterNames}`
                    : undefined
                }
                trigger={tooltipTrigger}
              >
                <Form
                  onFinish={(values) => {
                    onSearch && onSearch(values.searchText);
                  }}
                  form={formSearchTextRef}
                >
                  <Form.Item name="searchText">
                    <Input.Search
                      className="toolbar-filter-order__container-filter-order-principal-filter-operations__container-search__search"
                      placeholder={
                        filterNames
                          ? filterNames
                          : Tools.capitalize(t('placeholder.search'))
                      }
                      onSearch={() => {
                        formSearchTextRef.submit();
                      }}
                      enterButton
                      allowClear
                    />
                  </Form.Item>
                </Form>
              </Tooltip>
            </div>
          </>
        )}
        {showButtonClean && (
          <div>
            <Button
              onClick={() => {
                formSearchTextRef.resetFields();
                formSearchTextRef.submit();
                formFilterRef.resetFields();
                formFilterRef.submit();
                formPrincipalFilterRef.resetFields();
                formPrincipalFilterRef.submit();
                // formOrderRef.resetFields();
                // formOrderRef.submit();
                if (onClickButtonClean) {
                  onClickButtonClean(formFilterRef);
                }
              }}
              type="default"
            >
              {Tools.capitalize(t('action.cleanFilters'))}
            </Button>
          </div>
        )}
        {showButtonRefresh && (
          <div
            className={`toolbar-filter-order__container-button-refresh ${
              showButtonNew ? 'mr-1' : ''
            }`}
          >
            <ButtonTertiary
              type="text"
              onClick={() => {
                onClickButtonRefresh && onClickButtonRefresh();
              }}
              className="toolbar-filter-order__container-button-refresh__button"
            >
              <span className="material-symbols-outlined" translate="no">
                refresh
              </span>{' '}
              {t('action.update')}
            </ButtonTertiary>
          </div>
        )}
        {showButtonNew && (
          <div className="toolbar-filter-order__container button-new">
            <ButtonAction
              type="primary"
              onClick={() => {
                onClickButtonNew && onClickButtonNew();
              }}
            >
              {Tools.capitalize(t('action.new'))}
            </ButtonAction>
          </div>
        )}
      </div>
      {/* <Drawer
        title={Tools.capitalize(t('label.order'))}
        placement={'right'}
        closable
        onClose={() => {
          setOpenDrawerOrder(false);
        }}
        open={openDrawerOrder}
        key={'drawer_order_left'}
        className="drawer-order"
      >
        <Form
          form={formOrderRef}
          layout="vertical"
          className="drawer-order__form-order"
          onFinish={(values) => {
            const orderConfigurationCopy = { ...values };
            Object.keys(orderConfigurationCopy).forEach((ele) => {
              if (orderConfigurationCopy[ele]) {
                values[ele] = {
                  field: ele,
                  direction: orderConfigurationCopy[ele],
                };
              } else {
                values[ele] = undefined;
              }
            });
            onFinishOrder && onFinishOrder(values);
            setOpenDrawerOrder(false);
          }}
        >
          {columns &&
            columns.length > 0 &&
            columns.some((someSorter) => someSorter.sorter) &&
            columns
              .filter((filterSorter) => filterSorter.sorter)
              .map((column) => (
                <Form.Item
                  name={column.dataIndex}
                  label={column.title ? String(column.title) : undefined}
                >
                  <Radio.Group
                    onChange={(e) =>
                      onChangeRadioGroup(e, column.dataIndex as string)
                    }
                    value={valueRadioGroup}
                  >
                    <Space direction="vertical">
                      {configurationLabelValue[column.type || 'default'].map(
                        (radioConfiguration, idx) => (
                          <Radio
                            value={radioConfiguration.value}
                            key={radioConfiguration.value + idx}
                          >
                            {radioConfiguration.label}
                          </Radio>
                        ),
                      )}
                    </Space>
                  </Radio.Group>
                </Form.Item>
              ))}
          <Button
            className="drawer-order__form-order__button-apply"
            type="primary"
            htmlType="submit"
          >
            {Tools.capitalize(t('action.apply'))}
          </Button>
        </Form>
      </Drawer> */}
      <Drawer
        title={Tools.capitalize(t('label.filter'))}
        placement={'right'}
        closable
        onClose={() => {
          setOpenDrawerFilter(false);
        }}
        open={openDrawerFilter}
        key={'drawer_filter_right'}
        className="drawer-filter"
        forceRender
      >
        <Form
          form={formFilterRef}
          layout="vertical"
          className="drawer-filter__form-filter"
          onFinish={(values) => {
            onFinishFilter && onFinishFilter(values);
            setOpenDrawerFilter(false);
          }}
        >
          {columns &&
            columns.length > 0 &&
            columns
              .filter(
                (column) => !column.hideInSearch && !column.principalFilter,
              )
              .map((ele, idx) =>
                !ele.dataIndexOfColumnInline ? (
                  !columns.some(
                    (columnInline) =>
                      columnInline.dataIndexOfColumnInline === ele.dataIndex,
                  ) && (
                    <Form.Item
                      name={ele.dataIndex}
                      label={ele.title ? String(ele.title) : undefined}
                      {...ele.formItemProps}
                      key={((ele.dataIndex as string) ?? '') + idx}
                    >
                      {ele.renderFormItem ? (
                        ele.renderFormItem(
                          { type: 'form' },
                          {
                            defaultRender: () => {
                              return <></>;
                            },
                            type: 'form',
                          },
                          formFilterRef,
                        )
                      ) : (
                        <Input
                          placeholder={t('placeholder.abm.inputPlaceholder', {
                            replace: {
                              entity:
                                ele.title || ele.dataIndex || t('label.value'),
                            },
                          })}
                          key={((ele.dataIndex as string) ?? '') + idx}
                          allowClear
                        />
                      )}
                    </Form.Item>
                  )
                ) : (
                  <div className="drawer-filter__form-filter__container-columns-inline">
                    {!columns.some(
                      (columnInline) =>
                        columnInline.dataIndexOfColumnInline === ele.dataIndex,
                    ) && (
                      <>
                        <Form.Item
                          name={ele.dataIndex}
                          label={ele.title ? String(ele.title) : undefined}
                          {...ele.formItemProps}
                        >
                          {ele.renderFormItem ? (
                            ele.renderFormItem(
                              { type: 'form' },
                              {
                                defaultRender: () => {
                                  return <></>;
                                },
                                type: 'form',
                              },
                              formFilterRef,
                            )
                          ) : (
                            <Input
                              placeholder={t(
                                'placeholder.abm.inputPlaceholder',
                                {
                                  replace: {
                                    entity:
                                      ele.title ||
                                      ele.dataIndex ||
                                      t('label.value'),
                                  },
                                },
                              )}
                            />
                          )}
                        </Form.Item>
                        {columns.filter(
                          (columnInline) =>
                            columnInline.dataIndex ===
                              ele.dataIndexOfColumnInline &&
                            !columnInline.hideInSearch,
                        ).length ? (
                          <>
                            {columns
                              .filter(
                                (columnInline) =>
                                  columnInline.dataIndex ===
                                    ele.dataIndexOfColumnInline &&
                                  !columnInline.hideInSearch,
                              )
                              .map((columnInline) => (
                                <>
                                  <Form.Item
                                    name={columnInline.dataIndex}
                                    label={
                                      columnInline.title
                                        ? String(columnInline.title)
                                        : undefined
                                    }
                                    {...columnInline.formItemProps}
                                  >
                                    {columnInline.renderFormItem ? (
                                      columnInline.renderFormItem(
                                        { type: 'form' },
                                        {
                                          defaultRender: () => {
                                            return <></>;
                                          },
                                          type: 'form',
                                        },
                                        formFilterRef,
                                      )
                                    ) : (
                                      <Input
                                        placeholder={t(
                                          'placeholder.abm.inputPlaceholder',
                                          {
                                            replace: {
                                              entity:
                                                columnInline.title ||
                                                columnInline.dataIndex ||
                                                t('label.value'),
                                            },
                                          },
                                        )}
                                      />
                                    )}
                                  </Form.Item>
                                </>
                              ))}
                          </>
                        ) : null}
                      </>
                    )}
                  </div>
                ),
              )}
          <div className="drawer-filter__buttons">
            <Button
              onClick={() => {
                formFilterRef.resetFields();
                formFilterRef.submit();
                if (onClickButtonClean) {
                  onClickButtonClean(formFilterRef);
                }
              }}
              type="default"
            >
              {Tools.capitalize(t('action.cleanFilters'))}
            </Button>
            <Button
              className="drawer-filter__form-filter__button-apply"
              type="primary"
              htmlType="submit"
            >
              {Tools.capitalize(t('action.apply'))}
            </Button>
          </div>
        </Form>
      </Drawer>
    </div>
  );
};
export default ToolbarFilterOrder;
