export const UPSERT_REVIEW_RECEIPT_HEADER_FIELD = `
mutation upsertReviewReceiptHeaderField($input: UpsertReviewReceiptHeaderFieldInput!){
    upsertReviewReceiptHeaderField(input:$input)
    {
        __typename
        ... on  ReviewReceiptHeaderField{
        id
        receipt_header_id
        field
        value
        json_value
        page_number
        its,
        uts,
        dts
    }
        ...on ResultError {
        status_code
        message
        message_translation_key
        }
    }
}
`;

export const DELETE_MANY_REVIEW_RECEIPT_HEADER_FIELD = `
mutation deleteManyReviewReceiptHeaderField($input: DeleteReviewReceiptHeaderFieldInput!){
    deleteManyReviewReceiptHeaderField(input:$input)
    {
        __typename
        ... on ResponseMessage{
        status
        response
    }
        ...on ResultError {
            status_code
            message
            message_translation_key
        }
    }
}
`;