import { useContext, useState } from 'react';
import { Button, Card, Form } from 'antd';
import './Validate2FA.less';
import { NavContext } from '@ionic/react';
import GraphqlService from '../../../services/graphql/GraphqlService';
import { IResponseMessage } from '../../../interfaces/ResponseMessage';
import ReactCodeInput from 'react-code-input';
import { PageLoading } from '@ant-design/pro-layout';
import { CustomMessage } from '../../../hooks';
import { ContextApp } from '../../../contexts/ContextApp';
import { EnumsValues } from '../../../enums/EnumsValues';
import { UserService } from '../../../services/UserService';
import { Tools } from '../../../shared';
import { notificationContext } from '../../../contexts/NotificationContext';

const Validate2FA = () => {
  // services and hooks
  const [token2fa, setToken2fa] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [valid, setValid] = useState(true);
  const { Mutation, customRequest } = GraphqlService();
  const { logout } = UserService();

  const { openNotification } = useContext(notificationContext);
  const { getErrorMessage } = CustomMessage();

  const { checkAuth, t, selectedTenantId, user } = useContext(ContextApp);
  const { navigate } = useContext(NavContext);

  const validateTwoFactorAuth = async () => {
    setLoading(true);
    try {
      const userId = localStorage.getItem(EnumsValues.LocalStorageKeys.UserId);
      const data: IResponseMessage = await customRequest({
        mutation: Mutation.twoFactorAuth,
        variables: { id: Number(userId), token: token2fa },
      });

      if (data.status) {
        localStorage.setItem(EnumsValues.LocalStorageKeys.Token, data.response);
        localStorage.setItem(
          EnumsValues.LocalStorageKeys.TwoFactorValidated,
          EnumsValues.TwoFactorValidatedValues.True,
        );
        await checkAuth(selectedTenantId);
        navigate('/');
      } else {
        openNotification({
          type: 'error',
          msj: data.response,
          context: 'Validate2FA.validateTwoFactorAuth.1',
        });
      }
    } catch (error: any) {
      openNotification({
        type: 'error',
        msj: getErrorMessage(error),
        context: 'Validate2FA.validateTwoFactorAuth.2',
      });
    }
    setLoading(false);
  };

  const handleTokenChange = (value: string) => {
    setToken2fa(value);

    if (value.replace(/[0-9]/g, '').length) {
      setValid(false);
    } else {
      setValid(true);
    }
  };

  const returnToLogin = () => {
    navigate('/login');
    logout();
  };

  const goBack = () => {
    navigate('/2fa/generate');
  };

  if (loading) {
    return <PageLoading />;
  }

  return (
    <>
      <Card title={t('2FA.validateTitle')} className="main-card-validate2FA">
        <p>{t('2FA.validateDescription')}</p>

        <Form>
          <div className="input-token">
            <div className="container-input">
              <ReactCodeInput
                fields={6}
                type={'text'}
                name={'token-totp'}
                inputMode={'numeric'}
                onChange={handleTokenChange}
                isValid={valid}
              />

              {!valid && (
                <p className="warning-input">{t('2FA.validateWarning')}</p>
              )}
            </div>

            <div className="container-buttons">
              <Button type="default" onClick={user ? goBack : returnToLogin}>
                {user
                  ? Tools.capitalize(t('action.return'))
                  : Tools.capitalize(t('action.returnToLogin'))}
              </Button>
              <Button
                onClick={validateTwoFactorAuth}
                disabled={!valid}
                className="enter-validation-code"
                htmlType="submit"
              >
                {t('action.enter')}
              </Button>
            </div>
          </div>
        </Form>
      </Card>
    </>
  );
};

export default Validate2FA;
