export const UPDATE_PERMISSION = `
  mutation updatePermission($input:  UpdatePermissionInput!) {
    updatePermission(input: $input) {
      __typename
      ... on Permissions {
        id
        name
        code
        description
        uts
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
`;
