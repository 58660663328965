import Service from './graphql/GraphqlService';
import { ContextApp } from '../contexts/ContextApp';
import { useContext } from 'react';

import { logoutFirebase } from '../configs/firebase/firebase-config';
import { EnumsValues } from '../enums/EnumsValues';

export const UserService = () => {
  const { customRequest, Query } = Service();
  const {
    setUser,
    setRoles,
    setFunctions,
    setSessionExpired,
    user,
    setUserDataFirebase,
  } = useContext(ContextApp);

  const authenticate = async (lastTenantId?: number) => {
    try {
      const res = await customRequest({
        query: Query.authenticate,
        variables: {
          tenant_id: lastTenantId,
        },
      });
      return res;
    } catch (error) {
      return null;
    }
  };

  const logout = async () => {
    // TODO: falta implementar lista negra en servicio
    try {
      localStorage.removeItem(EnumsValues.LocalStorageKeys.Token);
      localStorage.removeItem(EnumsValues.LocalStorageKeys.LastTenantId);
      localStorage.removeItem(EnumsValues.LocalStorageKeys.TwoFactorValidated);
      localStorage.removeItem(EnumsValues.LocalStorageKeys.UserId);
      sessionStorage.removeItem(
        EnumsValues.SessionStorageKeys.FromDateMonitorPage,
      );
      sessionStorage.removeItem(
        EnumsValues.SessionStorageKeys.ToDateMonitorPage,
      );
      if (user?.uid) {
        logoutFirebase();
      }
      setUser(() => null);
      setRoles(() => []);
      setFunctions(() => []);
      setSessionExpired(false);
      setUserDataFirebase(() => null);
      await customRequest({
        query: Query.logout,
      });
    } catch (error) {
      throw error;
    }
  };

  const expireSession = () => {
    try {
      localStorage.removeItem(EnumsValues.LocalStorageKeys.Token);
      setRoles(() => []);
      setFunctions(() => []);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  return {
    authenticate,
    logout,
    expireSession,
  };
};
