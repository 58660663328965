import { useMemo } from 'react';
import { useSettingSwitch } from './TenantSettingSwitch';
import { ISettingType, ISettingToUpdate } from '../../interfaces/TenantSetting';
import useSettingCurrencySelect from './TenantSettingCurrencySelect';
import useSettingLanguageSelect from './TenantSettingLanguageSelect';
import { useSettingNumberInput } from './TenantSettingNumberInput';
import useSettingReceiptTaxTypeSelect from './TenantSettingReceiptTaxTypeSelect';
import useSettingAllReceiptTypes from './TenantSettingAllReceiptTypes';
import { useSettingStringArrayInput } from './TenantSettingStringArrayInput';
export enum SettingType {
  switch = 'switch',
  currency = 'currency',
  language = 'language',
  number = 'number',
  receipt_tax_type = 'receipt_tax_type',
  all_receipt_types = 'all_receipt_types',
  stringArray = 'stringArray',
}

type DynamicSettingProps = {
  settingsArray: ISettingToUpdate[];
  handleChange: (id: any, value: any) => void;
};

export const useDynamicSetting = (props: DynamicSettingProps) => {
  const { settingsArray, handleChange } = props;
  const { languageSetting } = useSettingLanguageSelect({
    settingsArray,
    handleChange,
  });
  const { currencyTypeSetting, currencies } = useSettingCurrencySelect({
    settingsArray,
    handleChange,
  });
  const { booleanTypeSetting } = useSettingSwitch({
    settingsArray,
    handleChange,
  });
  const { numberTypeSetting } = useSettingNumberInput({
    settingsArray,
    handleChange,
  });
  const { receiptTaxTypeSetting } = useSettingReceiptTaxTypeSelect({
    settingsArray,
    handleChange,
  });
  const { allReceiptTypeSetting } = useSettingAllReceiptTypes({
    settingsArray,
    handleChange,
  });
  const { stringArrayTypeSetting } = useSettingStringArrayInput({
    settingsArray,
    handleChange,
  });

  const dynamicSettings: Record<SettingType, ISettingType> = useMemo(
    () => ({
      [SettingType.switch]: booleanTypeSetting,
      [SettingType.currency]: currencyTypeSetting,
      [SettingType.language]: languageSetting,
      [SettingType.number]: numberTypeSetting,
      [SettingType.receipt_tax_type]: receiptTaxTypeSetting,
      [SettingType.all_receipt_types]: allReceiptTypeSetting,
      [SettingType.stringArray]: stringArrayTypeSetting,
    }),
    [settingsArray, languageSetting, currencyTypeSetting, booleanTypeSetting],
  );
  return { dynamicSettings, currencies };
};
