import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import WarningModal from './UnsavedChangesModal/WarningModal';

type UseUnsavedChangesWarningProps = {
  onSave: () => Promise<void>;
};

/*
  Este hook sirve para manejar los casos en los que tenemos un formulario 
  con cambios sin guardar y el usuario intenta cambiar de pagina, salir de la pagina o realizar 
  una accion que pueda generar la perdida de los datos dentro de la misma pagina.
  El funcionamiento es mostrar un modal con las opciones "Volver", "Salir sin guardar" 
  y "Guardar y Salir" cuando detecta que se realiza una accion que pueda generar la
  perdida de los datos.

  Para usarlo, se debe instanciar en el componente destino y pasarle la funcion onSave
  como parametro, la misma va a manejar lo que sucede al presionar "guardar y salir".

  Este hook expone 3 cosas: 
    - el componente UnsavedChangesModal que debe ser utilizado en el componente padre
      para renderizar el modal
    - la funcion withUnsavedWarning que sirve para wrappear acciones que deseamos "proteger"
      (esto es util en para controlar acciones que pueden generar perdida de datos sin tener que
      cambiar de pagina, como un select que cambia un formulario por otro)
    - la funcion setHasUnsavedChanges que sirve para controlar la bandera de cambios sin guardar
*/

export const useUnsavedChangesWarning = ({
  onSave,
}: UseUnsavedChangesWarningProps) => {
  const history = useHistory();
  const [shouldBlockAction, setShouldBlockAction] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [actionToProtect, setActionToProtect] =
    useState<(() => void) | null>(null);
  const [nextLocation, setNextLocation] = useState<Location | null>(null);

  const handleBlockNavigation = useCallback(
    (location: Location) => {
      if (
        shouldBlockAction &&
        location.pathname !== history.location.pathname
      ) {
        setIsModalVisible(true);
        setNextLocation(location);
        return false;
      }
      return true;
    },
    [shouldBlockAction, history.location.pathname],
  );

  const withUnsavedWarning = (action: () => void) => {
    if (shouldBlockAction) {
      setActionToProtect(() => action);
      setIsModalVisible(true);
    } else {
      action();
    }
  };

  useEffect(() => {
    const unblock = history.block(handleBlockNavigation);
    return () => {
      unblock();
    };
  }, [handleBlockNavigation, history]);

  useEffect(() => {
    if (!shouldBlockAction) {
      if (actionToProtect) {
        actionToProtect();
        setActionToProtect(null);
      }
      if (nextLocation) {
        history.push(nextLocation.pathname);
        setNextLocation(null);
      }
    }
  }, [shouldBlockAction, actionToProtect, nextLocation, history]);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (shouldBlockAction) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [shouldBlockAction]);

  const handleStay = () => {
    setIsModalVisible(false);
    setNextLocation(null);
    setActionToProtect(null);
  };

  const handleDiscard = () => {
    setShouldBlockAction(false);
    setIsModalVisible(false);
  };

  const handleSaveAndExecute = async () => {
    await onSave();
    setShouldBlockAction(false);
    setIsModalVisible(false);
  };

  const UnsavedChangesModal = () => (
    <WarningModal
      isVisible={isModalVisible}
      onStay={handleStay}
      onExitWithoutSaving={handleDiscard}
      onSaveAndExit={handleSaveAndExecute}
    />
  );

  return {
    UnsavedChangesModal,
    withUnsavedWarning,
    setShouldBlockAction,
    shouldBlockAction,
  };
};
