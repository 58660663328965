import { Tools } from '../shared';
import { ITenant } from '../interfaces';
import GraphqlService from './graphql/GraphqlService';
import { IBooleanResult } from '../interfaces/Boolean';
export const NotficationService = () => {
  const { customRequest, Query } = GraphqlService();

  const getTenantWithReceiptOrDesicionItemIncomplete = async (
    tenant_id: number,
  ) => {
    try {
      const res: IBooleanResult = await customRequest({
        query: Query.tenantWithReceiptOrDesicionItemIncomplete,
        variables: {
          tenant_id,
        },
      });
      return res.result;
    } catch (error) {
      return null;
    }
  };

  const getTenantWithIntegrationIncomplete = async (tenant_id: number) => {
    try {
      const res: IBooleanResult = await customRequest({
        query: Query.tenantWithActiveIntegrationIncomplete,
        variables: {
          tenant_id,
        },
      });
      return res.result;
    } catch (error) {
      return null;
    }
  };

  const thereIsNotQuota = async (tenant_id: number) => {
    try {
      const res = await customRequest({
        query: Query.availableQuota,
        variables: {
          tenant_id,
        },
      });
      return res.count <= 0;
    } catch (error) {
      return null;
    }
  };

  const getNotifications = async (tenant: ITenant) => {
    try {
      const tenant_with_receipt_or_decision_item_incomplete: boolean | null =
        await getTenantWithReceiptOrDesicionItemIncomplete(tenant.id);
      const tenant_with_integration_incomplete: boolean | null =
        tenant.integration_id
          ? await getTenantWithIntegrationIncomplete(tenant.id)
          : null;

      const there_is_not_quota: boolean | null = await thereIsNotQuota(
        tenant.id,
      );

      let miss_billing_data: boolean = false;
      let quota_is_expired: boolean = false;
      if (tenant) {
        miss_billing_data = Tools.missBillingData(tenant);
        quota_is_expired = !!Tools.quotaIsExpired(tenant);
      }

      return {
        missBillingData: miss_billing_data,
        missConfigurationOfTheIntegration: !!tenant_with_integration_incomplete,
        missDataInDecisionTable:
          !!tenant_with_receipt_or_decision_item_incomplete,
        quotaIsExpired: quota_is_expired,
        thereIsNotQuota: !!there_is_not_quota,
      };
    } catch (error) {
      return;
    }
  };

  return {
    getNotifications,
  };
};
