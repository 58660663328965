import { amountFormat } from '../../regex/regexValues';
import number from './number';

const amount = (content: string): string => {
  if (isNaN(Number(content.replace(/[,.]/g, '')))) return '';
  let response = number(content);
  if (amountFormat.test(response)) return response;
  response = response.replace('.', ',');
  response = response.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return response;
};
export default amount;
