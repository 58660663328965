export const REMOVE_RECEIPT = `
mutation removeReceipt($tenant_id: Int!, $receipt_id: Int!) {
  removeReceipt(tenant_id: $tenant_id, receipt_id: $receipt_id) {
    __typename
    ... on BooleanResult {
      result
    }

    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const REPROCESS_INCOMPLETE_RECEIPT_HEADERS = `
mutation reprocessIncompleteReceiptHeaders($tenant_id: Int!) {
  reprocessIncompleteReceiptHeaders(tenant_id: $tenant_id) {
    __typename
    ... on BooleanResult{
      result
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}`;

export const SET_RECEIPT_HEADER_STATUS_DISCARDED = `
mutation setReceiptHeaderStatusDiscarded($input: ChangeStatusDiscardedReceiptHeaderInput!) {
  setReceiptHeaderStatusDiscarded(input:$input) {
    __typename
    ...on ApiResponse{
      status
      message
      translation_key
    }

    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const UPDATE_RECEIPT_HEADER = `
mutation updateReceiptHeader($input: UpdateReceiptHeaderInput!) {
  updateReceiptHeader(input: $input) {
    __typename
    ... on ReceiptHeader {
      id
      tenant_id
      tenant {
        id
      }
      status_id
      status {
        id
        color
        name
        translation_key
      }
      receipt_type_id
      receipt_type {
        id
        name
        translation_key
      }
      receipt_letter_id
      receipt_letter {
        id
        name
        translation_key
      }
      payment_method
      receipt_number
      receipt_date
      receipt_file_id
      receipt_file {
        id
        filename
        mimetype
        encoding
      }
      receipt_detail {
        id
        quantity
        unit_price
        subtotal
        subtotal_without_tax
        receipt_line_discount_total
        receipt_line_tax_total
        tax_rate
        discount_percentage
        receipt_detail_item_type_id
        receipt_detail_item_type{
          id
          name
          description
        }
      }
      receipt_total
      receipt_tax_total
      receipt_subtotal
      has_details
      sender {
        id
        receipt_header_id
        name
        tax_condition
      }
      due_date
      receipt_verification_code
      receipt_verification_due_date
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const CREATE_RECEIPT_CONCEPT_EXTRACTED = `
mutation createReceiptConceptExtracted($input: CreateReceiptConceptExtractedInput!) {
  createReceiptConceptExtracted(input: $input) {
    __typename
    ... on  ReceiptConceptExtracted{
      id
      concept_master_id
      receipt_header_id
      label
      amount
      concept_type_id
      receipt_tax_type_id
      its,
      uts,
      dts
    }
    ...on ResultError {
      status_code
      message
      message_translation_key
    }
    
  }
}
`;

export const UPDATE_RECEIPT_CONCEPT_EXTRACTED = `
mutation updateReceiptConceptExtracted($input: UpdateReceiptConceptExtractedInput!) {
  updateReceiptConceptExtracted(input: $input) {
    __typename
    ... on  ReceiptConceptExtracted{
      id
      concept_master_id
      receipt_header_id
      label
      amount
      concept_type_id
      receipt_tax_type_id
      its,
      uts,
      dts
    }
    ...on ResultError {
      status_code
      message
      message_translation_key
    }
    
  }
}
`;

export const DELETE_RECEIPT_CONCEPT_EXTRACTED = `
mutation deleteReceiptConceptExtracted($id: Int!) {
  deleteReceiptConceptExtracted(id: $id) {
    __typename
    ... on  ReceiptConceptExtracted{
      id
      concept_master_id
      receipt_header_id
      label
      amount
      concept_type_id
      receipt_tax_type_id
      its,
      uts,
      dts
    }
    ...on ResultError {
      status_code
      message
      message_translation_key
    }
    
  }
}
`;

export const FINISH_REVIEW = `
mutation finishReview($input: FinishReviewInput!) {
  finishReview(input: $input) {
    __typename
    ... on  FinishReviewResponse{
      hasValidationErrors
      logDescription
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
