import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { EnumsValues } from './enums/EnumsValues';
import GraphqlService from './services/graphql/GraphqlService';

const SendActivity = () => {
  const { customRequest, Query } = GraphqlService();
  const location = useLocation();

  const sendActivity = async () => {
    //Consultar ID-Flujo-GA para estadísticas
    try {
      const data = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingNames.GAClientID },
        },
      });
      if (data) {
        let src =
          'https://www.googletagmanager.com/gtag/js?id=' + data.setting_value;

        var ga_script = document.createElement('script');
        ga_script.setAttribute('src', src);
        document.head.appendChild(ga_script);

        let scriptBody: string =
          'window.dataLayer = window.dataLayer || [];' +
          ' function gtag(){dataLayer.push(arguments);}' +
          " gtag('js', new Date()); gtag('config', '" +
          data.setting_value +
          "');";

        var ga_data = document.createElement('script');
        ga_data.text = scriptBody;
        document.head.appendChild(ga_data);
      }
    } catch (error: any) {
      console.error(error.message);
      throw error;
    }
  };

  useEffect(() => {
    sendActivity();
  }, [location]);
};

export default SendActivity;
