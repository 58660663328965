import { useContext, useState } from 'react';
import { Button, Card } from 'antd';
import { NavContext } from '@ionic/react';
import GraphqlService from '../../../services/graphql/GraphqlService';
import { IResponseMessage } from '../../../interfaces/ResponseMessage';
import { CustomMessage } from '../../../hooks';
import { ContextApp } from '../../../contexts/ContextApp';
import { PageLoading } from '@ant-design/pro-layout';
import QrCode from '../../../components/common/QrCode';
import './generate2FA.less';
import { notificationContext } from '../../../contexts/NotificationContext';

const Generate2FA = () => {
  // services and hooks
  const [totpUrl, setTotpUrl] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const { Mutation, customRequest } = GraphqlService();
  const { openNotification } = useContext(notificationContext);
  const { getErrorMessage } = CustomMessage();

  const { t } = useContext(ContextApp);
  const { navigate } = useContext(NavContext);
  const generateTotpUrl = async () => {
    setLoading(true);
    try {
      const data: IResponseMessage = await customRequest({
        mutation: Mutation.generateTotpUrl,
      });
      setTotpUrl(data.response);
    } catch (error: any) {
      openNotification({
        type: 'error',
        msj: getErrorMessage(error),
        context: 'Generate2FA.generateTotpUrl.1',
      });
    }
    setLoading(false);
  };
  const qrContainer = totpUrl && (
    <Card className="main-card-generate2FA" type="inner">
      <p>{t('2FA.generateInstructions')}</p>
      <div className="container-qr-image">
        <QrCode value={totpUrl} />
      </div>
      <div className="container-button">
        <Button
          type="primary"
          className="main-card-generate2FA__button"
          onClick={() => {
            navigate('/2fa/validateTotp');
          }}
        >
          {t('action.continue')}
        </Button>
      </div>
    </Card>
  );

  return (
    <>
      <div className="main-card-generate2FA__container">
        <Card
          title={t('2FA.configureTitle')}
          className="main-card-generate2FA"
          type="inner"
        >
          <p>{t('2FA.configure')}</p>
          <p>{t('2FA.configureLater')}</p>
          <div className="container-buttons">
            <Button
              type="default"
              onClick={() => {
                navigate('/app/config2fa');
              }}
            >
              {t('action.return')}
            </Button>

            <Button className="generate-qr-btn" onClick={generateTotpUrl}>
              {t('entity.enable')}
            </Button>
          </div>
        </Card>
        {loading ? <PageLoading /> : qrContainer}
      </div>
    </>
  );
};

export default Generate2FA;
