import { useContext } from 'react';
import { ContextApp } from '../../../../contexts/ContextApp';
import { useCurrentRoute } from '../../../../hooks';
import { MenuTrigger } from '../../MenuTrigger';

const CurrentRouteLabel = ({ className }: { className?: string }) => {
  const { leafRoute } = useCurrentRoute();
  const { mainMenuCollapsed } = useContext(ContextApp);
  if (leafRoute) {
    return (
      <div className={className}>
        {mainMenuCollapsed ? (<MenuTrigger />) : null}
        <span className="currentRouteLabelName">{leafRoute.name || ''}</span>
      </div>
    );
  }

  return null;
};

export default CurrentRouteLabel;
