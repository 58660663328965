import { IPeople } from '../../interfaces/People';

const peopleJavascript: IPeople[] = [
  {
    fullName: 'Ekerdt, Jerónimo',
    jobPosition: 'Javascript Development',
  },
  { fullName: 'Gomez, María Cecilia', jobPosition: 'Javascript Development' },
  {
    fullName: 'Landaburo, Santiago Martin',
    jobPosition: 'Javascript Development',
  },
  { fullName: 'Loureyro, Juan Pablo', jobPosition: 'Javascript Development' },
  { fullName: 'Magnin, Lucas', jobPosition: 'Javascript Development' },
  {
    fullName: 'Rodríguez, Pablo Nicolás',
    jobPosition: 'Javascript Development',
  },
  {
    fullName: 'Romero, Rodrigo Nicolás',
    jobPosition: 'Javascript Development',
  },
  {
    fullName: 'Aressi, Emiliano',
    jobPosition: 'Javascript Development',
  },
];

const phpDeveloper: IPeople[] = [
  { fullName: 'Cerante, Lucas', jobPosition: 'PHP Development' },
  { fullName: 'Espindola, Paula', jobPosition: 'Frontend Developer' },
];

const peopleRpa: IPeople[] = [
  {
    fullName: 'Arias, María Belén',
    jobPosition: 'RPA - Automation Expert Lab',
  },
  { fullName: 'Fernandez, Pablo', jobPosition: 'RPA - Automation Expert Lab' },
  { fullName: 'Sotto, Nazareno', jobPosition: 'RPA - Javascript Development' },
];

const peopleUXUI: IPeople[] = [
  { fullName: 'Sartori, Marcial', jobPosition: 'UX/UI Designer' },
];

const peopleDevOps: IPeople[] = [
  {
    fullName: 'Vechetti, Ariel',
    jobPosition: 'DevOps - Javascript Development',
  },
  { fullName: 'Karle, Carlos', jobPosition: 'DevOps - Technical Leader' },
];

const peopleManagment: IPeople[] = [
  {
    fullName: 'Ferrari, María Cecilia',
    jobPosition: 'Technical Solution Manager',
  },
  { fullName: 'Orsini, Aldo', jobPosition: 'Director of Engineering' },
];

const peopleCommunication: IPeople[] = [
  {
    fullName: 'Ardanza, Federico',
    jobPosition: 'Sales Automation Representative',
  },
  { fullName: 'Arias, Germán', jobPosition: 'Corporate Communication' },
];

export {
  peopleJavascript,
  peopleRpa,
  peopleUXUI,
  peopleDevOps,
  peopleManagment,
  peopleCommunication,
  phpDeveloper,
};
