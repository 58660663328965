import { useContext } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { ContextApp } from '../../contexts/ContextApp';

const InvitationPage = () => {
  const location = useLocation();
  const { user } = useContext(ContextApp);
  if (user) {
    return (
      <Redirect
        to={{
          ...location,
          pathname: '/app/receipt',
        }}
      />
    );
  } else {
    return (
      <Redirect
        to={{
          ...location,
          pathname: '/login',
        }}
      />
    );
  }
};

export default InvitationPage;
