export const UPDATE_MANY_FIELD_ATTRIBUTE_TENANT = `
mutation updateManyFieldAttributeTenant($input: [UpdateFieldAttributeTenantInput!]!, $receipt_type_id: Int!, $tenant_id: Int!) {
  updateManyFieldAttributeTenant(input: $input, receipt_type_id: $receipt_type_id, tenant_id: $tenant_id) {
    __typename
    ... on ResponseMessage {
      status
      response
    }
    
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;
