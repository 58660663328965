import { NavContext } from '@ionic/react';
import { List } from 'antd';
import { useContext } from 'react';
import './Notification.less';
interface INotificationProps {
  title: string;
  description: string;
  linkTo?: string;
  onClick?: () => void;
}
const Notification = (props: INotificationProps) => {
  const { title, description, linkTo, onClick } = props;
  const { navigate } = useContext(NavContext);
  return (
    <List.Item
      className={`notification ${linkTo ? 'pointer' : null}`}
      onClick={() => {
        onClick && onClick();
        linkTo && navigate(linkTo);
      }}
    >
      <div className="notification__container-content">
        <span className="notification__container-content__title">{title}</span>
        <span className="notification__container-content__description">
          {description}
        </span>
      </div>
    </List.Item>
  );
};
export default Notification;
