import QRCode from 'qrcode';

interface QrGeneratorProps {
  value: string;
  margin?: number | undefined;
}

export const generateQrCode = async (input: QrGeneratorProps) => {
  const { margin, value } = input;
  const qr = await QRCode.toDataURL(value, {
    margin,
  });
  return qr;
};
