export const CONCEPT_TYPES = ` 
query conceptTypes(
    $filter: FilterConceptTypeInput!
    $orderBy: ConceptTypeOrderInput
    $skip: Int
    $take: Int
  ) {
    conceptTypes(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take) {
      __typename
      ... on  ConceptType{
        id
        name
        description
        translation_key
        default,
        concept_type_status_id,
        its,
        uts
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }`;

export const LOGICAL_OPERATION_TYPES = ` 
  query logicalOperationTypes(
    $filter: FilterLogicalOperationTypeInput!
    $orderBy: LogicalOperationTypeOrderInput
    $skip: Int
    $take: Int
  ) {
    logicalOperationTypes(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take) {
      __typename
      ... on  LogicalOperationType{
        id
        name
        description
        translation_key
        default,
        logical_operation_type_status_id,
        its,
        uts
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }`;

export const CONCEPT_RULE_TYPES = `
  query conceptRuleTypes(
    $filter: FilterConceptRuleTypeInput!
    $orderBy: ConceptRuleTypeOrderInput
    $skip: Int
    $take: Int
  ) {
    conceptRuleTypes(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take) {
      __typename
      ... on  ConceptRuleType{
        id
        name
        description
        translation_key
        level_priority_id
        concept_rule_type_status_id,
        its,
        uts
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
  `;

export const CONCEPT_CONDITIONALS = `
  query conceptConditionals(
    $filter: FilterConceptConditionalInput!
    $orderBy: ConceptConditionalOrderInput
    $skip: Int
    $take: Int
  ) {
    conceptConditionals(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take) {
      __typename
      ... on  ConceptConditional{
        id
        name
        description
        translation_key
        default
        concept_conditional_status_id,
        its,
        uts
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
  `;

export const CONCEPT_CONDITIONAL_TYPES = `
  query conceptConditionalTypes(
    $filter: FilterConceptConditionalTypeInput!
    $orderBy: ConceptConditionalTypeOrderInput
    $skip: Int
    $take: Int
  ) {
    conceptConditionalTypes(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take) {
      __typename
      ... on  ConceptConditionalType{
        id
        concept_conditional_id
        concept_type_id,
        concept_conditional{
          id
          name
          description
          translation_key
          default
          concept_conditional_status_id,
          its,
          uts
      }
        concept_type {
          id
          name
          description
          translation_key
          default,
          concept_type_status_id,
          its,
          uts
      }
        its,
        uts
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
  `;

export const CONCEPT_MASTERS = `
  query conceptMasters(
    $searchText: String, 
    $filter: FilterConceptMasterInput!
    $orderBy: ConceptMasterOrderInput
    $skip: Int
    $take: Int
  ) {
    conceptMasters(searchText: $searchText, filter: $filter, orderBy: $orderBy, skip: $skip, take: $take) {
      __typename
      ... on  ConceptMaster{
        id
        name
        code
        tenant_id
        logical_operation_type_id
        logical_operation_type {
          id
          name
        }
        concept_type_id
        concept_type {
          id
          name
        }
        concept_rule {
          id
          concept_master_id
          concept_rule_status_id
          concept_rule_type_id
          concept_rule_type {
            id
            name
          }
          concept_rule_conditional_type {
            id
            concept_rule_id
            concept_conditional_type_id
            value
            concept_conditional_type {
              id
              concept_conditional_id
              concept_type_id
              concept_conditional {
                id
                name
              }
              concept_type{
                id
                name
              }
            }
          }
        }
        its,
        uts,
        dts
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }`;

export const CONCEPT_MASTER_COUNT = `
  query conceptMasterCount($searchText: String, $filter: FilterConceptMasterInput, $orderBy: ConceptMasterOrderInput) {
    conceptMasterCount(searchText: $searchText, filter: $filter, orderBy: $orderBy) {
    	__typename
      ... on Count {
        count
      }
      ... on ResultError {
        status_code
        message
        message_translation_key
      }
    }
}`;
