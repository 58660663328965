export const PACKS = `
query packs(
  $filter: FilterPackInput!
  $orderBy: PackOrderInput
  $skip: Int
  $take: Int
) {
  packs(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take) {
    __typename
    ... on Pack {
      id
      name
      description
      receipt_number
      validity_days
      offer_status
      price
      discount
      target_id
      target {
        id
        name
        description
        free_receipts_quota
        free_validity_days
      }
      status
      start_date
      expiration_date
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
