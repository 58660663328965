export const TENANT_STATES = `
query tenantStates(
  $filter: FilterTenantStatusInput!
  $skip: Int
  $take: Int
) {
  tenantStates(filter: $filter, skip: $skip, take: $take) {
    __typename
    ... on TenantStatus {
      id
      name
      its,
      uts,
      dts
      translation_key
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
