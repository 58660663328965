import { Avatar, Dropdown, Menu, Modal } from 'antd';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserService } from '../../../../services/UserService';
import { LanguageButton } from './LanguageButton';
import './index.less';
import { NavContext } from '@ionic/react';
import { ContextApp } from '../../../../contexts/ContextApp';
import GraphqlService from '../../../../services/graphql/GraphqlService';
import { CustomMessage } from '../../../../hooks/CustomMessage';
import { notificationContext } from '../../../../contexts/NotificationContext';
import { MenuItemType } from 'antd/lib/menu/hooks/useItems';

export const AvatarComponent = () => {
  const { user, languages, userDataFirebase, t } = useContext(ContextApp);
  const { navigate } = useContext(NavContext);
  const { logout } = UserService();
  const [languageModalVisible, setLanguageModalVisible] = useState(false);
  const [, i18n] = useTranslation();
  const { Mutation, customRequest } = GraphqlService();
  const { openNotification } = useContext(notificationContext);
  const { getErrorMessage } = CustomMessage();

  const setLanguage = async (language_id: number) => {
    try {
      if (!user?.profile) return;
      await customRequest({
        mutation: Mutation.updateUser,
        variables: {
          input: {
            id: user?.id,
          },
          inputProfile: { language_id },
        },
      });
    } catch (err) {
      openNotification({
        msj: getErrorMessage(err),
        type: 'error',
      });
    }
  };

  const items: MenuItemType[] = [
    {
      key: '0',
      icon: <span className="material-symbols-outlined profile-icon-item" translate='no'>person</span>,
      label: t('page.myProfile'),
      onClick: () => navigate('/app/profile'),
    },
    {
      key: '1',
      icon: <span className="material-symbols-outlined profile-icon-item" translate='no'>lock</span>,
      label: t('page.config2fa'),
      onClick: () => navigate('/app/config2fa'),
    },
    {
      key: '2',
      icon: <span className="material-symbols-outlined profile-icon-item" translate='no'>translate</span>,
      label: t('action.language'),
      onClick: () => setLanguageModalVisible(() => true),
      className: 'language-button' //oculta el boton
    },
    {
      key: '3',
      icon: <span className="material-symbols-outlined profile-icon-item" translate='no'>logout</span>,
      label: t('action.signOut'),
      onClick: () => logout(),
    },
  ];

  return user?.firstname || user?.email ? (
    <>
      <div className="AvatarComponent">
        <Dropdown overlay={<Menu items={items} />} trigger={['click']}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()} translate='no'>
            <Avatar
              className="Avatar"
              src={
                userDataFirebase?.photoURL
                  ? userDataFirebase?.photoURL
                  : undefined
              }
            >
              {user?.firstname
                ? user.firstname[0].toUpperCase()
                : user?.email[0].toUpperCase()}
            </Avatar>
            <span className="AvatarName" translate='no'>{user?.firstname || user?.email}</span>
          </a>
        </Dropdown>
      </div>
      <Modal
        open={languageModalVisible}
        onCancel={() => setLanguageModalVisible(() => false)}
        footer={null}
        title="Lenguaje"
      >
        <div className="container-flag-buttons">
          {languages?.map((language) => (
            <LanguageButton
              onClick={() => {
                i18n.changeLanguage(language.language_code);
                setLanguage(language.id);
                setLanguageModalVisible(false);
              }}
              language={language}
              i18nCurrentLanguage={i18n.language}
              key={language.id}
            />
          ))}
        </div>
      </Modal>
    </>
  ) : null;
};
