import { SchemaActionTypes, FieldAttributeTenantValue } from '../enum';

import { SchemaField } from '../../../interfaces/ValidationSchema';
import { SchemaAction, SchemaState } from '../interface';

function schemaFieldsRecursion(
  fields: SchemaField[],
  condition: (field: SchemaField) => boolean,
  action: (field: SchemaField) => SchemaField,
): SchemaField[] {
  return fields.map((field) => {
    if (field.schema_field && field.schema_field.length > 0) {
      return {
        ...field,
        schema_field: schemaFieldsRecursion(
          field.schema_field,
          condition,
          action,
        ),
      };
    } else if (condition(field)) {
      return action(field);
    }
    return field;
  });
}

const actionHandlers: {
  [K in SchemaActionTypes]: (
    state: SchemaState,
    action: Extract<SchemaAction, { type: K }>,
  ) => SchemaState;
} = {
  [SchemaActionTypes.SET_SCHEMA]: (_state, action) => {
    return action.payload;
  },

  [SchemaActionTypes.TOGGLE_SWITCH]: (state, action) => {
    const updateField = (field: SchemaField): SchemaField => {
      const updatedAttributes =
        field.field.field_attribute_tenant?.map((attribute) => {
          if (
            attribute.attribute_type.code === action.payload.attribute_type.code
          ) {
            if (action.payload.tenant_id) {
              return {
                ...attribute,
                value: action.switchStatus
                  ? FieldAttributeTenantValue.TRUE
                  : FieldAttributeTenantValue.FALSE,
              };
            }
          }
          return attribute;
        }) ?? [];

      if (!action.payload.tenant_id) {
        updatedAttributes.push({
          ...action.payload,
          value: action.switchStatus
            ? FieldAttributeTenantValue.TRUE
            : FieldAttributeTenantValue.FALSE,
          tenant_id: action.tenantId,
        });
      }

      return {
        ...field,
        field: {
          ...field.field,
          field_attribute_tenant: updatedAttributes,
        },
      };
    };

    return {
      ...state,
      current: {
        ...state.current,
        schema_field: schemaFieldsRecursion(
          state.current.schema_field ?? [],
          (field) => field.field?.id === action.payload.field_id,
          updateField,
        ),
      },
    };
  },

  [SchemaActionTypes.RESET_FIELD_TO_DEFAULT]: (state, action) => {
    const resetField = (field: SchemaField): SchemaField => ({
      ...field,
      field: {
        ...field.field,
        field_attribute_tenant: field.field.field_attribute_tenant?.filter(
          (attribute) => !attribute.tenant_id,
        ),
      },
    });

    return {
      ...state,
      current: {
        ...state.current,
        schema_field: schemaFieldsRecursion(
          state.current.schema_field ?? [],
          (field) => field.field?.id === action.payload.field?.id,
          resetField,
        ),
      },
    };
  },

  [SchemaActionTypes.RESET_FIELD_TO_PREVIOUS_STATE]: (state, action) => {
    const findPreviousStateField = (
      schemaFields: SchemaField[],
    ): SchemaField | undefined => {
      for (const field of schemaFields) {
        if (field.field?.id === action.payload.field?.id) {
          return field;
        }
        if (field.schema_field && field.schema_field.length > 0) {
          const found = findPreviousStateField(field.schema_field);
          if (found) {
            return found;
          }
        }
      }
      return undefined;
    };

    const restoreField = (field: SchemaField): SchemaField => {
      const previousField = findPreviousStateField(
        state.original.schema_field ?? [],
      );
      return {
        ...field,
        field: {
          ...field.field,
          field_attribute_tenant:
            previousField?.field?.field_attribute_tenant ??
            field.field.field_attribute_tenant,
        },
      };
    };

    return {
      ...state,
      current: {
        ...state.current,
        schema_field: schemaFieldsRecursion(
          state.current.schema_field ?? [],
          (field) => field.field?.id === action.payload.field?.id,
          restoreField,
        ),
      },
    };
  },

  [SchemaActionTypes.CONFIGURE_DATA_VALIDATION]: (state) => {
    // TODO: Implementar lógica para configurar la validación de datos
    return state;
  },

  [SchemaActionTypes.RESET_ALL_FIELDS_TO_DEFAULT]: (state) => {
    const resetAllFields = (field: SchemaField): SchemaField => ({
      ...field,
      field: {
        ...field.field,
        field_attribute_tenant: field.field.field_attribute_tenant?.filter(
          (attribute) => !attribute.tenant_id,
        ),
      },
    });

    return {
      ...state,
      current: {
        ...state.current,
        schema_field: schemaFieldsRecursion(
          state.current.schema_field ?? [],
          () => true,
          resetAllFields,
        ),
      },
    };
  },

  [SchemaActionTypes.RESET_ALL_FIELDS_TO_PREVIOUS_STATE]: (state) => ({
    ...state,
    current: state.original,
  }),
};

export const validationSettingsReducer = (
  state: SchemaState,
  action: SchemaAction,
): SchemaState => {
  const handler = actionHandlers[action.type];
  if (handler) {
    try {
      return handler(state, action as any);
    } catch (error) {
      console.log(`Error handling action ${action.type}:`, error);
      return state;
    }
  }
  return state;
};
