import { PageLoading } from '@ant-design/pro-layout';
import { ReactNode, useContext, useEffect, useState } from 'react';

import './FeatureFlagWrapper.less';
import useFeatureFlagConfig from './hooks/useFeatureFlagConfig';
import { ContextApp } from '../../contexts/ContextApp';
import { IAppSetting } from '../../interfaces';
import { EnumsValues } from '../../enums/EnumsValues';
import GraphqlService from '../../services/graphql/GraphqlService';
import { useHistory } from 'react-router';
import { GenericCard } from '../../components/common/GenericCard/GenericCard';

interface FeatureFlagWrapperProps {
  featureFlag: string;
  loadingComponent?: JSX.Element | null;
  children: ReactNode;
  publicAccess?: boolean;
}

const ROUTES_TO_IGNORE = ['/app/home', '/login'];

export const FeatureFlagWrapper = (props: FeatureFlagWrapperProps) => {
  const { featureFlag, children, loadingComponent, publicAccess } = props;
  const { t } = useContext(ContextApp);
  const { customRequest, Query } = GraphqlService();
  const [contactLink, setContactLink] = useState('');
  const history = useHistory();

  const { isAvailable, isHidden, loading } = useFeatureFlagConfig({
    featureFlag: featureFlag,
    publicAccess,
  });

  const getContactLink = async () => {
    try {
      const data: IAppSetting = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingNames.ContactLinkOnPackPage },
        },
      });
      setContactLink(data.setting_value);
    } catch (error) {
      //intentional
    }
  };

  useEffect(() => {
    getContactLink();
  }, []);

  if (loading) {
    if (loadingComponent === undefined) return <PageLoading></PageLoading>;

    return loadingComponent;
  }

  if (!isAvailable && !ROUTES_TO_IGNORE.includes(history.location.pathname))
    return (
      <>
        <GenericCard
          icon={
            <span className="material-symbols-outlined icon-warning-disabled">
              error
            </span>
          }
          description={
            <p className="message-info-disabled">
              {t('featureFlag.wrapper.functionDisabled')}&nbsp;{' '}
              <b>
                <u>
                  <a href={contactLink}>{t('featureFlag.wrapper.contactUs')}</a>
                </u>
              </b>
            </p>
          }
          className="message-warning-disabled-container"
        />
        <div className="wrapper-disabled">{children}</div>
      </>
    );

  if (!isHidden)
    return (
      <>
        <div className="featureFlag-children">{children}</div>
      </>
    );

  return <></>;
};
