import { useContext, useEffect, useState } from 'react';
import { ContextApp } from '../../contexts/ContextApp';
import { version } from '../../../package.json';
import moment from 'moment';
import './style.less';
import { IAppSetting } from '../../interfaces';
import { EnumsValues } from '../../enums/EnumsValues';
import GraphqlService from '../../services/graphql/GraphqlService';
import { Typography } from 'antd';
import {
  peopleCommunication,
  peopleDevOps,
  peopleJavascript,
  peopleManagment,
  peopleRpa,
  peopleUXUI,
  phpDeveloper,
} from './dataPeople';

const people = [
  peopleJavascript,
  phpDeveloper,
  peopleRpa,
  peopleUXUI,
  peopleDevOps,
  peopleManagment,
  peopleCommunication,
];
const AboutPage = () => {
  const { t } = useContext(ContextApp);
  const { Query, customRequest } = GraphqlService();
  const [supportEmail, setSupportEmail] = useState<string>();
  const { Text } = Typography;

  const getSupportEmail = async () => {
    try {
      const data: IAppSetting = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingNames.supportEmail },
        },
      });
      if (data.setting_value) {
        setSupportEmail(data.setting_value);
      }
    } catch (error) {
      //intentional
    }
  };

  useEffect(() => {
    getSupportEmail();
  }, []);

  return (
    <div className="AboutPage">
      <div className="AboutPage__contact-container">
        <span className="AboutPage__contact-container__title">
          <p>{t('message.titleContactUs')}</p>
        </span>
        <span>
          {supportEmail ? (
            <p>{`${t('entity.email')}: ${supportEmail}`}</p>
          ) : undefined}
          <p>
            {t('entity.version')}: {version}
          </p>
        </span>
      </div>
      <div className="AboutPage__people-container">
        <div
          className="AboutPage__people-container__container-title"
          style={{
            zIndex: 100,
            position: 'relative',
          }}
        >
          <span className="AboutPage__people-container__container-title__title">
            <p>{t('message.ourTeam')}</p>
          </span>
        </div>
        <div className="AboutPage__people-container__container-image">
          <img alt="Braulio" src="/assets/icon/logo_braulio_transparent.png" />
        </div>
        <div className="AboutPage__people-container__people-info">
          {people.map((arrayPeople, outerIndex) => (
            <div key={outerIndex}>
              {arrayPeople.map((people, innerIndex) => (
                <div
                  key={`${innerIndex}-${people.fullName}`}
                  className="AboutPage__people-container__people-info__people-info-container"
                >
                  <div className="AboutPage__people-container__people-info__people-info-container__people-name">
                    <Text>{people.fullName}</Text>
                  </div>
                  <div className="AboutPage__people-container__people-info__people-info-container__people-job-position">
                    <Text
                      ellipsis={{
                        tooltip: people.jobPosition,
                      }}
                      style={{ width: '100%' }}
                      className="AboutPage__people-container__people-info__people-info-container__people-job-position__job-position"
                    >
                      {people.jobPosition}
                    </Text>
                  </div>
                </div>
              ))}
              <br />
            </div>
          ))}
        </div>
      </div>
      <div>
        <p>{moment().format('YYYY')} Bombieri &#9415;</p>
        <img alt="Bombieri" src="/assets/bombieri-logo.png" />
      </div>
    </div>
  );
};

export default AboutPage;
