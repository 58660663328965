import { Form, Select } from 'antd';
import { ISettingToUpdate, ISettingType } from '../../interfaces/TenantSetting';

type Props = {
  settingsArray: ISettingToUpdate[];
  handleChange: (id: any, value: any) => void;
  stringSeparators?: string[]
};

const defaultStringSeparators = [',', ' ', ';', '|'];

export const useSettingStringArrayInput = (props: Props) => {
  const { settingsArray, handleChange, stringSeparators } = props;

  const onInputChange = (id: number | undefined, e: string[]) => {
    const valueToSave = JSON.stringify(e);

    handleChange(id, valueToSave);
  };

  const stringArrayTypeSetting: ISettingType = {
    render: (props) => {
      const valueSelected = settingsArray.find(
        (item) => item.id === props.id,
      )?.value;

      let valueAsArray: string[] = [];

      if (valueSelected) {
        try {
          valueAsArray = JSON.parse(valueSelected);
        } catch (error) {
          /* Intentional */
        }
      }

      return (
        <Form.Item
          className="margin-component"
        >
          <Select<string[]>
            value={valueAsArray}
            mode="tags"
            tokenSeparators={stringSeparators || defaultStringSeparators}
            onChange={(e) => onInputChange(props.id, e)}
            options={[]}
          />
        </Form.Item>
      );
    },
  };

  return { stringArrayTypeSetting };
};
