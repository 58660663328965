import { useContext, useEffect, useRef, useState } from 'react';
import { ContextApp } from '../contexts/ContextApp';
import useNotification from './useNotification';

const useCurrentTenantPoll = ({
  intervalMs = 3000,
}: {
  intervalMs?: number;
}) => {
  const { getTenantsAssociatedWithUser } = useContext(ContextApp);
  const { refreshNotification } = useNotification({});
  const [polling, setPolling] = useState(false);
  const [waitingResponse, setWaitingResponse] = useState(false);
  const intervalRef = useRef<number | undefined>();

  const pollFunction = async () => {
    setWaitingResponse(true);
    try {
      await Promise.all([
        getTenantsAssociatedWithUser(),
        refreshNotification(),
      ]);
    } catch (error) {
      console.error(error);
    } finally {
      setWaitingResponse(false);
    }
  };

  const startPolling = () => {
    setPolling(true);
    try {
      const clearIntervalId = window.setInterval(pollFunction, intervalMs);
      intervalRef.current = clearIntervalId;
    } catch (error) {
      setPolling(false);
    }
  };

  const stopPolling = () => {
    if (intervalRef.current) window.clearInterval(intervalRef.current);
    setPolling(false);
  };

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        stopPolling();
      }
    };
  }, []);

  return {
    startPolling,
    stopPolling,
    polling,
    waitingResponse,
  };
};

export default useCurrentTenantPoll;
