import ProTable, { ActionType } from '@ant-design/pro-table';
import {
  Button,
  Input,
  Modal,
  Tooltip,
  FormInstance,
  Row,
  Col,
  Select,
  Collapse,
  Form,
  InputNumber,
} from 'antd';
import {
  Dispatch,
  FocusEvent,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { SaveForm } from '../../components/common/ABM';
import { ContextApp } from '../../contexts/ContextApp';
import {
  IReceiptDetail,
  ReceiptHC,
  IReceiptDetailItemType,
  IReceiptTaxType,
} from '../../interfaces/Receipt';
import { ExportableColumn } from '../../shared/Exporter';
import './ReviewReceiptDetail.less';
import GraphqlService from '../../services/graphql/GraphqlService';
import { CustomMessage } from '../../hooks';
import ReviewReceiptCanvas from './ReviewReceiptCanvas';
import { ILog } from '../../interfaces/Log';
import { IWord } from '../../interfaces/Extraction';
import validations from './validations/validations';
import { Query } from '../../services/graphql/query';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { notificationContext } from '../../contexts/NotificationContext';
import { DetailItemType, EnumsValues } from '../../enums/EnumsValues';
import { formatValueOnLoad } from '../../shared/formatValueOnLoad';
import { formatValueOnSave } from '../../shared/formatValueOnSave';

interface IReviewDetailProps {
  receiptDetail?: IReceiptDetail[];
  setReceiptDetail?: Dispatch<SetStateAction<IReceiptDetail[] | undefined>>;
  receiptHeaderId?: number;
  file: string;
  marks: any;
  lastLog: ILog | undefined;
  receiptDetailConfig: ReceiptHC | undefined;
  subtotalItemDetail: number;
  typeOfItemSelected: string;
  setSubtotalItemDetail: Dispatch<SetStateAction<number>>;
  onItemTypeChange?: (selectedValue: string, formIntance: FormInstance) => void;
  onClickEditItem?: (selectedValue: number | undefined) => void;
  isFormChanged: React.MutableRefObject<boolean>;
}

interface IDetailToReview {
  field: string;
  value: string;
  json_value: IWord[] | null;
  page_number: number;
}

interface IArrayJson {
  json_element: IWord[];
  focused_element_id: string;
  pageNumber: number | null;
  shift: boolean;
}

export default function ReviewReceiptDetail(props: IReviewDetailProps) {
  const {
    receiptDetail,
    receiptHeaderId,
    file,
    marks,
    lastLog,
    receiptDetailConfig,
    subtotalItemDetail,
    setSubtotalItemDetail,
    onItemTypeChange,
    onClickEditItem,
    setReceiptDetail,
    isFormChanged,
  } = props;
  const { openNotification } = useContext(notificationContext);
  const actionRef = useRef<ActionType>();
  const { Mutation, customRequest } = GraphqlService();
  const [addFormReceiptDetail, setAddFormReceiptDetail] = useState(false);
  const [updateDetailModalVisible, setUpdateDetailModalVisible] =
    useState<boolean>(false);
  const [detailItemTypes, setDetailItemTypes] = useState<
    IReceiptDetailItemType[]
  >([]);
  const [editFormValues, setEditFormValues] = useState<any>({});
  const [firstLoad, setFirstLoad] = useState<boolean>(false);
  const [isDiscount, setIsDiscount] = useState<boolean>(false);
  const { getErrorMessage } = CustomMessage();
  const { t, isNumeric, selectedTenantId } = useContext(ContextApp);
  const focusedInputDetailRef = useRef<string>();
  const lastFocusedInputDetailRef = useRef<string>();
  const concatenatedContentRef = useRef<string>('');
  const createModalFormRef = useRef<FormInstance>();
  const updateModalFormRef = useRef<FormInstance>();
  const receiptDetailIdRef = useRef<number>();
  const detailToReviewArrayRef = useRef<IDetailToReview[]>([]);
  const detailJsonElementArrayRef = useRef<IArrayJson>({
    json_element: [],
    focused_element_id: '',
    pageNumber: null,
    shift: false,
  });
  const [receiptTaxTypes, setReceiptTaxTypes] = useState<IReceiptTaxType[]>([]);
  const [expandedLogs, setExpandedLogs] = useState<boolean>(false);
  const receiptDetailConfigRef = useRef<ReceiptHC>();
  const isDiscountRef = useRef<boolean>();
  const getReceiptTaxTypes = async () => {
    try {
      const data: IReceiptTaxType[] = await customRequest({
        query: Query.receiptTaxTypes,
        variables: {
          filter: {
            tenant_id: selectedTenantId,
          },
        },
      });

      if (data) {
        setReceiptTaxTypes(data);
      }
    } catch (error) {
      openNotification({
        msj: getErrorMessage(error),
        type: 'error',
      });
    }
  };

  useEffect(() => {
    getReceiptTaxTypes();
  }, []);

  const deleteReceiptDetail = async (receiptDetailId: number) => {
    try {
      if (setReceiptDetail && receiptDetail) {
        setReceiptDetail((oldReceiptDetail) =>
          oldReceiptDetail
            ? oldReceiptDetail.filter((item) => item.id !== receiptDetailId)
            : oldReceiptDetail,
        );
      }
      isFormChanged.current = true;

      openNotification({
        msj: t('ReviewReceiptDetail.itemRemoved'),
        type: 'success',
      });
    } catch (error: any) {
      openNotification({
        msj: getErrorMessage(error),
        type: 'error',
      });
    }
  };

  const removeRecordReceiptDetail = (receiptDetailId: number) => {
    Modal.confirm({
      content: <div>{t('ReviewReceiptDetail.confirmRemoveItem')}</div>,
      icon: (
        <span className="material-symbols-outlined receipt-item-exclamation-icon">
          error
        </span>
      ),
      onOk: () => {
        deleteReceiptDetail(receiptDetailId);
      },
      okButtonProps: { className: 'save-btn' },
    });
  };

  const deleteManyReviewsReceiptDetail = async () => {
    await customRequest({
      mutation: Mutation.deleteManyReviewReceiptDetailField,
      variables: {
        input: {
          receipt_detail_ids: [receiptDetailIdRef.current],
        },
      },
    });
  };

  const fetchReceiptDetailItemTypes = async () => {
    try {
      const data: IReceiptDetailItemType[] = await customRequest({
        query: Query.receiptDetailItemTypes,
        variables: {
          filter: {
            tenant_id: selectedTenantId,
          },
        },
      });

      setDetailItemTypes(data);
    } catch (error) {
      openNotification({
        msj: getErrorMessage(error),
        type: 'error',
      });
    }
  };

  useEffect(() => {
    if (!firstLoad) {
      setFirstLoad(true);
    } else {
      fetchReceiptDetailItemTypes();
    }
  }, [firstLoad]);

  function getValueFromFormObject(
    formObject: any,
    fieldConfig: {
      name: string;
      classification_id: number;
      type: string;
      hidden?: boolean;
      readonly?: boolean;
    },
  ) {
    const formField = formObject[fieldConfig.name];

    if (!formField && fieldConfig.classification_id === 1) {
      return null;
    } else if (fieldConfig.classification_id === 2 && !formField) {
      return null;
    }

    switch (fieldConfig.type) {
      case 'string':
        return String(formField);
      case 'number':
        if (
          fieldConfig.name === 'subtotal' ||
          fieldConfig.name === 'unit_price' ||
          fieldConfig.name === 'receipt_line_discount_total' ||
          fieldConfig.name === 'receipt_line_tax_total' ||
          fieldConfig.name === 'subtotal_without_tax' ||
          fieldConfig.name === 'quantity'
        ) {
          return parseFloat(formatValueOnSave(String(formField)));
        }
        if (
          fieldConfig.name === 'tax_rate' ||
          fieldConfig.name === 'discount_percentage'
        ) {
          return parseFloat(String(formField?.replace(',', '.')));
        }
        return Number(formField);
      default:
        return formField;
    }
  }
  useEffect(() => {
    if (editFormValues.id) {
      receiptDetailIdRef.current = editFormValues.id;
      setIsDiscount(editFormValues.receipt_detail_item_type_id===DetailItemType.discount)
    }
  }, [editFormValues]);

  const cleanupFormData = () => {
    detailToReviewArrayRef.current = [];
    detailJsonElementArrayRef.current.json_element = [];
    concatenatedContentRef.current = '';
  };

  const updateReceiptProduct = async (
    values: any,
    receipt_detail_id: null | number = null,
    shouldCloseModal: boolean = true,
  ) => {
    try {
      if (
        detailJsonElementArrayRef.current.json_element.length > 0 &&
        detailJsonElementArrayRef.current.shift
      ) {
        await customRequest({
          mutation: Mutation.upsertReviewReceiptDetailField,
          variables: {
            input: {
              receipt_detail_id: Number(receiptDetailIdRef.current),
              field: detailJsonElementArrayRef.current.focused_element_id,
              value: concatenatedContentRef.current,
              json_value: detailJsonElementArrayRef.current.json_element,
              page_number: detailJsonElementArrayRef.current.pageNumber,
            },
          },
        });
      }

      const dataToSendToServerRD: Record<string, any> = {};
      if (receiptDetailConfig?.fields) {
        for (const fieldConfig of receiptDetailConfig.fields) {
          if (fieldConfig.hidden) {
            continue;
          }

          const value = getValueFromFormObject(values, fieldConfig);

          dataToSendToServerRD[fieldConfig.name] = value;
        }
        dataToSendToServerRD['id'] = receipt_detail_id ?? editFormValues.id;
        dataToSendToServerRD['receipt_header_id'] = Number(receiptHeaderId);
      }

      const receiptTaxType = receiptTaxTypes.find(
        (taxType) => taxType.id === Number(values['receipt_tax_type_id']),
      );
      if (receiptTaxType) {
        dataToSendToServerRD['receipt_tax_type'] = receiptTaxType;
        dataToSendToServerRD['tax_rate'] = receiptTaxType?.tax_rate;
      }

      if (values.receipt_detail_item_type_id) {
        const detailItemType = detailItemTypes.find(
          (itemType) =>
            itemType.id === Number(values.receipt_detail_item_type_id),
        );
        dataToSendToServerRD['receipt_detail_item_type'] = detailItemType;
      }
      if (setReceiptDetail && receiptDetail) {
        setReceiptDetail((oldReceiptDetail) =>
          oldReceiptDetail
            ? oldReceiptDetail.map((item) =>
                item.id === dataToSendToServerRD['id']
                  ? (dataToSendToServerRD as IReceiptDetail)
                  : item,
              )
            : oldReceiptDetail,
        );
      }

      if (!receipt_detail_id && shouldCloseModal) {
        openNotification({
          msj: t('ReviewReceiptDetail.editReviewReceiptDetailForm.itemUpdated'),
          type: 'success',
        });
      }

      cleanupFormData();
      if (shouldCloseModal) {
        setUpdateDetailModalVisible(false);
      }
    } catch (error: any) {
      openNotification({
        msj: getErrorMessage(error),
        type: 'error',
      });
    }
  };

  const createReceiptDetail = async (values: any) => {
    try {
      const dataToSendToServerRD: Record<string, any> = {};
      if (receiptDetailConfig?.fields) {
        for (const fieldConfig of receiptDetailConfig.fields) {
          if (fieldConfig.hidden) {
            continue;
          }

          const value = getValueFromFormObject(values, fieldConfig);

          dataToSendToServerRD[fieldConfig.name] = value;
        }
        dataToSendToServerRD['receipt_header_id'] = Number(receiptHeaderId);
      }

      if (receiptDetail) {
        dataToSendToServerRD['id'] =
          receiptDetail.filter((ele) => ele.id < 0).length * -1 - 1;
      }

      const receiptTaxType = receiptTaxTypes.find(
        (taxType) => taxType.id === Number(values['receipt_tax_type_id']),
      );
      if (receiptTaxType) {
        dataToSendToServerRD['receipt_tax_type'] = receiptTaxType;
        dataToSendToServerRD['tax_rate'] = receiptTaxType?.tax_rate;
      }
      if (values.receipt_detail_item_type_id) {
        const detailItemType = detailItemTypes.find(
          (itemType) =>
            itemType.id === Number(values.receipt_detail_item_type_id),
        );
        dataToSendToServerRD['receipt_detail_item_type'] = detailItemType;
      }

      if (setReceiptDetail && receiptDetail) {
        setReceiptDetail((oldReceiptDetail) =>
          oldReceiptDetail
            ? [...oldReceiptDetail, dataToSendToServerRD as IReceiptDetail]
            : oldReceiptDetail,
        );
      }

      if (
        detailJsonElementArrayRef.current.json_element.length >
          EnumsValues.ConstNumbers.zero &&
        detailJsonElementArrayRef.current.shift &&
        detailJsonElementArrayRef.current.pageNumber
      ) {
        const reviewToInsert: IDetailToReview = {
          field: detailJsonElementArrayRef.current.focused_element_id,
          value: concatenatedContentRef.current,
          json_value: detailJsonElementArrayRef.current.json_element,
          page_number: detailJsonElementArrayRef.current.pageNumber,
        };
        const existingReviewIndex = detailToReviewArrayRef.current.findIndex(
          (item) => item.field === reviewToInsert.field,
        );
        if (existingReviewIndex !== -EnumsValues.ConstNumbers.one) {
          detailToReviewArrayRef.current[existingReviewIndex] = reviewToInsert;
        } else {
          detailToReviewArrayRef.current.push(reviewToInsert);
        }
      }

      detailToReviewArrayRef.current.forEach(async (element) => {
        await customRequest({
          mutation: Mutation.upsertReviewReceiptDetailField,
          variables: {
            input: {
              receipt_detail_id: Number(dataToSendToServerRD['id']),
              field: element.field,
              value: element.value,
              json_value: element.json_value,
              page_number: element.page_number,
            },
          },
        });
      });

      cleanupFormData();

      setAddFormReceiptDetail(false);
      openNotification({
        msj: t('ReviewReceiptDetail.addReviewReceiptDetailForm.itemCreated'),
        type: 'success',
      });
      setAddFormReceiptDetail(false);
    } catch (error: any) {
      openNotification({
        msj: getErrorMessage(error),
        type: 'error',
      });
    }
  };

  const onCanvasItemDetailClick = useCallback(
    async (
      content: string,
      shiftKey: boolean,
      form: FormInstance,
      element: IWord | null,
      pageNumber,
    ) => {
      if (
        focusedInputDetailRef.current &&
        element &&
        receiptDetailConfigRef.current
      ) {
        if (
          lastFocusedInputDetailRef.current &&
          lastFocusedInputDetailRef.current !== focusedInputDetailRef.current
        ) {
          const activeElements = document.querySelectorAll(
            '.pdf-canvas-item-active',
          );
          activeElements.forEach((element) => {
            element.classList.remove('pdf-canvas-item-active');
          });
          shiftKey = false;
          detailJsonElementArrayRef.current.shift = false;

          if (detailJsonElementArrayRef.current.json_element.length > 1) {
            if (receiptDetailIdRef.current) {
              await customRequest({
                mutation: Mutation.upsertReviewReceiptDetailField,
                variables: {
                  input: {
                    receipt_detail_id: Number(receiptDetailIdRef.current),
                    field: detailJsonElementArrayRef.current.focused_element_id,
                    value: concatenatedContentRef.current,
                    json_value: detailJsonElementArrayRef.current.json_element,
                    page_number: pageNumber,
                  },
                },
              });
            } else {
              const reviewToInsert: IDetailToReview = {
                field: detailJsonElementArrayRef.current.focused_element_id,
                value: concatenatedContentRef.current,
                json_value: detailJsonElementArrayRef.current.json_element,
                page_number: pageNumber,
              };
              const existingReviewIndex =
                detailToReviewArrayRef.current.findIndex(
                  (item) => item.field === reviewToInsert.field,
                );
              if (existingReviewIndex !== -1) {
                detailToReviewArrayRef.current[existingReviewIndex] =
                  reviewToInsert;
              } else {
                detailToReviewArrayRef.current.push(reviewToInsert);
              }
            }
          }
          detailJsonElementArrayRef.current.json_element = [];
        }
        const focusedElement = document.getElementById(
          focusedInputDetailRef.current,
        );
        if (!focusedElement || focusedElement?.id === 'receipt_tax_type_id')
          return;

        focusedElement.focus({ preventScroll: true });

        const inputConfig = receiptDetailConfigRef.current.fields.find(
          (item) => item.name === focusedElement?.id,
        );
        const inputConfigType = inputConfig?.validation_type ?? undefined;

        if (shiftKey) {
          detailJsonElementArrayRef.current.shift = true;
          detailJsonElementArrayRef.current.json_element.push(element);
          detailJsonElementArrayRef.current.focused_element_id =
            focusedElement?.id;
          detailJsonElementArrayRef.current.pageNumber = pageNumber;

          if (concatenatedContentRef.current !== '') {
            concatenatedContentRef.current =
              concatenatedContentRef.current +
              (inputConfig?.separator ? inputConfig.separator : ' ') +
              content;
          } else {
            concatenatedContentRef.current = content;
          }

          form.setFieldValue(
            focusedInputDetailRef.current,
            concatenatedContentRef.current,
          );
          lastFocusedInputDetailRef.current = focusedInputDetailRef.current;
        } else {
          detailJsonElementArrayRef.current.json_element = [];
          detailJsonElementArrayRef.current.json_element.push(element);
          detailJsonElementArrayRef.current.shift = false;
          concatenatedContentRef.current = content;

          if (content.indexOf('$') !== -1 || content.indexOf('usd$')) {
            content = content.replace('$', '');
            content = content.replace('usd$', '');
          }

          content = validations({
            valueToValidate: content,
            type: inputConfigType,
          });
          if (
            isDiscountRef.current &&
            (focusedInputDetailRef.current === 'unit_price' ||
              focusedInputDetailRef.current === 'subtotal' ||
              focusedInputDetailRef.current === 'receipt_line_tax_total' ||
              focusedInputDetailRef.current === 'subtotal_without_tax')
          ) {
            content = !content.includes('-') ? '-' + content : content;
          }

          if (
            receiptDetailIdRef.current &&
            receiptDetailIdRef.current > EnumsValues.ConstNumbers.zero
          ) {
            await customRequest({
              mutation: Mutation.upsertReviewReceiptDetailField,
              variables: {
                input: {
                  receipt_detail_id: Number(receiptDetailIdRef.current),
                  field: focusedInputDetailRef.current,
                  value: content,
                  json_value: detailJsonElementArrayRef.current.json_element,
                  page_number: pageNumber,
                },
              },
            });
          } else {
            const reviewToInsert: IDetailToReview = {
              field: focusedInputDetailRef.current,
              value: content,
              json_value: detailJsonElementArrayRef.current.json_element,
              page_number: pageNumber,
            };
            const existingReviewIndex =
              detailToReviewArrayRef.current.findIndex(
                (item) => item.field === reviewToInsert.field,
              );
            if (existingReviewIndex !== -1) {
              detailToReviewArrayRef.current[existingReviewIndex] =
                reviewToInsert;
            } else {
              detailToReviewArrayRef.current.push(reviewToInsert);
            }
          }

          form.setFieldValue(focusedInputDetailRef.current, content);
          lastFocusedInputDetailRef.current = focusedInputDetailRef.current;
        }
        form.validateFields([focusedInputDetailRef.current]);
      }
    },
    [],
  );

  const onCreateCanvasItemDetailClick = useCallback(
    (content: string, shiftKey: boolean, element: IWord | null, pageNumber) => {
      if (createModalFormRef.current)
        onCanvasItemDetailClick(
          content,
          shiftKey,
          createModalFormRef.current,
          element,
          pageNumber,
        );
    },
    [onCanvasItemDetailClick],
  );

  const onUpdateCanvasItemDetailClick = useCallback(
    (content: string, shiftKey: boolean, element: IWord | null, pageNumber) => {
      if (updateModalFormRef.current)
        onCanvasItemDetailClick(
          content,
          shiftKey,
          updateModalFormRef.current,
          element,
          pageNumber,
        );
    },
    [onCanvasItemDetailClick],
  );

  const onFormInputDetailFocus = (
    event: FocusEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    focusedInputDetailRef.current = event.target.id;
  };

  useEffect(() => {
    let subtotal = 0;
    receiptDetail?.forEach((element) => {
      subtotal = element?.subtotal_without_tax
        ? subtotal + element.subtotal_without_tax
        : subtotal;
    });
    setSubtotalItemDetail(Number(subtotal.toFixed(2)));
    if (actionRef.current) {
      actionRef.current.reload();
    }
  }, [receiptDetail]);

  const showInput = useCallback(
    (inputName: string) => {
      const fieldRH = receiptDetailConfig?.fields.find(
        (field: any) => field.name === inputName,
      );
      if (fieldRH) {
        return fieldRH.hidden;
      }
    },
    [receiptDetailConfig],
  );

  const requiredInput = useCallback(
    (inputName: string) => {
      const fieldRH = receiptDetailConfig?.fields.find(
        (field: any) => field.name === inputName,
      );
      if (fieldRH) {
        return fieldRH.classification_id !== 2;
      }
    },
    [receiptDetailConfig],
  );

  const columns = useCallback(
    (_editMode: boolean): ExportableColumn<IReceiptDetail>[] => [
      {
        export: false,
        dataIndex: 'receipt_line_number',
        hideInTable: showInput('receipt_line_number'),
        formItemProps: {
          hidden: showInput('receipt_line_number'),
          label:
            t('entity.receiptLineNumber') +
            (requiredInput('receipt_line_number')
              ? ''
              : t('ReviewReceiptDetail.optional')),
        },
        render: (_, record) => record.receipt_line_number,
        renderFormItem: () => (
          <InputNumber
            placeholder={t('ReviewReceiptDetail.placeholder.receiptLineNumber')}
            type="numeric"
            controls={false}
            id="receipt_line_number"
            onFocus={onFormInputDetailFocus}
          />
        ),
        align: 'left',
        hideInForm: false,
        hideInSearch: true,
        width: '35px',
      },
      {
        export: false,
        dataIndex: 'description',
        title: t('entity.description'),
        hideInTable: showInput('description'),
        width: '20%',
        formItemProps: {
          label:
            t('entity.description') +
            (requiredInput('description')
              ? ''
              : t('ReviewReceiptDetail.optional')),
          hidden: showInput('description'),
        },
        render: (_, record) => <span translate='no'>{record.description}</span>,
        renderFormItem: () => (
          <Input
            id="description"
            onFocus={onFormInputDetailFocus}
            placeholder={t('ReviewReceiptDetail.placeholder.description')}
          />
        ),
        align: 'left',
        hideInForm: false,
        hideInSearch: true,
      },
      {
        export: false,
        dataIndex: 'receipt_detail_item_type_id',
        title: t(
          'ReceiptTray.modalViewReceipt.detailsTab.receiptDetailItemTypeInTable',
        ),
        hideInTable: showInput('receipt_detail_item_type_id'),
        width: '9%',
        formItemProps: {
          label:
            t('entity.receipt_detail_item_type_id') +
            (requiredInput('receipt_detail_item_type_id')
              ? ''
              : t('ReviewReceiptDetail.optional')),
          hidden: showInput('receipt_detail_item_type_id'),
        },
        render: (_, record) => record.receipt_detail_item_type?.name ?? '-',
        renderFormItem: (_, __, formInstance) => (
          <Select
            onSelect={(selectedValue: string) => {
              setIsDiscount(Number(selectedValue) === DetailItemType.discount);
              onItemTypeChange?.(selectedValue, formInstance);
            }}
            getPopupContainer={(node) => node.parentNode}
            options={detailItemTypes.map((type) => ({
              label: type.name,
              value: type.id.toString(),
            }))}
            placeholder={`${t('action.input.select')} ${t(
              'entity.receipt_detail_item_type_id',
            ).toLocaleLowerCase()}`}
            maxLength={200}
          />
        ),
        align: 'left',
        hideInForm: false,
        hideInSearch: true,
      },
      {
        export: false,
        dataIndex: 'quantity',
        title: t('ReceiptTray.modalViewReceipt.detailsTab.quantityInTable'),
        hideInTable: showInput('quantity'),
        formItemProps: {
          label:
            t('entity.quantity') +
            (requiredInput('quantity')
              ? ''
              : t('ReviewReceiptDetail.optional')),
          hidden: showInput('quantity'),
        },
        render: (_, record) =>
          record.quantity != null && isNumeric(record.quantity)
            ? formatValueOnLoad(record.quantity)
            : null,
        renderFormItem: () => (
          <Input
            id="quantity"
            onFocus={onFormInputDetailFocus}
            placeholder={t('ReviewReceiptDetail.placeholder.quantity')}
          />
        ),
        align: 'right',
        hideInForm: false,
        hideInSearch: true,
      },
      {
        export: false,
        dataIndex: 'unit_price',
        title: t('ReceiptTray.modalViewReceipt.detailsTab.unitPriceInTable'),
        hideInTable: showInput('unit_price'),
        render: (_, record) =>
          record.unit_price != null && isNumeric(record.unit_price)
            ? formatValueOnLoad(record.unit_price)
            : null,
        align: 'right',
        hideInForm: true,
        hideInSearch: true,
        width: '8%',
      },
      {
        export: false,
        dataIndex: 'unit_price',
        title: t('entity.unitPrice'),
        formItemProps: {
          normalize: (value: string) => {
            if (isDiscount) {
              return value &&
                !value.includes('-') &&
                Number(formatValueOnSave(value)) !== 0
                ? '-' + value
                : value;
            } else {
              return value;
            }
          },
          label:
            t('entity.unitPrice') +
            (requiredInput('unit_price')
              ? ''
              : t('ReviewReceiptDetail.optional')),
          hidden: showInput('unit_price'),
        },
        renderFormItem: () => (
          <Input
            id="unit_price"
            onFocus={onFormInputDetailFocus}
            placeholder={t('ReviewReceiptDetail.placeholder.unitPrice')}
          />
        ),
        align: 'right',
        hideInTable: true,
        hideInForm: false,
        hideInSearch: true,
      },
      {
        export: false,
        dataIndex: 'receipt_line_discount_total',
        title: t('entity.receiptLineDiscountTotal'),
        hideInTable: true,
        formItemProps: {
          label:
            t('entity.receiptLineDiscountTotal') +
            (requiredInput('receipt_line_discount_total')
              ? ''
              : t('ReviewReceiptDetail.optional')),
          hidden: showInput('receipt_line_discount_total'),
        },
        renderFormItem: () => (
          <Input
            id="receipt_line_discount_total"
            onFocus={onFormInputDetailFocus}
            placeholder={t(
              'ReviewReceiptDetail.placeholder.receiptLineDiscountTotal',
            )}
          />
        ),
        align: 'right',
        hideInForm: false,
        hideInSearch: true,
      },
      {
        export: false,
        dataIndex: 'receipt_line_discount_total',
        title: t('entity.receiptLineDiscountTotalInTable'),
        width: '8%',
        hideInTable: false, //devolver
        render: (_, record) =>
          record.receipt_line_discount_total != null &&
          isNumeric(record.receipt_line_discount_total)
            ? formatValueOnLoad(record.receipt_line_discount_total)
            : null,
        align: 'right',
        hideInForm: true,
        hideInSearch: true,
      },
      {
        export: false,
        dataIndex: 'discount_percentage',
        title: t('entity.discountPercentage'),
        hideInTable: true,
        formItemProps: {
          label:
            t('entity.discountPercentage') +
            (requiredInput('discount_percentage')
              ? ''
              : t('ReviewReceiptDetail.optional')),
          hidden: showInput('discount_percentage'),
        },
        renderFormItem: () => (
          <Input
            id="discount_percentage"
            onFocus={onFormInputDetailFocus}
            placeholder={t(
              'ReviewReceiptDetail.placeholder.discountPercentage',
            )}
          />
        ),
        align: 'right',
        hideInForm: false,
        hideInSearch: true,
      },
      {
        export: false,
        dataIndex: 'discount_percentage',
        title: t('ReceiptTray.modalViewReceipt.detailsTab.discountPercentage'),
        hideInTable: false, //devolver
        render: (_, record) =>
          record.discount_percentage != null &&
          isNumeric(record.discount_percentage)
            ? formatValueOnLoad(record.discount_percentage)
            : null,
        align: 'right',
        hideInForm: true,
        hideInSearch: true,
        width: '7%',
      },
      {
        export: false,
        dataIndex: 'receipt_tax_type_id',
        title:
          t('ReceiptTray.modalViewReceipt.detailsTab.percentageIva') +
          t('ReviewReceiptDetail.optional'),
        hideInTable: true,
        hideInSearch: true,
        formItemProps: {
          hidden: showInput('receipt_tax_type_id'),
        },
        renderFormItem: () => (
          <Form.Item name="receipt_tax_type_id">
            <Select
              id="receipt_tax_type_id"
              placeholder={t('ReviewReceipt.message.selectOption')}
              getPopupContainer={(node) => node.parentNode}
              onFocus={onFormInputDetailFocus}
              options={(receiptTaxTypes || []).map((conceptType) => ({
                label: conceptType.name,
                value: conceptType.id.toString(),
              }))}
              allowClear
            />
          </Form.Item>
        ),
      },
      {
        export: false,
        dataIndex: 'receipt_line_tax_total',
        title: t('entity.receiptLineTaxTotal'),
        hideInTable: showInput('receipt_line_tax_total'),
        formItemProps: {
          label:
            t('entity.receiptLineTaxTotal') +
            (requiredInput('receipt_line_tax_total')
              ? ''
              : t('ReviewReceiptDetail.optional')),
          normalize: (value: string) => {
            if (isDiscount) {
              return value &&
                !value.includes('-') &&
                Number(formatValueOnSave(value)) !== 0
                ? '-' + value
                : value;
            } else {
              return value;
            }
          },
          hidden: showInput('receipt_line_tax_total'),
        },
        render: (_, record) =>
          record.receipt_line_tax_total != null &&
          isNumeric(record.receipt_line_tax_total)
            ? formatValueOnLoad(record.receipt_line_tax_total)
            : null,
        renderFormItem: () => (
          <Input
            id="receipt_line_tax_total"
            onFocus={onFormInputDetailFocus}
            placeholder={t(
              'ReviewReceiptDetail.placeholder.receiptLineTaxTotal',
            )}
          />
        ),
        align: 'right',
        width: '8%',
        hideInForm: false,
        hideInSearch: true,
      },
      {
        export: false,
        dataIndex: 'tax_rate',
        title: t('entity.taxRate'),
        hideInTable: true,
        formItemProps: {
          label:
            t('entity.taxRate') +
            (requiredInput('tax_rate')
              ? ''
              : t('ReviewReceiptDetail.optional')),
          hidden: showInput('tax_rate'),
        },
        renderFormItem: () => (
          <Input
            id="tax_rate"
            onFocus={onFormInputDetailFocus}
            placeholder={t('ReviewReceiptDetail.placeholder.taxRate')}
          />
        ),
        align: 'right',
        width: '7%',
        hideInForm: true,
        hideInSearch: true,
      },
      {
        export: false,
        dataIndex: 'tax_rate',
        title: t('entity.taxRateInTable'),
        hideInTable: showInput('tax_rate'),
        render: (_, record) =>
          record.receipt_tax_type?.tax_rate != null &&
          isNumeric(record.receipt_tax_type?.tax_rate)
            ? formatValueOnLoad(record.receipt_tax_type?.tax_rate)
            : null,
        align: 'right',
        hideInForm: true,
        hideInSearch: true,
      },
      {
        export: false,
        dataIndex: 'subtotal_without_tax',
        title: t(
          'ReceiptTray.modalViewReceipt.detailsTab.subtotalWithoutTaxInTable',
        ),
        hideInTable: false,
        formItemProps: {
          normalize: (value: string) => {
            if (isDiscount) {
              return value &&
                !value.includes('-') &&
                Number(formatValueOnSave(value)) !== 0
                ? '-' + value
                : value;
            } else {
              return value;
            }
          },
          label:
            t('entity.subtotalWithoutTax') +
            (requiredInput('subtotal_without_tax')
              ? ''
              : t('ReviewReceiptDetail.optional')),
          hidden: showInput('subtotal_without_tax'),
        },
        render: (_, record) =>
          record.subtotal_without_tax != null &&
          isNumeric(record.subtotal_without_tax)
            ? formatValueOnLoad(record.subtotal_without_tax)
            : null,
        renderFormItem: () => (
          <Input
            type="numeric"
            id="subtotal_without_tax"
            onFocus={onFormInputDetailFocus}
            placeholder={t(
              'ReviewReceiptDetail.placeholder.subtotalWithoutTax',
            )}
          />
        ),
        align: 'right',
        width: '9%',
        hideInForm: false,
        hideInSearch: true,
      },
      {
        export: false,
        dataIndex: 'subtotal',
        title: t(
          'ReceiptTray.modalViewReceipt.detailsTab.subtotalWithIvaInTable',
        ),
        hideInTable: showInput('subtotal'),
        formItemProps: {
          normalize: (value: string) => {
            if (isDiscount) {
              return value &&
                !value.includes('-') &&
                Number(formatValueOnSave(value)) !== 0
                ? '-' + value
                : value;
            } else {
              return value;
            }
          },
          label:
            t('entity.subtotal') +
            (requiredInput('subtotal')
              ? ''
              : t('ReviewReceiptDetail.optional')),
          hidden: showInput('subtotal'),
        },
        render: (_, record) =>
          record.subtotal != null && isNumeric(record.subtotal)
            ? formatValueOnLoad(record.subtotal)
            : null,
        renderFormItem: () => (
          <Input
            type="numeric"
            id="subtotal"
            onFocus={onFormInputDetailFocus}
            placeholder={t('ReviewReceiptDetail.placeholder.subtotal')}
          />
        ),
        align: 'right',
        width: '9%',
        hideInForm: false,
        hideInSearch: true,
      },
      {
        title: t('entity.actions'),
        dataIndex: 'option',
        valueType: 'option',
        fixed: 'right',
        width: '75px',
        export: false,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        align: 'right',
        render: (_, record) => (
          <>
            <Tooltip
              key="edit_setting"
              title={t('ReviewReceiptDetail.editItem')}
            >
              <span
              translate='no'
                className="material-symbols-outlined pointer receipt-detail-action-icon"
                onClick={() => {
                  onClickEditItem?.(record.receipt_detail_item_type_id);
                  setEditFormValues(record);
                  setUpdateDetailModalVisible(true);
                }}
              >
                edit
              </span>
            </Tooltip>
            <Tooltip
              key="remove_setting"
              title={t('ReviewReceiptDetail.removeItem')}
            >
              <span
              translate='no'
                className="material-symbols-outlined pointer receipt-detail-action-icon"
                onClick={() => {
                  removeRecordReceiptDetail(record.id);
                }}
              >
                delete
              </span>
            </Tooltip>
          </>
        ),
      },
    ],
    [editFormValues, showInput, requiredInput, detailItemTypes, isDiscount],
  );

  useEffect(() => {
    if (receiptDetailConfig) {
      receiptDetailConfigRef.current = receiptDetailConfig;
    }
  }, [receiptDetailConfig]);

  useEffect(() => {
    isDiscountRef.current = isDiscount;
  }, [isDiscount]);

  return (
    <>
      <Collapse
        className="collapse-receipt-item"
        bordered={false}
        defaultActiveKey={2}
      >
        <CollapsePanel
          header={
            <div className="collapse-receipt-item-title">
              <div className="collapse-receipt-item-title-header">
                {t('ReceiptTray.modalViewReceipt.detailsTab.items')}
              </div>
              <div className="collapse-receipt-item-title-header">
                {t('entity.subtotalWithoutTax')}:{' '}
                <b>
                  {formatValueOnLoad(subtotalItemDetail)}
                </b>
              </div>
            </div>
          }
          key="2"
        >
          <ProTable
            dataSource={receiptDetail}
            columns={columns(false)}
            actionRef={actionRef}
            pagination={false}
            scroll={{ y: 300 }}
            size="small"
            className="receipt-detail-thead"
            search={false}
            toolBarRender={false}
            rowKey="key"
            bordered={false}
          />
          <Tooltip key="btn-add-item" title={t('ReviewReceiptDetail.addItem')}>
            <Button
              className="add-receipt-detail"
              icon={
                <span className="material-symbols-outlined receipt-detail-add-icon" translate='no'>
                  add
                </span>
              }
              onClick={() => {
                receiptDetailIdRef.current = undefined;
                setAddFormReceiptDetail(true);
                setIsDiscount(false)
              }}
            >
              {t('ReviewReceiptDetail.addItem')}
            </Button>
          </Tooltip>
        </CollapsePanel>
      </Collapse>

      {editFormValues && (
        <Modal
          destroyOnClose
          title={t('ReviewReceiptDetail.editReviewReceiptDetailForm.title')}
          className="receipt-detail-saveForm"
          open={updateDetailModalVisible}
          onCancel={() => {
            cleanupFormData();
            deleteManyReviewsReceiptDetail();
            setUpdateDetailModalVisible(false);
            setEditFormValues({});
          }}
          footer={null}
          width="90vw"
        >
          <Row>
            <Col span={7}>
              <SaveForm
                loading={false}
                title={t(
                  'ReviewReceiptDetail.editReviewReceiptDetailForm.title',
                )}
                values={{
                  ...editFormValues,
                  receipt_detail_item_type_id:
                    editFormValues.receipt_detail_item_type_id ||
                    EnumsValues.DetailItemType.product,
                  quantity: isNumeric(editFormValues.quantity)
                    ? formatValueOnLoad(editFormValues.quantity)
                    : null,
                  unit_price: isNumeric(editFormValues.unit_price)
                    ? formatValueOnLoad(editFormValues.unit_price)
                    : null,
                  receipt_line_discount_total: isNumeric(
                    editFormValues.receipt_line_discount_total,
                  )
                    ? formatValueOnLoad(
                        editFormValues.receipt_line_discount_total,
                      )
                    : null,
                  receipt_line_tax_total: isNumeric(
                    editFormValues.receipt_line_tax_total,
                  )
                    ? formatValueOnLoad(editFormValues.receipt_line_tax_total)
                    : null,
                  discount_percentage: isNumeric(
                    editFormValues.discount_percentage,
                  )
                    ? String(editFormValues.discount_percentage).replace(
                        '.',
                        ',',
                      )
                    : null,
                  subtotal: isNumeric(editFormValues.subtotal)
                    ? formatValueOnLoad(editFormValues.subtotal)
                    : null,
                  subtotal_without_tax: isNumeric(
                    editFormValues.subtotal_without_tax,
                  )
                    ? formatValueOnLoad(editFormValues.subtotal_without_tax)
                    : null,
                  tax_rate: isNumeric(editFormValues.tax_rate)
                    ? String(editFormValues.tax_rate).replace('.', ',')
                    : null,
                  timezone: editFormValues.profile?.timezone,
                  date_format: editFormValues.profile?.date_format,
                  date_time_format: editFormValues.profile?.date_time_format,
                  phone: editFormValues.profile?.phone,
                  receipt_tax_type_id: editFormValues.receipt_tax_type_id,
                }}
                modalVisible={updateDetailModalVisible}
                columns={columns(true)}
                renderAsChildren
                formRef={updateModalFormRef}
                onOk={(value) => {
                  updateReceiptProduct(value);
                  isFormChanged.current = true;
                }}
                onCancel={() => {
                  setUpdateDetailModalVisible(false);
                  setEditFormValues({});
                  deleteManyReviewsReceiptDetail();
                }}
                saveFormFooterIcon={{
                  reset: <></>,
                }}
                buttonCancel={true}
                buttonReset={false}
              />
            </Col>
            <Col span={17}>
              <ReviewReceiptCanvas
                file={file}
                pages={marks}
                onCanvasItemClick={onUpdateCanvasItemDetailClick}
                lastLog={lastLog}
                expandedLogs={expandedLogs}
                setExpandedLogs={setExpandedLogs}
                handleValidateButton={() => {
                  const values = updateModalFormRef.current?.getFieldsValue();
                  updateReceiptProduct(values, null, false);
                }}
                shouldValidateButtonBeVisible={true}
              />
            </Col>
          </Row>
        </Modal>
      )}

      <Modal
        destroyOnClose
        title={t('ReviewReceiptDetail.addReviewReceiptDetailForm.title')}
        className="receipt-detail-saveForm"
        open={addFormReceiptDetail}
        onCancel={() => {
          cleanupFormData();
          setAddFormReceiptDetail(false);
        }}
        footer={null}
        width="90vw"
      >
        <Row>
          <Col span={7}>
            <SaveForm
              loading={false}
              title={t('ReviewReceiptDetail.addReviewReceiptDetailForm.title')}
              modalVisible={addFormReceiptDetail}
              columns={columns(true)}
              renderAsChildren
              values={{
                receipt_detail_item_type_id: EnumsValues.DetailItemType.product,
              }}
              formRef={createModalFormRef}
              onOk={(value) => {
                createReceiptDetail(value);
                isFormChanged.current = true;
              }}
              onCancel={() => {
                setAddFormReceiptDetail(false);
                deleteManyReviewsReceiptDetail();
              }}
              saveFormFooterIcon={{
                reset: <></>,
              }}
              buttonCancel={true}
              buttonReset={false}
            />
          </Col>
          <Col span={17}>
            <ReviewReceiptCanvas
              file={file}
              pages={marks}
              onCanvasItemClick={onCreateCanvasItemDetailClick}
              lastLog={lastLog}
              expandedLogs={expandedLogs}
              setExpandedLogs={setExpandedLogs}
              shouldValidateButtonBeVisible={false}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
}
