export const FIELD_ATTRIBUTE_TENANTS_BY_RECEIPT_TYPE = `
query fieldAttributeTenantsByReceiptType($receipt_type_id: Int!, $tenant_id: Int!){
  fieldAttributeTenantsByReceiptType(receipt_type_id:$receipt_type_id,  tenant_id: $tenant_id){
    __typename
    ... on  FieldAttributeTenant{
      id
      field_id
      attribute_type_id
      value
      configuration
      tenant_id
      readonly
      attribute_type {
        id
        code
        translation_key
        tags
      }
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;
