import { Button } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

export interface IExpandIconProps<T> {
  record: T;
  expanded: boolean;
  onExpand: (record: T, e: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
}
export const ExpandIcon: React.FC<IExpandIconProps<any>> = (
  props: IExpandIconProps<any>,
) => {
  const { expanded, onExpand, record, disabled } = props;

  return (
    // Fix for tooltip to work with disabled buttons
    <span style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}>
      <Button
        type="link"
        onClick={(e) => {
          onExpand(record, e);
        }}
        style={{
          // Fix for tooltip to work with disabled buttons
          pointerEvents: disabled ? 'none' : undefined,
        }}
        disabled={disabled}
      >
        {expanded ? (
          <UpOutlined
            className="btn-icon-subtable"
            style={{ color: 'black' }}
          />
        ) : (
          <DownOutlined
            className="btn-icon-subtable"
            style={{ color: disabled ? 'grey' : 'black' }}
          />
        )}
      </Button>
    </span>
  );
};
