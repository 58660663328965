import { Button, ButtonProps } from 'antd';

const ButtonPrimary = (props: ButtonProps) => {
  return (
    <Button type="primary" {...props}>
      {props.children}
    </Button>
  );
};

export default ButtonPrimary;
