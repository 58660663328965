export const INTEGRATION_SETTINGS = `
query integrationSettings(
  $input: IntegrationSettingsInput!
  $filter: FilterIntegrationSettingInput
) {
  integrationSettings(input: $input, filter: $filter) {
    __typename
    ... on IntegrationSetting {
      id
      name
      description
      translation_key
      is_encrypted
      readonly
      integration_setting_integration {
        id
        integration_id
        default_value
        orden
        divider
      }
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;
