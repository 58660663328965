import { FormInstance } from 'antd';
import React, { useRef, useState, useCallback } from 'react';
import './RangePickerTag.less';

interface RangePickerTagProps {
  formInstance: FormInstance<any>;
  element: JSX.Element;
  keyValue: string;
}

const RangePickerTag = (props: RangePickerTagProps) => {
  const { formInstance, element, keyValue } = props;
  const panelRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const handleChange = useCallback(
    (value: any) => {
      formInstance.setFieldValue(keyValue, value);

      if (!value) {
        formInstance.submit();
      }
    },
    [formInstance, keyValue],
  );

  const handleOpenChange = useCallback(
    (open: boolean) => {
      setOpen(open);
      if (!open) {
        formInstance.submit();
      }
    },
    [formInstance],
  );

  return React.cloneElement(element, {
    value: formInstance.getFieldValue(keyValue),
    onChange: handleChange,
    panelRender: (panel: JSX.Element) =>
      open ? <div ref={panelRef}>{panel}</div> : null,
    onOpenChange: handleOpenChange,
    className: 'date-picker-tag',
  });
};

export default RangePickerTag;
