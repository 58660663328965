import { useEffect } from 'react';
import useScript from '../../../hooks/useScript';

interface IMercadoPagoProps {
  preferenceId: string;
  instanceCheckoutMercadoPago?: (instance: any) => void;
}

export const Mercadopago = (props: IMercadoPagoProps) => {
  const { preferenceId, instanceCheckoutMercadoPago } = props;
  const stateScript = useScript('https://sdk.mercadopago.com/js/v2');

  const createCheckout = () => {
    // @ts-ignore
    const mercadopago = new window.MercadoPago(
      process.env.REACT_APP_PUBLIC_KEY_MARKETPLACE,
      {
        locale: 'es-AR',
      },
    );
    return mercadopago;
  };

  useEffect(() => {
    if (stateScript.value === 'loaded') {
      if (preferenceId) {
        const mercadopago = createCheckout();
        instanceCheckoutMercadoPago &&
          instanceCheckoutMercadoPago(
            mercadopago.checkout({
              preference: {
                id: preferenceId,
              },
            }),
          );
      }
    }
  }, [stateScript.value, preferenceId]);

  return <div className="cho-container" />;
};
