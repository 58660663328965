import { Modal } from 'antd';
import { SchemaField } from '../../../../interfaces/ValidationSchema';
import './FieldRestoreModal.less';
import { ContextApp } from '../../../../contexts/ContextApp';
import { useContext } from 'react';
import ButtonAction from '../../../../components/common/Buttons/ButtonAction';
import ButtonSecondary from '../../../../components/common/Buttons/ButtonSecondary';
import ButtonTertiary from '../../../../components/common/Buttons/ButtonTertiary';

interface FieldRestoreModalProps {
  modalVisible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onRestoreToPreviousState: () => void;
  onRestoreToDefault: () => void;
  currentField: SchemaField;
}

const FieldRestoreModal = (props: FieldRestoreModalProps) => {
  const {
    modalVisible,
    setModalVisible,
    onRestoreToPreviousState,
    onRestoreToDefault,
    currentField,
  } = props;

  const { t } = useContext(ContextApp);
  return (
    <Modal
      destroyOnClose
      title={
        <div className="modal-content__title">
          <span className="material-symbols-outlined receipt-exclamation-icon">
            error
          </span>
          {t('settingsPage.fieldRestoreModal.title')}
        </div>
      }
      open={modalVisible}
      onCancel={() => setModalVisible(false)}
      footer={null}
      className="field-restore-modal"
      width={'max-content'}
    >
      <div className="modal-content">
        <p className="modal-content__text">
          {t('settingsPage.fieldRestoreModal.paragraph', {
            fieldName: (
              t((currentField?.field?.translation_key as never) || '') as string
            ).toLowerCase(),
          })}
        </p>
        <div className="modal-content__actions">
          <ButtonTertiary type="text" onClick={() => setModalVisible(false)}>
            Cancelar
          </ButtonTertiary>
          <ButtonSecondary onClick={onRestoreToDefault}>
            {t('settingsPage.fieldRestoreModal.restoreToDefault')}
          </ButtonSecondary>
          <ButtonAction onClick={onRestoreToPreviousState}>
            {t('settingsPage.fieldRestoreModal.restoreToPreviousState')}
          </ButtonAction>
        </div>
      </div>
    </Modal>
  );
};

export default FieldRestoreModal;
