export const RECEIPT_TYPE_DOCUMENT_TYPE_BY_RECEIPT_TYPE = `
query receiptTypeDocumentTypeByReceiptType($receipt_type_id: Int!, $tenant_id: Int!){
  receiptTypeDocumentTypeByReceiptType(receipt_type_id:$receipt_type_id,  tenant_id: $tenant_id){
    __typename
    ... on  ReceiptTypeDocumentType{
      id
      receipt_type_id
      document_type_id
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`