import { Menu, MenuProps } from 'antd';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IRouteConfig } from '../../../interfaces/routeConfig';
import { Authorization } from '../../../shared';
import { useCurrentRoute } from '../../../hooks';
import { LogoComponent } from './LogoComponent';
import { ContextApp } from '../../../contexts/ContextApp';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { NavContext } from '@ionic/react';
import { FeatureFlag } from '../../../shared/FeatureFlag';

export const MainMenu = ({ routes }: { routes: IRouteConfig[] }) => {
  const { functions, mainMenuCollapsed, selectedTenantId, featureFlags } =
    useContext(ContextApp);
  const { navigate } = useContext(NavContext);
  const { leafRoute, matchedRoutes } = useCurrentRoute();
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  useEffect(() => {
    if (leafRoute) {
      if (!selectedTenantId && leafRoute.requiresTenantSelected)
        navigate('/app/selectTenant');
      setSelectedKeys([leafRoute.key]);
    } else {
      setSelectedKeys([]);
    }

    const matchedNotLeafRoutes = matchedRoutes.filter(
      (item) => !!item.routes?.length,
    );
    if (matchedNotLeafRoutes.length && !mainMenuCollapsed) {
      const routesKeys = matchedNotLeafRoutes.map((item) => item.key);
      setOpenKeys((oldOpenKeys) => {
        const keys = new Set(oldOpenKeys);
        for (const routesKey of routesKeys) {
          keys.add(routesKey);
        }
        return Array.from(keys);
      });
    }
  }, [leafRoute?.key, mainMenuCollapsed]);

  useEffect(() => {
    if (mainMenuCollapsed) {
      setOpenKeys([]);
    }
  }, [mainMenuCollapsed]);

  const menuProps: MenuProps = {
    theme: 'dark',
    mode: 'inline',
  };

  const toggleSubMenuOpen = (e: { key: string }) => {
    setOpenKeys((oldOpenKeys) => {
      if (oldOpenKeys.includes(e.key)) {
        return oldOpenKeys.filter((item) => item !== e.key);
      }
      return [...oldOpenKeys, e.key];
    });
  };

  const renderMenuRoutes = useCallback(
    (paramRoutes: IRouteConfig[]): ItemType[] => {
      return paramRoutes
        .filter(
          (item) =>
            !(
              (item.authority &&
                !Authorization.security(functions, item.authority)) ||
              (item.featureFlag &&
                FeatureFlag.hideInMenu(featureFlags, item.featureFlag)) ||
              item.hideInMenu
            ),
        )
        .map((item): ItemType => {
          if (item.routes) {
            return {
              children: renderMenuRoutes(item.routes),
              key: item.key,
              onTitleClick: toggleSubMenuOpen,
              icon: item.icon,
              label: item.name,
              className: item.className,
            };
          }
          return {
            key: item.key,
            label: <Link to={item.path}>{item.name}</Link>,
            icon: item.icon,
            className: item.className,
          };
        });
    },
    [functions, featureFlags],
  );

  return (
    <>
      <LogoComponent />
      <Menu
        className="MainMenu"
        {...menuProps}
        selectedKeys={selectedKeys}
        openKeys={openKeys}
        items={renderMenuRoutes(routes)}
      />
    </>
  );
};
