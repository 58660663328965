import { useContext, useMemo, useRef, useState } from 'react';
import ProTable, { ActionType } from '@ant-design/pro-table';
import { ContextApp } from '../../contexts/ContextApp';

import '../FunctionalitiesPage/Functionalities.less';
import './TenantSetting.less';

import { Button } from 'antd';
import {
  ITenantSettingCurrentValue,
  ISettingType,
  ISettingToUpdate,
} from '../../interfaces/TenantSetting';
import { Query } from '../../services/graphql/query';
import GraphqlService from '../../services/graphql/GraphqlService';
import { Mutation } from '../../services/graphql/mutation';
import { CustomMessage } from '../../hooks';
import { notificationContext } from '../../contexts/NotificationContext';
import { useDynamicSetting } from '../../hooks/tenantSettingInput/useDynamicSetting';
import { ExportableColumn } from '../../shared/Exporter';
import { Tools } from '../../shared';
import { EnumsValues } from '../../enums/EnumsValues';

export enum SettingTenantType {
  switch = 'switch',
  currency = 'currency',
}

export const TenantSetting = () => {
  const { t, selectedTenantId } = useContext(ContextApp);

  const { openNotification } = useContext(notificationContext);
  const { customRequest } = GraphqlService();
  const [settingsArray, setSettingsArray] = useState<ISettingToUpdate[]>([]);
  const [toleranceAndDefaulIva, setToleranceAndDefaultIva] = useState<
    ITenantSettingCurrentValue[]
  >([]);
  const [otherSettings, setOtherSettings] = useState<
    ITenantSettingCurrentValue[]
  >([]);
  const [searchText, setSearchText] = useState<string>('');
  const variables = useRef<any>({});
  const actionRef = useRef<ActionType>();
  const customParamsRef = useRef<any>({});

  const { getErrorMessage } = CustomMessage();

  const request = async (_params: any) => {
    try {
      variables.current.filter = {};

      if (customParamsRef.current && customParamsRef.current.filter) {
        Object.entries(customParamsRef.current.filter).forEach(
          ([key, value]) => {
            if (
              Tools.isDefined(value) &&
              (!Array.isArray(value) || value.length > 0)
            ) {
              variables.current.filter[key] = value;
            }
          },
        );
      }

      if (searchText) {
        variables.current.searchText = searchText;
      } else {
        delete variables.current.searchText;
      }

      variables.current.filter['tenant_id'] = selectedTenantId;

      const data: ITenantSettingCurrentValue[] = await customRequest({
        query: Query.tenantSettingsCurrentValues,
        variables: variables.current,
      });

      /* Ordenamos el arreglo */
      data.sort((a, b) => a.tenant_setting_id - b.tenant_setting_id);

      setSettingsArray(
        data.map((item) => {
          return {
            id: item.id,
            setting_id: item.tenant_setting_id,
            value: item.value,
            setting_type: item.tenant_setting.setting_type.name,
          };
        }),
      );

      //para separar la data que va a cada una de las dos tablas
      const toleranceAndDefaultIvaData: any = [];
      const otherSettingsData: any = [];

      data.forEach((item) => {
        const setting = {
          id: item.id,
          setting_id: item.tenant_setting.id,
          value: item.value,
          setting_type: item.tenant_setting.setting_type.name,
          tenant_setting: item.tenant_setting,
        };

        if (
          setting.tenant_setting.name ===
            EnumsValues.TenantSettingNames.Tolerance ||
          setting.tenant_setting.name ===
            EnumsValues.TenantSettingNames.DefaultIva
        ) {
          toleranceAndDefaultIvaData.push(setting);
        } else {
          otherSettingsData.push(setting);
        }
      });

      setToleranceAndDefaultIva(toleranceAndDefaultIvaData);
      setOtherSettings(otherSettingsData);

      return {
        current: 1,
        data,
        pageSize: '1',
        success: true,
        total: data.length,
      };
    } catch (error) {
      return {
        current: 1,
        data: [],
        pageSize: '1',
        success: true,
        total: 0,
      };
    }
  };

  const updateTenantSetting = async () => {
    try {
      const settingsMapping = settingsArray.map((setting) => {
        return {
          current_tenant_setting_id: setting.id,
          value: setting.value,
        };
      });
      await customRequest({
        mutation: Mutation.updateTenantSettingValue,
        variables: {
          input: settingsMapping,
          tenant_id: selectedTenantId,
        },
      });

      openNotification({
        msj: t('message.updateSuccess'),
        type: 'success',
      });
    } catch (error: any) {
      if (error.status_code && error.message) {
        openNotification({
          msj: getErrorMessage(error),
          type: 'error',
        });
      }
    }
  };

  const handleChange = (id: any, value: any) => {
    if (!id || value === null || typeof value === 'undefined') {
      return;
    }

    const newData = [...settingsArray];
    const index = newData.findIndex((item) => item.id === id);

    if (index > -1) {
      newData[index].value = value.toString();
    } else {
      newData.push({ id: id, value: value.toString() });
    }

    //Cambiar a un solo arreglo que lleve la configuración actual y la que se está realizando
    setSettingsArray(newData);
  };

  const { dynamicSettings } = useDynamicSetting({
    settingsArray,
    handleChange,
  });

  const columns: ExportableColumn<ITenantSettingCurrentValue>[] =
    useMemo(() => {
      return [
        {
          export: false,
          dataIndex: 'searchText',
          title: `${t('action.search')}`,
          hideInTable: true,
          hideInSearch: true,
        },
        {
          export: false,
          title: t('entity.description'),
          dataIndex: 'description',
          key: 'description',
          render: (_, record) => <div>{record.tenant_setting.description}</div>,
          hideInSearch: true,
          hideInTable: false,
        },
        {
          export: false,
          colSpan: 4,
          align: 'right',
          width: '280px',
          render: (_, record: ITenantSettingCurrentValue) => {
            const settingTypeConfig: ISettingType =
              dynamicSettings[
                record.tenant_setting.setting_type
                  .name as keyof typeof SettingTenantType
              ];
            if (!settingTypeConfig) return <div>{record.value || ''}</div>;
            return settingTypeConfig.render({
              ...record,
              key: record.tenant_setting.name,
            });
          },
          shouldCellUpdate: () => true,
          hideInSearch: true,
        },
      ];
    }, [dynamicSettings]);

  return (
    <>
      <div className="protable-tenant-setting--wrapper">
        <div className="tenant-setting-section-title">
          <h5 className="h5">{t('tenant_setting.settingTitle1')}</h5>
        </div>
        <ProTable
          className="protable-tenant-setting"
          actionRef={actionRef}
          dataSource={otherSettings}
          columns={columns}
          pagination={false}
          rowKey="id"
          search={false}
          onReset={() => {
            setSearchText('');
          }}
          options={{ reload: false, setting: false, density: false }}
          request={async (params, sorter, filter) =>
            request({ ...params, sorter, filter })
          }
        />

        <div className="tenant-setting-section-title">
          <h5 className="h5">{t('tenant_setting.settingTitle2')}</h5>
        </div>

        <ProTable
          className="protable-tenant-setting"
          actionRef={actionRef}
          dataSource={toleranceAndDefaulIva}
          columns={columns}
          pagination={false}
          rowKey="id"
          search={false}
          onReset={() => {
            setSearchText('');
          }}
          options={{ reload: false, setting: false, density: false }}
          request={async (params, sorter, filter) =>
            request({ ...params, sorter, filter })
          }
        />
      </div>
      <div className="tenant-setting-save-button">
        <Button
          className={`btn-save ${settingsArray.length === 0 ? 'disabled' : ''}`}
          disabled={settingsArray.length === 0}
          onClick={updateTenantSetting}
        >
          {t('action.save')}
        </Button>
      </div>
    </>
  );
};
