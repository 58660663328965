export const UPDATE_RECEIPT_DETAIL_BY_DECISION = `
mutation updateReceiptDetailByDecision (
  $input: UpdateReceiptDetailByDecisionInput!,
  $inputProduct: CreateProductInput
){
  updateReceiptDetailByDecision (
    input: $input,
    inputProduct: $inputProduct
  ){
    __typename
    ... on ReceiptDetail{
      id
      code
      description
      quantity
      unit_price
      discount_price
      discount_percentage
      unit
      product_id
      cost_center_id
      product{
        id
        description
        code
        tenant_id
      }
      receipt_detail_item_type_id
      receipt_detail_item_type{
          id
          name
          description
        }
      subtotal
      subtotal_without_tax
      receipt_line_discount_total
      receipt_line_tax_total
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
export const UPDATE_RECEIPT_DETAIL = `
mutation updateReceiptDetail($input: UpdateReceiptDetailInput!) {
  updateReceiptDetail(input: $input) {
    __typename
    ... on ReceiptDetail{
      id
      code
      description
      quantity
      unit_price
      discount_price
      discount_percentage
      unit
      product_id
      cost_center_id
      product{
        id
        description
        code
        tenant_id
      }
      receipt_detail_item_type_id
      receipt_detail_item_type{
          id
          name
          description
      }
      subtotal
      subtotal_without_tax
      receipt_line_tax_total
      receipt_line_discount_total
    }
    ...on ResultError {
      status_code
      message
      message_translation_key
    }
    
  }
}
`;

export const DELETE_RECEIPT_DETAIL = `
mutation deleteReceiptDetail($id: Int!) {
  deleteReceiptDetail(id: $id) {
    __typename
    ... on ReceiptDetail{
      id
      code
      description
      quantity
      unit_price
      discount_price
      discount_percentage
      unit
      product_id
      cost_center_id
      product{
        id
        description
        code
        tenant_id
      }
      receipt_detail_item_type_id
      receipt_detail_item_type{
          id
          name
          description
      }
      subtotal
      subtotal_without_tax
      receipt_line_tax_total
      receipt_line_discount_total
    }
    ...on ResultError {
      status_code
      message
      message_translation_key
    }
    
  }
}
`;

export const CREATE_RECEIPT_DETAIL = `
mutation createReceiptDetail($input: CreateReceiptDetailInput!) {
  createReceiptDetail(input: $input) {
    __typename
    ... on ReceiptDetail{
      id
      code
      description
      quantity
      unit_price
      discount_price
      discount_percentage
      unit
      product_id
      cost_center_id
      product{
        id
        description
        code
        tenant_id
      }
      receipt_detail_item_type_id
      receipt_detail_item_type{
          id
          name
          description
        }
      subtotal
      subtotal_without_tax
      receipt_line_tax_total
      receipt_line_discount_total
    }
    ...on ResultError {
      status_code
      message
      message_translation_key
    }
    
  }
}
`;
