import './DeviceUnavailableCheck.less';
import image from '../../../assets/logo_braulio.png';
import { ContextApp } from '../../../contexts/ContextApp';
import { useContext } from 'react';
import ImageMobileSvg from './ImageMobileSvg';

export const DeviceUnavailableCheck = () => {
  const { t } = useContext(ContextApp);
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      window.navigator.userAgent,
    );

  return !isMobile ? (
    <></>
  ) : (
    <div className="DeviceUnavailableCheck-cover">
      <div className="DeviceUnavailableCheck-container">
        <div className="radial-gradient-blue"></div>
        <div className="radial-gradient-red"></div>
        <img
          className="DeviceUnavailableCheck-container--image"
          src={image}
          alt="Logo Braulio"
        />

        <p className="DeviceUnavailableCheck-container--title">
          {t('deviceUnavaibakleCheck.title')}
        </p>

        <ImageMobileSvg />

        <p className="DeviceUnavailableCheck-container--subtitle1">
          {t('deviceUnavaibakleCheck.subtitle1')}
        </p>
        <p className="DeviceUnavailableCheck-container--subtitle2">
          {t('deviceUnavaibakleCheck.subtitle2')}
        </p>
      </div>
    </div>
  );
};
