export const GROUPERS = `
query groupers(
    $filter: FilterGrouperInput,
    $orderBy: GrouperOrderInput
  ){
    groupers(
      filter:$filter, 
      orderBy:$orderBy
    ){
      __typename
      ... on Grouper{
        id
        name
        translation_key
        tab_title
        translation_key_tab_title
      }
      ... on ResultError {
        status_code
        message
        message_translation_key
      }
    }
  }
`;
