import { useContext } from 'react';
import { ContextApp } from '../../../../../../contexts/ContextApp';
import useGetDetails from '../../hooks/useGetDetails';
import CollapseWithTable from '../CollapseWithTable/CollapseWithTable';

import './DetailsRender.less';
import { IReceiptDetail } from '../../../../../../interfaces/Receipt';
import { formatValueOnLoad } from '../../../../../../shared/formatValueOnLoad';

interface IDetailsRenderProps {
  data: IReceiptDetail[] | undefined;
  total: number | undefined | null;
  headerId: number | undefined;
  tenantId: number | undefined;
}

const DetailsRender = (props: IDetailsRenderProps) => {
  const { data, total, headerId, tenantId } = props;

  const {
    detailItemsColumn,
    discountDetailsColumn,
    taxDetailsColumn,
    expandedRowRender,
    detailItemsData,
    subTotalDetailItems,
    discountDetails,
    taxDetails,
    subTotalDiscountDetails,
    subTotalTaxDetails,
  } = useGetDetails({
    data,
    headerId,
    tenantId,
  });

  const { t } = useContext(ContextApp);

  return (
    <div className="details-render">
      <div className="details-render__total">
        {/* //TODO: se oculta hasta decidir si se muestra o no */}
        {/*  //TODO: agregar valores corretos cuando este el servicio */}
        {/* <p>
          {t('ReceiptTray.modalViewReceipt.detailsTab.netUntaxed')}
          <span>: $ -</span>
        </p> */}
        {/*  //TODO: agregar valores corretos cuando este el servicio */}
        {/* <p>
          {t('ReceiptTray.modalViewReceipt.detailsTab.netTaxed')}
          <span>: $ 188.092,80</span>
        </p> */}
        {/*  //TODO: agregar valores corretos cuando este el servicio */}
        <p>
          <b>
            {t('ReceiptTray.modalViewReceipt.detailsTab.total')}
            <span>
              : {' '}{total ? formatValueOnLoad(total) : total?.toLocaleString('es')}
            </span>
          </b>
        </p>
      </div>

      <CollapseWithTable
        collapseTitle={t('ReceiptTray.modalViewReceipt.detailsTab.itemDetails')}
        columns={detailItemsColumn}
        expandedRowRender={expandedRowRender}
        data={detailItemsData}
        subtotal={subTotalDetailItems}
        subtitle={t(
          'ReceiptTray.modalViewReceipt.detailsTab.subtotalWithoutTax',
        )}
      />
      <CollapseWithTable
        collapseTitle={t('ReceiptTray.modalViewReceipt.detailsTab.taxDetails')}
        columns={taxDetailsColumn}
        data={taxDetails}
        subtotal={subTotalTaxDetails}
        subtitle={t('ReceiptTray.modalViewReceipt.detailsTab.taxTotal')}
        defaultOpen={false}
      />
      <CollapseWithTable
        collapseTitle={t(
          'ReceiptTray.modalViewReceipt.detailsTab.discountDetails',
        )}
        columns={discountDetailsColumn}
        data={discountDetails}
        subtotal={subTotalDiscountDetails}
        subtitle={t('ReceiptTray.modalViewReceipt.detailsTab.discountTotal')}
        defaultOpen={false}
      />
    </div>
  );
};

export default DetailsRender;
