import amount from './type/amount';
import date from './type/date';
import number from './type/number';
import percentage from './type/percentage';
import taxTypeId from './type/taxTypeId';
import tax_number from './type/tax_number';

interface IValidations {
  valueToValidate: string;
  type: string | undefined;
}

export const validations = ({ valueToValidate, type }: IValidations) => {
  switch (type) {
    case 'date':
      return date(valueToValidate);
    case 'number':
      return number(valueToValidate);
    case 'amount':
      return amount(valueToValidate);
    case 'negative_amount':
      let amountFormated = amount(valueToValidate);
      return !amountFormated.includes('-') ? '-' + amountFormated : amountFormated;
    case 'string':
      return valueToValidate;
    case 'tax_type_id':
      return taxTypeId(valueToValidate);
    case 'receipt_letter':
      return valueToValidate;
    case 'tax_number':
      return tax_number(valueToValidate);
    case 'percentage':
      return percentage(valueToValidate);
    case 'email':
      return valueToValidate;

    default:
      return valueToValidate;
  }
};
export default validations;
