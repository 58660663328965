import './Indicators.less';
import { ContextApp } from '../../contexts/ContextApp';
import { Button, Col, DatePicker, Divider, Form, Row } from 'antd';
import { useContext, useEffect, useState } from 'react';
import MomentTimezoneService from '../../services/moment-timezone/MomentTimezoneService';
import { CardIndicator } from '../../components/Indicators/CardIndicator';
import { CardStatisticsEntities } from '../../components/Indicators/CardStatisticsEntities';
import { FileTextOutlined, UserOutlined } from '@ant-design/icons';
import GraphqlService from '../../services/graphql/GraphqlService';
import { CustomMessage } from '../../hooks';
import { IUserCount } from '../../interfaces';
import { PageLoading } from '@ant-design/pro-layout';
import { IReceiptHeaderToIndicator } from '../../interfaces/Receipt';
import moment from 'moment';
import { Authorization } from '../../shared';
import { EnumsValues } from '../../enums/EnumsValues';
import { useForm } from 'antd/es/form/Form';
import { IGetReceiptHeaderToIndicatorArgs } from '../../interfaces/Indicators';

const DEFAULT_RANGE_OF_DATES = 30;

const DEFAULT_OPERATION_DATE_FROM = new Date(
  moment().subtract(DEFAULT_RANGE_OF_DATES, 'd').toDate(),
);
const DEFAULT_OPERATION_DATE_TO = new Date();

export const Indicators = () => {
  const [form] = useForm();
  const { t, selectedTenantId, functions } = useContext(ContextApp);
  const [userCountToIndicator, setUserCountToIndicator] =
    useState<IUserCount>();
  const [receiptHeaderToIndicator, setReceiptHeaderToIndicator] = useState<
    IReceiptHeaderToIndicator[]
  >([]);
  const [totalOfReceiptsHeader, setTotalOfReceiptsHeader] = useState<number>(0);
  const [
    quantityOfReceiptsHeaderIncomplete,
    setQuantityOfReceiptsHeaderIncomplete,
  ] = useState<number>(0);
  const [
    quantityOfReceiptsHeaderFinished,
    setQuantityOfReceiptsHeaderFinished,
  ] = useState<number>(0);
  const [quantityOfReceiptsHeaderError, setQuantityOfReceiptsHeaderError] =
    useState<number>(0);

  const [loading, setLoading] = useState<boolean>(true);

  const { getUserDateFormat } = MomentTimezoneService();
  const { customRequest, Query } = GraphqlService();
  const { showMessageError } = CustomMessage();
  const getUserCountToIndicator = async () => {
    if (!selectedTenantId) return;
    try {
      const data: IUserCount = await customRequest({
        query: Query.userCountToIndicator,
        variables: {
          filter: {
            tenants: [selectedTenantId],
          },
        },
      });
      setUserCountToIndicator(data);
    } catch (error: any) {
      showMessageError({
        context: 'Indicators.getUserCountToIndicator.1',
        error,
      });
    }
  };

  const getReceiptHeaderToIndicator = async (
    values?: IGetReceiptHeaderToIndicatorArgs,
  ) => {
    if (!selectedTenantId) return;
    try {
      const data: IReceiptHeaderToIndicator[] = await customRequest({
        query: Query.receiptHeaderToIndicator,
        variables: {
          filter: {
            tenants: [selectedTenantId],
            operation_date_from: values
              ? values.operation_date_from
              : DEFAULT_OPERATION_DATE_FROM.getTime(),
            operation_date_to: values
              ? values.operation_date_to
              : DEFAULT_OPERATION_DATE_TO.getTime(),
          },
        },
      });
      setReceiptHeaderToIndicator([...data]);
    } catch (error: any) {
      showMessageError({
        context: 'Indicators.getUserCountToIndicator.1',
        error,
      });
    }
  };

  const getAll = async (values?: IGetReceiptHeaderToIndicatorArgs) => {
    if (!values) return;
    setLoading(true);
    Promise.all([
      getUserCountToIndicator(),
      getReceiptHeaderToIndicator({ ...values }),
    ]).finally(() => setLoading(false));
  };

  useEffect(() => {
    Promise.all([
      getUserCountToIndicator(),
      getReceiptHeaderToIndicator(),
    ]).finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (receiptHeaderToIndicator.length) {
      let summationReceiptHeaders = 0;
      receiptHeaderToIndicator.forEach((element) => {
        summationReceiptHeaders = summationReceiptHeaders + element.count;
      });
      setTotalOfReceiptsHeader(summationReceiptHeaders);
      setQuantityOfReceiptsHeaderFinished(
        receiptHeaderToIndicator.find(
          (ele) => ele.status_id === EnumsValues.ReceiptStatus.Finished,
        )?.count || 0,
      );
      setQuantityOfReceiptsHeaderError(
        receiptHeaderToIndicator.find(
          (ele) => ele.status_id === EnumsValues.ReceiptStatus.Error,
        )?.count || 0,
      );
      setQuantityOfReceiptsHeaderIncomplete(
        receiptHeaderToIndicator.find(
          (ele) => ele.status_id === EnumsValues.ReceiptStatus.Incomplete,
        )?.count || 0,
      );
    }
  }, [receiptHeaderToIndicator]);

  return loading ? (
    <PageLoading />
  ) : (
    <div className="indicators">
      {Authorization.security(
        functions,
        EnumsValues.Functions.GetReceiptHeaderToIndicator,
      ) && (
        <>
          <div className="indicators__header">
            <Form
              form={form}
              layout="inline"
              className="indicators__header__form-filter"
              onFinish={(values) => {
                const { dates_range } = values;
                if (!dates_range) return;
                getAll({
                  operation_date_from: dates_range[0].toDate().getTime(),
                  operation_date_to: dates_range[1].toDate().getTime(),
                });
              }}
            >
              <Form.Item
                name={'dates_range'}
                label={t('entity.dateRange')}
                initialValue={[
                  moment(DEFAULT_OPERATION_DATE_FROM),
                  moment(DEFAULT_OPERATION_DATE_TO),
                ]}
                rules={[
                  {
                    required: true,
                    message: t('Indicators.dateRangeRequired'),
                  },
                ]}
              >
                <DatePicker.RangePicker
                  placeholder={[t('entity.dateFrom'), t('entity.dateTo')]}
                  allowClear={false}
                  format={getUserDateFormat()}
                />
              </Form.Item>
              <Divider
                type="vertical"
                orientation="center"
                className="indicators__header__form-filter__divider"
              />
              <Form.Item>
                <Button htmlType="submit">{t('action.apply')}</Button>
              </Form.Item>
            </Form>
          </div>
          <Divider className="indicators__divider" />
        </>
      )}
      <div className="indicators__content">
        {Authorization.security(
          functions,
          EnumsValues.Functions.GetReceiptHeaderToIndicator,
        ) && (
          <>
            {receiptHeaderToIndicator && (
              <>
                <Row gutter={[26, 26]}>
                  <Col span={8}>
                    <div className="indicators__content__container-cards">
                      <CardIndicator
                        quantity={quantityOfReceiptsHeaderFinished}
                        percentage={
                          totalOfReceiptsHeader > 0
                            ? (quantityOfReceiptsHeaderFinished * 100) /
                              totalOfReceiptsHeader
                            : 0
                        }
                        variation={30}
                        type="done"
                      />
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className="indicators__content__container-cards">
                      <CardIndicator
                        quantity={quantityOfReceiptsHeaderIncomplete}
                        percentage={
                          totalOfReceiptsHeader > 0
                            ? (quantityOfReceiptsHeaderIncomplete * 100) /
                              totalOfReceiptsHeader
                            : 0
                        }
                        variation={0}
                        type="incomplete"
                      />
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className="indicators__content__container-cards">
                      <CardIndicator
                        quantity={quantityOfReceiptsHeaderError}
                        percentage={
                          totalOfReceiptsHeader > 0
                            ? (quantityOfReceiptsHeaderError * 100) /
                              totalOfReceiptsHeader
                            : 0
                        }
                        variation={-10}
                        type="error"
                      />
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
        {Authorization.security(
          functions,
          EnumsValues.Functions.GetReceiptHeaderToIndicator,
        ) && (
          <>
            {userCountToIndicator && (
              <>
                <Row
                  gutter={[26, 26]}
                  className="indicators__content__container-cards-statistics-entities"
                >
                  <Col
                    span={12}
                    className="indicators__content__container-cards-statistics-entities__col-cards"
                  >
                    <CardStatisticsEntities
                      icon={<UserOutlined />}
                      title={t('entity.usersActive')}
                      frequency={userCountToIndicator.count}
                    />
                  </Col>
                  <Col
                    span={12}
                    className="indicators__content__container-cards-statistics-entities__col-cards"
                  >
                    <CardStatisticsEntities
                      icon={<FileTextOutlined />}
                      title={t('entity.totalReceipts')}
                      frequency={totalOfReceiptsHeader}
                    />
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
