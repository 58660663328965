import { LeftOutlined, RightOutlined } from '@ant-design/icons';

interface IChangePdfPageProps {
  disabled: boolean;
  onClick: () => void;
  icon: 'left' | 'right';
}

const ChangePdfPage = (props: IChangePdfPageProps) => {
  const { onClick, disabled, icon } = props;
  return (
    <span style={{ paddingTop: '4px' }}>
      <button
        onClick={() => {
          onClick();
        }}
        disabled={disabled}
        style={{ background: 'transparent' }}
      >
        {icon === 'left' ? (
          <LeftOutlined className="receipt-page-icon" />
        ) : (
          <RightOutlined className="receipt-page-icon" />
        )}
      </button>
    </span>
  );
};
export default ChangePdfPage;
