import { Form, Input, Modal, Tooltip } from 'antd';

import './ConceptRuleSubtableParticular.less';

import {
  IConceptRuleConditionalType,
  IConceptRuleGrouped,
} from '../../interfaces/Concept';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ContextApp } from '../../contexts/ContextApp';
import ProTable, { ActionType } from '@ant-design/pro-table';
import { ExportableColumn } from '../../shared/Exporter';
import { SaveForm } from '../../components/common/ABM';
import { EnumsValues } from '../../enums/EnumsValues';
import GraphqlService from '../../services/graphql/GraphqlService';
import { Mutation } from '../../services/graphql/mutation';
import { notificationContext } from '../../contexts/NotificationContext';
import { CustomMessage } from '../../hooks';
export interface IReceiptDetailSubTableProps {
  conceptRuleGrouped: IConceptRuleGrouped;
  conceptMasterId?: number;
  onChange?: () => void;
}

export interface IGrouperCuitTag {
  id: number;
  parsedDataElementCuit: IConceptRuleConditionalType;
  parsedDataElementTag: IConceptRuleConditionalType;
  tag: string;
  cuit: string;
}

export default function ConceptRuleSubtableParticular(
  props: IReceiptDetailSubTableProps,
) {
  const { t, selectedTenantId } = useContext(ContextApp);
  const { conceptRuleGrouped, conceptMasterId, onChange } = props;
  const { customRequest } = GraphqlService();
  const { openNotification } = useContext(notificationContext);
  const { getErrorMessage } = CustomMessage();

  const actionRef = useRef<ActionType>();

  const [createModalVisible, setCreateModalVisible] = useState<boolean>(false);
  const [updateModalVisible, handleUpdateModalVisible] =
    useState<boolean>(false);
  const [newTag, setNewTag] = useState<string>('');
  const [editForm, setEditFormValues] =
    useState<IGrouperCuitTag | undefined>(undefined);

  const [formLoading, setFormLoading] = useState(false);
  const [conceptRuleCuitsGrouped, setConceptRuleCuitsGrouped] = useState<
    IGrouperCuitTag[]
  >([]);

  const agruparCuits = async (data: IConceptRuleGrouped) => {
    setNewTag(data.tag);
    const parsedData: IGrouperCuitTag[] = [];
    for (let index = 0; index < data.particularRule.length; index++) {
      const particularRule = data.particularRule[index];
      const parsedDataElement =
        particularRule.concept_rule_conditional_type.find(
          (item) => item.concept_conditional_type_id === 2,
        );

      const parsedDataElementTag =
        particularRule.concept_rule_conditional_type.find(
          (item) => item.concept_conditional_type_id === 1,
        );
      if (!parsedDataElement || !parsedDataElementTag) continue;

      parsedData.push({
        id: index,
        parsedDataElementCuit: parsedDataElement,
        parsedDataElementTag: parsedDataElementTag,
        tag: parsedDataElementTag.value,
        cuit: parsedDataElement.value,
      });
    }

    setConceptRuleCuitsGrouped(parsedData);
  };

  const getGroupedData = async (conceptRuleGrouped: IConceptRuleGrouped) => {
    await agruparCuits(conceptRuleGrouped);
  };

  useEffect(() => {
    getGroupedData(conceptRuleGrouped);
  }, [conceptRuleGrouped]);

  const createConceptCuit = async (value: any) => {
    setFormLoading(true);
    try {
      await customRequest({
        mutation: Mutation.createConceptRule,
        variables: {
          input: {
            tenant_id: selectedTenantId,
            concept_master_id: conceptMasterId,
            concept_rule_type_id: EnumsValues.ConceptRuleType.particular,
            concept_rule_conditional_type: [
              {
                concept_conditional_type_id:
                  EnumsValues.ConceptConditionalType.etiquetaImpuesto,
                value: conceptRuleGrouped.tag,
              },
              {
                concept_conditional_type_id:
                  EnumsValues.ConceptConditionalType.cuitImpuesto,
                value: value.cuit,
              },
            ],
          },
        },
      });
      setCreateModalVisible(false);
      if (onChange) {
        onChange();
      }
      await openNotification({
        msj: t('ConceptRule.subTable.message.createCUITSuccess'),
        type: 'success',
      });

      if (actionRef.current) {
        actionRef.current.reload();
      }
    } catch (error: any) {
      if (error.message) {
        setFormLoading(false);
        openNotification({
          msj: getErrorMessage(error),
          type: 'error',
        });
      }
    }
    setFormLoading(false);
  };

  const updateConcept = async (value: any) => {
    if (!editForm) return;
    setFormLoading(true);

    try {
      await customRequest({
        mutation: Mutation.updateConceptRule,
        variables: {
          input: {
            id: editForm.parsedDataElementCuit?.concept_rule_id,
            tenant_id: selectedTenantId,
            concept_master_id: conceptMasterId,
            concept_rule_type_id: EnumsValues.ConceptRuleType.particular,
            concept_rule_status_id: 1,
            concept_rule_conditional_type: [
              {
                concept_conditional_type_id:
                  EnumsValues.ConceptConditionalType.etiquetaImpuesto,
                value: value.tag,
              },
              {
                concept_conditional_type_id:
                  EnumsValues.ConceptConditionalType.cuitImpuesto,
                value: value.cuit,
              },
            ],
          },
        },
      });
      if (onChange) {
        onChange();
      }
      await openNotification({
        msj: t('ConceptRule.subTable.message.updateCUITSuccess'),
        type: 'success',
      });

      handleUpdateModalVisible(false);
      if (actionRef.current) {
        actionRef.current.reload();
      }
    } catch (error: any) {
      setFormLoading(false);
      if (error.status_code && error.message) {
        openNotification({
          msj: getErrorMessage(error),
          type: 'error',
        });
      }
    }
    handleUpdateModalVisible(false);
    setEditFormValues(undefined);
    setFormLoading(false);
  };

  const deleteConcept = (value: IGrouperCuitTag) => {
    Modal.confirm({
      content: (
        <>
          <div>
            {t('ConceptRule.subTable.message.askDeleteCUIT', {
              cuitName: value.cuit,
            })}
          </div>
        </>
      ),
      cancelText: t('action.cancel'),
      okText: t('action.accept'),
      onOk: () => {
        deleteConfirmedConcept(value.parsedDataElementCuit.concept_rule_id);
      },
      okButtonProps:{className:'save-btn'}
    });
  };

  const deleteConfirmedConcept = async (id: number) => {
    try {
      await customRequest({
        mutation: Mutation.deleteConceptRule,
        variables: {
          id: id,
        },
      });

      if (onChange) {
        onChange();
      }
      await openNotification({
        msj: t('ConceptRule.subTable.message.deleteCUITSuccess'),
        type: 'success',
      });

      if (actionRef.current) {
        actionRef.current.reload();
      }
    } catch (error: any) {
      if (error.message) {
        setFormLoading(false);
        openNotification({
          msj: getErrorMessage(error),
          type: 'error',
        });
      }
    }
  };

  const taxNumberValidator = (_: any, value: string) => {
    const expresionRegular = /^[0-9]+$/;
    if (value) {
      if (expresionRegular.test(value.toString())) {
        return Promise.resolve();
      }
      return Promise.reject(
        t('ReviewReceipt.messageError.taxNumberFormatError'),
      );
    } else {
      return Promise.resolve();
    }
  };

  const columns = useCallback(
    (
      _editMode?: boolean,
      _createMode?: boolean,
    ): ExportableColumn<IGrouperCuitTag>[] => [
      {
        title: (
          <div className='subtable-title-col' >
            <p className='subtable-title'>
              {t('ConceptRule.subTable.particularRules')}
            </p>
            <p className='subtable-title-description' >
              {t('ConceptRule.subTable.particularRulesDescription')}
            </p>
          </div>
        ),
        dataIndex: 'value_title',
        key: 'value_title',
        export: true,
        render: (_, record) => <div>{record.parsedDataElementCuit.value}</div>,
        hideInSearch: true,
        hideInForm: true,
      },
      {
        title: t('entity.tag'),
        dataIndex: 'tag',
        key: 'tag',
        export: true,
        renderFormItem: () => <Input disabled />,
        hideInSearch: true,
        hideInTable: true,
      },
      {
        title: t('entity.CUIT'),
        dataIndex: 'cuit',
        key: 'cuit',
        export: true,
        renderFormItem: () => (
          <Form.Item
            name="cuit"
            rules={[
              {
                required: true,
                message: t('ConceptRule.subTable.message.cuitRequired'),
              },
              {
                max: 11,
                min: 11,
                message: t(
                  'ReviewReceipt.messageError.senderTaxNumberMaxLength',
                ),
              },
              {
                validator: taxNumberValidator,
              },
            ]}
          >
            <Input
              placeholder={t('ConceptRule.subTable.placeholder.enterCUIT')}
            />
          </Form.Item>
        ),
        hideInSearch: true,
        hideInTable: true,
      },
      {
        export: true,
        renderFormItem: () => (
          <div className="modal-concept-info">
            <div className="modal-concept-info-icon" >
              <span className="material-symbols-outlined" translate='no'>info</span>
            </div>
            <div className="modal-concept-info-text">
              <p className="modal-concept-info-text-description">
                {t('ConceptRule.subTable.modalParticularRulesDescription1')}
              </p>
              <p className="modal-concept-info-text-description">
                {t('ConceptRule.subTable.modalParticularRulesDescription2')}
              </p>
            </div>
          </div>
        ),
        hideInSearch: true,
        hideInTable: true,
      },
      {
        title: (
          <span className="add-cuit">
            <button
              type="button"
              className="receipt-back-link"
              onClick={() => {
                setCreateModalVisible(true);
              }}
            >
              <span className="material-symbols-outlined conceptRule-add-icon" translate='no'>add</span>
              {t('ConceptRule.subTable.addCUIT')}
            </button>
          </span>
        ),
        dataIndex: 'option',
        valueType: 'option',
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        export: false,
        align: 'right',
        render: (_, record) => (
          <>
            <Tooltip
              key="edit_setting"
              title={t('ConceptRule.editConceptRuleCUIT')}
            >
              <span
              translate='no'
                className="material-symbols-outlined pointer conceptRule-action-icon"
                onClick={() => {
                  setEditFormValues(record);
                  handleUpdateModalVisible(true);
                }}
              >
                edit
              </span>
            </Tooltip>
            <Tooltip
              key="remove_setting"
              title={t('ConceptRule.removeConceptRuleCUIT')}
            >
               <span
               translate='no'
                className="material-symbols-outlined pointer conceptRule-action-icon"
                onClick={() => {
                  deleteConcept(record);
                }}
              >
                delete
              </span>
            </Tooltip>
          </>
        ),
      },
    ],
    [conceptRuleCuitsGrouped],
  );

  return (
    <>
      <ProTable
        className="protable-concept-rule-subtable"
        columns={columns()}
        dataSource={conceptRuleCuitsGrouped}
        pagination={false}
        actionRef={actionRef}
        rowKey="id"
        search={false}
        toolBarRender={false}
      />

      <SaveForm
      style={{minWidth: '448px'}}
        loading={formLoading}
        title={t('ConceptRule.subTable.addCUIT')}
        className="modal-concept"
        onCancel={() => {
          setCreateModalVisible(false);
        }}
        modalVisible={createModalVisible}
        values={{ tag: newTag }}
        onOk={(value) => {
          createConceptCuit(value);
        }}
        columns={columns(false, true)}
        notIgnoreFalsyValues={true}
        saveFormFooterIcon={{
          reset: <></>,
        }}
        buttonCancel={true}
        buttonReset={false}
      />
      {editForm && (
        <SaveForm
        style={{minWidth: '448px'}}
          loading={formLoading}
          className="modal-concept"
          title={t('ConceptRule.subTable.editCUIT')}
          modalVisible={updateModalVisible}
          values={{
            ...editForm,
          }}
          columns={columns(true)}
          onOk={(value) => {
            updateConcept(value);
          }}
          onCancel={() => {
            handleUpdateModalVisible(false);
            setEditFormValues(undefined);
          }}
          notIgnoreFalsyValues={true}
          saveFormFooterIcon={{
            reset: <></>,
          }}
          buttonCancel={true}
          buttonReset={false}
        />
      )}
    </>
  );
}
