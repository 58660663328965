import { Descriptions } from 'antd';
import { DataType } from '../../ModalViewReceipt';
import './GeneralTabContent.less';

const GeneralTabContent = ({
  title,
  data,
}: {
  title: string;
  data: DataType[];
}) => {
  return (
    <Descriptions
      title={title}
      layout="horizontal"
      size="middle"
      column={1}
      bordered
    >
      {data.map((i) => (
        <Descriptions.Item key={i.label} label={i.label}>
          {i.value}
        </Descriptions.Item>
      ))}
    </Descriptions>
  );
};

export default GeneralTabContent;
