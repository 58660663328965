export const UPDATE_EMAIL_CONFIGURATION = `
mutation updateEmailConfiguration($input: UpdateTenantEmailReceiptInput!) {
    updateEmailConfiguration(input: $input) {
    __typename
    ... on  TenantEmailReceipt{
      tenant_id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;