import { Layout, Tag } from 'antd';
import { useContext, useLayoutEffect, useEffect, useState } from 'react';
import { renderRoutes } from 'react-router-config';
import ExpiredSession from '../../components/auth/ExpiredSession/ExpiredSession';
import ExceptionManager from '../../components/common/ExceptionManager';
import { ContextApp } from '../../contexts/ContextApp';
import './WizardLayout.less';
import { isPlatform, NavContext } from '@ionic/react';
import moment from 'moment';
import { Redirect, useLocation } from 'react-router';
import ProfilePage from '../../pages/Profile/ProfilePage';
import { HeaderComponent } from '../registered/HeaderComponent';
import { SecondaryHeader } from '../registered/SecondaryHeader/SecondaryHeader';
import { TenantStatus } from '../../enums/EnumsValues';
import { ContextWizard } from '../../contexts/ContextWizard';
import { ITenant } from '../../interfaces/Tenant';
import GraphqlService from '../../services/graphql/GraphqlService';

const { Content } = Layout;

const ENVTagColor: Record<string, string> = {
  dev: 'orange',
  test: 'green',
  stage: '#87d068',
};

export const WizardLayout = ({ route }: any) => {
  const { user, sessionExpired, selectedTenantId } = useContext(ContextApp);
  const { navigate } = useContext(NavContext);
  const { customRequest, Query } = GraphqlService();
  const location = useLocation();
  const [currentStepWizard, setCurrentStepWizard] = useState(0);

  const nextStepWizard = () => {
    setCurrentStepWizard((lastStep) => lastStep + 1);
  };

  const prevStepWizard = () => {
    setCurrentStepWizard((lastStep) => lastStep - 1);
  };

  const getTenant = async (tenant_id: number) => {
    try {
      const data: ITenant = await customRequest({
        query: Query.tenant,
        variables: {
          input: {
            id: tenant_id,
          },
        },
      });
      return data;
    } catch {
      // Intentional
    }
  };

  const checkPackPurchaseStep = async (tenant_id: number) => {
    const data = await getTenant(tenant_id);
    if (data) {
      if (
        data.receipts_quota_tenant &&
        data.receipts_quota_tenant.receipts_quota > 0 &&
        (!data.receipts_quota_tenant.quota_expiration_date ||
          moment(data.receipts_quota_tenant.quota_expiration_date) >
            moment(new Date()))
      ) {
        return true;
      }
    }
  };

  const checkPurchaseNavigation = async (tenant_id: number, step: number) => {
    const check = await checkPackPurchaseStep(tenant_id);
    if (check) {
      setCurrentStepWizard(step);
    }
  };

  const checkIntegrationNavigation = async (
    tenant_id: number,
    step: number,
  ) => {
    const tenant = await getTenant(tenant_id);
    if (tenant?.tenant_status_id === TenantStatus.Activa) {
      setCurrentStepWizard(step);
    } else {
      setCurrentStepWizard(0);
    }
  };

  useEffect(() => {
    switch (location.pathname) {
      case '/wizard/packs':
        setCurrentStepWizard(0);
        break;
      case '/wizard/integration':
        if (selectedTenantId) {
          checkPurchaseNavigation(selectedTenantId, 1);
        }
        break;
      case '/wizard/billingForm':
        if (selectedTenantId) {
          checkIntegrationNavigation(selectedTenantId, 2);
        }
        break;
      default:
        break;
    }
  }, [location]);

  useEffect(() => {
    switch (currentStepWizard) {
      case 1:
        navigate('/wizard/integration');
        break;
      case 2:
        navigate('/wizard/billingForm');
        break;

      default:
        break;
    }
  }, [currentStepWizard]);

  useLayoutEffect(() => {
    if (sessionExpired) {
      ExpiredSession();
    }
  }, [sessionExpired]);

  useEffect(() => {
    moment.tz.setDefault(
      user?.profile?.timezone
        ? user?.profile?.timezone
        : process.env.REACT_APP_TIMEZONE
        ? process.env.REACT_APP_TIMEZONE
        : '',
    );
  }, [user]);

  if (!user) {
    return <Redirect to="/login" />;
  }

  return (
    <ContextWizard.Provider
      value={{
        nextStepWizard,
        currentStepWizard,
        checkPackPurchaseStep,
        prevStepWizard,
      }}
    >
      <Layout className="WizardLayout">
        {process.env?.REACT_APP_ENV && ENVTagColor[process.env.REACT_APP_ENV] && (
          <Tag
            className="env-tag"
            color={ENVTagColor[process.env.REACT_APP_ENV]}
          >
            {process.env.REACT_APP_ENV}
          </Tag>
        )}

        <Layout
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}
        >
          <div className="backdrop-content"></div>
          <HeaderComponent />
          {isPlatform('mobile') && window.innerWidth < 992 ? (
            <SecondaryHeader />
          ) : null}
          <Content className="site-layout">
            {!sessionExpired ? (
              user?.profile_id ? (
                <ExceptionManager>
                  {renderRoutes(route.routes)}
                </ExceptionManager>
              ) : (
                <ProfilePage />
              )
            ) : (
              <ExpiredSession />
            )}
          </Content>
        </Layout>
      </Layout>
    </ContextWizard.Provider>
  );
};
