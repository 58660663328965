import { Button, ButtonProps } from 'antd';

const ButtonTertiary = (props: ButtonProps) => {
  return (
    <Button type="text" {...props}>
      {props.children}
    </Button>
  );
};

export default ButtonTertiary;
