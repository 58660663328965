import { useContext } from 'react';
import { ContextApp } from '../../../../contexts/ContextApp';
import './AutomationControlCard.less';

interface AutomationControlCardProps {
  isRecommended: boolean;
  value: number;
  currentValue: number;
  translation: string;
  onClick: () => void;
  children: JSX.Element;
}
const AutomationControlCard = (props: AutomationControlCardProps) => {
  const { isRecommended, value, currentValue, translation, onClick } = props;
  const { t } = useContext(ContextApp);
  return (
    <div
      className={`automation-control__card ${
        currentValue === value && 'automation-control__card--active'
      }`}
      onClick={onClick}
    >
      <div className="automation-control__card__content">
        <div className="automation-control__card__radio">{props.children}</div>
        <div className="automation-control__card__header">
          <h5 className="automation-control__card__title">
            {t(
              `settingsPage.reviewerConfiguration.${translation}.title` as never,
            )}
          </h5>
          {isRecommended && (
            <div className="automation-control__card__badge">Recomendado</div>
          )}
        </div>
      </div>
      <p className="automation-control__card__text">
        {t(
          `settingsPage.reviewerConfiguration.${translation}.paragraph` as never,
        )}
      </p>
    </div>
  );
};

export default AutomationControlCard;
