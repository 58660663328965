import { Form, Input, Modal } from 'antd';
import { useContext } from 'react';
import { ContextApp } from '../../../../contexts/ContextApp';
import './ExportSendEmail.less';
import { EnumsValues } from '../../../../enums/EnumsValues';

interface ExportSendEmailProps {
  open: boolean;
  onCancel?: () => void;
  onFinish?: (values: any) => void;
}

export const ExportSendEmail = (props: ExportSendEmailProps) => {
  const { open, onCancel, onFinish } = props;
  const { t } = useContext(ContextApp);

  const [form] = Form.useForm();

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      onOk={form.submit}
      okText={t('action.send')}
      title={t('ReceiptTray.sendReceiptEmail.title')}
      className="export-send-email-modal"
    >
      <Form
        name="sendEmail"
        form={form}
        onFinish={onFinish}
        initialValues={{ format: 'xlsx' }}
      >
        <p>{t('ReceiptTray.sendReceiptEmail.instructions')}</p>
        <Form.Item
          name="email"
          label={t('ReceiptTray.sendReceiptEmail.to')}
          normalize={(value) => value?.trim()}
          rules={[
            {
              required: true,
              message: t('error.abm.emailGroupRequired'),
            },
            {
              validator: (_, value) => {
                if (value) {
                  const multiEmailPattern = new RegExp(
                    EnumsValues.RegexPattern.MultiEmailPattern,
                    '',
                  );
                  if (!multiEmailPattern.test(value)) {
                    return Promise.reject(
                      new Error(t('error.abm.invalidEmailGroupFormat')),
                    );
                  }
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            type="text"
            aria-label="email"
            placeholder={t('ReceiptTray.sendReceiptEmail.to')}
            allowClear
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
