import { EnumsValues } from '../../../../enums/EnumsValues';
import {
  commaDecimalFormat,
  decimalWithoutInteger,
  dotDecimalFormat,
  extraDecimalWithComma,
  extraDecimalWithDot,
  extraDigitsWithDotOrCommaFormat,
  optionalCommaDecimalFormat,
  optionalDotDecimalFormat,
  contentWithDecimalOrDotOrComa,
  decimalWithOnlyDotsNotComma,
} from '../../regex/regexValues';

const number = (content: string): string => {
  if (!optionalCommaDecimalFormat.test(content)) {
    if (
      (content.indexOf(',') > EnumsValues.ConstNumbers.negativeOne ||
        content.indexOf('.') > EnumsValues.ConstNumbers.negativeOne) &&
      contentWithDecimalOrDotOrComa.test(content)
    ) {
      if (extraDecimalWithComma.test(content)) {
        content = content.replace(/\./g, '');
        content = content.replace(',', '.');
        content = Number(content).toFixed(3).toString();
        content = content.replace('.', ',');
        content = content.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      } else if (extraDecimalWithDot.test(content)) {
        content = content.replace(/,/g, '');
        content = Number(content).toFixed(3).toString();
        content = content.replace('.', ',');
        content = content.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      } else if (decimalWithOnlyDotsNotComma.test(content)) {
        content = content.replace(/\.(?=\d{2}$)/, ',');
      } else {
        content = content.replace(/,/g, '+');
        content = content.replace(/\./g, ',');
        content = content.replace(/\+/g, '.');
      }
    } else if (
      content.split(',').length - 1 > 1 &&
      contentWithDecimalOrDotOrComa.test(content)
    ) {
      content = content.replace(',', '.');
    } else if (commaDecimalFormat.test(content)) {
      content = content.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    } else if (dotDecimalFormat.test(content)) {
      content = content.replace('.', ',');
    } else if (optionalDotDecimalFormat.test(content)) {
      content = content.replace('.', ',');
      content = content.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    } else if (extraDigitsWithDotOrCommaFormat.test(content)) {
      if (content.indexOf(',') > -1) {
        content = content.replace(',', '.');
      }
      content = Number(content).toFixed(3).toString();
      content = content.replace('.', ',');
      content = content.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    } else if (decimalWithoutInteger.test(content)) {
      content = content.replace(
        decimalWithoutInteger,
        (match: string) => `,${match.substring(1)}`,
      );
    }
  }
  return content;
};

export default number;
