import { PageLoading } from '@ant-design/pro-layout';
import { Suspense } from 'react';
import { renderRoutes } from 'react-router-config';
import ExceptionManager from '../../components/common/ExceptionManager';
import './index.less';

const TwoFALayout = ({ route }: any) => {
  return (
    <div
      className="TwoFALayout"
    >
      {/* Aca se puede crear elementos para todas las paginas*/}
      <div className="TwoFALayoutTitle">
        <img src="assets/icon/logo_braulio.png" alt="Braulio" />
      </div>
      {/* child routes won't render without this */}
      <div className="TwoFALayoutContent">
        <Suspense fallback={<PageLoading />}>
          <ExceptionManager>{renderRoutes(route.routes)}</ExceptionManager>{' '}
        </Suspense>
      </div>
      <div className="TwoFALayoutFooter"></div>
    </div>
  );
};

export default TwoFALayout;
