import { ActionType } from '@ant-design/pro-table';
import { Button } from 'antd';
import { useContext, useState } from 'react';
import { ContextApp } from '../../../contexts/ContextApp';
import { CustomMessage } from '../../../hooks';
import useNotification from '../../../hooks/useNotification';
import GraphqlService from '../../../services/graphql/GraphqlService';

import './ReprocessCard.less';
import { notificationContext } from '../../../contexts/NotificationContext';

interface IReprocessCardProps {
  icon: JSX.Element;
  title: string;
  description1: string;
  description2: string | JSX.Element;
  hideButton: boolean;
  tableActionRef: React.MutableRefObject<ActionType | undefined>;
}

export const ReprocessCard = (props: IReprocessCardProps) => {
  const {
    icon,
    title,
    description1,
    description2,
    hideButton,
    tableActionRef,
  } = props;

  const { openNotification } = useContext(notificationContext);
  const { getErrorMessage } = CustomMessage();
  const { t, selectedTenantId } = useContext(ContextApp);
  const { Mutation, customRequest } = GraphqlService();
  const [loading, setLoading] = useState<boolean>(false);

  const { refreshNotification } = useNotification({});

  const handleReprocess = async () => {
    setLoading(true);
    try {
      await customRequest({
        mutation: Mutation.reprocessIncompleteReceiptHeaders,
        variables: { tenant_id: selectedTenantId },
      });

      openNotification({
        type: 'success',
        msj: t('ReprocessCard.successfulReprocess'),
        context: 'ReprocessCard.handleReprocess.1',
      });

      refreshNotification();
      tableActionRef.current?.reload();
    } catch (error) {
      openNotification({
        type: 'error',
        msj: getErrorMessage(error),
        context: 'ReprocessCard.handleReprocess.2',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="ReprocessCard-container">
      <div className="ReprocessCard-container--information">
        {icon}
        <div>
          <p className="ReprocessCard-container--information--title">{title}</p>
          <p className="ReprocessCard-container--information--description">
            {description1}
          </p>
          <p className="ReprocessCard-container--information--description">
            {description2}
          </p>
        </div>
      </div>
      {!hideButton && (
        <Button
          type="default"
          onClick={handleReprocess}
          loading={loading}
          disabled={loading}
        >
          {t('action.reprocess')}
        </Button>
      )}
    </div>
  );
};
