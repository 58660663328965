import { ReviewerConfigurationActionTypes } from '../enum';
import {
  ReviewerConfigurationAction,
  ReviewerConfigurationState,
} from '../interface';

const actionHandlers: {
  [K in ReviewerConfigurationActionTypes]: (
    state: ReviewerConfigurationState,
    action: Extract<ReviewerConfigurationAction, { type: K }>,
  ) => ReviewerConfigurationState;
} = {
  [ReviewerConfigurationActionTypes.SET_REVIEWER_CONFIGURATION]: (
    _state,
    action,
  ) => {
    return action.payload;
  },

  [ReviewerConfigurationActionTypes.UPDATE_REVIEWER_CONFIGURATION]: (
    state,
    action,
  ) => {
    const [tenantConfiguration] =
      state.current.reviewer_configuration_type_tenant;

    const newState = {
      ...state.current,
      reviewer_configuration_type_tenant: [
        {
          ...tenantConfiguration,
          reviewer_configuration_type_option_id: action.payload,
        },
      ],
    };

    return {
      ...state,
      current: newState,
    };
  },

  [ReviewerConfigurationActionTypes.RESET_CONFIGURATION_TO_DEFAULT]: (
    state,
  ) => {
    return {
      ...state,
      current: {
        ...state.original,
        reviewer_configuration_type_tenant: [],
      },
    };
  },

  [ReviewerConfigurationActionTypes.RESET_CONFIGURATION_TO_PREVIOUS_STATE]: (
    state,
  ) => {
    return {
      ...state,
      current: state.original,
    };
  },
};

export const reviewerConfigurationReducer = (
  state: ReviewerConfigurationState,
  action: ReviewerConfigurationAction,
) => {
  const handler = actionHandlers[action.type];
  if (handler) {
    try {
      return handler(state, action as any);
    } catch (error) {
      console.log(`Error handling action ${action.type}:`, error);
      return state;
    }
  }
  return state;
};
