export const CREATE_USER = `
  mutation createUser(
    $input:  CreateUserInput!
  ) {
    createUser(
      input: $input
    ) {
      __typename
      ... on User {
        email
        id
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
`;

export const UPDATE_USER = `
mutation updateUser(
	$input: UpdateUserInput!,
  $inputProfile: UpdateProfileInput
  ) {
    updateUser(
      input: $input,
      inputProfile: $inputProfile,
    ) {
      __typename
      ... on User {
        id
        firstname
        lastname
        email
        profile_id
        profile{
          phone
          timezone
          date_format
          date_time_format
          language_id
        }
        profile_picture_id
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
`;

export const DELETE_USER = `
  mutation deleteUser($id: Int!) {
    deleteUser(id: $id) {
      __typename
      ... on User {
        id
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
`;

export const RESET_PASSWORD = `
  mutation resetPassword(
    $email: String,
  ){
    resetPassword(email:$email){
      __typename
      ...on RecoveryResult{
        result
      }
      ...on ResultError{
        status_code
        message
}
    }
  }
`;

export const SET_PASSWORD = `
  mutation setPassword(
    $token: String!,
    $password: String!,
  )
  {
    setPassword(
        token:$token, 
        password:$password
    ){
      __typename
      ...on RecoveryResult{
        result
      }
      ...on ResultError{
        status_code
        message
}
    }
  }
`;

export const UPSERT_USER_ROLE = `
  mutation upsertUserRole($input: UpsertUserRoleInput!){
  upsertUserRole(input:$input)
  {
    __typename
    ...on UserRole{
      id
      role {
        id
        name
      }
    }
    ...on ResultError {
      status_code
      message
    }
  }
}
`;

export const UPSERT_USER_FIREBASE = `
mutation upsertUserFirebase(
  $input: UpsertUserFirebaseInput!
){
  upsertUserFirebase(
    input:$input    
  ){
    __typename
    ...on User{
            uid
    }
    ...on ResultError{
      status_code
      message
    }    
  }
}
`;

export const CREATE_PROFILE_PICTURE = `
mutation createProfilePicture(
  $file:  Upload!
){
  createProfilePicture(
    file: $file,
  ){
    __typename
    ...on ProfilePicture{
      id
      filename
      mimetype
      encoding
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

export const UPDATE_PROFILE_PICTURE = `
mutation updateProfilePicture($file: Upload!){
  updateProfilePicture(
    file: $file,
  ){
    __typename
    ...on ProfilePicture{
      id
      filename
      mimetype
      encoding
      file
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;
export const DELETE_PROFILE_PICTURE = `
mutation deleteProfilePicture($id: Int!){
  deleteProfilePicture(
    id: $id,
  ){
    __typename
    ...on ProfilePicture{
      id
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

export const UPDATE_MY_PASSWORD = `
mutation updateMyPassword($input: UpdatePasswordInput!) {
  updateMyPassword(
    input: $input
  ) {
    __typename
    ... on User {
      id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const COMPLETE_REGISTER = `
mutation completeRegister($token: String!, $password:String){
  completeRegister(token:$token, password:$password){
    __typename
    ...on CompleteRegister{
      result
    }
    ...on ResultError{
      status_code
      message
    }
  }
}`;

export const RESEND_VALIDATE_TOKEN = `
mutation resendValidateToken($expired_token:String!, $is_email_update: Boolean){
  resendValidateToken(expired_token:$expired_token, is_email_update:$is_email_update){
    __typename
    ...on CompleteRegister{
			result
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

export const CREATE_USER_PUBLIC = `
mutation createUserPublic(
  $input:  CreateUserInput!
  $tenantName: String!
) {
  createUserPublic(
    input: $input
		tenantName: $tenantName    
  ) {
    __typename
    ... on User {
      id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const UPDATE_LAST_TENANT_ID_SELECTED = `
mutation updateLastTenantIdSelected($tenant_id: Int!) {
  updateLastTenantIdSelected(tenant_id: $tenant_id) {
    __typename
    ... on User {
      id
      last_tenant_id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const CREATE_USER_TENANT = `
  mutation createUserTenant(
    $input:  CreateUserInput!
    $tenant_id: Int!
    $role_id: Int!
    $inputProfile: CreateProfileInput
  ) {
    createUserTenant(
      input: $input
      tenant_id: $tenant_id
      role_id: $role_id
      inputProfile: $inputProfile
    ) {
      __typename
      ... on User {
        email
        id
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
`;

export const DELETE_USER_TENANT = `
  mutation deleteUserTenant(
    $user_id: Int!
    $tenant_id: Int!
  ) {
    deleteUserTenant(
      user_id: $user_id
      tenant_id: $tenant_id
    ) {
      __typename
      ... on ResponseMessage {
        status
        response
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
`;
