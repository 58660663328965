import { Card } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import './Status2FA.less';
import { useContext } from 'react';
import { ContextApp } from '../../../contexts/ContextApp';

interface IStatus2FA {
  status: boolean | undefined;
}

const Status2FA = ({ status }: IStatus2FA) => {
  const { t } = useContext(ContextApp);

  return (
    <>
      <Card
        size="small"
        title={`${t('entity.authentication')} 2FA`}
        className="status2fa__card"
      >
        {status ? (
          <>
            <p className="status2fa-texts">{t('entity.activated')}</p>
            <CheckCircleOutlined style={{ color: 'green' }} />
          </>
        ) : (
          <>
            <p className="status2fa-texts">{t('entity.deactivated')}</p>
            <CloseCircleOutlined style={{ color: 'red' }} />
          </>
        )}
      </Card>
    </>
  );
};

export default Status2FA;
