import { Upload, UploadProps } from 'antd';
import { useContext } from 'react';
import { ContextApp } from '../../../contexts/ContextApp';
import './UploadDragAndDrop.less';

const { Dragger } = Upload;

interface UploadDragAndDropProps {
  uploadProps: UploadProps;
  maxPagePerDocument: string;
}

export const UploadDragAndDrop = (props: UploadDragAndDropProps) => {
  const { uploadProps, maxPagePerDocument } = props;

  const { t } = useContext(ContextApp);

  const isDisabled = uploadProps.disabled;

  const ReceiptTrayDraggerStyles = {
    backgroundColor: isDisabled ? 'hsla(0, 0%, 67%, 0.3)' : '#e9e5f8',
    border: isDisabled ? '1px dashed #AAAAAA' : '1px dashed #ccbfff',
  };

  const UploadIconStyles = {
    color: isDisabled ? 'hsla(0, 0%, 67%, 1)' : '#03729F',
  };

  const UploadTextStyles = {
    color: isDisabled ? 'hsla(0, 0%, 67%, 1)' : '#03729F',
  };

  return (
    <Dragger
      {...uploadProps}
      className="receipt-tray-dragger"
      style={ReceiptTrayDraggerStyles}
    >
      <span
      translate='no'
        className="material-symbols-outlined ant-upload-drag-icon"
        style={UploadIconStyles}
      >
        cloud_upload
      </span>
      <div className="ant-upload-text" style={UploadTextStyles}>
        {t('ReceiptTray.dragReceipts')}
        <br></br>
        <p className="ant-upload-subtext">
          {t('ReceiptTray.dragReceiptsFormat', { maxPagePerDocument })}
        </p>
      </div>
    </Dragger>
  );
};
