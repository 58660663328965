export const GET_PENDING_RECEIPT = `
query getPendingReceipt($tenant_id: Int!, $receipt_name: String!) {
  getPendingReceipt(tenant_id: $tenant_id, receipt_name: $receipt_name) {
    __typename
    ... on BaseFile {
      filename
      mimetype
      encoding
      file
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const PENDING_RECEIPTS = `
query pendingReceipts($tenant_id: Int!) {
  pendingReceipts(tenant_id: $tenant_id) {
    __typename
    ... on FileItem {
      name
      isDirectory
      size
      etag
      lastModified
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const AVAILABLE_QUOTA = `
query availableQuota($tenant_id: Int!) {
  availableQuota(tenant_id: $tenant_id) {
    __typename
    ... on Count {
      count
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;
