import { PageLoading } from '@ant-design/pro-layout';
import { NavContext } from '@ionic/react';
import { Button, Form, Input } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router';
import { ContextApp } from '../../contexts/ContextApp';
import { EnumsValues } from '../../enums/EnumsValues';
import { CustomMessage } from '../../hooks';
import { IAppSetting } from '../../interfaces';
import GraphqlService from '../../services/graphql/GraphqlService';
import './CompleteRegisterPage.less';
import { IFormCompleteRegisterInput } from './interfaces';
import { notificationContext } from '../../contexts/NotificationContext';

export const CompleteRegisterPage = () => {
  const { t } = useContext(ContextApp);
  const { navigate } = useContext(NavContext);
  const [validateTokenIsValid, setValidateTokenIsValid] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>(false);
  const [passwordRegex, setPasswordRegex] = useState<IAppSetting>();
  const [userAlreadyValidated, setUserAlreadyValidated] =
    useState<boolean>(false);
  const { search } = useLocation();
  const queryStrings = new URLSearchParams(search);
  const token = queryStrings.get('token');
  const [form] = Form.useForm();
  const { Query, Mutation, customRequest } = GraphqlService();
  const { openNotification } = useContext(notificationContext);
  const { getErrorMessage } = CustomMessage();
  const history = useHistory();
  const validateValidationToken = async () => {
    /* Verificamos si el token es válido*/
    setLoading(true);
    try {
      const response = await customRequest({
        query: Query.verifyCompleteRegisterByEmailToken,
        variables: { token },
      });
      setValidateTokenIsValid(response.result);
    } catch (error: any) {
      if (error.status_code) {
        if (
          error.status_code ===
          EnumsValues.ErrorsStatusCode.userIsAlreadyValidatedCode
        ) {
          setUserAlreadyValidated(true);
          setLoading(false);
          openNotification({
            type: 'success',
            context: 'CompleteRegisterPage.resendValidateToken.2',
            msj: getErrorMessage(error),
          });
          history.push({
            pathname: '/login',
          });
        } else {
          openNotification({
            type: 'error',
            context: 'CompleteRegisterPage.resendValidateToken.2',
            msj: getErrorMessage(error),
          });
        }
      }

      setValidateTokenIsValid(false);
    }
    setLoading(false);
  };
  const getRegex = async (): Promise<void> => {
    try {
      const data = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingNames.PasswordRegex },
        },
      });
      setPasswordRegex(() => data);
    } catch (error) {
      openNotification({
        type: 'error',
        context: 'CompleteRegisterPage.getRegex.1',
        msj: getErrorMessage(error),
      });
    }
  };

  const compareToFirstPassword = (_: any, value: string) => {
    if (value && value !== form.getFieldValue('password')) {
      return Promise.reject(t('error.abm.repeatedPasswordNotMatching'));
    } else {
      return Promise.resolve();
    }
  };

  const completeRegister = async (values: IFormCompleteRegisterInput) => {
    setLoading(true);
    try {
      await customRequest({
        query: Mutation.completeRegister,
        variables: {
          token,
          password: values.password,
        },
      });
      openNotification({
        type: 'success',
        context: 'CompleteRegisterPage.completeRegister.1',
        msj: t('message.abm.userCreatedSuccessfully'),
      });
      navigate('/');
    } catch (error: any) {
      await validateValidationToken();
      openNotification({
        type: 'error',
        context: 'CompleteRegisterPage.completeRegister.2',
        msj: getErrorMessage(error),
      });
    }
    setLoading(false);
  };

  const resendValidateToken = async () => {
    setLoading(true);
    try {
      await customRequest({
        query: Mutation.resendValidateToken,
        variables: {
          expired_token: token,
        },
      });
      openNotification({
        type: 'info',
        context: 'CompleteRegisterPage.resendValidateToken.1',
        msj: t('message.resendTokenSuccess'),
      });
      navigate('/login');
    } catch (error: any) {
      openNotification({
        type: 'error',
        context: 'CompleteRegisterPage.resendValidateToken.2',
        msj: getErrorMessage(error),
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getRegex();
    validateValidationToken();
    if (token) validateValidationToken();
  }, []);

  if (!token) return <Redirect to={'/'} />;
  return (
    <div className="complete-register-page">
      {loading ? (
        <>
          <PageLoading />
        </>
      ) : validateTokenIsValid ? (
        <>
          <div className="complete-register-page__container-form">
            <Form
              onFinish={(value: IFormCompleteRegisterInput) => {
                completeRegister(value);
              }}
              form={form}
            >
              <h3 className="complete-register-page__container-form__title">
                {t('message.completeRegisterTitle')}
              </h3>
              <p className="complete-register-page__container-form__password">
                {t('message.completeRegisterDescription')}
              </p>
              <Form.Item
                hasFeedback
                name="password"
                className="complete-register-page-errorMsj"
                rules={[
                  {
                    required: true,
                    message: t('error.abm.enterPassword'),
                  },
                  {
                    validator(_, value) {
                      setTimeout(() => {
                        form.validateFields(['confirm']);
                      }, 50);
                      if (passwordRegex) {
                        let regex = new RegExp(
                          String(passwordRegex?.setting_value),
                          'g',
                        );
                        if (regex.test(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(t('error.abm.passwordRequirements')),
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input.Password placeholder={t('entity.password')} />
              </Form.Item>
              <p className="complete-register-page__container-form__password_requirements">
                <span>{passwordRegex?.description}</span>
              </p>
              <Form.Item
                hasFeedback
                name="confirm"
                className="complete-register-page-errorMsj"
                rules={[
                  {
                    required: true,
                    message: t('error.abm.repeatedPasswordRequired'),
                  },
                  {
                    validator: compareToFirstPassword,
                  },
                ]}
              >
                <Input.Password placeholder={t('entity.repeatPassword')} />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-completeRegister"
                >
                  {t('action.confirm')}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </>
      ) : userAlreadyValidated ? (
        <>
          <div>
            <p>{t('message.registerIsCompletedMessage')}</p>
            <Button type="primary" onClick={() => navigate('/login')}>
              {t('action.goToLogin')}
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="complete-register-page__container-token-is-expired">
            <h3 className="complete-register-page__container-token-is-expired__title">
              {t('message.completeRegisterTokenIsExpiredTitle')}
            </h3>
            <p>{t('message.tokenIsExpiredMessage')}</p>
            <div className="complete-register-page__container-token-is-expired__container-buttons">
              <Button
                className="complete-register-page__container-token-is-expired__container-buttons__button-resend-activation-link"
                onClick={() => resendValidateToken()}
              >
                {t('action.sendActivationMail')}
              </Button>
              <br />
              <Button
                className="complete-register-page__container-token-is-expired__container-buttons__button-go-to-login"
                type="link"
                onClick={() => navigate('/login')}
              >
                {t('action.goToLogin')}
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
