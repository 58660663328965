import { createContext } from "react";

export interface ITourContext {
    createTour: (section: number, wordIndex?: number) => void,
    
    createSeenTour: (section: number) => Promise<void>
}

export const tourContext = createContext<ITourContext>({
    createTour: () => {},
    createSeenTour: async () => {}
})