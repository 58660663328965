import { Header } from 'antd/lib/layout/layout';
import { TenantSelector } from '../../../components/common/TenantSelector/TenantSelector';
import './SecondaryHeader.less';

export const SecondaryHeader = () => {
  return (
    <Header className="secondary-header">
      <TenantSelector />
    </Header>
  );
};
