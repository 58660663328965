import { ExportableColumn } from './Exporter';

export const showCollapseRender = (columns: ExportableColumn<any>[]) => {
  if (
    columns.filter((column) => !column.hideInSearch && column.renderFormItem)
      .length === 1
  ) {
    return false;
  } else {
    return undefined;
  }
};
