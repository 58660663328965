import './ErrorCard.less';

interface IErrorCardProps {
  icon: JSX.Element;
  title: string;
  description: JSX.Element;
  description2?: string;
}

export const ErrorCard = (props: IErrorCardProps) => {
  const { icon, title, description, description2 } = props;

  return (
    <div className="ErrorCard-container">
      {icon}
      <div>
        <p className="ErrorCard-container--title">{title}</p>
        <p className="ErrorCard-container--description">{description}</p>
        {description2 && (
          <p className="ErrorCard-container--description">{description2}</p>
        )}
      </div>
    </div>
  );
};
