import { useContext, useEffect, useRef } from 'react';
import { ContextApp } from '../../../contexts/ContextApp';

const FreshSurvey = () => {
  const { user } = useContext(ContextApp);
  const surveyServScriptRef = useRef<HTMLScriptElement | null>(null);

  useEffect(() => {
    // Agregar el primer script solo si aún no ha sido agregado
    if (!surveyServScriptRef.current) {
      const script = document.createElement('script');
      script.src = 'https://cdn.surveyserv.com/widget.min.js';
      script.async = true;

      script.id = 'surveyServScript';

      document.head.appendChild(script);
      surveyServScriptRef.current = script;
    }

    return () => {
      // Limpiar el primer script al desmontar el componente
      const script = surveyServScriptRef.current;
      if (script && script.parentNode) {
        document.head.removeChild(script);
      }
    };
  }, []);

  useEffect(() => {
    const divElement = document.getElementById('freshworks-survey-widget');

    if (divElement) {
      divElement.parentNode?.removeChild(divElement);
    }

    if (user) {
      const freshSurveyScript = document.createElement('script');
      freshSurveyScript.text = `
            SurveyWidget.init({
            type: "default",
            delay: 4,
            include_unsubscribe: false,
            survey_id: "${process.env.REACT_APP_SURVEY_ID}",
            survey_collector_id: "${process.env.REACT_APP_SURVEY_COLLECTOR_ID}",
            contact: {
                "first_name": "${user.firstname}",
                "last_name": "${user.lastname}",
                "work_email": "${user.email}"
            }
            });
      `;
      
      freshSurveyScript.id = 'freshSurveyScript';

      document.head.appendChild(freshSurveyScript);

      // Limpiar ambos scripts al desmontar el componente o cuando el usuario cambia
      return () => {
        const script = document.getElementById('freshSurveyScript');
        if (script) {
          document.head.removeChild(script);
        }
      };
    }
  }, [user]);

 
  return null;

};

export default FreshSurvey;
