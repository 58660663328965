export const RECEIPT_TYPES = `
query receiptTypes(
    $filter: FilterReceiptTypeInput!
    $orderBy: ReceiptTypeOrderInput
    $skip: Int
    $take: Int
  ) {
    receiptTypes(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take) {
      __typename
      ... on ReceiptType {
        id
        code
        name
        description
        active
        translation_key
        its,
        uts,
        dts
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
`;