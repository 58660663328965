export const CREATE_DECISION_ITEM = `
mutation createDecisionItem(
    $input: CreateDecisionItemInput!,
    $inputProduct: CreateProductInput
  ){
  createDecisionItem(
    input: $input,
    inputProduct: $inputProduct
  ){
    __typename
    ...on DecisionItem{
      id      
    }
    ...on ResultError{
      status_code
      message
    }
  }
}

`;

export const UPDATE_DECISION_ITEM = `
mutation updateDecisionItem(
    $input: UpdateDecisionItemInput!,
    $inputProduct: CreateProductInput
  ){
  updateDecisionItem(
    input:$input,
    inputProduct: $inputProduct
  ){
    __typename
    ...on DecisionItem{
      id
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

export const DELETE_DECISION_ITEM = `
mutation deleteDecisionItem(
    $id:  Int!
  ){
  deleteDecisionItem(
    id: $id
  ){
    __typename
    ...on DecisionItem{
      id
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

export const IMPORT_PRODUCTS = `
mutation importProducts(
  $file: Upload!,
  $input:  ImportProductsInput!,
){
  importProducts(
    file: $file,
    input: $input,
  ){
    __typename
    ... on BooleanResult{
      result
    }
    ... on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;
