import { useState, useRef, RefObject, useLayoutEffect } from 'react';

interface UseIntersectionObserverOptions
  extends Omit<IntersectionObserverInit, 'threshold'> {
  root?: Element | null;
  threshold?: number | number[];
}

function useIntersectionObserver<T extends Element>(
  options: UseIntersectionObserverOptions = {},
): [RefObject<T>, boolean] | [RefObject<T>, boolean[]] {
  const { threshold = 0, ...restOptions } = options;
  const isMultiThreshold = Array.isArray(threshold) && threshold.length > 0;
  const [isIntersecting, setIsIntersecting] = useState<boolean | boolean[]>(
    Array.isArray(threshold) && threshold.length > 0
      ? threshold.map(() => true)
      : true,
  );
  const elementRef = useRef<T>(null);

  useLayoutEffect(() => {
    const element = elementRef.current;
    if (!element) return;

    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (isMultiThreshold) {
          setIsIntersecting(
            (threshold as number[]).map((t) => entry.intersectionRatio >= t),
          );
        } else {
          setIsIntersecting(entry.isIntersecting);
        }
      },
      { ...restOptions, threshold },
    );

    observer.observe(element);

    return () => {
      observer.unobserve(element);
    };
  }, [threshold, restOptions]);

  return [elementRef, isIntersecting];
}

export default useIntersectionObserver;
