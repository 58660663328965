export const PRODUCTS_BY_DESCRIPTION = `
query productsByDescription($input: ProductsByDescriptionInput!){
  productsByDescription(input: $input){
    __typename
    ... on Product{
      code
      description
      tenant_id
      id
    }
    ... on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const PRODUCTS = `
query products(
  $filter: FilterProductInput,
  $orderBy:  ProductOrderInput,
  $skip: Int,
  $take: Int,
  $searchText: String,
) {
  products (
    filter: $filter,
    orderBy: $orderBy
    skip: $skip
    take: $take
    searchText: $searchText
  ) {
    __typename
    ... on Product {
      id
      code
      description
      tenant_id
      isInUse
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const PRODUCT_COUNT = `
query productCount(
  $filter: FilterProductInput,
  $orderBy:  ProductOrderInput,
  $searchText: String,
) {
  productCount (
    filter: $filter,
    orderBy: $orderBy
    searchText: $searchText
  ) {
    __typename
    ... on Count {
      count
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
