import { useCallback, useContext } from 'react';
import { BillingForm } from '../../components/common/BillingForm/BillingForm';
import { ContextApp } from '../../contexts/ContextApp';
import useNotification from '../../hooks/useNotification';
import GraphqlService from '../../services/graphql/GraphqlService';
import './BillingFormPage.less';
import { CustomMessage } from '../../hooks';
import { NavContext } from '@ionic/react';
import { ContextWizard } from '../../contexts/ContextWizard';

interface IUpdateTenantValues {
  address: string;
  billing_name: string;
  name: string;
  tax_number: string;
  tax_type_id: string;
}

interface IUpdateVariables {
  id: number;
  input: {
    name: string;
    tenant_picture_id?: number;
    billing_name?: string;
    address?: string;
    tax_type_id?: number;
    tax_number?: string;
    tenant_status_id?: number;
    deleteCurrentImage?: boolean;
  };
}

export const BillingFormPage = () => {
  const { checkAuth, selectedTenantId, getTenantsAssociatedWithUser } =
    useContext(ContextApp);
  const { refreshNotification } = useNotification({});
  const { customRequest, Mutation } = GraphqlService();
  const { navigate } = useContext(NavContext);
  const { prevStepWizard } = useContext(ContextWizard);
  const {
    messageError,
    messageUpdateSuccess,
    messageUpdateError,
    getErrorMessage,
  } = CustomMessage();

  const updateTenant = async (
    value: IUpdateTenantValues,
    tenant_id: number,
  ) => {
    if (!value || !tenant_id) return;

    const variables: IUpdateVariables = {
      id: tenant_id,
      input: {
        address: value.address || undefined,
        billing_name: value.billing_name || undefined,
        name: value.name,
        tax_number: value.tax_number || undefined,
        tax_type_id: Number(value.tax_type_id) || undefined,
      },
    };

    try {
      await customRequest({
        mutation: Mutation.updateTenant,
        variables,
      });
      messageUpdateSuccess({
        context: 'BillingFormPage.updateTenant.1',
      });
      getTenantsAssociatedWithUser();
    } catch (error: any) {
      if (error.status_code && error.message) {
        return messageError({
          context: 'BillingFormPage.updateTenant.2',
          message: getErrorMessage(error),
        });
      }
      messageUpdateError({ context: 'BillingFormPage.updateTenant.3' });
    }
  };

  const onFinish = useCallback(
    async (value: IUpdateTenantValues) => {
      selectedTenantId && (await updateTenant(value, selectedTenantId));
      checkAuth(selectedTenantId);
      refreshNotification();
    },
    [selectedTenantId],
  );

  const onReturn = useCallback(() => {
    prevStepWizard();
  }, []);

  const onOmit = () => {
    checkAuth(selectedTenantId);
    navigate('/');
  };

  return (
    <BillingForm onFinish={onFinish} onOmit={onOmit} onReturn={onReturn} />
  );
};
