import {
  ReviewerConfiguration,
  UpdateReviewerConfigurationTypeTenantInput,
} from '../../../interfaces/ReviewerConfiguration';

export const getReviewerConfigurationInput = (
  config: ReviewerConfiguration,
  tenantId: number,
  documentTypeId: number,
): UpdateReviewerConfigurationTypeTenantInput => {
  const [tenantConfiguration] = config.reviewer_configuration_type_tenant;

  return {
    ...tenantConfiguration,
    tenant_id: tenantId,
    reviewer_configuration_type_id: documentTypeId,
  };
};
