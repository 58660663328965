import { useContext, useEffect, useState, useCallback } from 'react';
import { ICurrencyType } from '../../interfaces/Receipt';
import { Form, Select } from 'antd';
import { ContextApp } from '../../contexts/ContextApp';
import {
  ISettingProperties,
  ISettingToUpdate,
} from '../../interfaces/TenantSetting';
import { Query } from '../../services/graphql/query';
import GraphqlService from '../../services/graphql/GraphqlService';
import { ISettingType } from '../../interfaces/TenantSetting';
import './TenantSettingCurrencySelect.less';

type CurrencySelectProps = {
  settingsArray: ISettingToUpdate[];
  handleChange: (id: any, value: any) => void;
};

export const useSettingCurrencySelect = (props: CurrencySelectProps) => {
  const { settingsArray, handleChange } = props;
  const [currencies, setCurrencies] = useState<ICurrencyType[]>([]);
  const { selectedTenantId } = useContext(ContextApp);
  const { customRequest } = GraphqlService();

  useEffect(() => {
    if (selectedTenantId) {
      getCurrencyTypes(selectedTenantId);
    }
  }, [selectedTenantId]);

  const getCurrencyTypes = async (tenant_id: number) => {
    const data: ICurrencyType[] = await customRequest({
      query: Query.currencyTypes,
      variables: {
        filter: {
          tenant_id: tenant_id,
        },
      },
    });

    setCurrencies(data);
  };

  const CurrencySelect = useCallback(
    (props: ISettingProperties) => {
      const valueSelected = settingsArray
        .find((item) => item.id === props.id)
        ?.value?.toString();

      return (
        <Form.Item className='margin-component' required key={props.key || undefined}>
          {!currencies.length || !settingsArray.length ? (
            <Select loading />
          ) : (
            <Select
              className='select-currency'
              onChange={(e) => handleChange(props.id, e)}
              value={valueSelected}
              getPopupContainer={(node) => node.parentNode}
              options={currencies.map((item: any) => ({
                label: item.name,
                value: item.code,
              }))}
            ></Select>
          )}
        </Form.Item>
      );
    },
    [currencies, settingsArray],
  );

  const currencyTypeSetting: ISettingType = {
    render: useCallback(
      (props) => {
        return <CurrencySelect {...props} />;
      },
      [currencies, settingsArray],
    ),
  };

  return { currencyTypeSetting, currencies };
};

export default useSettingCurrencySelect;
