import { useState, useLayoutEffect } from 'react';

const useGetElementDimensions = (selector: string) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    const updateDimensions = () => {
      const destinationElement = document.querySelector(selector);
      if (destinationElement) {
        setDimensions({
          width: destinationElement.clientWidth,
          height: destinationElement.clientHeight,
        });
      } else {
        console.warn(`Element with selector ${selector} not found`);
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => window.removeEventListener('resize', updateDimensions);
  }, [selector]);

  return dimensions;
};

export default useGetElementDimensions;
