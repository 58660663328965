import { Dispatch, useContext, useMemo } from 'react';
import { ContextApp } from '../../../contexts/ContextApp';
import './AutomationControl.less';
import { Radio } from 'antd';
import {
  ReviewerConfiguration,
  ReviewerConfigurationTypeOption,
} from '../../../interfaces/ReviewerConfiguration';
import { ReviewerConfigurationAction } from '../interface';
import { ReviewerConfigurationActionTypes } from '../enum';
import AutomationControlCard from './AutomationControlCard/AutomationControlCard';

interface AutomationControlProps {
  reviewerConfiguration: ReviewerConfiguration | null;
  dispatch: Dispatch<ReviewerConfigurationAction>;
  reviewerConfigurationOptions: ReviewerConfigurationTypeOption[] | [];
}

const AutomationControl = (props: AutomationControlProps) => {
  const { reviewerConfiguration, dispatch, reviewerConfigurationOptions } =
    props;
  const { t } = useContext(ContextApp);

  const handleRadioChange = (optionId: number) => {
    dispatch({
      type: ReviewerConfigurationActionTypes.UPDATE_REVIEWER_CONFIGURATION,
      payload: optionId,
    });
  };

  const getCurrentValue = useMemo(() => {
    if (reviewerConfiguration) {
      const [tenantConfiguration] =
        reviewerConfiguration.reviewer_configuration_type_tenant;

      if (tenantConfiguration) {
        return tenantConfiguration.reviewer_configuration_type_option_id;
      }
      return reviewerConfiguration.reviewer_configuration_type_option_id;
    }
  }, [reviewerConfiguration]);

  return (
    reviewerConfiguration && (
      <div className="automation-control">
        <div className="automation-control__header">
          <h4 className="automation-control__title">
            {t('settingsPage.reviewerConfiguration.title')}
          </h4>
          <p className="automation-control__text">
            {t('settingsPage.reviewerConfiguration.paragraph')}
          </p>
        </div>
        <div className="automation-control__options">
          {getCurrentValue && reviewerConfigurationOptions.length > 0 && (
            <Radio.Group
              className="automation-control__radio-group"
              onChange={(e) => handleRadioChange(e.target.value)}
              value={getCurrentValue}
            >
              {(
                reviewerConfigurationOptions as ReviewerConfigurationTypeOption[]
              ).map((option) => (
                <AutomationControlCard
                  key={option.id}
                  value={option.id}
                  currentValue={getCurrentValue}
                  translation={option.translation_key}
                  isRecommended={
                    reviewerConfiguration.reviewer_configuration_type_option_id ===
                    option.id
                  }
                  onClick={() => handleRadioChange(option.id)}
                >
                  <Radio value={option.id} />
                </AutomationControlCard>
              ))}
            </Radio.Group>
          )}
        </div>
      </div>
    )
  );
};

export default AutomationControl;
