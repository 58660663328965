export const INTEGRATIONS = `
query integrations(
  $filter: FilterIntegrationInput
  $orderBy: IntegrationOrderInput
  $skip: Int
  $take: Int
) {
  integrations(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take) {
    __typename
    ... on Integration {
      id
      name
      description
      logo
      integration_status_id
      integration_status {
        name
      }
      order
      code
      allow_integration_finishes_in_finished
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
