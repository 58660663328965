import { PageLoading } from '@ant-design/pro-layout';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import Modal from 'antd/lib/modal/Modal';
import { useContext, useState } from 'react';
import { NotificationCategory } from '../../../interfaces/NotificationCategory';
import { UserNotificationType } from '../../../interfaces/UserNotificationType';
import './style.less';
import { ContextApp } from '../../../contexts/ContextApp';

interface IModalNotificationsProps {
  onCancel: () => void;
  onOk: (add: number[], remove: number[]) => void;
  titleModal: string;
  loading?: boolean;
  userNotificationTypes: UserNotificationType[];
  notificationCategories: NotificationCategory[];
}

export default function ModalNotifications(props: IModalNotificationsProps) {
  const {
    onCancel,
    titleModal,
    onOk,
    loading,
    notificationCategories,
    userNotificationTypes,
  } = {
    ...props,
  };
  const {t} = useContext(ContextApp);
  const getDefaultIds = () => {
    return userNotificationTypes.map(
      ({ notification_type_id }) => notification_type_id,
    );
  };

  const [checkedIds, setCheckedIds] = useState<number[]>(getDefaultIds());

  const handleCheck = (id: number) => {
    if (!checkedIds.includes(id)) {
      setCheckedIds((curr) => [...curr, id]);
    } else {
      setCheckedIds((curr) => curr.filter((v) => v !== id));
    }
  };

  return (
    <>
      <Modal
      style={{minWidth: 448}}
        open
        title={titleModal}
        destroyOnClose
        onCancel={() => onCancel()}
        onOk={() => {
          const defaultIds = getDefaultIds();
          onOk(
            checkedIds.filter((v) => !defaultIds.includes(v)),
            defaultIds.filter((v) => !checkedIds.includes(v)),
          );
        }}
        okText={t('action.save')}
        cancelText={t('action.cancel')}
        okButtonProps={{className:'save-btn'}}
      >
        {loading ? (
          <PageLoading />
        ) : (
          <div className="notificationCategories">
            {notificationCategories.map((category) => (
              <div>
                <div>{category.name}</div>
                <div className="notificationTypes">
                  {category.notification_type?.map((type) => (
                    <Checkbox
                      key={type.id}
                      onChange={() => handleCheck(type.id)}
                      checked={checkedIds.includes(type.id)}
                    >
                      {type.name}
                    </Checkbox>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </Modal>
    </>
  );
}
