import { PageLoading } from '@ant-design/pro-layout';
import { isPlatform } from '@ionic/react';
import { Tooltip } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import Modal from 'antd/lib/modal/Modal';
import { useContext, useEffect, useRef, useState } from 'react';
import './style.less';
import { ContextApp } from '../../../contexts/ContextApp';
/**
 * Las opciones se renderizan con una función en el componenete renderLabel.
 * Ejemplo:
 * renderLabel={renderModalConfigureLabel}
 * 
 * dentro del componente padre (PumpOrder en este caso). Defino la función de renderizado
 * const renderModalConfigureLabel: IModalConfigureMultipleProps<IOperator>["renderLabel"] =
    (entity: IOperator) => {
      return `${entity.lastname}, ${entity.firstname} (${entity.employee_code})`;
    };
 */
export interface IModalConfigureMultipleProps<T> {
  data: any;
  modalVisible: boolean;
  checks: (any & {
    id: number;
  })[];
  onCancel: () => void;
  onOk: (id: number, add: number[], remove: number[]) => Promise<void>;
  titleModal: string;
  renderLabel: (entity: T) => React.ReactNode;
  loading?: boolean;
  exclusiveChecks?: (any & {
    id: number;
  })[];
  exclusiveAlertMessage?: string;
}

export default function ModalConfigureMultiple<T>(
  props: IModalConfigureMultipleProps<T>,
) {
  const {t} = useContext(ContextApp);
  const [originalIds, setOriginalIds] = useState<number[]>([]);
  const [checkedIds, setCheckedIds] = useState<number[]>([]);
  const [unCheckedIds, setUncheckedIds] = useState<number[]>([]);
  const [previousCheckedIds, setPreviousCheckedIds] = useState<number[]>([]);
  const {
    modalVisible,
    onCancel,
    checks,
    titleModal,
    data,
    onOk,
    renderLabel,
    loading,
    exclusiveChecks,
    exclusiveAlertMessage,
  } = {
    ...props,
  };
  const preventDataChange = useRef<boolean>(false);
  const cleanUpState = () => {
    setCheckedIds(() => []);
    setUncheckedIds(() => []);
    setOriginalIds(() => []);
  };

  useEffect(() => {
    if (!preventDataChange.current) {
      cleanUpState();
      if (modalVisible) {
        data[1].map((checkId: number) =>
          setCheckedIds((oldCheckedIds) => [...oldCheckedIds, checkId]),
        );
        data[1].map((checkId: number) =>
          setOriginalIds((oldCheckedIds) => [...oldCheckedIds, checkId]),
        );
      }
    }
  }, [data, modalVisible]);

  const handleCheck = (id: number) => {
    if (
      exclusiveChecks &&
      exclusiveChecks.some((exclusiveCheck: any) => exclusiveCheck.id === id)
    ) {
      const exclusiveChecksIds = exclusiveChecks?.map((item) => item.id);
      // Se hizo click en un check exclusivo.
      // Si el check estaba desactivado, activarlo y guardar los checks previos si estos no incluian otro check exclusivo.
      if (!checkedIds.some((item) => exclusiveChecksIds.includes(item))) {
        setPreviousCheckedIds(checkedIds);
      }
      // Si el check estaba activado, desactivar el check y renovar los checks guardados.
      if (!checkedIds.includes(id)) {
        setCheckedIds([id]);
        setUncheckedIds(
          checks.map((item) => item.id).filter((itemId) => itemId !== id),
        );
      } else {
        // Si el check estaba activado, desactivarlo y activar los checks previos
        setCheckedIds(previousCheckedIds);
        setUncheckedIds(
          checks
            .map((item) => item.id)
            .filter((itemId) => !previousCheckedIds.includes(itemId)),
        );
      }
    } else {
      if (
        checkedIds.some((item) =>
          exclusiveChecks
            ?.map((exclusiveCheck) => exclusiveCheck.id)
            .includes(item),
        ) &&
        !checkedIds.some((checkedId) => checkedId === id)
      ) {
        return;
      }
      if (checkedIds.some((checkedId) => checkedId === id)) {
        setCheckedIds(() => checkedIds.filter((checkedId) => checkedId !== id));
        setUncheckedIds(() => [...unCheckedIds, id]);
      } else {
        setUncheckedIds(() =>
          unCheckedIds.filter((unCheckedId) => unCheckedId !== id),
        );
        setCheckedIds(() => [...checkedIds, id]);
      }
    }
  };

  const remove = (originalIds: number[], unchecked: number[]) => {
    return unchecked.filter((value) => originalIds.includes(value));
  };

  const add = (originalIds: number[], checked: number[]) => {
    return checked.filter((item) => originalIds.indexOf(item) < 0);
  };

  return (
    <>
      <Modal
      style={{minWidth: '448px'}}
        title={titleModal}
        destroyOnClose
        open={modalVisible}
        onCancel={() => onCancel()}
        onOk={() => {
          preventDataChange.current = true;
          onOk(
            data[0],
            add(originalIds, checkedIds),
            remove(originalIds, unCheckedIds),
          ).finally(() => {
            preventDataChange.current = false;
          });
        }}
        okText={t('action.save')}
        cancelText={t('action.cancel')}
        okButtonProps={{className:'save-btn'}}
      >
        {loading ? (
          <PageLoading />
        ) : (
          <>
            <div className="containerChecks">
              {checks.map((check) => (
                <Checkbox
                  key={check.id}
                  onChange={() => handleCheck(check.id)}
                  checked={checkedIds.some(
                    (checkedId) => checkedId === check.id,
                  )}
                >
                  <Tooltip
                    key="permissions_description_tooltip"
                    trigger={isPlatform('desktop') ? 'hover' : 'focus'}
                    title={check.description}
                  >
                    {renderLabel(check)}
                  </Tooltip>
                </Checkbox>
              ))}
            </div>
            {exclusiveAlertMessage &&
            checkedIds.some((id) =>
              exclusiveChecks?.map((item) => item.id).includes(id),
            ) ? (
              <div className="modal-footer-alert">{exclusiveAlertMessage}</div>
            ) : null}
          </>
        )}
      </Modal>
    </>
  );
}
