export const RECEIPTS_DETAILS = `
query receiptsDetails($filter: FilterReceiptDetailInput, $orderBy: ReceiptDetailOrderInput){
  receiptsDetails(filter: $filter, orderBy:$orderBy){
    __typename
    ... on ReceiptDetail{
      id
      code
      description
      quantity
      unit_price
      discount_price
      discount_percentage
      unit
      product_id
      cost_center_id
      product{
        id
        description
        code
        tenant_id
      }
      receipt_detail_item_type_id
      receipt_detail_item_type{
          id
          name
          description
      }
      subtotal
      subtotal_without_tax
      receipt_line_discount_total
      receipt_line_tax_total
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
