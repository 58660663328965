export const SCHEMAS = `
query schemas(
    $filter: FilterSchemaInput!
    $orderBy: SchemaOrderInput
    $skip: Int
    $take: Int
  ) {
    schemas(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take) {
      __typename
      ... on  Schema {
        id
        its
        name
        generated_from_schema_id
        translation_key
        tenant_id
        generated_from_schema_id
        document_type {
          id
          name
          schema_id
          valid_undel
          its
          document_type_tenant{
            id
            tenant_id
            document_type_id
          }
        }
        schema_field {
          id
          schema_id
          field_id
          field {
            id
            code
            name
            data_index
            is_list
            field_type_id
            translation_key
            schema_id
            field_type {
              id
              code
              name
              translation_key
              field_type_status_id
            }
          }
        }
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
`;
