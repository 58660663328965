import { Dispatch, useContext, useState } from 'react';
import { ContextApp } from '../../../contexts/ContextApp';
import { BusinessValidation } from '../../../interfaces/BusinessValidation';
import CustomTable from '../../../components/common/CustomTable/CustomTable';
import useGetElementDimensions from '../../../hooks/useGetElementDimensions';
import { ColumnsType } from 'antd/lib/table';
import { Switch } from 'antd';
import { BusinessSettingsAction } from '../interface';
import { BusinessSettingsActionTypes } from '../enum';
import { ResetSettings } from '../../../components/Icons/ResetSettings';
import BusinessSettingRestoreModal from './BusinessTableSwitch/BusinessSettingRestoreModal/BusinessSettingRestoreModal';
import './BusinessSettingsTable.less';

interface BusinessSettingsTableProps {
  businessSettings: BusinessValidation[];
  dispatch: Dispatch<BusinessSettingsAction>;
}

const BusinessSettingsTable = (props: BusinessSettingsTableProps) => {
  const { businessSettings, dispatch } = props;
  const { t, selectedTenantId } = useContext(ContextApp);

  const [restoreModalStatus, setRestoreModalStatus] = useState(false);
  const [currentSetting, setCurrentSetting] =
    useState<BusinessValidation | null>(null);

  const { height: headerTopHeight } = useGetElementDimensions(
    '.settings-header-toggle',
  );

  const handleSwitchToggle = (checked: boolean, record: BusinessValidation) => {
    if (!selectedTenantId) return;

    dispatch({
      type: BusinessSettingsActionTypes.TOGGLE_SWITCH,
      switchStatus: checked,
      payload: record,
      tenantId: selectedTenantId,
    });
  };

  const getSwitchStatus = (record: BusinessValidation) => {
    if (
      record.document_type_business_validation_tenant &&
      record.document_type_business_validation_tenant.length > 0
    ) {
      return record.document_type_business_validation_tenant[0].value === true;
    }
    return record.default_value === true;
  };

  const isDefaultValue = (record: BusinessValidation) => {
    return !(
      record.document_type_business_validation_tenant &&
      record.document_type_business_validation_tenant.length > 0
    );
  };

  const columns: ColumnsType<BusinessValidation> = [
    {
      title: t('settingsPage.table.description'),
      dataIndex: ['translation_key'],
      render: (translationKey: string) => (
        <span
          dangerouslySetInnerHTML={{ __html: t(translationKey as never) }}
        />
      ),
    },
    {
      key: 'required',
      width: '10%',
      align: 'center',
      title: t('settingsPage.table.active'),
      render: (record: BusinessValidation) => {
        return (
          <Switch
            checked={getSwitchStatus(record)}
            onChange={(checked) => handleSwitchToggle(checked, record)}
          />
        );
      },
    },
    {
      key: 'actions',
      width: '10%',
      align: 'center',
      render: (record: BusinessValidation) => (
        <div className="business-settings__icon-container">
          <ResetSettings
            onClick={() => {
              setCurrentSetting(record);
              setRestoreModalStatus(true);
            }}
            className={`business-settings__icon ${
              isDefaultValue(record) ? 'icon-disabled' : ''
            }`}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="business-settings">
        <div className="business-settings__header">
          <h4 className="business-settings__header__title">
            {t('settingsPage.businessSettings.title')}
          </h4>
          <p className="business-settings__header__text">
            {t('settingsPage.businessSettings.paragraph')}
          </p>
        </div>

        <CustomTable
          className="business-settings__table"
          columns={columns}
          dataSource={businessSettings || []}
          rowKey="id"
          sticky={{ offsetHeader: headerTopHeight }}
          pagination={false}
        />
      </div>
      {currentSetting && (
        <BusinessSettingRestoreModal
          modalVisible={restoreModalStatus}
          setModalVisible={setRestoreModalStatus}
          currentSetting={currentSetting}
          onRestoreToDefault={() => {
            dispatch({
              type: BusinessSettingsActionTypes.RESET_SETTING_TO_DEFAULT,
              payload: currentSetting,
            });
            setRestoreModalStatus(false);
          }}
          onRestoreToPreviousState={() => {
            dispatch({
              type: BusinessSettingsActionTypes.RESET_SETTING_TO_PREVIOUS_STATE,
              payload: currentSetting,
            });
            setRestoreModalStatus(false);
          }}
        />
      )}
    </>
  );
};

export default BusinessSettingsTable;
