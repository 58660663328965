import { PageLoading } from '@ant-design/pro-layout';
import { NavContext } from '@ionic/react';
import { Suspense, useContext, useEffect } from 'react';
import { renderRoutes } from 'react-router-config';
import ExceptionManager from '../../components/common/ExceptionManager';
import { ContextApp } from '../../contexts/ContextApp';
import { useCurrentRoute } from '../../hooks';
import './index.less';
import { Col, Row } from 'antd';

const PublicLayout = ({ route }: any) => {
  const { user, t } = useContext(ContextApp);
  const { navigate } = useContext(NavContext);
  const { leafRoute } = useCurrentRoute();
  useEffect(() => {
    if (user && leafRoute && leafRoute?.path !== '/') navigate('/');
  }, [user, leafRoute]);

  return (
    <div className='PublicLayout'>
      <Row className='index-row'>
          <Col md={12} className="leftSide" >
            {/* Aca se puede crear elementos para todas las paginas*/}
            <div className='PublicLayoutHeader'>
              <img className="PublicLayoutTitle" src="assets/icon/logo_braulio.png" alt="Braulio" />
            </div>
            <p className="braulio-settings-info" >{t('loginPage.info')}</p>

            <div className="features-container">
              <div className="features-container_row">
                <div className="features-container_item">
                  <div className="features-container_icon">
                    <img src="assets/icon/savings.svg" alt="savings" />
                  </div>
                  <div className="features-container_description">
                    <h2>{t('loginPage.savings')}</h2>
                    <p>{t('loginPage.savingsInfo')}</p>
                  </div>
                </div>
                <div className="features-container_item">
                  <div className="features-container_icon">
                    <img src="assets/icon/flash_on.svg" alt="flash_on" />
                  </div>
                  <div className="features-container_description">
                    <h2>{t('loginPage.velocity')}</h2>
                    <p>{t('loginPage.valocityInfo')}</p>
                  </div>
                </div>
              </div>
              <div className="features-container_row">
                <div className="features-container_item">
                  <div className="features-container_icon">
                    <img src="assets/icon/sell.svg" alt="sell" />
                  </div>
                  <div className="features-container_description">
                    <h2>{t('loginPage.sell')}</h2>
                    <p>{t('loginPage.sellInfo')}</p>
                  </div>
                </div>
                <div className="features-container_item">
                  <div className="features-container_icon">
                    <img src="assets/icon/neurology.svg" alt="neurology" />
                  </div>
                  <div className="features-container_description">
                    <h2>{t('loginPage.presition')}</h2>
                    <p>{t('loginPage.presitionInfo')}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="braulio-empresas_banner">
              <div className="braulio-empresas_banner-text"> 
                <p><strong>{t('loginPage.braulioEnterprise')}</strong></p>
                <p>{t('loginPage.braulioEnterpriseInfo')}</p>
              </div> 
             <div>
             <img className="img-braulio-empresa" src="assets/braulio-empresas.png" alt="braulio-empresas" />
             </div>
            </div>
          </Col>
          <Col md={12} className='rightSide'>
            {/* child routes won't render without this */}
            <div className="PublicLayoutContent">
              <Suspense fallback={<PageLoading />}>
                <ExceptionManager>
                  {renderRoutes(route.routes)}
                </ExceptionManager>{' '}
              </Suspense>
            </div>
            <div className="PublicLayoutFooter"></div>
          </Col>

      </Row>
    </div>
  );
};

export default PublicLayout;
