export const RECEIPT_LETTERS = `
query receiptLetters(
    $filter: FilterReceiptLetterInput!
    $orderBy: ReceiptLetterOrderInput
    $skip: Int
    $take: Int
  ) {
    receiptLetters(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take) {
      __typename
      ... on ReceiptLetter {
        id
        code
        name
        description
        active
        translation_key
        its,
        uts,
        dts
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
`;