export const GET_LOGS = `
query getLogs($input: FilterLogInput!, $skip: Int, $take: Int){
  getLogs(input: $input, skip: $skip, take: $take){
    __typename
    ... on Log{
      id
      its
      created_by
      action_id
      tenant_id
      receipt_detail_id
      receipt_header_id
      log_action{
        name
        translation_key
        color
      }
      createdBy{
        firstname
        lastname
      }
      description
    }
    ... on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;
export const EXPORT_LOGS = `
query exportLogs (
  $format: ExportFormat!, 
  $dataIndexes: LogDataIndexInput!, 
  $filter: FilterLogInput!, 
  $translationData: String!){  
    exportLogs(
    format:$format , 
    dataIndexes: $dataIndexes, 
    filter: $filter , 
    translationData: $translationData){
    __typename
    ... on ExportResult{
      file
      mimetype
      encoding
      filename
    }
    ... on ResultError{
      status_code
      message
      message_translation_key
    }
  }
} `;


export const LAST_LOG_BY_VALIDATING_RECEIPT = `
query lastLogByValidatingReceipt(
  $receipt_header_id: Int!
) {
  lastLogByValidatingReceipt(
    receipt_header_id: $receipt_header_id
  ) {
    __typename
    ... on Log{
      id
      tenant_id
      receipt_header_id,
      action_id
      description
    }
    ... on ResultError{
      status_code
      message
      message_translation_key
    }
  }
} `;
