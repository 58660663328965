import { useContext } from 'react';
import ButtonPrimary from '../../components/common/Buttons/ButtonPrimary';
import { ContextApp } from '../../contexts/ContextApp';
import { Form } from 'antd';
import { Tools } from '../../shared';

interface ReceiptValidateButtonProps {
  onButtonClick?: () => void;
  hasErrorLogs: boolean;
  shouldBeVisible: boolean;
}
const ReceiptValidateButton = (props: ReceiptValidateButtonProps) => {
  const { onButtonClick, hasErrorLogs, shouldBeVisible } = props;
  const { t } = useContext(ContextApp);
  const form = Form.useFormInstance();

  const handleButtonClick = () => {
    if (onButtonClick && typeof onButtonClick === 'function') {
      onButtonClick();
    }
    form.setFieldValue('validateOnly', true);
    form.submit();
  };

  return shouldBeVisible ? (
    <ButtonPrimary onClick={handleButtonClick} disabled={!hasErrorLogs}>
      {Tools.capitalize(t('action.validate'))}
    </ButtonPrimary>
  ) : null;
};

export default ReceiptValidateButton;
