import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import esTranslations from '../locales/es/translations.json';
// TODO: Descomentar para incluir traducciones en inglés
// import enTranslations from '../locales/en/translations.json';

require('dotenv').config();

export const defaultLanguage = process.env.REACT_APP_FALLBACK_LNG || 'es';
const debugMode = process.env.REACT_APP_I18N_DEBUG === 'TRUE';

const languageDetector = new LanguageDetector();
const detectLanguageFunction = languageDetector.detect;
languageDetector.detect = (detectionOrder) => {
  const detectedLanguage = detectLanguageFunction.call(
    languageDetector, // override internal this
    detectionOrder,
  );
  if (Array.isArray(detectedLanguage)) {
    return detectedLanguage.map((detection) => detection.split('-')[0]);
  } else {
    return detectedLanguage;
  }
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(languageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: defaultLanguage,

    load: 'languageOnly',
    returnNull: true,
    debug: debugMode,
    resources: {
      // TODO: Descomentar para incluir traducciones en inglés
      // en: {
      //   translation: enTranslations.translation,
      // },
      es: {
        translation: esTranslations.translation,
      },
    },
  });

i18n.on('languageChanged', () => {});

export default i18n;
