export const CREATE_INVITATION = `
mutation createInvitation($email: String!, $role_id: Int!, $tenant_id: Int!) {
  createInvitation(email: $email, role_id: $role_id, tenant_id: $tenant_id) {
    __typename
    ... on Invitation {
      id
      its
      uts
      dts
      uid
      tenant_id
      role_id
      user_id
      tenant{
        name
      }
      role{
        name
      }
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const INVITATION_RESPONSE = `
mutation invitationResponse($invitation_uid: String!, $accept: Boolean!) {
  invitationResponse(invitation_uid: $invitation_uid, accept: $accept) {
    __typename
    ... on Invitation {
      id
      its
      uts
      dts
      uid
      tenant_id
      role_id
      user_id
      tenant{
        name
      }
      role{
        name
      }
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;
