import {
  BusinessValidation,
  UpdateBusinessValidationInput,
} from '../../../interfaces/BusinessValidation';

export const getCustomBusinessSettings = (
  businessSettings: BusinessValidation[],
): UpdateBusinessValidationInput[] => {
  return businessSettings
    .filter((businessSetting) => {
      return (
        businessSetting.document_type_business_validation_tenant &&
        businessSetting.document_type_business_validation_tenant.length > 0
      );
    })
    .map((businessSetting) => {
      return {
        document_type_business_validation_id: businessSetting.id,
        value:
          businessSetting.document_type_business_validation_tenant![0].value,
        tenant_id:
          businessSetting.document_type_business_validation_tenant![0]
            .tenant_id,
      };
    });
};
