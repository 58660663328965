import { useEffect, useMemo, useState } from 'react';
import GraphqlService from '../../../../../services/graphql/GraphqlService';
import { EnumsValues } from '../../../../../enums/EnumsValues';
import { IPage } from '../../../../../interfaces/Extraction';

interface IGetReceiptPdfProps {
  id?: number;
}

const useGetReceiptPdf = (props: IGetReceiptPdfProps) => {
  const { id } = props;
  const { Query, customRequest } = GraphqlService();
  const [pdfBase64, setPdfBase64] = useState<string>('');
  const [pages, setPages] = useState<IPage[]>([]);
  const [loadingPages, setLoadingPages] = useState<boolean>(false);
  const [loadingPdf, setLoadingPdf] = useState<boolean>(false);

  const getReceiptPdf = async (receipt_id: number) => {
    try {
      setLoadingPdf(true);
      const data = await customRequest({
        query: Query.getReceiptHeaderPdf,
        variables: {
          id: Number(receipt_id),
        },
      });
      setPdfBase64(data?.file);
    } catch (error) {
      //intentional
    } finally {
      setLoadingPdf(false);
    }
  };

  const getExtractionLog = async (receipt_id: number) => {
    try {
      setLoadingPages(true);
      const data = await customRequest({
        query: Query.extractionLog,
        variables: {
          receipt_header_id: receipt_id,
        },
      });
      setPages(data.json_response);
    } catch (error) {
      //intentional
    } finally {
      setLoadingPages(false);
    }
  };

  useEffect(() => {
    if (id) {
      getReceiptPdf(id);
      getExtractionLog(id);
    }
  }, []);

  const isPdfLoading = useMemo(() => {
    return loadingPages || loadingPdf;
  }, [loadingPages, loadingPdf]);

  return {
    pdfBase64: EnumsValues.FileDataType.PdfBase64 + pdfBase64,
    pages,
    isPdfLoading,
  };
};
export default useGetReceiptPdf;
