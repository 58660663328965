import moment from 'moment';

/**
 * @description Usando la libreria MomentJS
 *
 * https://momentjs.com/
 */
const MomentJS = () => {};

// @TODO: agregar en el .env y sacarlo de aca
export const DEFAULT_FORMAT_DATE = 'DD-MM-YYYY';
export const DEFAULT_FORMAT_DATE_2 = 'DD/MM/YYYY';
export const ALTERNATIVE_FORMAT_DATE = 'DDMMYYYY';
export const FORMAT_DATE_TIME_1 = 'DD-MM-YYYY HH:mm';
export const FORMAT_DATE_TIME_2 = 'YYYY-MM-DD HH:mm:ss';
export const FORMAT_DATE_TIME_3 = 'YYYY-MM-DD';
export const FORMAT_DATE_TIME_4 = 'DD/MM/YYYY';
export const FORMAT_DATE_TIME_5 = 'DD/MM/YYYY HH:mm';
export const FORMAT_DATE_TIME_6 = 'DD/MM/YYYY HH:mm:ss';
export const FORMAT_DATE_TIME_7 = 'DD-MM-YYYY HH:mm:ss:SS';

export const DEFAULT_TIMEZONE = 'America/Buenos_aires';
/**
 * Devuelve el momento actual
 * @param {String} set_format
 * @returns {String}
 */
const getEventMoment = (event: {
  date: any;
  format?: string;
  utc?: boolean;
}): moment.Moment | null => {
  if (event.date) {
    const nan: any = Number(event.date);
    const datetime = !(nan.toString() === 'NaN') ? nan : event.date;
    if (!(nan.toString() === 'NaN')) {
      return event.utc ? moment.utc(datetime) : moment(datetime);
    }
    return event.utc
      ? moment.utc(datetime, event.format || DEFAULT_FORMAT_DATE)
      : moment(datetime, event.format || DEFAULT_FORMAT_DATE);
  }
  return null;
};

/**
 * Devuelve el momento actual
 * @param {String} set_format
 * @returns {String}
 */
MomentJS.getMoment = (event?: { date: any; format?: string }) => {
  return event ? moment(event) : moment();
};

/**
 * Devuelve la fecha en el formato por default de un moment
 * @param {moment.Moment} event_moment
 * @returns {String}
 */
MomentJS.getDefaultFormat = (event_moment: moment.Moment) => {
  return event_moment.format(DEFAULT_FORMAT_DATE);
};

/**
 * Devuelve el momento actual
 * @param {String} set_format
 * @returns {String}
 */
MomentJS.currentMoment = (set_format?: any) => {
  return set_format ? moment().format(set_format) : moment().format();
};

/**
 * Devuelve el momento especificado
 * @param {String} event.date
 * @param {String} set_format
 * @returns {String}
 */
MomentJS.specificMoment = (
  event: { date: any; format?: string; utc?: boolean },
  set_format?: any,
) => {
  if (set_format) {
    return getEventMoment(event)?.format(set_format);
  }
  return getEventMoment(event)?.format();
};

/**
 * Devuelve el momento en el formato por default
 * @returns {String}
 */
MomentJS.momentDefaultFormat = (event?: {
  date: any;
  format?: string;
  utc?: boolean;
}) => {
  if (event) {
    return getEventMoment(event)?.format(DEFAULT_FORMAT_DATE);
  }
  return moment().format(DEFAULT_FORMAT_DATE);
};

/**
 * Devuelve el momento en el formato alternativo
 * @returns {String}
 */
MomentJS.momentAlternativeFormat = (event?: {
  date: any;
  format?: string;
  utc?: boolean;
}) => {
  if (event) {
    return getEventMoment(event)?.format(ALTERNATIVE_FORMAT_DATE);
  }
  return moment().format(ALTERNATIVE_FORMAT_DATE);
};
/**
 * Devuelve el momento en el formato ISO
 * @returns {String}
 */
MomentJS.momentToISOString = (event?: { date: any; format?: string }) => {
  return event ? getEventMoment(event)?.toISOString() : moment().toISOString();
};

/**
 * Devuelve el momento en el formato unix
 * @returns {String}
 */
MomentJS.momentUnix = (event?: { date: any; format?: string }) => {
  if (event) {
    const res = getEventMoment({ ...event, utc: true });
    return res ? res.unix() * 1000 : 0;
  }
  return moment().unix() * 1000;
};

/**
 * Devuelve el momento actual en UTC (Coordinated Universal Time)
 * Ej: 2020-07-21T09:29:34-03:00 -> 2020-07-21T12:29:34Z
 * @param {String} event_format
 * @returns {String}
 */
MomentJS.getMomentUTC = (
  event?: { date: any; format?: string },
  set_format?: any,
) => {
  const momUnix = MomentJS.momentUnix(event);
  if (set_format) {
    return momUnix
      ? moment.utc(momUnix).format(set_format)
      : moment.utc().format(set_format);
  }
  return momUnix
    ? moment.utc(momUnix).toISOString()
    : moment.utc().toISOString();
};

MomentJS.getDateRangeToUnixCalendar = (dates: any[]) => {
  const init = MomentJS.getDefaultFormat(dates[0]);
  const end = MomentJS.getDefaultFormat(dates[1]);
  const initUnix = MomentJS.momentUnix({ date: init });
  const endUnix = MomentJS.momentUnix({
    date: `${end} 23:59:59`,
    format: 'DD-MM-YYYY HH:mm:ss',
  });
  return [initUnix, endUnix];
};

export { MomentJS };
