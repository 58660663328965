import { ReportHandler } from 'web-vitals';

const reportWebVitals = async (onPerfEntry?: ReportHandler) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    try {
      const res = await import('web-vitals');
      res.getCLS(onPerfEntry);
      res.getFID(onPerfEntry);
      res.getFCP(onPerfEntry);
      res.getLCP(onPerfEntry);
      res.getTTFB(onPerfEntry);
    } catch (error) {
      console.error(error);
    }
  }
};

export default reportWebVitals;
