import { Form, InputNumber } from 'antd';
import { ISettingToUpdate, ISettingType } from '../../interfaces/TenantSetting';

type Props = {
  settingsArray: ISettingToUpdate[];
  handleChange: (id: any, value: any) => void;
};

export const useSettingNumberInput = (props: Props) => {
  const { settingsArray, handleChange } = props;

  const numberTypeSetting: ISettingType = {
    render: (props) => {
      const valueSelected = settingsArray
        .find((item) => item.id === props.id)
        ?.value?.toString();
      return (
        <Form.Item className='margin-component' valuePropName="checked">
          <InputNumber
            value={valueSelected}
            onChange={(e) => handleChange(props.id, e)}
            type='number'
            controls={false}
            onWheel={() => {return}} /* Evita el cambio del valor con la rueda del mouse */
          />
        </Form.Item>
      );
    },
  };

  return { numberTypeSetting };
};