import { ColumnsType } from 'antd/lib/table';
import { Settings } from '../../../components/Icons/Settings';
import { ResetSettings } from '../../../components/Icons/ResetSettings';
import './FieldSettingsTable.less';
import { Dispatch, useContext, useState } from 'react';
import { ContextApp } from '../../../contexts/ContextApp';
import CustomTable from '../../../components/common/CustomTable/CustomTable';
import {
  FieldAttributeTenant,
  Schema,
  SchemaField,
} from '../../../interfaces/ValidationSchema';
import { EnumsValues, FieldAttributeTags } from '../../../enums/EnumsValues';
import useGetElementDimensions from '../../../hooks/useGetElementDimensions';
import FieldRestoreModal from './FieldRestoreModal/FieldRestoreModal';
import { SchemaAction } from '../interface';
import { FieldAttributeTenantValue, SchemaActionTypes } from '../enum';
import FieldConfigurationModal from './FieldConfigurationModal/FieldConfigurationModal';
import { Switch } from 'antd';

interface FieldSettingsTableProps {
  schema: Schema;
  dispatch: Dispatch<SchemaAction>;
}

const FieldSettingsTable = (props: FieldSettingsTableProps) => {
  const { schema, dispatch } = props;
  const { t, selectedTenantId } = useContext(ContextApp);

  const [fieldRestoreModalStatus, setFieldRestoreModalStatus] = useState(false);
  const [fieldConfigurationModalStatus, setFieldConfigurationModalStatus] =
    useState(false);
  const [currentField, setCurrentField] = useState<SchemaField>(
    {} as SchemaField,
  );
  const [currentAttribute, setCurrentAttribute] =
    useState<FieldAttributeTenant>({} as FieldAttributeTenant);

  const { height: headerTopHeight } = useGetElementDimensions(
    '.settings-header-toggle',
  );
  const shouldBeRendered = (record: SchemaField) => {
    return record.field?.field_type?.code !== EnumsValues.FieldTypes.OBJECT;
  };

  const isDefaultValue = (record: SchemaField) =>
    !record.field?.field_attribute_tenant?.some(
      (attribute) => attribute.tenant_id !== null,
    );

  const getFieldAttributeTenant = (
    record: SchemaField,
    validationType: FieldAttributeTags,
  ) => {
    const matchedFields = record?.field?.field_attribute_tenant?.filter(
      (attribute) => {
        return (
          attribute.attribute_type.tags &&
          attribute.attribute_type.tags.includes(validationType)
        );
      },
    );

    return (
      matchedFields?.find((attribute) => attribute.tenant_id) ||
      matchedFields?.[0]
    );
  };

  const getSwitchStatus = (record: FieldAttributeTenant) => {
    return record.value === FieldAttributeTenantValue.TRUE || false;
  };

  const handleSwitchToggle = (
    checked: boolean,
    fieldAttributeTenant: FieldAttributeTenant,
  ) => {
    if (!selectedTenantId) return false;
    dispatch({
      type: SchemaActionTypes.TOGGLE_SWITCH,
      switchStatus: checked,
      payload: fieldAttributeTenant,
      tenantId: selectedTenantId,
    });
  };

  const columns: ColumnsType<SchemaField> = [
    {
      title: t('settingsPage.table.description'),
      dataIndex: ['field', 'translation_key'],
      render: (translationKey: string) => t(translationKey as never),
    },
    {
      align: 'center',
      width: '10%',
      title: t('settingsPage.table.validateData'),
      render: (record: SchemaField) => {
        const fieldAttributeTenant = getFieldAttributeTenant(
          record,
          EnumsValues.FieldAttributeTags.DATA_VALIDATION,
        );

        return (
          fieldAttributeTenant &&
          shouldBeRendered(record) && (
            <Switch
              disabled={fieldAttributeTenant.readonly}
              checked={getSwitchStatus(fieldAttributeTenant)}
              onChange={(checked: boolean) =>
                handleSwitchToggle(checked, fieldAttributeTenant)
              }
            />
          )
        );
      },
    },
    {
      key: 'required',
      width: '10%',
      align: 'center',
      title: t('settingsPage.table.required'),
      render: (record: SchemaField) => {
        const fieldAttributeTenant = getFieldAttributeTenant(
          record,
          EnumsValues.FieldAttributeTags.REQUIRED,
        );

        return (
          fieldAttributeTenant &&
          shouldBeRendered(record) && (
            <Switch
              disabled={fieldAttributeTenant.readonly}
              checked={getSwitchStatus(fieldAttributeTenant)}
              onChange={(checked: boolean) =>
                handleSwitchToggle(checked, fieldAttributeTenant)
              }
            />
          )
        );
      },
    },
    {
      key: 'actions',
      width: '10%',
      align: 'center',
      render: (record: SchemaField) => {
        const fieldAttributeTenant = getFieldAttributeTenant(
          record,
          EnumsValues.FieldAttributeTags.DATA_VALIDATION,
        );

        return (
          fieldAttributeTenant &&
          shouldBeRendered(record) && (
            <div className="data-validation__actions">
              <div className="data-validation__icon-container">
                {fieldAttributeTenant &&
                  fieldAttributeTenant.attribute_type.code ===
                    'validateRegex' && (
                    <Settings
                      className="data-validation__icon"
                      onClick={() => {
                        setCurrentField(record);
                        setCurrentAttribute(fieldAttributeTenant);
                        setFieldConfigurationModalStatus(true);
                      }}
                    />
                  )}
              </div>
              <div className="data-validation__icon-container">
                <ResetSettings
                  className={'data-validation__icon icon-disabled'}
                  {...(!isDefaultValue(record) &&
                    !fieldAttributeTenant.readonly && {
                      onClick: () => {
                        setCurrentField(record);
                        setFieldRestoreModalStatus(true);
                      },
                      className: `data-validation__icon`,
                    })}
                />
              </div>
            </div>
          )
        );
      },
    },
  ];

  const handleFieldRestoreToDefault = () => {
    dispatch({
      type: SchemaActionTypes.RESET_FIELD_TO_DEFAULT,
      payload: currentField,
    });
    setFieldRestoreModalStatus(false);
  };

  const handleFieldRestoreToPreviousState = () => {
    dispatch({
      type: SchemaActionTypes.RESET_FIELD_TO_PREVIOUS_STATE,
      payload: currentField,
    });
    setFieldRestoreModalStatus(false);
  };

  // TODO: se puede sacar el header en un componente compartido
  return (
    <>
      <div className="data-validation">
        <div className="data-validation__header">
          <h4 className="data-validation__header__title">
            {t('settingsPage.fieldValidationSettings.title')}
          </h4>
          <p className="data-validation__header__text">
            {t('settingsPage.fieldValidationSettings.paragraph1')}
          </p>
        </div>
        <CustomTable
          className="data-validation__table"
          columns={columns}
          dataSource={schema?.schema_field || []}
          rowKey="id"
          sticky={{ offsetHeader: headerTopHeight }}
          pagination={false}
          rowClassName={(record) =>
            (record.field?.field_type?.code === EnumsValues.FieldTypes.OBJECT &&
              'group-row') ||
            ''
          }
          expandable={{
            indentSize: 20,
            expandRowByClick: true,
            childrenColumnName: 'schema_field',
          }}
        />
      </div>
      <FieldRestoreModal
        modalVisible={fieldRestoreModalStatus}
        setModalVisible={setFieldRestoreModalStatus}
        onRestoreToPreviousState={handleFieldRestoreToPreviousState}
        onRestoreToDefault={handleFieldRestoreToDefault}
        currentField={currentField}
      />
      <FieldConfigurationModal
        modalVisible={fieldConfigurationModalStatus}
        setModalVisible={setFieldConfigurationModalStatus}
        currentAttribute={currentAttribute}
        currentField={currentField}
      />
    </>
  );
};

export default FieldSettingsTable;
