import { Form, Switch } from 'antd';
import { ISettingToUpdate, ISettingType } from '../../interfaces/TenantSetting';

type SwitchProps = {
  settingsArray: ISettingToUpdate[];
  handleChange: (id: any, value: any) => void;
};

export const useSettingSwitch = (props: SwitchProps) => {
  const { settingsArray, handleChange } = props;

  const booleanTypeSetting: ISettingType = {
    render: (props) => {
      const valueSelected = settingsArray
        .find((item) => item.id === props.id)
        ?.value?.toString();
      return (
        <Form.Item className='margin-component' valuePropName="checked">
          <Switch
            defaultChecked={
              valueSelected && valueSelected === 'true' ? true : false
            }
            onChange={(e) => handleChange(props.id, e)}
          />
        </Form.Item>
      );
    },
  };

  return { booleanTypeSetting };
};
